<template>
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M40 80C32.0888 80 24.3552 77.654 17.7772 73.2588C11.1992 68.8635 6.07234 62.6164 3.04484 55.3073C0.0173315 47.9983 -0.774802 39.9556 0.768607 32.1964C2.31202 24.4372 6.12164 17.3098 11.7157 11.7157C17.3098 6.12164 24.4372 2.31202 32.1964 0.768607C39.9556 -0.774802 47.9983 0.0173315 55.3073 3.04484C62.6164 6.07234 68.8635 11.1992 73.2588 17.7772C77.654 24.3552 80 32.0888 80 40C79.9817 50.6031 75.7616 60.7666 68.2641 68.2641C60.7666 75.7616 50.6031 79.9817 40 80ZM40 2.18184C32.5203 2.18184 25.2085 4.39983 18.9894 8.55535C12.7702 12.7109 7.92294 18.6173 5.06057 25.5276C2.1982 32.438 1.44928 40.042 2.9085 47.378C4.36772 54.714 7.96956 61.4525 13.2585 66.7415C18.5475 72.0305 25.286 75.6323 32.622 77.0915C39.9581 78.5507 47.562 77.8018 54.4724 74.9394C61.3828 72.0771 67.2892 67.2298 71.4447 61.0107C75.6002 54.7915 77.8182 47.4797 77.8182 40C77.8182 29.97 73.8338 20.3508 66.7415 13.2585C59.6492 6.16624 50.03 2.18184 40 2.18184Z"
      fill="#33353A"
    />
    <path
      d="M40.5 53.8329C40.875 53.8329 41.25 53.5267 41.25 53.0162V18.7146C41.25 18.3063 40.875 18 40.5 18C40.0312 18 39.75 18.3063 39.75 18.7146V53.0162C39.75 53.5267 40.0312 53.8329 40.5 53.8329ZM40.5 58.7332C39.6562 58.7332 39 59.5499 39 60.3666C39 61.2854 39.6562 62 40.5 62C41.25 62 42 61.2854 42 60.3666C42 59.5499 41.25 58.7332 40.5 58.7332Z"
      fill="url(#paint0_linear_1534_14449)"
    />
    <defs>
      <linearGradient id="paint0_linear_1534_14449" x1="40.5" y1="18" x2="40.5" y2="62" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFB800" />
        <stop offset="1" stop-color="#EBA400" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "DIconPopupAlert",
};
</script>
