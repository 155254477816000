<template>
  <div class="d-icon-processing-bar-flex">
    <svg version="1.1" :width="width" :height="height" xmlns="http://www.w3.org/2000/svg">
      <rect x="0" y="0" width="32%" height="100%" ry="50%" :fill="customColor.grey">
        <animate
          attributeName="fill"
          :dur="seconds.duration"
          :values="customColor.animation"
          repeatCount="indefinite"
          begin="0"
        ></animate>
      </rect>
      <rect x="34%" y="0" width="32%" height="100%" ry="50%" :fill="customColor.grey">
        <animate
          attributeName="fill"
          :dur="seconds.duration"
          :values="customColor.animation"
          repeatCount="indefinite"
          :begin="seconds.begin"
        ></animate>
      </rect>
      <rect x="68%" y="0" width="32%" height="100%" ry="50%" :fill="customColor.grey">
        <animate
          attributeName="fill"
          :dur="seconds.duration"
          :values="customColor.animation"
          repeatCount="indefinite"
          :begin="seconds.begin * 2"
        ></animate>
      </rect>
    </svg>
  </div>
</template>

<script>
export default {
  name: "DIconProcessingBarFlex",
  props: {
    height: {
      type: String,
      default: "8px",
    },
    width: {
      type: String,
      default: "240px",
    },
    light: {
      type: Boolean,
      default: false,
    },
    customGrey: {
      type: String,
      default: "#17181a",
    },
    speed: {
      type: String,
      default: "normal",
    },
  },
  computed: {
    customColor() {
      const mainBlue = "#4689f3";
      const mainGrey = this.light ? "#b6b6b6" : this.customGrey;
      return {
        grey: mainGrey,
        animation: [mainGrey, mainGrey, mainGrey, mainBlue, mainBlue, mainBlue].join(";"),
      };
    },
    seconds() {
      const map = {
        slow: { duration: 6, begin: 1 },
        normal: { duration: 3, begin: 0.5 },
        fast: { duration: 1, begin: 0.5 },
      };
      return map[this.speed];
    },
  },
};
</script>
<style scoped>
.d-icon-processing-bar-flex {
  position: relative;
}
</style>
