<template>
  <!-- <div ref="dVideoDiv" class="d-video">
    <div class="test-test">
      {{videoHeight}} {{videoWidth}} currentTime:{{currentTime}}
      <br>
      videoDuration{{videoDuration}}
      <br>
      buffered:{{bufferedTime}}
      <br>
    </div>
    <div
      class="video-div fullscreen-center box-shadow"
      :style="{height:videoHeight+'px', width:videoWidth+'px'}"
    >
      <video
        ref="videoPlayer"
        class="video-player"
        preload
        :poster="poster"
        :src="videoSource"
        :muted="isVoiceOver"
        @timeupdate="timeUpdateEvent()"
        @ended="restartVideo()"
        @loadeddata="setUpPlayer()"
        @waiting="showSpinner()"
        @canplay="hideSpinner()"
        @progress="progressVideo()"
      ></video>
      <div v-if="isVoiceOver" class="for-voice-over">
        <div class="loading-text-div" :class=" isLoading? 'show-loading-text' :'hide-loading-text'">
          <div
            class="fullscreen-center loading-text"
          >Please wait while we load your video so you can record your voiceover…</div>
        </div>
        <div class="readonly-time-bar">
          <div class="time-bar-white"></div>
          <div class="time-bar-blue" :style="{width:getBarPercentage(currentTime)}"></div>
          <div class="time-bar-buffered" :style="{width:getBarPercentage(bufferedTime)}"></div>
          <span class="time-num">
            <span class="current-time-num">{{getTimeFormat(currentTime)}}</span>
            / {{getTimeFormat(videoDuration)}}
          </span>
          <div class="time-bar-darker"></div>
        </div>
      </div>

      <div
        v-if="!isVoiceOver"
        class="play-btn-div"
        :class="{'show-play-btn-div' : !isPlaying}"
        @click="playOrPauseVideo()"
      >
        <div class="fullscreen-center">
          <v-progress-circular v-if="showingSpinner" :size="70" color="white" indeterminate></v-progress-circular>
          <button v-else>
            <icon-base color="white" width="96" height="96">
              <d-icon-play v-if="!isPlaying"/>
              <d-icon-pause v-else/>
            </icon-base>
          </button>
        </div>
      </div>
    </div>
  </div>-->
  <div class="d-video-player">
    <!-- <div class="test-test">
      currentStep: {{currentStep}}
      steps: {{steps}}
    </div>-->
    <!-- <d-image class="video-poster" :aspect-ratio="16/9" :src="poster"></d-image> -->
    <div class="play-btn-div">
      <div class="fullscreen-center">
        <v-progress-circular v-if="showingSpinner" :size="48" color="white" indeterminate></v-progress-circular>
        <button v-else @click="playOrPauseVideo()">
          <icon-base color="white" width="64" height="64">
            <d-icon-play v-if="!isPlaying" />
            <d-icon-pause v-else />
          </icon-base>
        </button>
      </div>
    </div>
    <div class="video-player-div">
      <d-video-secure
        autoplay
        ref="videoPlayer"
        class="video-player"
        preload="auto"
        :poster="poster"
        :signedSrc="getVideoMetadata(currentStep)"
        @ended="autoPlayNextVideo()"
        @loadeddata="setUpPlayer()"
        @waiting="showSpinner()"
        @canplay="hideSpinner()"
        :crossorigin="'anonymous'"
      ></d-video-secure>
      <!-- @ended="restartVideo()" -->
      <!--
        @timeupdate="timeUpdateEvent()"
      @progress="progressVideo()"-->
    </div>
  </div>
</template>
<script>
import IconBase from "../IconBase.vue";
import DIconPlay from "../icons/DIconPlay.vue";
import DIconPause from "../icons/DIconPause.vue";
import DImage from "../ui_components/DImage.vue";
import DVideoSecure from "../ui_components/DVideoSecure.vue";

export default {
  name: "DVideoPlayer",
  props: {
    isThumbnailVideo: Boolean,
    videoSource: String,
    poster: String,
    steps: Array,
    // isVoiceOver: Boolean
  },
  watch: {
    // isLoading: function() {
    //   if (this.isVoiceOver && this.isLoading) {
    //     this.$emit("loadingStarted");
    //   } else {
    //     this.$emit("loadingFinished");
    //   }
    // }
  },
  mounted() {
    // this.preloadFullVideo();
    // this.$nextTick(() => {
    //   window.addEventListener("resize", this.getVideoSize);
    // });
    // this.getVideoSize();
  },
  beforeDestroy() {
    // window.removeEventListener("resize", this.getVideoSize);
  },
  data() {
    return {
      isLoading: false,
      videoHeight: 0,
      videoWidth: 0,
      isPlaying: false,
      showingSpinner: false,
      videoDuration: 0,
      currentTime: 0,
      bufferedTime: 0,
      showingSpinner: false,
      currentStep: 0,
    };
  },
  components: {
    IconBase,
    DIconPlay,
    DIconPause,
    DImage,
    DVideoSecure,
  },
  computed: {},
  methods: {
    autoPlayNextVideo() {
      if (this.isPlaying && this.currentStep < this.steps.length - 1) {
        this.currentStep++;
        this.$nextTick(() => this.$refs.videoPlayer.play());
      } else {
        this.currentStep = 0;
        this.pauseVideo();
      }
    },
    preloadFullVideo() {
      const self = this;
      const video = this.$refs.videoPlayer;
      video.onloadedmetadata = (event) => {
        if (video.duration === Infinity) {
          video.currentTime = Number.MAX_SAFE_INTEGER;
          video.ontimeupdate = () => {
            video.ontimeupdate = null;
            self.videoDuration = video.duration;
          };
        } else {
          self.videoDuration = video.duration;
        }
      };
      this.isLoading = true;
      const url = this.videoSource;
      // const self = this;
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "arraybuffer";
      xhr.onload = function (oEvent) {
        const blob = new Blob([oEvent.target.response], {
          type: "video/mp4",
        });
        video.src = URL.createObjectURL(blob);
      };
      xhr.onprogress = function (oEvent) {
        if (oEvent.lengthComputable) {
          let percentComplete = oEvent.loaded / oEvent.total;
          if (percentComplete == 1) {
            self.isLoading = false;
          }
        }
      };
      xhr.send();
    },
    showSpinner() {
      this.showingSpinner = true;
    },
    hideSpinner() {
      this.showingSpinner = false;
    },
    progressVideo() {
      const video = this.$refs.videoPlayer;
      if (this.$refs.videoPlayer) {
        this.bufferedTime = this.$refs.videoPlayer.buffered.end(0);
      }
    },
    getVideoSize(event) {
      const height = this.$refs.dVideoDiv.offsetHeight;
      const width = this.$refs.dVideoDiv.offsetWidth;
      if (height / 9 < width / 16) {
        this.videoHeight = height;
        this.videoWidth = parseInt((height / 9) * 16);
      } else {
        this.videoHeight = parseInt((width / 16) * 9);
        this.videoWidth = width;
      }
    },
    timeUpdateEvent() {
      this.currentTime = this.$refs.videoPlayer.currentTime;
    },
    restartVideo() {
      this.pauseVideo();
      this.$refs.videoPlayer.currentTime = 0;
    },
    playOrPauseVideo() {
      if (this.isPlaying) {
        this.pauseVideo();
      } else {
        this.playVideo();
      }
    },
    pauseVideo() {
      if (this.$refs.videoPlayer) {
        this.isPlaying = false;
        this.$refs.videoPlayer.pause();
      }
    },
    playVideo() {
      if (this.$refs.videoPlayer) {
        this.isPlaying = true;
        this.$refs.videoPlayer.play();
      }
    },
    getBarPercentage(time) {
      if (this.$refs.videoPlayer) {
        return (time / this.videoDuration) * 100 + "%";
      } else {
        return "0%";
      }
    },
    setUpPlayer() {
      const videoPlayer = this.$refs.videoPlayer;
      if (videoPlayer) {
        videoPlayer.currentTime = 0;
        this.currentTime = 0;
      }
    },
    getTimeFormat(time) {
      time = parseFloat(time).toFixed(0);
      const minutes = Math.floor(time / 60);
      const seconds = time - minutes * 60;
      const hours = Math.floor(time / 3600);
      time = time - hours * 3600;
      function str_pad_left(string, pad, length) {
        return (new Array(length + 1).join(pad) + string).slice(-length);
      }
      const finalTime = str_pad_left(minutes, "0", 2) + ":" + str_pad_left(seconds, "0", 2);
      return finalTime;
    },
    getVideoMetadata(currentStep) {
      const metadata = {
        type: "step",
        rez: "hls",
        id: this.steps[this.currentStep].id,
        notTranscodedVideo: this.steps[this.currentStep].video,
      };

      if (this.steps[this.currentStep].videos) {
        const rez = Object.keys(this.steps[this.currentStep].videos)[0];
        metadata.rez = rez;
      }

      return metadata;
    },
  },
};
</script>

<style scoped>
.d-video-player {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  /* background-color: rgba(71, 7, 71, 0.5); */
  background-color: #0c0c0e;
}
.video-poster {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
}

.video-player-div {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
}
/* ///// old*/
.video-div {
  position: relative;
  margin: 0 auto;
  background-color: #0c0c0e;
}
.fullscreen-center {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.video-player {
  object-fit: contain;
  height: 100%;
  max-width: 100%;
  background-color: #0c0c0e;
  outline: none;
}
.box-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
}
.for-voice-over {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  pointer-events: none;
}
.loading-text-div {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  padding: 0 24px;
  z-index: 1;
  opacity: 0;
}
.show-loading-text {
  opacity: 1;
  pointer-events: auto;
  /* transition-delay: 1s; */
  transition: 0.3s;
}
.hide-loading-text {
  opacity: 0;
  pointer-events: none;
  transition-delay: 0.4s;
  transition: 0.6s;
}
.loading-text {
  color: #ffffff;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
}
.readonly-time-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  pointer-events: none;
}
.time-num {
  position: absolute;
  bottom: 8px;
  left: 8px;
  color: #959595;
  font-family: Roboto;
  font-size: 10px;
  line-height: 12px;
}
.current-time-num {
  color: white;
}
.time-bar-white {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  width: 100%;
  opacity: 0.2;
  background-color: #ffffff;
}
.time-bar-blue {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  z-index: 1;
  background-color: #4689f3;
}
.time-bar-buffered {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  width: 100%;
  /* opacity: 0.2; */
  z-index: 0;
  background-color: #ffffff;
  opacity: 0.6;
  /* background-color: aquamarine;  */
}
.time-bar-darker {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 24px;
  width: 100%;
  z-index: -1;
  opacity: 0.8;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), 50%, rgba(0, 0, 0, 0));
}
.play-btn-div {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
  /* background-color: aqua;
  top: -400px; */
}
.show-play-btn-div {
  opacity: 1;
}
.video-div:hover .video-btn-pause {
  visibility: visible;
}
.video-div:hover .play-btn-div {
  opacity: 1;
}
/* .test-test {
  position: absolute;
  top: -100px;
  background-color: antiquewhite;
  color: black;
} */
.hide-part {
  opacity: 0;
}
</style>
