export function formatTimeSince({ timestampObj, vueInstanceRef }) {
  const timeStamp = new Date(timestampObj.seconds * 1000);
  const now = new Date();
  return timeDifference({
    current: now,
    previous: timeStamp,
    vueInstanceRef,
  });
}

export function timeDifference({ current, previous, vueInstanceRef }) {
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;
  const elapsed = current - previous;
  if (elapsed < 0) {
    return vueInstanceRef.$tc("explorer.secondsAgo", 0);
  } else if (elapsed < msPerMinute) {
    const secondNum = Math.round(elapsed / 1000);
    return vueInstanceRef.$tc("explorer.secondsAgo", secondNum);
  } else if (elapsed < msPerHour) {
    const minuteNum = Math.round(elapsed / msPerMinute);
    return vueInstanceRef.$tc("explorer.minutesAgo", minuteNum);
  } else if (elapsed < msPerDay) {
    const hourNum = Math.round(elapsed / msPerHour);
    return vueInstanceRef.$tc("explorer.hoursAgo", hourNum);
  } else if (elapsed < msPerMonth) {
    const dayNum = Math.round(elapsed / msPerDay);
    return vueInstanceRef.$tc("explorer.daysAgo", dayNum);
  } else if (elapsed < msPerYear) {
    const monthNum = Math.round(elapsed / msPerMonth);
    return vueInstanceRef.$tc("explorer.monthsAgo", monthNum);
  } else {
    const yearNum = Math.round(elapsed / msPerYear);
    return vueInstanceRef.$tc("explorer.yearsAgo", yearNum);
  }
}

export function formatIsoStrTimeSince({ isoStr, vueInstanceRef, simplify = false }) {
  const date = new Date(isoStr);
  const seconds = Math.floor(date.getTime() / 1000);
  const timeStamp = new Date(seconds * 1000);
  const now = new Date();
  if (simplify) {
    return timeDifferenceSimplify({
      current: now,
      previous: timeStamp,
      vueInstanceRef,
    });
  }
  return timeDifference({
    current: now,
    previous: timeStamp,
    vueInstanceRef,
  });
}

export function timeDifferenceSimplify({ current, previous, vueInstanceRef }) {
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;
  const elapsed = current - previous;
  if (elapsed < 0) {
    return vueInstanceRef.$tc("explorer.sAgo", 0);
  } else if (elapsed < msPerMinute) {
    const secondNum = Math.round(elapsed / 1000);
    return vueInstanceRef.$tc("explorer.sAgo", secondNum);
  } else if (elapsed < msPerHour) {
    const minuteNum = Math.round(elapsed / msPerMinute);
    return vueInstanceRef.$tc("explorer.minAgo", minuteNum);
  } else if (elapsed < msPerDay) {
    const hourNum = Math.round(elapsed / msPerHour);
    return vueInstanceRef.$tc("explorer.hAgo", hourNum);
  } else if (elapsed < msPerMonth) {
    const dayNum = Math.round(elapsed / msPerDay);
    return vueInstanceRef.$tc("explorer.dAgo", dayNum);
  } else if (elapsed < msPerYear) {
    const monthNum = Math.round(elapsed / msPerMonth);
    return vueInstanceRef.$tc("explorer.mAgo", monthNum);
  } else {
    const yearNum = Math.round(elapsed / msPerYear);
    return vueInstanceRef.$tc("explorer.yAgo", yearNum);
  }
}
