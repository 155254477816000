<template>
  <div class="btn-reset-to-original" v-on="$listeners" :class="{ disabled: !isClickable }">
    <span class="icon-reset">
      <icon-base :width="20" :height="20" :viewBox="'0 0.5 20 20'">
        <d-icon-reset></d-icon-reset>
      </icon-base>
    </span>
    <span class="text-reset">{{ $t("builder.resetToOriginal") }}</span>
  </div>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import DIconReset from "@/components/icons/editor/DIconReset";

export default {
  name: "BuilderResetButton",
  props: {
    isClickable: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    IconBase,
    DIconReset,
  },
};
</script>

<style scoped>
.btn-reset-to-original {
  border-radius: 25px;
  border: 1px solid currentColor;
  padding: 5.5px 22px 5.5px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
}

.icon-reset {
  margin-right: 6px;
}

.btn-reset-to-original {
  color: #4689f3;
}

.btn-reset-to-original:not(.disabled) .text-reset {
  color: white;
}

.btn-reset-to-original.disabled {
  color: #949494;
}

.disabled {
  pointer-events: none;
}
</style>
