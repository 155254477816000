<template lang="html">
  <section class="mixin-delete"></section>
</template>

<script>
import MixinDB from "./MixinDB.vue";
import { deleteWorkflow } from "../server/api-server";
import { loggingError } from "@/server/error-log-server.js";
export default {
  name: "mixin-delete",
  props: [],
  mixins: [MixinDB],
  mounted() {},
  data() {
    return {};
  },
  methods: {
    async deleteWorkflow(workflowId) {
      try {
        const { ok, errorMessage } = await deleteWorkflow(workflowId);
        if (!ok) {
          console.log(errorMessage);
          return ok;
        }
        const findIndex = this.$store.state.workflows.findIndex((workflow) => workflow.id === workflowId);
        if (findIndex) {
          this.$store.state.workflows.splice(findIndex, 1);
        }
        return ok;
      } catch (error) {
        console.log(error);
        loggingError(error);
      }
    },
  },
};
</script>
