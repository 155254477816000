var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"annotationTimeline"},[_c('div',{staticClass:"annotationTimeline__play-switch",on:{"click":function($event){_vm.$emit('handleVideoPlayOrPause')}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', _vm.playSwitchIcon]}})],1),_vm._v(" "),_c('div',{staticClass:"annotationTimeline__control-bar-block"},[_c('div',{ref:"timeline",staticClass:"annotationTimeline__display-inner-container"},[_c('div',{staticClass:"annotationTimeline__line",on:{"click":function($event){$event.stopPropagation();return _vm.handleCurrentTimeChange($event)}}},[_vm._l((_vm.pointGroupByPosition),function(pointGroup,position){return _c('div',{key:position,staticClass:"annotationTimeline__point-group",style:({
              left: (position + "%"),
            })},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(pointGroup.length > 1 && !_vm.selectedNote),expression:"pointGroup.length > 1 && !selectedNote"}],staticClass:"annotationTimeline__more-point"},[_c('d-icon-pencil-plus')],1),_vm._v(" "),_vm._l((pointGroup),function(pointIdx){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedNote ? _vm.selectedNote.id === _vm.sortedDataArray[pointIdx].id : true),expression:"selectedNote ? selectedNote.id === sortedDataArray[pointIdx].id : true"}],key:pointIdx,staticClass:"annotationTimeline__line-point",style:({
                'z-index': _vm.getRevertOrderZIndex(pointIdx),
              }),on:{"click":function($event){$event.stopPropagation();_vm.$emit('handleNoteSelect', _vm.sortedDataArray[pointIdx])}}},[_vm._v("\r\n              "+_vm._s(pointIdx + 1)+"\r\n            ")])})],2)}),_vm._v(" "),(_vm.selectedNote)?_c('div',{staticClass:"annotationTimeline__line-tracker"},[_c('input',{staticClass:"annotationTimeline__input-range",attrs:{"disabled":_vm.isPublished,"name":"startTime","type":"range","step":"0.01","min":"0","max":_vm.videoEndTime,"origin-value":_vm.selectedNote.startAt},domProps:{"value":_vm.selectedNote.startAt},on:{"input":function($event){$event.stopPropagation();_vm.handleInputRangeChange($event)},"focus":function($event){_vm.targetElm = 'start'},"blur":function($event){_vm.handleResetSelfTargetElmOnly('start')}}}),_vm._v(" "),_c('input',{staticClass:"annotationTimeline__input-range",attrs:{"disabled":_vm.isPublished,"name":"endTime","type":"range","step":"0.01","min":"0","max":_vm.videoEndTime,"origin-value":_vm.selectedNote.endAt},domProps:{"value":_vm.selectedNote.endAt},on:{"input":function($event){$event.stopPropagation();_vm.handleInputRangeChange($event)},"focus":function($event){_vm.targetElm = 'end'},"blur":function($event){_vm.handleResetSelfTargetElmOnly('end')}}}),_vm._v(" "),_c('div',{staticClass:"annotationTimeline__thumb-range",style:({
                left: ((_vm.selectedNote.startXRate) + "%"),
                width: ((_vm.selectedNote.width) + "%"),
              }),attrs:{"elm":"thumbRange"}})]):_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"annotationTimeline__current-time",style:({ left: (_vm.currentTimePosition + "%") })}),_vm._v(" "),_c('div',{staticClass:"annotationTimeline__listen-event-layer",class:{
            'annotationTimeline__listen-event-layer--tracking': _vm.targetElm === 'currentTime',
          },on:{"mousedown":_vm.handleSetCurrentTimeAndTargetElm,"mousemove":_vm.handleSetCurrentTime,"mouseup":function($event){_vm.handleResetSelfTargetElmOnly('currentTime')},"mouseleave":function($event){_vm.handleResetSelfTargetElmOnly('currentTime')}}})])]),_vm._v(" "),_c('div',{staticClass:"annotationTimeline__time-text"},[_vm._v("\r\n      "+_vm._s(_vm.getTimeFormat(_vm.currentTime))+" / "+_vm._s(_vm.getTimeFormat(_vm.videoEndTime))+"\r\n    ")]),_vm._v(" "),_c('div',{staticClass:"annotationTimeline__notes-switch",on:{"click":function($event){_vm.$emit('handleNoteSwitch')}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', _vm.notesSwitchIcon]}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }