<template>
  <div v-if="isLoadedCategories && searchInput.length == 0" class="DCategoriesFilter">
    <div class="filter-wrapper-left-side">
      <div class="arrow-div-l" :class="{ 'hide-arrow': scrollLeft <= 31 }">
        <div class="arrow-bg-l"></div>
        <button class="arrow-buttom" @click="onScrollLeft">
          <icon-base color="#3f3f3f" height="20" width="20">
            <d-icon-arrow-back />
          </icon-base>
        </button>
      </div>
      <div class="arrow-div-r" :class="{ 'hide-arrow': !showArrowRight }">
        <div class="arrow-bg-r"></div>
        <button class="arrow-buttom arrow-right" @click="onScrollRight">
          <icon-base color="#3f3f3f" height="20" width="20">
            <d-icon-arrow-back />
          </icon-base>
        </button>
      </div>
      <div v-if="isLoadedCategories" ref="itemScroll" class="filter-scroll center-horizontally" @scroll="onScroll">
        <div
          v-for="(filterCat, i) in filterCategories"
          :key="i"
          class="category-button"
          :class="{ 'hide-category': filterCat.items.length == 0 }"
        >
          <v-menu
            transition="slide-y-transition"
            bottom
            right
            offset-y
            :close-on-content-click="false"
            @input="onMenuToggle($event, filterCat)"
          >
            <button
              slot="activator"
              class="filter-btn"
              :class="{ 'selected-filter-btn': filterCat.selected.length > 0 }"
            >
              <span class="text-no-wrap">{{ filterCat.name }}</span>
            </button>
            <d-category-selector
              v-model="filterSearchInput"
              :placeholder="$t('builder.searchForCategoryName', { name: filterCat.name })"
              :filteredMenuItems="filteredMenuItems"
              :selectedItems="selectedItems"
              @remove-category-item="$emit('onClickCategoryItem', { filterCat, event: $event })"
              @on-click-category-item="$emit('onClickCategoryItem', { filterCat, event: $event })"
            ></d-category-selector>
          </v-menu>
        </div>
      </div>
    </div>
    <button
      class="clear-filter-btn"
      :class="{ 'disable-clear-filter': disableClearFilter }"
      @click="$emit('clearAllFilters')"
    >
      {{ $t("skills.clearFilter") }}
    </button>
  </div>
</template>
<script>
import { sortAlphabeticallyByColumnKey } from "@/js/common/listLib";
import IconBase from "@/components/IconBase.vue";
import DIconArrowBack from "@/components/icons/DIconArrowBack.vue";
import DCategorySelector from "@/components/ui_components/DCategorySelector.vue";

export default {
  name: "DCategoriesFilter",
  props: {
    isLoadedCategories: {
      type: Boolean,
      require: true,
    },
    disableClearFilter: {
      type: Boolean,
      require: true,
    },
    searchInput: {
      type: String,
      require: true,
    },
    filterCategories: {
      type: Array,
      require: true,
    },
    selectedItems: {
      type: Array,
      require: true,
    },
  },
  data() {
    return {
      scrollLeft: 0,
      showArrowRight: false,
      currentMenuItems: [],
      filterSearchInput: "",
    };
  },
  computed: {
    filteredMenuItems() {
      return this.currentMenuItems
        .filter((item) => {
          if (item.name.toLowerCase().includes(this.filterSearchInput.toLowerCase())) {
            return true;
          } else {
            return false;
          }
        })
        .sort(sortAlphabeticallyByColumnKey("name"));
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.onScroll();
      window.addEventListener("resize", this.onScroll);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onScroll);
  },
  components: {
    DCategorySelector,
    DIconArrowBack,
    IconBase,
  },
  watch: {
    isLoadedCategories: function (isLoadedCategories) {
      if (isLoadedCategories) {
        this.$nextTick(() => {
          this.onScroll();
        });
      }
    },
  },
  methods: {
    onMenuToggle(opened, filterCat) {
      if (opened) {
        this.currentMenuItems = filterCat.items;
      } else {
        this.filterSearchInput = "";
      }
    },
    onScrollRight() {
      this.$refs.itemScroll.scrollLeft = this.scrollLeft + this.$refs.itemScroll.clientWidth - 80;
    },
    onScrollLeft() {
      this.$refs.itemScroll.scrollLeft = this.scrollLeft - this.$refs.itemScroll.clientWidth + 120;
    },
    onScroll() {
      if (this.$refs.itemScroll) {
        this.scrollLeft = this.$refs.itemScroll.scrollLeft;
        const maxScrollTop = this.$refs.itemScroll.scrollWidth - this.$refs.itemScroll.clientWidth;
        if (this.$refs.itemScroll.scrollWidth != this.$refs.itemScroll.clientWidth) {
          this.showArrowRight = true;
        }
        if (maxScrollTop - this.scrollLeft <= 30) {
          this.showArrowRight = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.DCategoriesFilter {
  display: flex;
  align-items: center;
  position: absolute;
  top: 70px;
  left: 0;
  width: 100%;
  height: 52px;
  background-color: #ffffff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  .filter-wrapper-left-side {
    position: relative;
    width: 100%;
    height: 100%;
    flex: 1;
  }
  .arrow-div-l {
    position: absolute;
    top: 0;
    left: 30px;
    height: 100%;
    width: 20px;
    background-color: #fff;
    padding-top: 10px;
    z-index: 1;
    opacity: 1;
    transition: 0.3s;
  }
  .arrow-bg-l {
    position: absolute;
    top: 0;
    left: 20px;
    height: 100%;
    width: 20px;
    transform: scaleX(-1);
    background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0.1) 100%);
    z-index: 0;
    pointer-events: none;
  }
  .arrow-div-r {
    position: absolute;
    top: 0;
    right: 0px;
    height: 100%;
    width: 20px;
    background-color: #fff;
    padding-top: 10px;
    z-index: 1;
    opacity: 1;
    transition: 0.3s;
  }
  .arrow-bg-r {
    position: absolute;
    top: 0;
    right: 20px;
    height: 100%;
    width: 20px;
    background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0.1) 100%);
    z-index: 2;
    pointer-events: none;
  }
  .arrow-buttom {
    position: absolute;
    height: 32px;
    padding-top: 6px;
    left: 0;
    opacity: 0.7;
  }
  .arrow-buttom:hover {
    opacity: 1;
  }
  .arrow-right {
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
  }
  .hide-arrow {
    opacity: 0;
    transition: 0.3s;
    pointer-events: none;
  }

  .filter-scroll {
    position: absolute;
    top: 0;
    left: 30px;
    width: calc(100% - 30px);
    height: 100%;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    padding-right: 32px;
    transition: 0.3s;
    scroll-behavior: smooth;
  }
  .filter-scroll::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  .center-horizontally {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .category-button {
    position: relative;
    margin-right: 16px;
  }
  .hide-category {
    display: none;
  }
  .filter-btn {
    box-sizing: border-box;
    height: 32px;
    padding: 0 12px;
    border: 1px solid #e1e1e1;
    border-radius: 16px;
    color: #3f3f3f;
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 16px;
    text-align: right;
  }
  .filter-btn:hover {
    border: 1px solid #3f3f3f;
  }
  .selected-filter-btn {
    border: 1px solid #4689f3;
    background-color: #f0f0f0;
  }
  .clear-filter-btn {
    word-break: keep-all;
    padding-right: 30px;
    padding-left: 20px;
    color: #3f3f3f;
    font-family: Roboto;
    font-size: 16px;
    text-align: right;
    height: 24px;
    line-height: 24px;
    opacity: 1;
    pointer-events: auto;
  }
  .disable-clear-filter {
    opacity: 0.3;
    pointer-events: none;
  }
  .clear-filter-icon {
    margin-right: 6px;
  }
}
</style>
