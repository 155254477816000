export async function getWorkspaceColorConfig({ mixinDbRef }) {
  const config = await mixinDbRef.getDocument("configurations", "workspaceColors");
  return config;
}

export const getWorkspaceColorCss = (selectedColor, workspaceColors) => {
  const color = workspaceColors[selectedColor];
  const setting = "#" + color.color1;
  return setting;
};
