<template>
  <div class="PlayerCommentsMain" :class="{ PlayerCommentsMain__mobile: showFixedBottomCommentInput }">
    <div class="PlayerCommentsMain__top" :class="{ PlayerCommentsMain__bottomMobile: showFixedBottomCommentInput }">
      <comment-input-box :workflowId="workflowId" @send-comment-success="sendCommentSuccesss()" />
    </div>
    <div v-if="showNoCommentsMsg" class="PlayerCommentsMain__noComments">
      <div>
        <d-icon-comment-color />
        <div class="mt-3">{{ $t("player.noComments") }}</div>
        <div>
          <span>{{ $t("player.makeFirstComment") }}</span>
        </div>
      </div>
    </div>
    <div v-else class="PlayerCommentsMain__list">
      <loading-message transition :show="isLoading" />
      <div v-for="comment in commentsData" :key="comment.id">
        <comment-card :comment="comment" :isMobileDevice="isMobileDevice" />
      </div>
      <loading-message :show="isLoadingMore" class="mt-3 mb-3" />
    </div>
  </div>
</template>

<script>
import IconBase from "@/components/IconBase";
import DIconCommentColor from "@/components/icons/colored/DIconCommentColor.vue";
import CommentInputBox from "@/components/DWorkflowPlayer/comments/CommentInputBox.vue";
import CommentCard from "@/components/DWorkflowPlayer/comments/CommentCard.vue";
import LoadingMessage from "@/components/DWorkflowPlayer/toolbox/LoadingMessage.vue";
import { fetchCommentsById } from "@/server/api-server.js";
import { mapState, mapActions } from "vuex";
import { IS_MOBILE } from "@/constants/device-version";

export default {
  name: "PlayerCommentsMain",
  components: { IconBase, DIconCommentColor, CommentInputBox, CommentCard, LoadingMessage },
  props: {
    workflowId: String,
  },
  watch: {
    "selectedComment.deleted": function () {
      if (this.selectedComment.deleted.length > 0) {
        this.reloadCommentsAfterDelete();
      }
    },
  },
  async created() {
    this.clearCommentsData();
    this.clearSelectedComment();
    this.initialLoadComments();
  },
  data() {
    return {
      isMobileDevice: IS_MOBILE,
      isLoading: false,
      isLoadingMore: false,
      fetchedPage: 0,
      fetchSize: 50,
      loadedAll: false,
    };
  },
  beforeDestroy() {
    this.clearCommentsData();
    this.clearSelectedComment();
  },
  methods: {
    ...mapActions("workflowComments", [
      "storeCommentsData",
      "storeMoreCommentsData",
      "storeAddedNewComment",
      "clearSelectedComment",
      "clearCommentsData",
    ]),
    async loadMoreComments() {
      if (this.isLoading || this.isLoadingMore || this.loadedAll) return;
      this.isLoadingMore = true;
      const nextPage = this.fetchedPage + 1;
      const { ok, comments } = await this.getComments({ page: nextPage });
      if (!ok) {
        this.isLoadingMore = false;
        return;
      }
      if (comments.length === 0) {
        this.loadedAll = true;
      } else {
        if (comments.length < this.fetchSize) {
          this.loadedAll = true;
        }
        this.storeMoreCommentsData({ comments });
        this.fetchedPage++;
      }
      this.isLoadingMore = false;
    },
    async initialLoadComments() {
      this.isLoading = true;
      const { ok, comments } = await this.getComments({ page: 0 });
      if (!ok) {
        this.isLoading = false;
        return;
      }
      this.storeCommentsData({ comments });
      this.fetchedPage = 0;
      this.loadedAll = false;
      if (comments.length < this.fetchSize) {
        this.loadedAll = true;
      }
      this.isLoading = false;
    },
    async getComments({ page }) {
      const params = {
        level: "workflows",
        id: this.workflowId,
        withReactions: true,
        page,
        size: this.fetchSize,
      };
      const { ok, data, errorMessage } = await fetchCommentsById(params);
      if (!ok) {
        console.error(errorMessage);
        return { ok, comments: [] };
      }
      const { items: comments = [] } = data;
      return { ok, comments };
    },
    async reloadCommentsAfterDelete() {
      if (this.loadedAll) return;
      // reload comments after delete one,
      // if not scroll to load more will be missing one comment
      const currentCommentsSize = this.commentsData.length;
      const params = {
        level: "workflows",
        id: this.workflowId,
        withReactions: true,
        page: 0,
        size: currentCommentsSize,
      };
      const { ok, data, errorMessage } = await fetchCommentsById(params);
      if (ok) {
        const { items: comments = [] } = data;
        this.storeCommentsData({ comments });
      } else {
        console.error(errorMessage);
      }
    },
    async sendCommentSuccesss() {
      this.$emit("scroll-to-top");
      await this.initialLoadComments();
      const addedNewCommentId = this.commentsData[0].id;
      this.storeAddedNewComment({ commentId: addedNewCommentId });
    },
  },
  computed: {
    ...mapState("workflowPlayer", ["isDivideMode"]),
    ...mapState("workflowComments", ["commentsData", "selectedComment"]),
    showNoCommentsMsg() {
      return !this.isLoading && !this.isLoadingMore && this.commentsData.length === 0;
    },
    showFixedBottomCommentInput() {
      return this.isMobileDevice && !this.isDivideMode;
    },
  },
};
</script>

<style lang="scss" scoped>
.PlayerCommentsMain {
  position: relative;
  @media screen and (min-width: 960px) {
    width: 100%;
  }

  &__mobile {
    padding: 16px 0 80px 0;
  }
  &__top {
    z-index: 1;
    position: sticky;
    top: 0;
    left: 0;
    padding: 0 12px 16px;
  }
  &__bottomMobile {
    position: fixed;
    bottom: 0;
    top: unset;
    width: 100%;
    padding: 16px 12px 0 12px;
  }
  &__list {
    position: relative;
    padding: 0 12px 16px 12px;
  }
  &__noComments {
    position: relative;
    width: 100%;
    min-height: 280px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
    text-align: center;
    @media screen and (min-width: 960px) {
      position: absolute;
      top: 110px;
      width: 100%;
      padding-top: calc(50vh - 280px);
    }
    span {
      font-size: 16px;
      line-height: 18px;
      color: #9397a6;
    }
  }
}
</style>
