<template>
  <div class="annotationTimeline">
    <div class="annotationTimeline__play-switch" @click="$emit('handleVideoPlayOrPause')">
      <font-awesome-icon :icon="['fas', playSwitchIcon]" />
    </div>
    <div class="annotationTimeline__control-bar-block">
      <div class="annotationTimeline__display-inner-container" ref="timeline">
        <div class="annotationTimeline__line" @click.stop="handleCurrentTimeChange">
          <div
            v-for="(pointGroup, position) in pointGroupByPosition"
            :key="position"
            class="annotationTimeline__point-group"
            :style="{
              left: `${position}%`,
            }"
          >
            <div v-show="pointGroup.length > 1 && !selectedNote" class="annotationTimeline__more-point">
              <d-icon-pencil-plus />
            </div>
            <div
              v-for="pointIdx in pointGroup"
              :key="pointIdx"
              class="annotationTimeline__line-point"
              :style="{
                'z-index': getRevertOrderZIndex(pointIdx),
              }"
              @click.stop="$emit('handleNoteSelect', sortedDataArray[pointIdx])"
              v-show="selectedNote ? selectedNote.id === sortedDataArray[pointIdx].id : true"
            >
              {{ pointIdx + 1 }}
            </div>
          </div>
          <div v-if="selectedNote" class="annotationTimeline__line-tracker">
            <input
              :disabled="isPublished"
              class="annotationTimeline__input-range"
              name="startTime"
              type="range"
              step="0.01"
              min="0"
              :max="videoEndTime"
              :origin-value="selectedNote.startAt"
              :value="selectedNote.startAt"
              @input.stop="handleInputRangeChange($event)"
              @focus="targetElm = 'start'"
              @blur="handleResetSelfTargetElmOnly('start')"
            />
            <input
              :disabled="isPublished"
              class="annotationTimeline__input-range"
              name="endTime"
              type="range"
              step="0.01"
              min="0"
              :max="videoEndTime"
              :origin-value="selectedNote.endAt"
              :value="selectedNote.endAt"
              @input.stop="handleInputRangeChange($event)"
              @focus="targetElm = 'end'"
              @blur="handleResetSelfTargetElmOnly('end')"
            />
            <div
              elm="thumbRange"
              class="annotationTimeline__thumb-range"
              :style="{
                left: `${selectedNote.startXRate}%`,
                width: `${selectedNote.width}%`,
              }"
            />
          </div>
        </div>
        <div class="annotationTimeline__current-time" :style="{ left: `${currentTimePosition}%` }" />
        <div
          class="annotationTimeline__listen-event-layer"
          :class="{
            'annotationTimeline__listen-event-layer--tracking': targetElm === 'currentTime',
          }"
          @mousedown="handleSetCurrentTimeAndTargetElm"
          @mousemove="handleSetCurrentTime"
          @mouseup="handleResetSelfTargetElmOnly('currentTime')"
          @mouseleave="handleResetSelfTargetElmOnly('currentTime')"
        />
      </div>
    </div>
    <div class="annotationTimeline__time-text">
      {{ getTimeFormat(currentTime) }} / {{ getTimeFormat(videoEndTime) }}
    </div>
    <div class="annotationTimeline__notes-switch" @click="$emit('handleNoteSwitch')">
      <font-awesome-icon :icon="['fas', notesSwitchIcon]" />
    </div>
  </div>
</template>

<script>
import {
  isValidTimeRange,
  isValidSelectedTimeRange,
  toCentiSecondTimeFormat,
} from "@/js/video-player/video-player-time.js";
import DIconPencilPlus from "@/components/icons/DIconPencilPlus.vue";

export default {
  name: "BuilderAnnotationTimeline",
  props: {
    currentTime: Number,
    isHideCanvas: Boolean,
    isPlaying: Boolean,
    isPublished: Boolean,
    selectedId: String,
    sortedDataArray: Array,
    videoEndTime: Number,
  },
  data() {
    return {
      targetElm: "",
    };
  },
  components: {
    DIconPencilPlus,
  },
  mounted() {},
  computed: {
    playSwitchIcon() {
      return this.isPlaying ? "pause" : "play";
    },
    notesSwitchIcon() {
      return this.isHideCanvas ? "closed-captioning" : "closed-captioning-slash";
    },
    currentTimePosition() {
      return this.getTimePositionRate(this.currentTime);
    },
    pointGroupByPosition() {
      return this.sortedDataArray.reduce((result, data, index) => {
        const position = this.getTimePositionRate(data.startTime);
        const prevPointIndexs = result[position];
        const newPointIndexs = prevPointIndexs ? [...prevPointIndexs, index] : [index];
        return {
          ...result,
          [position]: newPointIndexs,
        };
      }, {});
    },
    selectedNote() {
      const data = this.sortedDataArray.find(({ id }) => id === this.selectedId);
      if (!data) return null;
      const startXRate = this.getTimePositionRate(data.startTime);
      const endXRate = this.getTimePositionRate(data.endTime);
      const width = endXRate - startXRate;
      return {
        id: this.selectedId,
        startXRate,
        endXRate,
        startAt: Number(data.startTime),
        endAt: Number(data.endTime),
        width: width < 0 ? 0 : width,
      };
    },
  },
  methods: {
    getRevertOrderZIndex(idx) {
      const basicIndex = 20;
      return basicIndex + (this.sortedDataArray.length - idx);
    },
    getTimeFormat(time) {
      return toCentiSecondTimeFormat(time);
    },
    getTimePositionRate(targetTime) {
      const rate = targetTime / this.videoEndTime;
      return rate * 100;
    },
    getTimelineWidth() {
      return this.$refs.timeline ? this.$refs.timeline.clientWidth : 0;
    },
    getTimeSecond(positionX) {
      const timelineWidth = this.getTimelineWidth();
      return (positionX / timelineWidth) * this.videoEndTime;
    },
    handleCurrentTimeChange(e) {
      const position = e.offsetX;
      const timelineWidth = this.getTimelineWidth();
      const isTargetThumbRange = e.target.getAttribute("elm") === "thumbRange";
      const currentPosition = isTargetThumbRange
        ? this.selectedNote.startXRate * timelineWidth * 0.01 + position
        : position;
      this.$emit("handleVideoCurrentTimeUpdate", this.getTimeSecond(currentPosition));
    },
    handleInputRangeChange(e) {
      const { name, value } = e.target;
      const originValue = e.target.getAttribute("origin-value");
      const { startAt, endAt, id } = this.selectedNote;
      const [min, max] = name === "startTime" ? [0, endAt] : [startAt, this.videoEndTime];
      const isValidRange = isValidTimeRange(value, min, max);
      const isValidSelectRange = isValidSelectedTimeRange(name, value, min, max);
      if (!isValidRange || !isValidSelectRange) {
        e.target.value = originValue;
        return;
      }
      const payload = {
        id,
        key: name,
        value: +value,
      };
      this.$emit("handleNoteTimeUpdate", payload);
      this.$emit("handleVideoCurrentTimeUpdate", payload.value);
    },
    handleSetCurrentTime(e) {
      if (this.targetElm !== "currentTime") return;
      const currentTime = this.getTimeSecond(e.offsetX);
      this.$emit("handleVideoCurrentTimeUpdate", currentTime);
    },
    handleSetCurrentTimeAndTargetElm(e) {
      this.targetElm = "currentTime";
      this.handleSetCurrentTime(e);
    },
    handleResetSelfTargetElmOnly(type) {
      if (this.targetElm !== type) return;
      this.targetElm = "";
    },
  },
};
</script>

<style scoped lang="scss">
/*
  point event priority is higher than listen drag current time,
  so listen-layer z-index is lower than point.
*/
$z-index: (
  listen-layer-tracking: 1001,
  input-thumb: 1000,
  point-hover: 500,
  point-more: 200,
  point-container: 20,
  listen-layer-normal: 19,
  display-fake-thumb: 18,
  current-time: 10,
);
.annotationTimeline {
  --primary: #4a90e2;
  --timeline-height: 56px;
  --timeline-width: 100%;
  --icon-button-width: 36px;
  --icon-size: 20px;
  --drag-button-width: 10px;

  width: 100%;
  height: var(--timeline-height);
  background: var(--dGrey2-color);
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  padding: 0 12px;
}

.annotationTimeline__play-switch,
.annotationTimeline__notes-switch {
  color: white;
  position: relative;
  cursor: pointer;
  text-align: center;
  width: 16px;
}
.annotationTimeline__notes-switch {
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
}

.annotationTimeline__time-text {
  color: #ffffff;
  text-align: right;
  white-space: nowrap;
  margin: 0 4px;
}

/* annotationTimeline__control-bar-block start */
.annotationTimeline__control-bar-block {
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 480px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.annotationTimeline__display-inner-container {
  position: relative;
  height: 100%;
  width: 100%;
  margin: 0 var(--drag-button-width);
  display: flex;
  justify-content: center;
  align-items: center;
}

.annotationTimeline__listen-event-layer {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  height: var(--timeline-height);
  width: 100%;
  z-index: map-get($z-index, "listen-layer-normal");
  &--tracking {
    z-index: map-get($z-index, "listen-layer-tracking");
  }
}

.annotationTimeline__current-time {
  z-index: map-get($z-index, current-time);
  position: absolute;
  background: #f5514d;
  width: 1px;
  height: 100%;
  top: 0;
  left: 0;
}

.annotationTimeline__line {
  z-index: map-get($z-index, point-container);
  position: relative;
  height: 8px;
  border-radius: 4px;
  background: rgba(172, 172, 172, 0.2);
  width: 100%;
}

@mixin point {
  cursor: pointer;
  position: absolute;
  background: var(--primary);
  border-radius: 50%;
  width: 16px;
  height: 16px;
  top: -4.5px;
}

.annotationTimeline__point-group {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  &:hover > .annotationTimeline__line-point {
    position: relative;
  }

  &:hover > .annotationTimeline__more-point {
    display: none;
  }
}
.annotationTimeline__more-point {
  @include point;
  z-index: map-get($z-index, "point-more");
  > svg {
    width: 16px;
    height: 16px;
  }
}

.annotationTimeline__line-point {
  @include point;
  line-height: 16px;
  text-align: center;
  color: white;
  font-size: 12px;
}

.annotationTimeline__line-point:hover {
  z-index: map-get($z-index, "point-hover") !important;
}

.annotationTimeline__line-tracker {
  position: absolute;
  height: 8px;
  width: 100%;
}

@mixin drag-btn {
  font-size: 20px;
  position: absolute;
  top: -12px;
  font-weight: bold;
  z-index: map-get($z-index, display-fake-thumb);
  width: var(--drag-button-width);
  text-align: center;
}

.annotationTimeline__thumb-range {
  position: absolute;
  height: 8px;
  border-radius: 4px;
  background: var(--primary);
  color: white;
  height: 8px;
  &::before {
    @include drag-btn;
    content: "[";
    left: 0;
    transform: translateX(-100%);
  }

  &::after {
    @include drag-btn;
    content: "]";
    left: 100%;
  }
}

.annotationTimeline__input-range {
  position: absolute;
  z-index: map-get($z-index, input-thumb);
  appearance: none;
  width: calc(100% + var(--drag-button-width));
  height: 8px;
  outline: none;
  margin: auto;
  top: 0;
  bottom: 0;
  background-color: transparent;
  pointer-events: none;
  &[name="startTime"] {
    left: calc(-1 * var(--drag-button-width));
  }
  &[name="endTime"] {
    left: 0;
  }

  &::-webkit-slider-thumb {
    cursor: ew-resize;
    appearance: none;
    pointer-events: all;
    height: 20px;
    width: var(--drag-button-width);
  }

  &::-moz-range-thumb {
    cursor: ew-resize;
    pointer-events: all;
    height: 20px;
    width: var(--drag-button-width);
    background-color: transparent;
    border: 0;
  }
}

/* annotationTimeline__control-bar-block end*/
</style>
