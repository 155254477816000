<template>
  <section>
    <admin-setting-permission-item
      class="ipRestriction--permissionItem"
      :allow="getter_ip_restriction_enabled"
      :title="$t('admin.ipRestriction')"
      :subtitle="$t('admin.allowIpRestrictionMessage')"
      autotest="ip-restriction-permission-toggle"
      @click-switch="showConfirmation = true"
    />

    <d-change-permission-confirmation
      v-if="showConfirmation"
      :title="$t('admin.ipRestriction')"
      :content="
        getter_ip_restriction_enabled ? $t('admin.disableIpRestrictionMessage') : $t('admin.enableIpRestrictionMessage')
      "
      @close-popup="showConfirmation = false"
      @change-permission="changeRestrictionIpSettings"
    ></d-change-permission-confirmation>
  </section>
</template>

<script>
import AdminSettingPermissionItem from "@/components/DAdmin/Settings/AdminSettingPermissionItem.vue";
import DChangePermissionConfirmation from "@/components/DAdmin/confirmations/DChangePermissionConfirmation.vue";
import { mapGetters } from "vuex";

export default {
  name: "AdminSettingIpRestriction",
  components: {
    AdminSettingPermissionItem,
    DChangePermissionConfirmation,
  },
  data() {
    return {
      showConfirmation: false,
    };
  },
  computed: {
    ...mapGetters("permission", ["getter_ip_restriction_enabled"]),
  },
  methods: {
    changeRestrictionIpSettings() {
      this.showConfirmation = false;
      this.$emit("enableIpRestrictionSetting", !this.getter_ip_restriction_enabled);
    },
  },
};
</script>

<style lang="scss" scoped>
.ipRestriction--permissionItem {
  margin-top: 0 !important;
}
</style>
