export const CONVERSION_STATUS = {
  SCHEDULED: "scheduled", // conversion collection only
  JOB_SCHEDULED: "job scheduled",
  RUNNING: "running",
  FINISHED: "finished",
  TRANSCODED: "transcoded",
  TRANSCODING: "transcoding",
  ERROR: "error",
};

export const PROGRESS_ICON_STATUS = {
  NONE: "NONE",
  FAILED: "FAILED",
  LOCKED: "LOCKED",
  SUCCESS: "SUCCESS",
  UPDATED: "UPDATED",
};
