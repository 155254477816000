import axios from "axios";
import authChoice from "../../auth";
import environment from "../../env";
import store from "../store/index";
import { apiWrapperGenerator } from "./lib";

function getBaseUrl() {
  return environment.backendType === "firebase"
    ? environment.service[environment.env].admin
    : environment.service[environment.env]["china"].admin;
}

const adminServer = axios.create({
  baseURL: getBaseUrl(),
});
adminServer.interceptors.request.use(
  async (config) => {
    try {
      config.headers["x-user-token"] = await authChoice().currentUser.getIdToken();
    } finally {
      return config;
    }
  },
  (error) => Promise.resolve(error)
);

export const apiWrapper = apiWrapperGenerator(adminServer);

export const checkIpAddress = () => apiWrapper("post", "/v1/permission/check-ip-access");

export const validateRusticiLogin = (rusticiToken, params) =>
  apiWrapper("post", `/v1/rustici/validate-user`, params, {
    headers: {
      "Content-Type": "application/json",
      "x-rustici-token": rusticiToken,
    },
  });

export const fetchWorkspace = async (params = { only_likes: false, keyword: "" }) => {
  try {
    let url = new URL(getBaseUrl() + "v1/groups");
    if (Object.keys(params).length) {
      for (const key in params) {
        if (params.hasOwnProperty(key) && params[key]) {
          url.searchParams.set(key, params[key]);
        }
      }
    }
    const { ok, data } = await apiWrapper("get", `/v1/group${url.search}}`);
    if (ok) {
      if (!("keyword" in params)) {
        await store.dispatch("navigator/storeOnlyLikeWorkspaces", data.items);
      } else if (!("only_likes" in params)) {
        await store.dispatch("navigator/storeOnlyKeywordsWorkspaces", data.items);
      } else {
        await store.dispatch("navigator/storeWorkspaceData", data.items);
      }
      return data;
    } else {
      console.error(data.errorMessage);
    }
  } catch (e) {
    console.log(e);
  }
};

export const fetchWorkspaceByWorkspaceId = async (workspaceId = "") => apiWrapper("get", `/v1/groups/${workspaceId}`);

export const fetchOrganizationLogo = (organizationId) =>
  apiWrapper("get", `/v1/organization-settings/${organizationId}/logo`);

export default adminServer;
