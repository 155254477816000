import envConfig from "../../env";

const IS_BETA = window.location.hostname.includes("beta");

export const IS_PRODUCTION_APP = envConfig.env === "prod" && !IS_BETA;

export const CLIENT_ENV = IS_BETA ? "prod_beta" : IS_PRODUCTION_APP ? "prod" : "dev";

// whitelist domains for maven
const WHITELIST_DOMAINS = [
  "prod-maven.deephow.net",
  "maven.deephow.ai",
  "dev-maven.deephow.ai",
  "dev-maven.deephow.net:9981",
  "localhost",
];
export const IS_MAVEN_WHITELIST_DOMAIN = WHITELIST_DOMAINS.includes(window.location.hostname);
