<template>
  <section class="mixin-video"></section>
</template>

<script>
import MixinUpload from "./MixinUpload.vue";
import Downloadjs from "downloadjs";
import MixinUser from "./MixinUser.vue";
import { getSignedUrlByRez } from "@/server/sign-server.js";
import { cloneDeep } from "lodash-es";

let audioBufferToWav = require("../js/audioBufferToWave.js");
export default {
  name: "mixin-video",
  props: [],
  mixins: [MixinUpload, MixinUser],
  mounted() {},
  data() {
    return {};
  },
  methods: {
    downloadBlobVideo(link, id, format = ".mp4", mime = "video/mp4", onprogressCallback) {
      return new Promise(function (resolve, reject) {
        const requestFn = function (videoLink) {
          var x = new XMLHttpRequest();
          x.open("GET", videoLink, true);
          x.responseType = "blob";
          x.onprogress = function (event) {
            onprogressCallback && onprogressCallback(event);
          };
          x.onload = function (e) {
            if (e.target.status === 200) {
              Downloadjs(e.target.response, id + format, mime);
              resolve();
              return;
            }
            reject(e);
          };
          x.onerror = function (err) {
            reject(err);
          };
          try {
            x.send();
          } catch (err) {
            reject(err);
          }
        };

        requestFn(link);
      });
    },
    toBase64(src) {
      return new Promise(function (resolve, reject) {
        var img = new Image();
        img.crossOrigin = "Anonymous";
        img.onload = function () {
          var canvas = document.createElement("CANVAS");
          var ctx = canvas.getContext("2d");
          var dataURL;
          canvas.height = this.naturalHeight;
          canvas.width = this.naturalWidth;
          ctx.drawImage(this, 0, 0);
          dataURL = canvas.toDataURL();
          resolve(dataURL);
        };

        img.src = src;
      });
    },
    fileToBlob(file) {
      return new Promise(function (resolve, reject) {
        try {
          const reader = new FileReader();
          reader.addEventListener("loadend", function () {
            const url = reader.result;
            fetch(url)
              .then((res) => res.blob())
              .then(function (blob) {
                resolve(blob);
              });
          });
          reader.readAsDataURL(file);
        } catch (err) {
          reject(err);
        }
      });
    },
    //input can be video or audio file/blob
    toWav(file) {
      return new Promise(function (resolve, reject) {
        try {
          let reader = new FileReader();
          let audioContext = new AudioContext();
          reader.readAsArrayBuffer(file);
          reader.onload = function () {
            let videoFileAsBuffer = reader.result; // arraybuffer
            audioContext.decodeAudioData(videoFileAsBuffer).then(function (decodedAudioData) {
              const sampleRate = 16000; //sample rate higher than 16000 have no appreciable effect on speech recognition quality.
              const numberOfChannels = 1;
              let duration = decodedAudioData.duration;
              let offlineAudioContext = new OfflineAudioContext(numberOfChannels, sampleRate * duration, sampleRate);
              let soundSource = offlineAudioContext.createBufferSource();
              let myBuffer = decodedAudioData;
              soundSource.buffer = myBuffer;
              soundSource.connect(offlineAudioContext.destination);
              soundSource.start();
              offlineAudioContext.startRendering();
              offlineAudioContext.oncomplete = function (e) {
                let wav = audioBufferToWav.audioBufferToWav(e.renderedBuffer);
                let blob = new window.Blob([new DataView(wav)], {
                  type: "audio/wav",
                });
                resolve(blob);
              };
            });
          };
        } catch (err) {
          reject(err);
        }
      });
    },
    getVideoDuration(video) {
      return new Promise(function (resolve, reject) {
        try {
          let duration;
          const videoPlayer = document.createElement("video");
          videoPlayer.addEventListener("loadedmetadata", function () {
            if (videoPlayer.duration === Infinity) {
              videoPlayer.currentTime = Number.MAX_SAFE_INTEGER;
              videoPlayer.ontimeupdate = () => {
                videoPlayer.ontimeupdate = null;
                duration = videoPlayer.duration;
                resolve(duration);
              };
            } else {
              duration = videoPlayer.duration;
              resolve(duration);
            }
          });
          const usingVideoUrl = window.URL.createObjectURL(video);

          videoPlayer.src = usingVideoUrl;
        } catch (err) {
          reject(err);
        }
      });
    },
    getVideoPoster(url, time, width, height) {
      const self = this;
      if (!time) {
        time = 2;
      }
      if (!width) {
        width = 495;
      }
      if (!height) {
        height = 270;
      }
      return new Promise(function (resolve, reject) {
        let video = document.createElement("video");
        video.setAttribute("crossorigin", "anonymous");
        let poster;
        video.src = url;
        //jump the video to the timestamp
        video.onloadedmetadata = function () {
          if (this.videoHeight > this.videoWidth) {
            [width, height] = [height, width]; //swap ratio
          }
          this.currentTime = time;
        };
        //when jumping is ready
        video.onseeked = function () {
          let canvas = document.createElement("canvas");
          canvas.height = height;
          canvas.width = width;
          var ctx = canvas.getContext("2d");
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          poster = new Image();
          canvas.toBlob(
            function (blob) {
              let imageFile = new File([blob], "image-" + self.uuidv4() + ".jpg", {
                type: "image/jpeg",
              });
              poster.src = window.URL.createObjectURL(blob);
              resolve(imageFile);
            },
            "image/jpeg",
            0.9
          );
        };
      });
    },
    getAudioFromVideo(video) {
      const self = this;
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let audioContext = new AudioContext();
        reader.readAsArrayBuffer(video);
        reader.onload = function () {
          let videoFileAsBuffer = reader.result; // arraybuffer
          audioContext.decodeAudioData(videoFileAsBuffer).then(function (decodedAudioData) {
            const sampleRate = 16000; //sample rate higher than 16000 have no appreciable effect on speech recognition quality.
            const numberOfChannels = 1;
            let duration = decodedAudioData.duration;
            let offlineAudioContext = new OfflineAudioContext(numberOfChannels, sampleRate * duration, sampleRate);
            let soundSource = offlineAudioContext.createBufferSource();
            let myBuffer = decodedAudioData;
            soundSource.buffer = myBuffer;
            soundSource.connect(offlineAudioContext.destination);
            soundSource.start();
            offlineAudioContext.startRendering();
            offlineAudioContext.oncomplete = function (e) {
              let wav = audioBufferToWav.audioBufferToWav(e.renderedBuffer);
              let blob = new window.Blob([new DataView(wav)], {
                type: "audio/wav",
              });
              resolve(blob);
            };
          });
        };
      });
    },
    getVideoResolution(video) {
      return new Promise(function (resolve, reject) {
        const player = document.createElement("video");
        player.onloadedmetadata = function (event) {
          const resolution = { width: this.videoWidth, height: this.videoHeight };
          resolve(resolution);
        };

        player.src = video;
      });
    },
    async handleCloudFrontStepUrlSignByRez(sourceSteps) {
      // prevent update sourceSteps (js reference issue)
      // only update cloneSteps and return cloneSteps.
      const cloneSteps = cloneDeep(sourceSteps);
      try {
        const waitGetSignedUrlResultPromises = [];
        for (const step of cloneSteps) {
          if (!step.videos) {
            continue;
          }

          for (let rez of Object.keys(step.videos)) {
            if (!step.videos[rez]) {
              continue;
            }
            waitGetSignedUrlResultPromises.push(
              new Promise((resolve) => {
                getSignedUrlByRez("step", step.id, rez).then((result) => {
                  if (result.ok) {
                    step.videos[rez] = result.url;
                  }
                  resolve(step);
                });
              })
            );
          }
        }
        await Promise.all(waitGetSignedUrlResultPromises);
        return { ok: true, steps: cloneSteps };
      } catch (e) {
        console.log(e);
        return { ok: false, steps: cloneSteps };
      }
    },
  },
  computed: {},
};
</script>
