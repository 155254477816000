<template>
  <section class="PasswordPopup">
    <v-card width="466" color="#FFFFFF" class="PasswordPopup--card">
      <button class="PasswordPopup--closeBtn" @click="$emit('close-popup')" autotest="close-reset-shared-password">
        <icon-base color="#0C0C0E" width="18" height="18">
          <d-icon-close-light />
        </icon-base>
      </button>
      <h2>{{ $t("admin.redefinePassword") }}</h2>
      <p>{{ $t("admin.redefinePasswordMsg") }}</p>
      <v-form @submit.prevent="resetSharedAccountPassword" v-model="formValid" autocomplete="off">
        <d-text-field
          v-model="newPassword"
          :append-icon="showPassword ? 'visibility' : 'visibility_off'"
          :type="showPassword ? 'text' : 'password'"
          :label="$t('auth.password')"
          @click-append="showPassword = !showPassword"
          autocomplete="chrome-off"
          :key="'newPassword' + showPassword"
          class="pb-2"
          :rules="[rules.required, rules.validPassword, rules.noWhiteSpace, rules.min, rules.max]"
        ></d-text-field>
      </v-form>
      <v-layout class="mt-4">
        <v-flex xs6 pr-2>
          <v-btn
            depressed
            round
            block
            outline
            class="body-2 text-capitalize mb-0"
            color="#4689f4"
            @click="$emit('close-popup')"
            autotest="reset-shared-password-cancel-btn"
            >{{ $t("all.cancel") }}</v-btn
          >
        </v-flex>
        <v-flex xs6 pl-2>
          <v-btn
            :disabled="!formValid"
            depressed
            round
            block
            class="body-2 text-capitalize white--text mb-0"
            color="#4689f4"
            @click="resetSharedAccountPassword"
            autotest="redefine-shared-password-save-btn"
            >{{ $t("admin.redefine") }}</v-btn
          >
        </v-flex>
      </v-layout>
    </v-card>

    <d-alert v-model="alert.show" :type="alert.type" :message="alert.message" />
  </section>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import DIconCloseLight from "@/components/icons/player/DIconCloseLight.vue";
import DTextField from "@/components/ui_components/DTextField.vue";
import DAlert from "@/components/ui_components/DAlert.vue";
import MixinAdmin from "@/components/MixinAdmin.vue";

export default {
  name: "AdminResetSharedAccountPasswordPopup",
  props: {
    formData: Object,
  },
  components: {
    IconBase,
    DIconCloseLight,
    DTextField,
    DAlert,
  },
  mixins: [MixinAdmin],
  data() {
    return {
      newPassword: "",
      showPassword: false,
      formValid: false,
      rules: {
        noWhiteSpace: (value) => {
          const pattern = /^[^ ]+$/;
          return pattern.test(value) || this.$t("rules.noSpace");
        },
        required: (value) => !!value || this.$t("rules.required"),
        min: (v) => (v && v.length >= 10) || this.$t("rules.min10"),
        max: (v) => (v && v.length <= 25) || this.$t("rules.max25"),
        validPassword: (value) => {
          // Include lower and upper case letters, numbers, and special characters.
          const pattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!-\/:-@[-\`{-~]).*$/;
          return pattern.test(value) || this.$t("rules.validPasswordCharacters");
        },
      },
      alert: {
        show: false,
        type: "",
        message: "",
      },
    };
  },
  methods: {
    async resetSharedAccountPassword() {
      this.$emit("resetPassword", this.newPassword);
    },
  },
};
</script>
<style lang="scss" scoped>
.PasswordPopup {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  &--card {
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin: 0 auto;
    padding: 48px;
    h2 {
      font-weight: 400;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      color: #52545d;
    }
    p {
      font-size: 16px;
      line-height: 30px;
      text-align: center;
      color: #8d909f;
      margin: 6px 0 50px 0;
    }
  }
  &--closeBtn {
    position: absolute;
    right: 26px;
    top: 26px;
  }
}
</style>
