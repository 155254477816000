<template>
  <div class="integrationsMenu">
    <v-list light class="integrationsMenu__list py-0">
      <v-list-tile
        v-for="item in integrationsMenu"
        :key="item.key"
        @click="setIntegrationPage(item.key)"
        class="integrationsMenu__listItem"
        :class="{ 'integrationsMenu__listItem--isActive': integrationPage === item.key }"
      >
        <v-list-tile-title class="body-1 integrationsMenu__listItemTitle">
          {{ item.title }}
        </v-list-tile-title>
      </v-list-tile>
    </v-list>
  </div>
</template>
<script>
export default {
  name: "AdminSettingIntegrationsMenu",
  props: {
    integrationPage: {
      type: String,
      default: "",
    },
    integrationsMenu: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    setIntegrationPage(item) {
      this.$emit("setIntegrationPage", item);
    },
  },
};
</script>

<style scoped lang="scss">
.integrationsMenu {
  border: 1px solid #e0e0e0;
  height: 100%;

  &__list {
    height: 100%;
    background: #f9fafb;
  }

  &__listItem {
    ::v-deep a {
      height: 60px;

      &:hover {
        background-color: #eff2f7;
      }
    }

    &--isActive {
      background: #eff2f7;
    }
  }
}
</style>
