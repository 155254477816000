<template>
  <div class="ZoomTimeline">
    <div class="ZoomTimeline__display-outer-container">
      <div v-show="canScrollable" ref="scrollShadowLeft" class="ZoomTimeline__display-outer-container--shadow-left" />
      <div v-show="canScrollable" ref="scrollShadowRight" class="ZoomTimeline__display-outer-container--shadow-right" />
      <div class="ZoomTimeline__hint-tooltip" v-show="showHintMessage && !isVoiceOver">
        <div class="ZoomTimeline__hint-tooltip--text">
          {{ hintMessage }}
          <div class="ZoomTimeline__hint-tooltip--arrow" />
        </div>
      </div>
      <div v-if="targetElm" class="ZoomTimeline__time-tooltip" :style="{ left: tooltipData.position }">
        <div class="ZoomTimeline__time-tooltip--text">
          {{ tooltipData.timeText }}
          <div class="ZoomTimeline__time-tooltip--arrow" />
        </div>
      </div>
      <div
        class="ZoomTimeline__display-outer-container--slot"
        :style="{
          left: `${startPosition}%`,
          width: `${endPosition - startPosition}%`,
        }"
      >
        <slot name="outer-container" />
      </div>
    </div>
    <div class="ZoomTimeline__scroll-container" ref="scrollContainer">
      <div
        class="ZoomTimeline__container"
        ref="innerContainer"
        :style="{
          width: `${zoomValueWidth}`,
          'max-width': `${zoomBreakPoints.MAX}px`,
        }"
      >
        <input
          class="ZoomTimeline__input-range"
          name="startTime"
          type="range"
          step="0.01"
          min="0"
          :max="videoEndTime"
          :disabled="disabled"
          :origin-value="startTime"
          :value="startTime"
          @input.stop="handleInputRangeChange($event)"
          @change="trackInputRangeChangeByHeap"
          @focus="targetElm = 'start'"
          @blur="handleResetSelfTargetElmOnly('start')"
        />
        <input
          class="ZoomTimeline__input-range"
          name="endTime"
          type="range"
          step="0.01"
          min="0"
          :max="videoEndTime"
          :disabled="disabled"
          :origin-value="endTime"
          :value="endTime"
          @input.stop="handleInputRangeChange($event)"
          @change="trackInputRangeChangeByHeap"
          @focus="targetElm = 'end'"
          @blur="handleResetSelfTargetElmOnly('end')"
        />
        <div class="ZoomTimeline__display-inner-container" ref="timeline">
          <div class="ZoomTimeline__preview-images">
            <d-lazy-image
              v-for="(img, index) in previewImages"
              :key="img + index"
              :aspect-ratio="4 / 3"
              height="100%"
              :src="img"
            />
          </div>
          <div class="ZoomTimeline__cover-layer" v-show="hasChanged || (videoEndTime && isVoiceOver)">
            <div
              class="ZoomTimeline__cover-range"
              :class="{ 'ZoomTimeline__cover--delete': type === 'trim' }"
              :style="{
                left: 0,
                width: `${startPosition}%`,
              }"
            />
            <div
              class="ZoomTimeline__cover-range"
              :class="{
                'ZoomTimeline__cover--delete': type === 'erase',
                'ZoomTimeline__cover--record': type === 'voiceOver',
              }"
              :style="{
                left: `${startPosition}%`,
                width: `${endPosition - startPosition}%`,
              }"
            >
              <slot name="range-container" />
            </div>
            <div
              class="ZoomTimeline__cover-range"
              :class="{ 'ZoomTimeline__cover--delete': type === 'trim' }"
              :style="{
                left: `${endPosition}%`,
                width: `${100 - endPosition}%`,
              }"
            />
          </div>
          <div ref="startDrag" class="ZoomTimeline__drag-start-btn" :style="{ left: `${startPosition}%` }">
            <v-icon small color="white">navigate_before</v-icon>
          </div>
          <div class="ZoomTimeline__drag-end-btn" :style="{ left: `${endPosition}%` }">
            <v-icon small color="white">navigate_next</v-icon>
          </div>
          <div
            class="ZoomTimeline__current-time"
            ref="ZoomTimeline__current-time"
            :class="{ isTouchBlueHandler: isWhiteThumbTimeEqualBlueHandler }"
            :style="{ left: `${currentTimePosition}%` }"
          />
          <div
            class="ZoomTimeline__listen-event-layer"
            :class="{
              'ZoomTimeline__listen-event-layer--tracking': targetElm === 'currentTime',
              'ZoomTimeline__listen-event-layer--disabled': disabled,
            }"
            @mousedown="handleSetCurrentTimeAndTargetElm"
            @mousemove="handleSetCurrentTime"
            @mouseup="handleResetSelfTargetElmOnly('currentTime')"
            @mouseleave="handleResetSelfTargetElmOnly('currentTime')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MixinDB from "@/components/MixinDB";
import DLazyImage from "@/components/ui_components/DLazyImage";
import {
  isValidTimeRange,
  isValidSelectedTimeRange,
  toCentiSecondTimeFormat,
} from "@/js/video-player/video-player-time.js";
import { floor } from "lodash-es";

export default {
  name: "ZoomTimeline",
  mixins: [MixinDB],
  props: {
    type: {
      type: String,
      validator: (type) => ["trim", "erase", "voiceOver"].includes(type),
      default: () => "trim",
    },
    currentTime: Number,
    disabled: Boolean,
    videoEndTime: Number,
    startTime: Number,
    endTime: Number,
    imageMapId: String,
    zoomValue: Number,
  },
  data() {
    return {
      scrollLeft: 0,
      showHintMessage: true,
      targetElm: "",
      imageMaps: {},
      imageMapWidth: (64 * 4) / 3,
      timelineWidthForPreview: 0,
      zoomMinBreakPointIndex: 0,
      zoomBreakPointKeys: ["ORIGIN", "MIN_1", "SEC_30", "SEC_1", "MAX"],
      isMadeChange: false,
    };
  },
  components: {
    DLazyImage,
  },
  mounted() {
    this.timelineWidthForPreview = this.getTimelineWidth();
    this.computeZoomMaxValue();
    this.$refs.scrollContainer.addEventListener("scroll", this.handleScroll);
    this.$refs.scrollContainer.addEventListener("wheel", this.handleWheel);
  },
  beforeDestroy() {
    this.$refs.scrollContainer.removeEventListener("scroll", this.handleScroll);
    this.$refs.scrollContainer.removeEventListener("wheel", this.handleWheel);
  },
  computed: {
    isVoiceOver() {
      return this.type === "voiceOver";
    },
    isWhiteThumbTimeEqualBlueHandler() {
      if (!this.isMadeChange) return false;
      const current = +this.currentTime.toFixed(2);
      const endTime = +this.endTime.toFixed(2);
      const startTime = +this.startTime.toFixed(2);
      return [endTime, startTime].includes(current);
    },
    canScrollable() {
      return this.zoomValue > 100;
    },
    hintMessage() {
      return this.$t(`editor.${this.type}HintMessage`);
    },
    zoomValueWidth() {
      if (this.zoomValue === 100) return "100%";
      const zoomLevel = (this.zoomValue - 100) / 50;
      const currentZoomKey = this.zoomBreakPointKeys[this.zoomMinBreakPointIndex + zoomLevel];
      return `${this.zoomBreakPoints[currentZoomKey]}px`;
    },
    zoomBreakPoints() {
      // Please make sure object key which match this.zoomBreakPointKeys
      return {
        ORIGIN: "100%",
        MIN_1: 1 * this.imageMapWidth * Math.ceil(this.videoEndTime / 60),
        SEC_30: 1 * this.imageMapWidth * Math.ceil(this.videoEndTime / 30),
        SEC_1: 1 * this.imageMapWidth * Math.ceil(this.videoEndTime),
        MAX: 3 * this.imageMapWidth * Math.ceil(this.videoEndTime),
      };
    },
    hasChanged() {
      return this.startTime !== 0 || this.endTime !== this.videoEndTime;
    },
    currentTimePosition() {
      return this.getTimePositionRate(this.currentTime);
    },
    startPosition() {
      return this.getTimePositionRate(this.startTime);
    },
    endPosition() {
      return this.getTimePositionRate(this.endTime);
    },
    tooltipData() {
      if (this.targetElm === "") return {};
      const ELEMENT_TIME_INFO_MAP = {
        start: [this.startTime, 27],
        end: [this.endTime, 9],
        currentTime: [this.currentTime, 20],
      };
      const [timeSeconds, shift] = ELEMENT_TIME_INFO_MAP[this.targetElm];
      const position = this.getTimePositionRate(timeSeconds);
      const timelineWidth = this.getTimelineWidth();
      const value = (position * timelineWidth) / 100 - this.scrollLeft - shift;
      return {
        position: `${value}px`,
        timeText: toCentiSecondTimeFormat(timeSeconds),
      };
    },
    previewImages() {
      const imageMapLength = Object.keys(this.imageMaps).length;
      if (this.timelineWidthForPreview === 0 || imageMapLength === 0) return [];
      const imageMapNum = Math.ceil(this.timelineWidthForPreview / this.imageMapWidth);
      const intervalSec = this.videoEndTime / imageMapNum;
      let previewImages = [];
      for (let i = 0; i < imageMapNum; i++) {
        const image = this.imageMaps[parseInt(intervalSec * i)];
        if (image) {
          previewImages.push(image);
        }
      }
      return previewImages;
    },
  },
  methods: {
    async fetchImageMapFromDB(imageMapId) {
      const value = await this.getDocument("imagemap", imageMapId);
      this.imageMaps = value.imageMap || value;
    },
    computeZoomMaxValue() {
      const MAX_INDEX = this.zoomBreakPointKeys.length - 1;
      const basicTimeline = this.$refs.scrollContainer.clientWidth;
      const dragBtnWidth = 18 * 2;
      const currentTimelineWidth = basicTimeline + dragBtnWidth;
      if (currentTimelineWidth >= this.zoomBreakPointKeys.MAX) {
        this.zoomMinBreakPointIndex = MAX_INDEX;
        this.$emit("handleZoomMaxValue", 100);
        return;
      }

      const alreayIncludeZoomBreakPointKey = this.zoomBreakPointKeys.filter(
        (key) => key === "ORIGIN" || currentTimelineWidth > this.zoomBreakPoints[key]
      );
      this.zoomMinBreakPointIndex = alreayIncludeZoomBreakPointKey.length - 1; // index is zero base.
      this.$emit("handleZoomMaxValue", (MAX_INDEX - this.zoomMinBreakPointIndex) * 50 + 100);
    },
    getTimelineWidth() {
      return this.$refs.timeline ? this.$refs.timeline.clientWidth : 0;
    },
    getTimePositionRate(targetTime) {
      const rate = targetTime / this.videoEndTime;
      return rate * 100;
    },
    getTimeSecond(positionX) {
      const timelineWidth = this.getTimelineWidth();
      return (positionX / timelineWidth) * this.videoEndTime;
    },
    judgeLowerThanGapDistance(time) {
      const timelineWidth = this.getTimelineWidth();
      const gapDistance = 7;
      return (timelineWidth / this.videoEndTime) * time < gapDistance;
    },
    handleWheel(evt) {
      evt.preventDefault();
      this.$refs.scrollContainer.scrollLeft += evt.deltaY;
    },
    handleScroll() {
      const { scrollLeft, clientWidth, scrollWidth } = this.$refs.scrollContainer;
      this.scrollLeft = scrollLeft;
      const maxScrollLeft = scrollWidth - clientWidth;
      this.$refs.scrollShadowLeft.style.opacity = scrollLeft === 0 ? 0 : 1;
      this.$refs.scrollShadowRight.style.opacity = scrollLeft === maxScrollLeft ? 0 : 1;
    },
    handleResetSelfTargetElmOnly(type) {
      if (this.targetElm !== type) return;
      this.targetElm = "";
    },
    handleSetCurrentTime(e) {
      if (this.targetElm !== "currentTime") return;
      this.isMadeChange = true;
      let currentTime = this.getTimeSecond(e.offsetX);
      if (this.judgeLowerThanGapDistance(Math.abs(currentTime - this.startTime))) {
        currentTime = this.startTime;
      } else if (this.judgeLowerThanGapDistance(Math.abs(currentTime - this.endTime))) {
        currentTime = this.endTime;
      }
      this.$emit("handleVideoSetCurrentTime", currentTime);
    },
    handleSetCurrentTimeAndTargetElm(e) {
      e.preventDefault();
      this.targetElm = "currentTime";
      this.handleSetCurrentTime(e);
    },
    handleInputRangeChange(e) {
      this.isMadeChange = true;
      this.showHintMessage = false;
      const { name, value } = e.target;
      const originValue = e.target.getAttribute("origin-value");
      const [min, max] = name === "startTime" ? [0, this.endTime] : [this.startTime, this.videoEndTime];
      const isValidRange = isValidTimeRange(value, min, max);
      const isValidCutRange = isValidSelectedTimeRange(name, value, min, max);
      if (!isValidRange || !isValidCutRange) {
        e.target.value = originValue;
        return;
      }

      const payload = { name, value: Number(value) };
      const time = Math.abs(this.currentTime - Number(value));
      if (this.judgeLowerThanGapDistance(time)) {
        payload.value = this.currentTime;
      }
      this.$emit("handleProgressDataChange", payload);
    },
    trackInputRangeChangeByHeap() {
      this.$emit("trackInputRangeChangeByHeap", this.type);
    },
  },
  watch: {
    imageMapId: {
      immediate: true,
      async handler(imageMapId) {
        if (!imageMapId) return;
        await this.fetchImageMapFromDB(imageMapId);
      },
    },
    type: {
      immediate: false,
      handler() {
        this.showHintMessage = true;
        this.isMadeChange = false;
      },
    },
    videoEndTime: {
      immediate: false,
      handler(videoEndTime) {
        if (videoEndTime === 0) return;
        this.computeZoomMaxValue();
      },
    },
    zoomValue: {
      immediate: false,
      handler() {
        this.showHintMessage = false;
        this.$nextTick(() => {
          this.timelineWidthForPreview = this.getTimelineWidth();
          this.$refs.startDrag.scrollIntoView({
            inline: "center",
          });
        });
      },
    },
  },
};
</script>

<style scoped lang="scss">
$z-index: (
  listen-layer-tracking: 1001,
  input-thumb: 1000,
  listen-layer-normal: 31,
  current-time: 30,
  display-fake-thumb: 20,
  tooltip: 1,
  shadow: 1,
);

@mixin drag-btn {
  position: absolute;
  top: 0;
  height: 100%;
  width: var(--drag-button-width);
  background-color: var(--primary);
  overflow: hidden;
  cursor: ew-resize;
  pointer-events: auto;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  z-index: map-get($z-index, display-fake-thumb);
}

@mixin shadow {
  position: absolute;
  content: " ";
  background: linear-gradient(270deg, #0a0a0a 0%, rgba(10, 10, 10, 0) 100%);
  top: 5px;
  width: 50px;
  height: 65px;
  z-index: map-get($z-index, shadow);
  opacity: 0;
}

@mixin tooltip {
  top: -35px;
  position: absolute;
  z-index: map-get($z-index, tooltip);
  border-radius: 4px;
  padding: 4px 8px;
  &--text {
    position: relative;
    width: 100%;
    height: 100%;
    background: inherit;
  }
  &--arrow {
    left: calc(50% - 5px);
    position: absolute;
    background: inherit;
    bottom: -8px;
    transform: translate(10px, 10px);
    width: 10px;
    height: 10px;
    transform: rotate(-45deg);
  }
}

.ZoomTimeline {
  --primary: #4a90e2;
  --timeline-height: 64px;
  --timeline-width: 100%;
  --drag-button-width: 18px;
  position: relative;
  width: 100%;
  height: 85px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  &__container {
    position: relative;
    margin-top: 4px;
    height: var(--timeline-height);
    width: var(--timeline-width);
    min-width: var(--timeline-width);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__display-inner-container {
    position: relative;
    height: 100%;
    width: 100%;
    margin: 0 var(--drag-button-width);
  }

  &__preview-images {
    display: flex;
    background: #2c2d32;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    pointer-events: none;
    border-radius: 4px;
    overflow: hidden;
  }

  &__current-time {
    position: absolute;
    background: white;
    width: 4px;
    height: 72px;
    top: -4px;
    left: 0;
    border-radius: 1.5px;
    z-index: map-get($z-index, current-time);

    &.isTouchBlueHandler {
      background: #4689f3;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.8);
      width: 3px;
    }
  }

  &__drag-start-btn {
    @include drag-btn;
    left: 0;
    border-radius: 4px 0 0 4px;
    transform: translateX(-100%);
  }

  &__drag-end-btn {
    @include drag-btn;
    border-radius: 0 4px 4px 0;
    left: 100%;
  }

  &__cover-range {
    height: var(--timeline-height);
    top: 0;
    position: absolute;
    opacity: 0.5;
  }

  &__cover--delete {
    background: repeating-linear-gradient(45deg, #e03535, #e03535 2px, #ffffff 2px, #ffffff 4px);
  }

  &__cover--record {
    background: #1a1a1a;
    opacity: 0.8;
  }

  &__listen-event-layer {
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    height: var(--timeline-height);
    width: 100%;
    z-index: map-get($z-index, "listen-layer-normal");
    &.ZoomTimeline__listen-event-layer--disabled {
      pointer-events: none;
    }
    &.ZoomTimeline__listen-event-layer--tracking {
      z-index: map-get($z-index, "listen-layer-tracking");
    }
  }
}

.ZoomTimeline__scroll-container {
  width: 100%;
  height: 100%;
  overflow-x: scroll;

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: rgba(26, 26, 26, 0.5);
    border-radius: 65px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb:hover {
    background: #585858 !important;
    border-radius: 65px;
  }
}

.ZoomTimeline__input-range {
  position: absolute;
  z-index: map-get($z-index, input-thumb);
  appearance: none;
  width: calc(var(--timeline-width) - var(--drag-button-width));
  height: var(--timeline-height);
  outline: none;
  margin: auto;
  top: 0;
  background-color: transparent;
  pointer-events: none;
  &[name="startTime"] {
    left: 0px;
  }
  &[name="endTime"] {
    left: var(--drag-button-width);
  }

  &::-webkit-slider-thumb {
    cursor: ew-resize;
    appearance: none;
    pointer-events: all;
    background: transparent;
    height: var(--timeline-height);
    width: var(--drag-button-width);
  }
  &::-moz-range-thumb {
    cursor: ew-resize;
    pointer-events: all;
    height: var(--timeline-height);
    width: var(--drag-button-width);
    background-color: transparent;
    border: 0;
  }
}

.ZoomTimeline__display-outer-container {
  width: 100%;
  position: relative;
  height: 1px;
  &--shadow-left {
    @include shadow;
    transform: rotate(-180deg);
    left: 0;
  }
  &--shadow-right {
    @include shadow;
    right: 0;
  }

  &--slot {
    position: absolute;
    text-align: center;
  }
}

.ZoomTimeline__hint-tooltip {
  @include tooltip;
  background-color: var(--primary);
  width: auto;
  left: 50%;
  transform: translateX(-50%);
  &--text {
    color: white;
  }
}

.ZoomTimeline__time-tooltip {
  @include tooltip;
  background-color: white;
  width: 80px;
  &--text {
    color: #2d2d2d;
  }
}
</style>
