<template>
  <section class="user-invite-create">
    <v-card width="466" color="#FFFFFF" class="popup-box fullscreen-center">
      <button class="close-btn" @click="$emit('closePopup')" :autotest="getAutotest('close')">
        <icon-base color="#4A4A4A" width="16" height="16">
          <d-icon-close2 />
        </icon-base>
      </button>

      <div class="title-div">
        <d-icon-workspace width="170" />
        <div>
          <div class="popup-title">
            <span v-if="showPopupType === 'create'">{{ $t("admin.createWorkspaceGroup") }}</span>
            <span v-if="showPopupType === 'edit'">{{ $t("admin.editWorkspaceGroup") }}</span>
          </div>
          <div>
            <v-form v-model="formValid" @submit.prevent autocomplete="off" :style="{ margin: '42px 0 72px 0' }">
              <d-flex class="xs12 mb-4">
                <d-text-field
                  v-if="isInitialized"
                  v-model="workspaceGroupName"
                  :disabled="disabledWorkspaceGroupName"
                  :label="$t('admin.workspaceGroupName') + '*'"
                  :rules="[rules.required, rules.noAllSpace, rules.workspaceGroupNameAllowed]"
                  :class="{ 'disabled-input': disabledWorkspaceGroupName }"
                  autofocus
                  class="create-input"
                  autocomplete="chrome-off"
                  :autotest="getAutotest('name-input')"
                />
              </d-flex>

              <v-select
                :items="languageList"
                v-model="selectedLanguage"
                dense
                :rules="[rules.required]"
                :autotest="getAutotest('language-select')"
                class="text-capitalize language-select"
                outline
              >
                <template slot="label">
                  <span class="text-capitalize">{{ $t("admin.defaultLanguage") }}*</span>
                </template>
                <template slot="selection" slot-scope="props">
                  <span class="text-capitalize">
                    {{ getUILocaleName(props.item) }}
                  </span>
                </template>
                <template slot="item" slot-scope="props">
                  <span class="text-capitalize">
                    {{ getUILocaleName(props.item) }}
                  </span>
                </template>
              </v-select>
            </v-form>
          </div>

          <v-card-actions class="btn-div">
            <v-spacer></v-spacer>
            <d-button
              v-if="showPopupType === 'create'"
              depressed
              round
              class="body-2 white--text text-capitalize btn-width"
              color="#4689F3"
              :disabled="createButtonDisabled"
              @click="createWorkspaceGroup"
              light
              autotest="create-workspace-group-popup-create-btn"
              >{{ $t("all.create") }}</d-button
            >
            <d-button
              v-if="showPopupType === 'edit'"
              depressed
              round
              class="body-2 white--text text-capitalize btn-width"
              color="#4689F3"
              :disabled="saveButtonDisabled"
              @click="updateWorkspaceGroup"
              light
              autotest="edit-workspace-group-popup-save-btn"
              >{{ $t("all.save") }}</d-button
            >
          </v-card-actions>
        </div>
      </div>
    </v-card>

    <div class="popup-close" @click="$emit('closePopup')"></div>
  </section>
</template>

<script>
import DTextField from "@/components/ui_components/DTextField.vue";
import IconBase from "@/components/IconBase.vue";
import DIconClose2 from "@/components/icons/DIconClose2.vue";
import DIconWorkspace from "@/components/icons/colored/DIconWorkspace.vue";
import { checkAdminCommonCharacters } from "@/js/character-validator/character-validator.js";
import { DEFAULT_LOCALE, MIGRATE_TO_CURRENT_UI_LOCALE_MAP, UI_LOCALES, getUILocaleName } from "@/lang/i18n";

export default {
  name: "AdminWorkspaceGroupsPopup",
  components: {
    DTextField,
    IconBase,
    DIconClose2,
    DIconWorkspace,
  },
  props: {
    showPopupType: String,
    editedWorkspaceGroup: Object,
    isProcessingAction: Boolean,
  },
  data() {
    return {
      workspaceGroupName: "",
      languageList: UI_LOCALES,
      selectedLanguage: DEFAULT_LOCALE,
      isInitialized: false,
      formValid: false,
      rules: {
        required: (value) => !!value || this.$t("rules.required"),
        noAllSpace: (value) => {
          const patt = /^[\s]*$/;
          return !patt.test(value) || this.$t("rules.noAllSpace");
        },
        workspaceGroupNameAllowed: () => !this.workspaceGroupNameNotAllowed || this.$t("rules.invalidName"),
      },
    };
  },
  computed: {
    workspaceGroupNameNotAllowed() {
      return checkAdminCommonCharacters(this.workspaceGroupName);
    },
    disabledWorkspaceGroupName() {
      return this.editedWorkspaceGroup.id === this.$defaultWorkspaceGroupId;
    },
    createButtonDisabled() {
      return !this.formValid || this.isProcessingAction;
    },
    saveButtonDisabled() {
      const isFormInvalid = !this.formValid;
      const isUnchanged =
        this.workspaceGroupName === this.editedWorkspaceGroup.name &&
        this.selectedLanguage === this.editedWorkspaceGroup.locale;
      const isProcessing = this.isProcessingAction;

      return isFormInvalid || isUnchanged || isProcessing;
    },
  },
  created() {},
  mounted() {
    this.initialize();
  },
  methods: {
    getUILocaleName,
    getAutotest(tag) {
      const autotestMapping = {
        create: {
          close: "create-workspace-group-popup-close-btn",
          "name-input": "create-workspace-group-popup-name-input",
          "language-select": "create-workspace-group-popup-language-dropdown",
        },
        edit: {
          close: "edit-workspace-group-popup-close-btn",
          "name-input": "edit-workspace-group-popup-name-input",
          "language-select": "edit-workspace-group-popup-language-dropdown",
        },
      };
      return autotestMapping[this.showForm] && autotestMapping[this.showForm][tag]
        ? autotestMapping[this.showForm][tag]
        : "";
    },
    initialize() {
      if (this.showPopupType === "edit") {
        this.setFormFields();
      }
      this.isInitialized = true;
    },
    setFormFields() {
      const { name, locale } = this.editedWorkspaceGroup;
      this.workspaceGroupName = name;
      this.selectedLanguage = MIGRATE_TO_CURRENT_UI_LOCALE_MAP[locale] || locale;
    },
    createWorkspaceGroup() {
      this.$emit("addWorkspaceGroup", { name: this.workspaceGroupName, locale: this.selectedLanguage });
    },
    updateWorkspaceGroup() {
      const payload = {
        id: this.editedWorkspaceGroup.id,
        name: this.workspaceGroupName,
        locale: this.selectedLanguage,
      };
      this.$emit("updateWorkspaceGroup", payload);
    },
  },
};
</script>

<style scoped lang="scss">
.disabled-input ::v-deep {
  color: #98a1b3 !important;
  .input-box {
    background-color: #eff2f7 !important;
  }
}

.create-input ::v-deep {
  .primary--text {
    caret-color: #888888 !important;
    color: rgba(0, 0, 0, 0.54) !important;
  }
  .field-label {
    font-weight: 400 !important;
    font-size: 12px !important;
    margin-bottom: 8px !important;
    color: rgba(0, 0, 0, 0.54) !important;
    margin-left: 5px !important;
  }
}
.language-select ::v-deep {
  .primary--text {
    color: rgba(0, 0, 0, 0.54) !important;
  }
  .v-select__selections {
    padding-top: 0 !important;
  }
  .v-input__slot {
    min-height: 40px !important;
    align-items: center !important;
    border: 1px solid #dbdbdb !important;
  }
  .v-input__append-inner {
    margin-top: 4px !important;
  }
  .v-label {
    top: -20px !important;
    left: -10px !important;
    transition: none !important;
  }
}
.user-invite-create {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
}
.close-btn {
  position: absolute;
  right: 24px;
  top: 24px;
}
.fullscreen-center {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.popup-box {
  position: relative;
  margin-top: 36px;
  padding: 48px 24px;
  margin: 0 auto;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.35);
}
.title-div {
  position: relative;
  width: 100%;
  text-align: center;
  padding: 28px 20px 0 20px;
}
.popup-title {
  color: #52545d;
  font-family: Roboto;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
  margin-top: 12px;
}

.popup-close {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.no-text-transform {
  text-transform: none !important;
}
.btn-div {
  margin-top: 32px;
}
.btn-width {
  width: 120px;
}
.dropdown-btn-menu {
  width: 100%;
}
.dropdown-btn {
  position: relative;
  width: 100%;
  left: 0;
  height: 46px;
}
.dropdown-text {
  position: relative;
  top: -2px;
  padding: 0;
  font-size: 16px;
  line-height: 19px;
}
.dropdown-arrow {
  position: absolute;
  right: 0;
  top: 20px;
}
.input-language-code {
  pointer-events: none;
}
</style>
