<template>
  <div class="CommentCard">
    <!-- "display" mode -->
    <div
      v-if="selectedComment.edit !== comment.id && selectedComment.delete !== comment.id"
      class="CommentCard__card"
      :class="{
        'CommentCard__card--desktopHover': !isMobileDevice,
        'CommentCard__card--highlight': selectedComment.updated === comment.id || selectedComment.new === comment.id,
      }"
    >
      <comment-card-display :comment="comment" :isMobileDevice="isMobileDevice" />
    </div>

    <!-- "edit" mode -->
    <div v-else-if="selectedComment.edit === comment.id" class="CommentCard__card CommentCard__card--highlight">
      <comment-card-edit :comment="comment" />
    </div>

    <!-- "delete" mode -->
    <div
      v-else-if="selectedComment.delete === comment.id"
      class="CommentCard__card CommentCard__card--delete"
      :class="{ 'CommentCard__card--deleted': selectedComment.deleted === comment.id }"
    >
      <comment-card-delete :comment="comment" />
    </div>
  </div>
</template>

<script>
import CommentCardDisplay from "@/components/DWorkflowPlayer/comments/CommentCardDisplay.vue";
import CommentCardEdit from "@/components/DWorkflowPlayer/comments/CommentCardEdit.vue";
import CommentCardDelete from "@/components/DWorkflowPlayer/comments/CommentCardDelete.vue";
import { mapState } from "vuex";

export default {
  name: "CommentCard",
  components: { CommentCardDisplay, CommentCardEdit, CommentCardDelete },
  props: {
    comment: {
      type: Object,
      require: true,
    },
    isMobileDevice: Boolean,
  },
  computed: {
    ...mapState("workflowComments", ["selectedComment"]),
  },
};
</script>

<style lang="scss" scoped>
.CommentCard {
  position: relative;
  padding: 4px 0;
  border-bottom: 1px solid #33353a;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  &__card {
    padding: 8px 12px;
    border-radius: 4px;
    background: none;
    transition: 0.3s;
    &--desktopHover {
      &:hover {
        background: #28292e;
      }
    }
    &--highlight {
      background: #28292e;
    }
    &--delete {
      max-height: 200px;
      background: #28292e;
      transition: 0.3s;
    }
    &--deleted {
      padding: 0 24px;
      max-height: 0;
      overflow: hidden;
      transition: 0.3s;
    }
  }
}
</style>
