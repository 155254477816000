import axios from "axios";
import authChoice from "../../auth";
import environment from "../../env";
import { apiWrapperGenerator } from "./lib";

function getBaseUrl() {
  return environment.backendType === "firebase"
    ? environment.service[environment.env].admin
    : environment.service[environment.env]["china"].admin;
}

const kahunaIntegrationServer = axios.create({
  baseURL: getBaseUrl(),
});
kahunaIntegrationServer.interceptors.request.use(
  async (config) => {
    config.headers["x-user-token"] = await authChoice().currentUser.getIdToken();
    return config;
  },
  (error) => Promise.resolve(error)
);
const apiWrapper = apiWrapperGenerator(kahunaIntegrationServer);

export async function getKahunaWorkspaces({ organizationId }) {
  return await apiWrapper("get", `/v1/kahuna/${organizationId}/workspaces`);
}

export async function getKahunaWorkspace({ organizationId, workspaceId }) {
  return await apiWrapper("get", `/v1/kahuna/${organizationId}/workspaces/${workspaceId}`);
}

export async function createKahunaWorkspaces({ organizationId, syncMode, workspaceIds }) {
  return await apiWrapper("post", `/v1/kahuna/${organizationId}/workspaces/batch-create`, {
    syncMode,
    workspaceIds,
  });
}

export async function deleteKahunaWorkspaces({ organizationId, workspaceIds }) {
  return await apiWrapper("post", `/v1/kahuna/${organizationId}/workspaces/batch-delete`, {
    workspaceIds,
  });
}
