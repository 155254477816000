import { escapeHtml } from "@/js/xss/escape-html.js";

const VEscapeHtml = {
  install(Vue) {
    const name = "escapeHtml";

    Vue.prototype[`$${name}`] = (dirty) => {
      return escapeHtml(dirty);
    };
  },
};

export default VEscapeHtml;
