import environment from "../../../env";
import { IS_PRODUCTION_APP } from "@/constants/envVersion";

const IS_DEV = environment.env === "dev";
const IS_BETA = window.location.hostname.includes("beta");

export function getReactionDisplayName({ name, vueInstanceRef }) {
  switch (name) {
    case "like_it":
      return vueInstanceRef.$t("reactions.likeIt");
    case "great_content":
      return vueInstanceRef.$t("reactions.greatContent");
    case "well_presented":
      return vueInstanceRef.$t("reactions.wellPresented");
    case "nice_production":
      return vueInstanceRef.$t("reactions.niceProduction");
    default:
      return "";
  }
}

export function getReactionSetByEnv({ allReactions }) {
  if (IS_DEV) {
    return allReactions.filter((reaction) => reaction.isDev);
  } else if (IS_BETA) {
    return allReactions.filter((reaction) => reaction.isBeta);
  } else if (IS_PRODUCTION_APP) {
    return allReactions.filter((reaction) => reaction.isProd);
  } else {
    return [];
  }
}

export function getFilteredUserReactionsData({ reactionSet, allUserReactions }) {
  let reactionSetIds = [];
  reactionSet.forEach((reaction) => {
    reactionSetIds.push(reaction.id);
  });
  const filteredUserReactions = allUserReactions.filter((item) => reactionSetIds.indexOf(item.reaction) >= 0);
  return filteredUserReactions;
}
