<template>
  <section class="confirmation-popup">
    <v-card width="466" color="#FFFFFF" class="popup-box fullscreen-center">
      <button class="close-btn" @click="$emit('closePopup')" autotest="downgrade-org-admin-popup-close-btn">
        <icon-base color="#4A4A4A" width="16" height="16">
          <d-icon-close2 />
        </icon-base>
      </button>
      <div class="title-div">
        <div class="avatar-div">
          <d-avatar :size="130" :userId="formData.id" class="avatar-pic" />
          <div class="org-admin-icon">
            <icon-base width="24" height="24" color="#EBEBEB">
              <d-icon-org-admin-outline />
            </icon-base>
          </div>
        </div>
        <div>
          <div class="title-text">{{ $t("admin.downgradeOrgAdmin") }}</div>
          <div
            class="subtitle-text"
            v-html="$t('admin.popupMsgDowngradeOrgAdmin', { name: formData.displayName })"
          ></div>
        </div>

        <div class="select-role-div">
          <v-menu transition="slide-y-transition" bottom offset-y class="select-role-menu">
            <button slot="activator" class="select-role-btn" autotest="downgrade-org-admin-popup-role-dropdown">
              <icon-base color="#4689F3" height="18" width="18" class="user-role-icon">
                <d-icon-user-role />
              </icon-base>
              <span v-if="selectedRole">{{ roleDictionary[selectedRole] }}</span>
              <span v-else class="select-role-text">{{ $t("admin.selectRole") }}</span>
              <v-spacer></v-spacer>
              <icon-base color="#1E1F22" height="14" width="14" class="select-role-arrow">
                <d-icon-arrow-forward />
              </icon-base>
            </button>
            <div>
              <v-list dense light>
                <v-list-tile
                  v-for="(role, i) in roleOptions"
                  :key="i"
                  class="dropdown-list-item"
                  @click="selectedRole = role"
                >
                  <v-list-tile-title class="dropdown-list-text text-capitalize">{{
                    roleDictionary[role]
                  }}</v-list-tile-title>
                </v-list-tile>
              </v-list>
            </div>
          </v-menu>
        </div>
      </div>
      <d-layout class="px-4">
        <d-flex class="xs6 mr-2">
          <d-button
            outline
            depressed
            round
            block
            class="body-2 text-capitalize"
            color="#4689f4"
            light
            @click="$emit('closePopup')"
            autotest="downgrade-org-admin-popup-no-btn"
            >{{ $t("all.no") }}</d-button
          >
        </d-flex>
        <d-flex class="xs6 ml-2">
          <d-button
            depressed
            round
            block
            class="body-2 white--text no-text-transform"
            color="#4689F3"
            :disabled="!selectedRole"
            light
            @click="downgradeWithOrgAdminRole()"
            autotest="downgrade-org-admin-popup-yes-btn"
            >{{ $t("admin.yesDowngrade") }}</d-button
          >
        </d-flex>
      </d-layout>
    </v-card>
    <div class="popup-close" @click="$emit('closePopup')"></div>
    <d-alert v-model="alert.isShow" :type="alert.type" :message="alert.message"></d-alert>
  </section>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import DIconClose2 from "@/components/icons/DIconClose2.vue";
import DIconOrgAdminOutline from "@/components/icons/DIconOrgAdminOutline.vue";
import DIconArrowForward from "@/components/icons/DIconArrowForward.vue";
import DIconUserRole from "@/components/icons/DIconUserRole.vue";
import DAvatar from "@/components/ui_components/DAvatar.vue";
import MixinDB from "@/components/MixinDB.vue";
import MixinUser from "@/components/MixinUser.vue";
import DAlert from "@/components/ui_components/DAlert.vue";
import { downgradeWithOrgAdminRole } from "@/js/user-role/user-role.js";
import { flow, toPairs, fromPairs } from "lodash-es";
export default {
  name: "DowngradeOrgAdminConfirmation",
  props: {
    formData: Object,
  },
  mixins: [MixinDB, MixinUser],
  components: {
    IconBase,
    DIconClose2,
    DIconOrgAdminOutline,
    DIconArrowForward,
    DIconUserRole,
    DAvatar,
    DAlert,
  },
  data() {
    return {
      alert: {
        type: null,
        isShow: false,
        message: "",
      },
      isDowngrading: false,
      roleOptions: ["viewer", "publisher", "admin-group"],
      roleDictionary: {
        viewer: this.$t("all.viewer"),
        publisher: this.$t("all.publisher"),
        "admin-group": this.$t("all.workspaceAdmin"),
        "admin-organization": this.$t("all.organizationAdmin"),
      },
      selectedRole: "",
    };
  },
  methods: {
    showAlertMessage({ message, type }) {
      this.alert.isShow = true;
      this.alert.type = type;
      this.alert.message = message;
    },
    downgradeWithOrgAdminRole() {
      if (this.isDowngrading) {
        return;
      }
      /**The Downgrade will change all workspace to same role */
      this.isDowngrading = true;
      downgradeWithOrgAdminRole({
        vueInstanceRef: this,
        userData: {
          role: this.selectedRole,
          email: this.formData.email,
        },
      })
        .then((response) => {
          const isSuccess = !!response;
          const selectedRoleName = this.roleDictionary[this.selectedRole];
          if (isSuccess) {
            this.showAlertMessage({
              message: this.$t("admin.alertSuccessfullyDowngradeToOrg", { userRole: selectedRoleName }),
              type: "success",
            });

            this.$emit("update-success", {
              targetUserData: this.formData,
            });

            /** update vuex data */
            const newRole = this.selectedRole;
            const replaceWithNewRole = (list) => {
              return list.map(([groupId, oldRole]) => [groupId, newRole]);
            };
            const newRoleMapInAllWorkspace = flow([toPairs, replaceWithNewRole, fromPairs])(this.formData.roles);
            const isToRemoveUser = false; // true is update user data

            this.$store.dispatch("updateUser", {
              user: this.formData,
              update: {
                role: newRole,
                roles: newRoleMapInAllWorkspace,
                disabled: isToRemoveUser,
              },
              action: "update",
            });
          } else {
            this.showAlertMessage({
              message: this.$t("admin.alertFailedDowngradeToOrg", { userRole: selectedRoleName }),
              type: "error",
            });
          }

          return new Promise((resolve) => {
            setTimeout(() => {
              this.$emit("closePopup");
              resolve();
            }, 1000);
          });
        })
        .finally(() => {
          this.isDowngrading = false;
        });
    },
  },
};
</script>
<style scoped>
.confirmation-popup {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
}
.close-btn {
  position: absolute;
  right: 24px;
  top: 24px;
}
.fullscreen-center {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.popup-box {
  position: relative;
  margin-top: 36px;
  padding: 48px 24px;
  margin: 0 auto;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.35);
}
.avatar-div {
  position: relative;
  display: inline-block;
  height: 130px;
  width: 130px;
}
.avatar-pic {
  border-radius: 50%;
  border: 1px solid #dadada;
}
.org-admin-icon {
  position: absolute;
  right: -8px;
  top: 0;
  height: 40px;
  width: 40px;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 6px;
  border: #ffbf18 1px dashed;
}
.title-div {
  position: relative;
  width: 100%;
  text-align: center;
  padding: 24px 24px 0 24px;
  margin-bottom: 24px;
}

.title-text {
  color: #52545d;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
  margin-top: 24px;
}
.subtitle-text {
  margin: 12px 0 16px 0;
  word-wrap: normal;
  word-break: break-word;
  color: #8d909f;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
}
.subtitle-text ::v-deep b {
  font-weight: 500 !important;
}
.popup-close {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.no-text-transform {
  text-transform: none !important;
}
.select-role-menu {
  width: 100%;
}
.select-role-div {
  position: relative;
  height: 40px;
  width: 100%;
  margin-bottom: 60px;
}
.select-role-btn {
  height: 40px;
  width: 100%;
  text-align: left;
  padding: 9px 16px 0 16px;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  background-color: #ffffff;
  color: #52545d;
  font-size: 16px;
  line-height: 20px;
  display: flex;
}
.select-role-text {
  color: #8d909f;
}
.select-role-arrow {
  height: 22px;
  width: 14px;
  transform: rotate(90deg);
}
.user-role-icon {
  margin: 1px 8px 0 0;
}
.dropdown-list-item {
  color: #78767a;
}
.dropdown-list-item:hover {
  background-color: #f5f5f5 !important;
}
.dropdown-list-text {
  color: #52545d;
  font-size: 16px;
}
</style>
