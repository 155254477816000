import { checkIsSignedUrl, getSignedURL } from "@/server/sign-server.js";

export const getSignedImageUrl = async (src) => {
  try {
    if (!src) return "";
    const isSigned = checkIsSignedUrl(src);
    if (isSigned) {
      return src;
    }
    const prepareToSignUrl = src.includes("?") ? src.split("?")[0] : src;
    const signedUrl = await getSignedURL({ src: prepareToSignUrl });
    return signedUrl;
  } catch (e) {
    return "";
  }
};

export const getImageBlob = (signedUrl) => {
  return new Promise(function (resolve, reject) {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", signedUrl, true);
    xhr.responseType = "arraybuffer";
    xhr.onload = function (e) {
      if (e.target.status === 200) {
        const arrayBufferView = new Uint8Array(this.response);
        const blob = new Blob([arrayBufferView], { type: "image/jpeg" });
        const urlCreator = window.URL || window.webkitURL;
        const blobUrl = urlCreator.createObjectURL(blob);
        resolve({ ok: true, url: blobUrl });
        return;
      }
      console.error("fetch signedUrl error", signedUrl);
      resolve({ ok: false, url: "" });
    };
    xhr.onerror = function (e) {
      console.error("fetch signedUrl error", signedUrl);
      resolve({ ok: false, url: "" });
    };
    xhr.send();
  });
};
