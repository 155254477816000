import { getUserProfileApi } from "@/server/api-server.js";
import { checkIpAddress } from "@/server/admin-server.js";
import { CHECKING_RESTRICTED_IP_STAUTS } from "@/constants/auth";
import { getIsIpPassedLocalStorage, setIsIpPassedLocalStorage } from "@/js/login/checkRestrictedIp";
import { registerUserLastInteraction } from "@/server/login-activity-server";
import userGuide from "@/js/userGuide";

export const namespaced = true;

export const state = {
  checkIpStatus: CHECKING_RESTRICTED_IP_STAUTS.TODO,
  clientIP: "",
  isLogin: false,
  userProfile: null,
};

export const actions = {
  setIsCheckIpNotAllowed({ commit }) {
    setIsIpPassedLocalStorage(false);
    commit("SET_IP_STATUS", CHECKING_RESTRICTED_IP_STAUTS.NOT_ALLOWED);
  },
  async checkIp({ state, commit }) {
    try {
      if (!state.isLogin) {
        return;
      }
      const isIpPassed = getIsIpPassedLocalStorage();
      if (isIpPassed) {
        commit("SET_IP_STATUS", CHECKING_RESTRICTED_IP_STAUTS.PASSED);
        return;
      }
      commit("SET_IP_STATUS", CHECKING_RESTRICTED_IP_STAUTS.CHECKING);
      const checkIpResult = await checkIpAddress();
      console.log(checkIpResult.data.item);
      const hasAllowedKey = checkIpResult?.data?.item?.allowed !== undefined;
      const checkIpStatus = hasAllowedKey
        ? checkIpResult.data.item?.allowed
          ? CHECKING_RESTRICTED_IP_STAUTS.PASSED
          : CHECKING_RESTRICTED_IP_STAUTS.NOT_ALLOWED
        : CHECKING_RESTRICTED_IP_STAUTS.CONNECT_FAILED;
      const checkIpOK = checkIpStatus === CHECKING_RESTRICTED_IP_STAUTS.PASSED;
      setIsIpPassedLocalStorage(checkIpOK);
      commit("SET_IP_STATUS", checkIpStatus);
      commit("SET_CLIENT_IP", checkIpResult.data?.item?.clientIP ?? "");
    } catch (e) {
      console.log(e);
      commit("SET_IP_STATUS", CHECKING_RESTRICTED_IP_STAUTS.TODO);
      commit("SET_CLIENT_IP", "");
    }
  },
  async getUserProfile({ commit }) {
    const { ok, data } = await getUserProfileApi();
    if (ok) {
      const userProfile = { ...data.item, changeCount: 0 };
      commit("SET_USER_PROFILE", userProfile);
      commit("setUserProfile", { data: userProfile }, { root: true });
      registerUserLastInteraction();
      userGuide.updateUserInfo(userProfile.id, {
        organizationId: userProfile.organizationInfo.id,
        highestRole: userProfile.role,
        uiLanguage: userProfile.locale,
        dateCreated: userProfile.createdAt,
        disabled: false,
      });
    } else {
      commit("LOGOUT");
    }
  },
  async logout({ commit }) {
    commit("LOGOUT");
  },
};
export const getters = {
  getter_user_role(state) {
    return state.userProfile?.role ?? "";
  },
  getter_user_ip_passed(state) {
    return state.checkIpStatus === CHECKING_RESTRICTED_IP_STAUTS.PASSED;
  },
  getter_user_ip_not_allowed(state) {
    return state.checkIpStatus === CHECKING_RESTRICTED_IP_STAUTS.NOT_ALLOWED;
  },
  getter_user_organization() {
    return state.userProfile.organizationInfo.id;
  },
  getter_user_id() {
    return state.userProfile.id;
  },
};

export const mutations = {
  LOGOUT(state) {
    setIsIpPassedLocalStorage(false);
    state.clientIP = "";
    state.isLogin = false;
    state.userProfile = null;
    state.checkIpStatus = CHECKING_RESTRICTED_IP_STAUTS.TODO;
  },
  SET_CLIENT_IP(state, payload) {
    state.clientIP = payload;
  },
  SET_IP_STATUS(state, payload) {
    state.checkIpStatus = payload;
  },
  SET_IS_LOGIN(state, payload) {
    state.isLogin = payload;
  },
  SET_USER_PROFILE(state, payload) {
    state.userProfile = payload;
  },
};
