<template>
  <div class="integrations">
    <div class="integrations__pageTitle" v-html="$tc('admin.integrationCount', integrationsCounts)"></div>

    <div class="integrations__pageMainWrapper">
      <d-layout class="integrations__pageMain">
        <d-flex class="integrations__sideMenu sm2" v-if="integrationsCounts >= 2">
          <admin-setting-integrations-menu
            @setIntegrationPage="setIntegrationPage"
            :integrationsMenu="integrationsMenu"
            :integrationPage="integrationPage"
          ></admin-setting-integrations-menu>
        </d-flex>

        <d-flex class="sm10" :class="{ sm12: integrationsCounts <= 1 }">
          <admin-setting-sso-mapping v-if="integrationPage === 'sso-mapping'"></admin-setting-sso-mapping>
          <admin-setting-kahuna v-if="integrationPage === 'kahuna'"></admin-setting-kahuna>
          <admin-setting-rustici
            v-if="integrationPage === 'rustici'"
            :isBottomTooltip="isBottomTooltip"
          ></admin-setting-rustici>
        </d-flex>
      </d-layout>
    </div>
  </div>
</template>

<script>
import MixinUser from "@/components/MixinUser.vue";
import MixinDB from "@/components/MixinDB.vue";
import AdminSettingIntegrationsMenu from "@/components/DAdmin/Settings/integrations/AdminSettingIntegrationsMenu.vue";
import AdminSettingSsoMapping from "@/components/DAdmin/Settings/integrations/AdminSettingSsoMapping.vue";
import AdminSettingKahuna from "@/components/DAdmin/Settings/integrations/AdminSettingKahuna.vue";
import AdminSettingRustici from "@/components/DAdmin/Settings/integrations/AdminSettingRustici.vue";
import { mapGetters } from "vuex";

export default {
  name: "DAdminIntegrations",
  components: {
    AdminSettingIntegrationsMenu,
    AdminSettingSsoMapping,
    AdminSettingKahuna,
    AdminSettingRustici,
  },
  mixins: [MixinUser, MixinDB],
  props: {
    isBottomTooltip: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      integrationsMenu: [],
      integrationPage: "",
    };
  },
  computed: {
    ...mapGetters("permission", [
      "getter_sso_mapping_enabled",
      "getter_kahuna_enabled",
      "getter_rustici_export_enabled",
    ]),
    integrationsCounts() {
      return this.integrationsMenu.length;
    },
  },
  mounted() {
    this.setIntegrationsMenu();
  },
  methods: {
    setIntegrationsMenu() {
      const menuItems = [
        { enabled: this.getter_sso_mapping_enabled, key: "sso-mapping", title: this.$t("admin.ssoMapping") },
        { enabled: this.getter_kahuna_enabled, key: "kahuna", title: this.$t("admin.kahuna") },
        { enabled: this.getter_rustici_export_enabled, key: "rustici", title: this.$t("admin.lmsExporter") },
      ];
      const filteredMenuItems = menuItems
        .filter((item) => item.enabled)
        .map((item) => ({
          key: item.key,
          title: item.title,
        }));
      this.integrationsMenu = filteredMenuItems;
      this.integrationPage = filteredMenuItems[0].key;
    },
    setIntegrationPage(page) {
      this.integrationPage = page;
    },
  },
};
</script>

<style scoped lang="scss">
.integrations {
  position: relative;
  width: 100%;
  text-align: left;
  z-index: 0;
  flex-direction: column;
  display: flex;
  padding: 0 !important;

  &__pageTitle {
    margin-bottom: 16px;
    height: 44px;
    color: #3f3f3f;
    font-family: Roboto;
    font-size: 32px;
    letter-spacing: 0;
    line-height: 44px;
    margin-top: 30px;
  }

  &__pageMainWrapper {
    width: 100%;
    padding-bottom: 100px;
  }

  &__pageMain {
    border: 1px solid #e0e0e0;
  }
}
</style>
