<template>
  <div class="PlayerSopMain" :class="{ PlayerSopMain__mobile: isMobileDevice }">
    <sop-collapse-item
      v-for="(item, index) in getter_sop_content"
      :key="index"
      :index="index"
      :item="item"
      :total="getter_sop_content.length"
    />
  </div>
</template>

<script>
import SopCollapseItem from "@/components/DWorkflowPlayer/sop/SopCollapseItem.vue";
import { mapGetters } from "vuex";

export default {
  name: "PlayerSopMain",
  components: { SopCollapseItem },
  props: {
    isMobileDevice: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("workflowPlayer", ["getter_sop_content"]),
  },
};
</script>

<style lang="scss" scoped>
.PlayerSopMain {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-05);
  width: 100%;
  margin-bottom: 32px;
}
</style>
