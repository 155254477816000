<template lang="html">
  <section class="mixin-idle">
    <!-- <h1>mixin-idle Component</h1> -->
  </section>
</template>

<script lang="js">
import eventbus from "../main";
import {
  BROWSER_TAB_ID_KEY,
  BROWSER_TAB_KEY,
  LOGOUT_NOTIFY_KEY,
  removeIdleLocalStorage,
} from "@/js/idle/idle.js";
import { removeSharedAccountFromLocalStorage, getSharedAccountFromLocalStorage } from "@/js/user/share-account.js";

export default {
  name: 'mixin-idle',
  methods: {
    updateIdleTimeout() {
      eventbus.$emit("update-idle-timer", { dateTime: new Date() });
    },
    updateIdleSetting(allowAutoLogout, autoLogoutTimeout) {
      eventbus.$emit("update-idle-setting", {allowAutoLogout: allowAutoLogout, autoLogoutTimeout: autoLogoutTimeout});
    },
    removeIdleTimeout() {
      eventbus.$emit("remove-idle-timer");
    },
    onDirectiveIdleTimeout(pause = true, idleId = "") {
      /** handle event from idle directive */
      eventbus.$emit("on-directive-idle-timer", { pause, idleId });
    },
  },
}
</script>
