import environment from "../../../env.js";
import { fetchWorkflows } from "@/server/api-server.js";

// root state object.
// each Vuex instance is just a single state tree.
export const state = {
  workflows: [],
};

// mutations are operations that actually mutates the state.
// each mutation handler gets the entire state tree as the
// first argument, followed by additional payload arguments.
// mutations must be synchronous and can be recorded by plugins
// for debugging purposes.
export const mutations = {
  addWorkflows(state, { workflows }) {
    state.workflows = [...state.workflows, ...workflows];
  },

  clearWorkflows(state) {
    state.workflows.splice(0);
    state.lastDoc = null;
  },
};

// actions are functions that cause side effects and can involve
// asynchronous operations.
export const actions = {
  //getWorkflows is called when the app first start
  async getWorkflows({ commit }, args) {
    const { page } = args;
    const isClearWorkflowArray = !args.isStopClearWorkflowArray;
    //use this when we need to manually run getWorkflows again
    if (args.getWorkflowsAgain) {
      commit("clearWorkflowsRef", {
        isClearWorkflowArray,
      });
    }

    state.getWorkflowsCounter++;
    const { ok, data } = await fetchWorkflows({
      locale: "N/A",
      filters: { group: args.group, workflows: { published: true } },
      paginate: { page: page || 1, size: 10 },
      sort: { field: args.sort, order: "DESC" },
    });
    if (ok) {
      commit("addWorkflows", { workflows: data.items.map((i) => i.workflow) });
    }
  },
};

export const namespaced = true;
