import axios from "axios";
import authChoice from "../../auth";
import environment from "../../env";
import { apiWrapperGenerator } from "./lib";
import { getEncodedTerm } from "./basic-authentication-server.js";

function getBaseUrl() {
  return environment.backendType === "firebase"
    ? environment.service[environment.env].admin
    : environment.service[environment.env]["china"].admin;
}

const authenticationServer = axios.create({
  baseURL: getBaseUrl(),
});

authenticationServer.interceptors.request.use(
  async (config) => {
    config.headers["x-user-token"] = await authChoice().currentUser.getIdToken();
    return config;
  },
  (error) => Promise.resolve(error)
);

const apiWrapper = apiWrapperGenerator(authenticationServer);

export async function resetPassword(payload) {
  const { currentPassword, newPassword } = payload;
  const encodedCurrentPassword = await getEncodedTerm(currentPassword);
  const encodedNewPassword = await getEncodedTerm(newPassword);
  const data = { currentPassword: encodedCurrentPassword, newPassword: encodedNewPassword };
  return await authenticationServer.post("/v1/auth/reset-password", data);
}

export async function getMasterPassword() {
  return await apiWrapper("get", "/v1/auth/employeeIdMP");
}

export async function resetMasterPassword(password) {
  const encodedPassword = await getEncodedTerm(password);
  return await apiWrapper("post", "/v1/auth/reset-employeeIdMP", { employeeIdMP: encodedPassword });
}

export async function createSharedAccount(payload) {
  const encodedURIpassword = encodeURIComponent(payload.password);
  const encodedPassword = await getEncodedTerm(encodedURIpassword);
  const sharedAccount = { ...payload, password: encodedPassword };
  return await apiWrapper("post", "/v1/admin/user/shared-account", sharedAccount);
}

export async function updateSharedAccount(user) {
  const { id, ...userInfo } = user;
  return await apiWrapper("patch", `/v1/admin/user/shared-account/${id}`, userInfo);
}

export async function resetSharedAccountPassword(payload) {
  const encodedURIpassword = encodeURIComponent(payload.password);
  const encodedPassword = await getEncodedTerm(encodedURIpassword);
  const data = { ...payload, password: encodedPassword };
  return await authenticationServer.post("/v1/auth/shared-account/reset-password", data);
}
