<template>
  <section class="permission-item mt-3">
    <div class="permission-item--title">
      {{ title }}
      <span v-if="beta" class="permission-item--tag ml-2">BETA</span>
    </div>
    <div class="permission-item--subtitle">{{ subtitle }}</div>
    <slot></slot>
    <div class="permission-item--switch">
      <v-switch
        v-model="allow"
        color="#4689F3"
        class="permission-item--switch__center"
        readonly
        @click.stop="$emit('click-switch')"
        :autotest="autotest"
      ></v-switch>
    </div>
  </section>
</template>

<script>
export default {
  name: "AdminSettingPermissionItem",
  props: {
    allow: {
      type: Boolean,
      default: false,
    },
    autotest: String,
    title: String,
    subtitle: String,
    beta: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.permission-item {
  position: relative;
  width: 100%;
  min-width: 760px;
  padding: 24px 88px 24px 24px;
  border: 1px solid #e7e7e7;
  background-color: #ffffff;
  box-shadow: 0 0 4px -2px rgba(0, 0, 0, 0.15);
  &--title {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    color: #3f3f3f;
    font-size: 20px;
    line-height: 20px;
  }
  &--subtitle {
    color: #919191;
    font-size: 16px;
    line-height: 20px;
  }
  &--switch {
    position: absolute;
    right: 24px;
    top: 0;
    height: 100%;
    width: 36px;
    &__center {
      position: absolute;
      top: 10px;
    }
  }
  &--tag {
    color: #ffffff;
    background-color: var(--primary-color);
    border-radius: 80px;
    line-height: 10px;
    font-size: 10px;
    font-weight: 700;
    padding: 4px;
  }
}
</style>
