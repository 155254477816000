<template>
  <section class="user-invite-create">
    <v-card width="466" color="#FFFFFF" class="popup-box fullscreen-center">
      <button class="close-btn" @click="$emit('closePopup')" :autotest="getAutotest('close')">
        <icon-base color="#4A4A4A" width="16" height="16">
          <d-icon-close2 />
        </icon-base>
      </button>

      <div class="title-div">
        <icon-base width="100" height="100">
          <d-icon-teams />
        </icon-base>
        <div>
          <div class="popup-title">
            <span v-if="showPopupType === 'create'">{{ $t("admin.createTeam") }}</span>
            <span v-if="showPopupType === 'edit'">{{ $t("admin.editTeam") }}</span>
          </div>
          <div>
            <v-form v-model="formValid" @submit.prevent autocomplete="off" class="userInviteCreate__form">
              <v-text-field
                autofocus
                v-model="teamName"
                class="create-input"
                :label="$t('admin.teamName') + '*'"
                :rules="[rules.required, rules.noAllSpace, rules.teamNameAllowed, rules.checkWorkspaceId]"
                autocomplete="chrome-off"
                @input="madeChanges = true"
                :autotest="getAutotest('name-input')"
              ></v-text-field>
              <d-composite-workspace-dropdown
                v-if="hasWorkspaceOptions"
                dropdownClass="mt-4"
                :workspaceId="selectedWorkspaceId"
                :workspaceLabel="$t('new.addToWorkspace')"
                :workspaceGroupId="selectedWorkspaceGroupId"
                @change:workspaceId="setSelectedWorkspaceId"
                @change:workspaceGroupId="setSelectedWorkspaceGroupId"
              />
            </v-form>
          </div>
          <v-card-actions class="btn-div">
            <v-spacer></v-spacer>
            <d-button
              depressed
              round
              class="body-2 white--text text-capitalize btn-width"
              color="#4689F3"
              :disabled="buttonProperties.disabled"
              light
              @click="buttonProperties.action"
              :autotest="buttonProperties.autotest"
            >
              {{ buttonProperties.text }}
            </d-button>
          </v-card-actions>
        </div>
      </div>
    </v-card>

    <div class="popup-close" @click="$emit('closePopup')"></div>
  </section>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import DIconClose2 from "@/components/icons/DIconClose2.vue";
import DIconTeams from "@/components/icons/colored/DIconTeams.vue";
import DWorkspacesDropMenu from "@/components/DSkills/DWorkspacesDropMenu.vue";
import DCompositeWorkspaceDropdown from "@/components/DStudio/DCompositeWorkspaceDropdown.vue";
import MixinDB from "@/components/MixinDB.vue";
import { checkAdminCommonCharacters } from "@/js/character-validator/character-validator.js";
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "AdminWorkspaceTeamPopup",
  props: {
    showPopupType: String,
    teams: Array,
    editedTeam: Object,
    workspaceId: String,
    workspaceName: String,
    hasWorkspaceOptions: Boolean,
  },
  components: {
    IconBase,
    DIconClose2,
    DIconTeams,
    DWorkspacesDropMenu,
    DCompositeWorkspaceDropdown,
  },
  mixins: [MixinDB],
  data() {
    return {
      teamName: "",
      formValid: false,
      madeChanges: false,
      selectedWorkspaceId: null,
      selectedWorkspaceGroupId: null,
      rules: {
        required: (value) => !!value || this.$t("rules.required"),
        noAllSpace: (value) => {
          const patt = /^[\s]*$/;
          return !patt.test(value) || this.$t("rules.noAllSpace");
        },
        teamNameAllowed: () => !this.teamNameNotAllowed || this.$t("rules.invalidName"),
        checkWorkspaceId: () => (this.hasWorkspaceOptions ? !this.selectedWorkspaceId : !this.workspaceId),
      },
    };
  },
  computed: {
    ...mapGetters("workspaceGroups", ["getter_get_admin_workspace_group_by_workspace_id"]),
    disabled() {
      const isDisabled = !this.formValid;
      const isEditDisabled = isDisabled || this.teamName === this.editedTeam.name;
      return {
        create: isDisabled,
        edit: isEditDisabled,
      };
    },
    buttonProperties() {
      const buttonPropertiesMap = {
        create: {
          disabled: !this.formValid,
          action: this.createTeam,
          autotest: "create-team-popup-create-btn",
          text: this.$t("all.create"),
        },
        edit: {
          disabled: !this.formValid || this.teamName === this.editedTeam.name,
          action: this.updateTeam,
          autotest: "edit-team-popup-save-btn",
          text: this.$t("all.save"),
        },
      };
      return buttonPropertiesMap[this.showPopupType];
    },
    teamNameNotAllowed() {
      return checkAdminCommonCharacters(this.teamName);
    },
  },
  mounted() {
    this.teamName = this.showPopupType === "edit" ? this.editedTeam.name : "";
  },
  methods: {
    ...mapMutations("global", ["MUTATE_ALERT_INFO"]),
    getAutotest(tag) {
      if (this.showPopupType === "create") {
        switch (tag) {
          case "close":
            return "create-team-popup-close-btn";
          case "name-input":
            return "create-team-popup-name-input";
          default:
            return "";
        }
      }
      if (this.showPopupType === "edit") {
        switch (tag) {
          case "close":
            return "edit-team-popup-close-btn";
          case "name-input":
            return "edit-team-popup-name-input";
          default:
            return "";
        }
      }
    },
    createTeam() {
      if (this.checkTeamExisted()) {
        const message = this.$t("admin.alertTeamAlreadyInSystem");
        return this.MUTATE_ALERT_INFO({ message, type: "error" });
      }
      const workspaceId = this.hasWorkspaceOptions ? this.selectedWorkspaceId : this.workspaceId;
      this.$emit("addTeam", { name: this.teamName, workspaceId });
    },
    async updateTeam() {
      if (this.checkTeamExisted()) {
        const message = this.$t("admin.alertTeamAlreadyInSystem");
        return this.MUTATE_ALERT_INFO({ message, type: "error" });
      }
      this.$emit("editTeam", { teamId: this.editedTeam.id, name: this.teamName });
    },
    checkTeamExisted() {
      // TODO: Confirm checking the team ID is necessary.
      // Also says, if we have to consider the same name in different workspaces.
      return this.teams.some((team) => team.name === this.teamName);
    },
    getWorkspaceGroupId(workspaceId) {
      const workspaceGroup = this.getter_get_admin_workspace_group_by_workspace_id(workspaceId);
      return (workspaceGroup && workspaceGroup.id) || "";
    },
    setSelectedWorkspaceGroupId(value) {
      this.selectedWorkspaceGroupId = value;
    },
    setSelectedWorkspaceId(value) {
      this.selectedWorkspaceId = value;
    },
  },
  created() {
    const workspaceId = this.hasWorkspaceOptions ? this.$defaultGroup : this.workspaceId;
    this.setSelectedWorkspaceId(workspaceId);
    const workspaceGroupId = this.getWorkspaceGroupId(this.selectedWorkspaceId);
    this.setSelectedWorkspaceGroupId(workspaceGroupId);
  },
};
</script>
<style scoped>
.userInviteCreate__form {
  margin: 42px 0 72px 0;
}
.user-invite-create {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
}
.close-btn {
  position: absolute;
  right: 24px;
  top: 24px;
}
.fullscreen-center {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.popup-box {
  position: relative;
  margin-top: 36px;
  padding: 48px 24px;
  margin: 0 auto;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.35);
}
.title-div {
  position: relative;
  width: 100%;
  text-align: center;
  padding: 28px 20px 0 20px;
}
.popup-title {
  color: #52545d;
  font-family: Roboto;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
  margin-top: 12px;
}

.popup-close {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.no-text-transform {
  text-transform: none !important;
}
.btn-div {
  margin-top: 32px;
}
.btn-width {
  width: 120px;
}
.dropdown-btn-menu {
  width: 100%;
}
.dropdown-btn {
  position: relative;
  width: 100%;
  left: 0;
  height: 46px;
}
.dropdown-text {
  position: relative;
  top: -2px;
  padding: 0;
  font-size: 16px;
  line-height: 19px;
}
.dropdown-arrow {
  position: absolute;
  right: 0;
  top: 20px;
}
.input-language-code {
  pointer-events: none;
}
</style>
