<template>
  <div class="DFlex">
    <slot />
  </div>
</template>

<script>
export default {
  name: "DFlex",
  props: {},
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@use "sass:math";
$max-col: 12;
$screen-min-width: (
  xs: 0px,
  sm: 600px,
  md: 960px,
  lg: 1264px,
  xl: 1904px,
);
$screen-size: "xs", "sm", "md", "lg", "xl";

.DFlex {
  flex: 1 1 auto;
  max-width: 100%;
  @each $size in $screen-size {
    @for $col-index from 1 through $max-col {
      $colPercent: #{math.div($col-index, $max-col) * 100%};
      @media (min-width: map-get($screen-min-width, $size)) {
        &.#{$size}#{$col-index} {
          flex-basis: $colPercent;
          flex-grow: 0;
          max-width: $colPercent;
        }
      }
    }
  }
}
</style>
