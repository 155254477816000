import Vue from "vue";
import { AUDIO_CONTENT_STATUS } from "@/constants/editorStatus";
import { createTextToSpeechs } from "@/server/api-server";
import { getTextToSpeechByIds } from "@/server/text-to-speech-server";
import { getUuidv4 } from "@/server/upload-server";
import { pick } from "lodash-es";
import { getBlockStatus, getTextToSpeechPayload } from "@/js/workflow/text-to-speech";
import { player } from "@/js/audio-player/AudioPlayer";
import { i18n } from "@/main";
import { add, round } from "mathjs";

export const namespaced = true;
export const state = {
  // for form/UI
  voice: "",
  blocks: [], // [{ key, text2SpeechId?, text, time, speed }]
  selectedBlockIndex: -1,
  isProcessingAllBlocks: false,
  // for api
  isRequestingMaps: {}, // {[key: blockKey]: boolean }
  audioSourceMaps: {}, // {[key: text2SpeechId]: id, voice, rate(=speed), status, duration, speechURL  }
  previousHistory: [],
  globalSpeedIndex: 2,
};

const getInitBlockData = (initData) => ({
  key: getUuidv4(),
  text2SpeechId: "",
  text: "",
  time: 0.5,
  speed: 1,
  ...initData,
});
export const mutations = {
  ADD_NEW_BLOCK_BY_INDEX(state, index) {
    const newBlock = getInitBlockData();
    state.blocks.splice(index, 0, {
      ...newBlock,
      speed: state.globalSpeedIndex === -1 ? 1 : [0.5, 0.75, 1, 1.25, 1.5][state.globalSpeedIndex],
    });
    state.selectedBlockIndex = index;
  },
  MUTATE_BLOCKS(state, blocks) {
    state.blocks = blocks;
  },
  DELETE_BLOCK_BY_INDEX(state, index) {
    state.blocks.splice(index, 1);
  },
  MUTATE_AUDIO_SOURCE(state, item) {
    Vue.set(state.audioSourceMaps, item.id, item);
  },
  MUTATE_BLOCK_BY_INDEX(state, { index, content }) {
    const blockItem = state.blocks[index];
    state.blocks.splice(index, 1, { ...blockItem, ...content });
  },
  MUTATE_IS_PROCESSING_ALL_BLOCKS(state, value) {
    state.isProcessingAllBlocks = value;
  },
  MUTATE_IS_REQUESTING(state, value) {
    state.isRequestingMaps = value;
  },
  MUTATE_IS_REQUESTING_BY_KEY(state, { key, value }) {
    Vue.set(state.isRequestingMaps, key, value);
  },
  MUTATE_VOICE(state, voice) {
    state.voice = voice;
  },
  MUTATE_PREVIOUS_HISTORIES(state, history) {
    state.previousHistory = history;
  },
  REORDER_BLOCK(state, { draggedIndex, droppedIndex }) {
    const draggedItem = state.blocks[draggedIndex];
    const insertDraggedItemIndex = droppedIndex < draggedIndex ? droppedIndex + 1 : droppedIndex;
    state.blocks.splice(draggedIndex, 1);
    state.blocks.splice(insertDraggedItemIndex, 0, draggedItem);
  },
  RESET_TEXT_TO_SPEECH_INFO(state) {
    state.blocks = [];
    state.selectedBlockIndex = -1;
    state.isProcessingAllBlocks = false;
    state.isRequestingMaps = {};
    state.audioSourceMaps = {};
  },
  MUTATE_SELECTED_INDEX(state, index) {
    state.selectedBlockIndex = index;
  },
  SET_GLOBAL_SPEED(state, speed) {
    state.globalSpeedIndex = speed;
    const speedMap = [0.5, 0.75, 1, 1.25, 1.5];
    if (speed === -1) return;
    state.blocks.forEach((block) => {
      block.speed = speedMap[speed];
    });
  },
};

export const getters = {
  getter_generating_text_to_speech_ids: (state) => {
    return Object.values(state.audioSourceMaps).reduce(
      (result, audioSource) =>
        [AUDIO_CONTENT_STATUS.PENDING, AUDIO_CONTENT_STATUS.PROCESSING].includes(audioSource.status)
          ? [...result, audioSource.id]
          : result,
      []
    );
  },
  getter_has_todo_block: (state) => {
    return state.blocks.some((blockItem) => {
      const status = getBlockStatus(
        state.audioSourceMaps[blockItem.text2SpeechId],
        blockItem,
        state.isRequestingMaps[blockItem.key],
        state.voice
      );
      return status === AUDIO_CONTENT_STATUS.TODO;
    });
  },
  getter_need_to_generate_blocks_with_index: (state) => {
    return state.blocks.reduce((result, blockItem, blockIndex) => {
      const status = getBlockStatus(
        state.audioSourceMaps[blockItem.text2SpeechId],
        blockItem,
        state.isRequestingMaps[blockItem.key],
        state.voice
      );
      return [AUDIO_CONTENT_STATUS.TODO, AUDIO_CONTENT_STATUS.FAILURE].includes(status)
        ? [...result, { ...blockItem, index: blockIndex }]
        : result;
    }, []);
  },
  getter_is_all_audio_ready_to_play: (state) => {
    let isReadyPlayAllAudio = true;
    if (state.isProcessingAllBlocks) {
      return false;
    }
    for (let i = 0; i < state.blocks.length; i++) {
      const { text2SpeechId } = state.blocks[i];
      if (!text2SpeechId || !state.audioSourceMaps[text2SpeechId]) {
        isReadyPlayAllAudio = false;
        break;
      }
    }

    return isReadyPlayAllAudio;
  },
  getter_is_local_data_identical_to_server: (state) => {
    let isIdentical = state.blocks.length === state.previousHistory.length;

    if (isIdentical) {
      for (let i = 0; i < state.blocks.length; i++) {
        const block = state.blocks[i];
        const previousHistory = state.previousHistory[i];
        if (block.text2SpeechId !== previousHistory.id) {
          isIdentical = false;
          break;
        }
        if (block.text !== previousHistory.text) {
          isIdentical = false;
          break;
        }
        if (block.speed !== (previousHistory.rate || 1)) {
          isIdentical = false;
          break;
        }
        if (block.time !== round(add(previousHistory.endTime, -previousHistory.startTime), 2)) {
          isIdentical = false;
          break;
        }
      }
    }
    return isIdentical;
  },
};

export const actions = {
  addNewBlockByIndex({ commit }, index) {
    commit("ADD_NEW_BLOCK_BY_INDEX", index);
  },
  changeBlockContentByIndex({ commit, state }, payload) {
    commit("MUTATE_BLOCK_BY_INDEX", payload);
    if (payload.content.speed !== undefined && payload.content.speed !== state.globalSpeedIndex) {
      commit("SET_GLOBAL_SPEED", -1);
    }
  },
  changeVoice({ commit }, payload) {
    commit("MUTATE_VOICE", payload.value);
  },
  deleteBlockByIndex({ commit }, index) {
    commit("DELETE_BLOCK_BY_INDEX", index);
  },
  async generateBlockAudioByIndex({ commit, state }, { index, workflowId }) {
    const blockItem = state.blocks[index];
    try {
      commit("MUTATE_IS_REQUESTING_BY_KEY", { key: blockItem.key, value: true });
      const textToSpeechPayload = getTextToSpeechPayload(blockItem, state.voice);
      const { ok, data } = await createTextToSpeechs({ workflowId, text2Speechs: [textToSpeechPayload] });
      if (ok) {
        const { text2SpeechId } = data.items[0];
        commit("MUTATE_BLOCK_BY_INDEX", { index, content: { text2SpeechId } });
        commit("MUTATE_AUDIO_SOURCE", {
          id: text2SpeechId,
          voice: state.voice,
          rate: blockItem.speed,
          text: blockItem.text,
          duration: blockItem.text.length === "" ? blockItem.time : undefined,
          status: AUDIO_CONTENT_STATUS.PENDING,
          speechURL: "",
        });
      }
    } catch (e) {
      console.log("generateBlockAudioByIndex error", e);
    } finally {
      commit("MUTATE_IS_REQUESTING_BY_KEY", { key: blockItem.key, value: false });
    }
  },
  generateBlockAudioByTranscription({ commit, rootState }) {
    const newBlocks = rootState.editor.transcription.map(({ sentence }) => getInitBlockData({ text: sentence }));
    commit("MUTATE_BLOCKS", newBlocks);
  },
  generateBlockAudioByHistories({ commit }, histories) {
    let languageVoice = null;
    const blocks = histories.map(({ text, rate, duration, id, speechURL, voice, status, startTime, endTime }) => {
      commit("MUTATE_AUDIO_SOURCE", {
        text,
        rate,
        duration,
        id,
        speechURL,
        voice,
        status,
      });
      languageVoice = languageVoice || voice;
      return {
        text,
        speed: rate || 1,
        time: round(add(endTime, -startTime), 2),
        text2SpeechId: id,
        key: getUuidv4(),
      };
    });
    if (languageVoice) {
      commit("MUTATE_VOICE", languageVoice);
    }
    commit("MUTATE_BLOCKS", blocks);
    commit("MUTATE_PREVIOUS_HISTORIES", histories);
  },
  async generateAllBlocks({ commit, state, getters, dispatch }, workflowId) {
    try {
      const needToGenerateBlocksWithIndex = getters.getter_need_to_generate_blocks_with_index;
      if (needToGenerateBlocksWithIndex.length === 0) return;
      const isRequestingMaps = needToGenerateBlocksWithIndex.reduce(
        (result, blockItemWithIndex) => ({
          ...result,
          [blockItemWithIndex.key]: true,
        }),
        {}
      );
      commit("MUTATE_IS_PROCESSING_ALL_BLOCKS", true);
      commit("MUTATE_IS_REQUESTING", isRequestingMaps);
      const textToSpeechsPayload = needToGenerateBlocksWithIndex.map((blockItemWithIndex) =>
        getTextToSpeechPayload(blockItemWithIndex, state.voice)
      );
      const { ok, data } = await createTextToSpeechs({ workflowId, text2Speechs: textToSpeechsPayload });
      if (!ok) {
        commit("MUTATE_IS_PROCESSING_ALL_BLOCKS", false);
        dispatch("global/openAlert", { message: i18n.t("alert.unexpectedError"), type: "error" }, { root: true });
        return;
      }
      needToGenerateBlocksWithIndex.forEach((blockItemWithIndex, text2SpeechIndex) => {
        const { text2SpeechId } = data.items[text2SpeechIndex];
        commit("MUTATE_BLOCK_BY_INDEX", { index: blockItemWithIndex.index, content: { text2SpeechId } });
        commit("MUTATE_AUDIO_SOURCE", {
          id: text2SpeechId,
          voice: state.voice,
          rate: blockItemWithIndex.speed,
          text: blockItemWithIndex.text,
          duration: blockItemWithIndex.text.length === "" ? blockItemWithIndex.time : undefined,
          status: AUDIO_CONTENT_STATUS.PENDING,
          speechURL: "",
        });
      });
    } catch (e) {
      commit("MUTATE_IS_PROCESSING_ALL_BLOCKS", false);
      console.log("generateAllBlocks error", e);
    } finally {
      commit("MUTATE_IS_REQUESTING", {});
    }
  },
  async syncTextToSpeechStatusByIds({ commit, state }, text2SpeechIds) {
    try {
      const { ok, data } = await getTextToSpeechByIds({ text2SpeechIds });
      if (!ok) return;
      data.items.forEach((textToSpeechItem) => {
        commit(
          "MUTATE_AUDIO_SOURCE",
          pick(textToSpeechItem, ["id", "rate", "voice", "text", "status", "duration", "speechURL"])
        );

        if (textToSpeechItem.status !== AUDIO_CONTENT_STATUS.COMPLETED) return;

        // if audio complete, check time and read speechURL
        const blockItemIndex = state.blocks.findIndex(({ text2SpeechId }) => text2SpeechId === textToSpeechItem.id);
        if (blockItemIndex === null) return;
        if (textToSpeechItem.speechURL) {
          player.append({ playId: textToSpeechItem.id, speechURL: textToSpeechItem.speechURL });
        }
        commit("MUTATE_BLOCK_BY_INDEX", {
          index: blockItemIndex,
          content: { time: Math.max(state.blocks[blockItemIndex].time, textToSpeechItem.duration) },
        });
      });
    } catch (e) {
      console.log("syncTextToSpeechStatusByIds error", e);
    }
  },
  reorderBlock({ commit }, payload) {
    commit("REORDER_BLOCK", payload);
  },
  resetTextToSpeechInfo({ commit }) {
    commit("RESET_TEXT_TO_SPEECH_INFO");
  },
  resetIsProcessingAllBlocks({ commit }) {
    commit("MUTATE_IS_PROCESSING_ALL_BLOCKS", false);
  },
  changeSelectedBlockIndex({ commit }, index) {
    commit("MUTATE_SELECTED_INDEX", index);
  },
  setGlobalSpeedIndex({ commit }, index) {
    commit("SET_GLOBAL_SPEED", index);
  },
};
