import axios from "axios";
import authChoice from "../../auth";
import environment from "../../env";
import { apiWrapperGenerator } from "./lib";

function getBaseUrl() {
  return environment.backendType === "firebase"
    ? environment.service[environment.env].admin
    : environment.service[environment.env]["china"].admin;
}

const customFeaturesServer = axios.create({
  baseURL: getBaseUrl(),
});
customFeaturesServer.interceptors.request.use(
  async (config) => {
    config.headers["x-user-token"] = await authChoice().currentUser.getIdToken();
    return config;
  },
  (error) => Promise.resolve(error)
);

const apiWrapper = apiWrapperGenerator(customFeaturesServer);

export async function updateIpRestrictionSettings({ enableRestrictedIP, allowedRules }) {
  return await apiWrapper("put", "/v1/organization-settings/my/customized-permissions/restricted-ip", {
    enableRestrictedIP,
    allowedRules,
  });
}
