export const namespaced = true;
import Vue from "vue";
import {
  postGetReducedEmbeddingMaven,
  postSemanticSearchWorkflow,
  postQuestionInfo,
  postSemanticSearchQuestion,
  postRelatedWorkflow,
} from "@/server/visualization-server.js";
import { fetchGroups } from "@/server/api-server.js";
import { fetchWorkflowByWorkflowId } from "@/server/api-server.js";
import { postGetWorkflowKeypoints } from "@/server/summarization-server.js";

export const state = {
  mavenMapNodes: [],
  mavenMapUserGroupList: [],
  isLoadingWorkflowData: false,
  workflowTooltip: {
    show: false,
    workflowId: null,
    x: null,
    y: null,
  },
  workflowNodesData: {},
  questionTooltip: {
    show: false,
    questionId: null,
    question: null,
    x: null,
    y: null,
  },
  showWorkflowInfoPanel: false,
  activeWorkflowInfoId: null,
  isLoadingWorkflowSummary: false,
  workflowAdditionalData: {
    /** keypoints: String[] */
  },
  showQuestionInfoPanel: false,
  activeQuestionInfoId: null,
  questionNodesData: {},
  isLoadingWorkflowSearch: false,
  isOnSearchMode: false,
  searchedWorkflowList: [],
  isLoadingQuestionSearch: false,
  searchedQuestionList: [],
  isLoadingRelatedWorkflowList: false,
  relatedWorkflowList: [],
  clusterInfo: {
    /** radius: float[], topic: string[]  */
  },
};

export const mutations = {
  SET_MAVEN_MAP_NODES(state, payload) {
    state.mavenMapNodes = payload;
  },
  SET_MAVEN_MAP_USER_GROUP_LIST(state, payload) {
    state.mavenMapUserGroupList = payload;
  },
  SET_IS_LOADING_WORKFLOW_DATA(state, payload) {
    state.isLoadingWorkflowData = payload;
  },
  SET_WORKFLOW_TOOLTIP(state, payload) {
    state.workflowTooltip = payload;
  },
  ADD_WORKFLOW_NODES_DATA(state, payload = { workflowId, groupId, title, poster }) {
    Vue.set(state.workflowNodesData, payload.workflowId, payload);
  },
  CLEAR_WORKFLOW_NODES_DATA(state) {
    state.workflowNodesData = {};
  },
  SET_QUESTION_TOOLTIP(state, payload) {
    state.questionTooltip = payload;
  },
  SET_SHOW_WORKFLOW_INFO_PANEL(state, payload) {
    state.showWorkflowInfoPanel = payload;
  },
  SET_ACTIVE_WORKFLOW_INFO_ID(state, payload) {
    state.activeWorkflowInfoId = payload;
  },
  SET_IS_LOADING_WORKFLOW_SUMMARY(state, payload) {
    state.isLoadingWorkflowSummary = payload;
  },
  ADD_WORKFLOW_ADDITIONAL_DATA(state, payload = { workflowId, keypoints }) {
    Vue.set(state.workflowAdditionalData, payload.workflowId, payload);
  },
  CLEAR_WORKFLOW_ADDITIONAL_DATA(state) {
    state.workflowAdditionalData = {};
  },
  SET_SHOW_QUESTION_INFO_PANEL(state, payload) {
    state.showQuestionInfoPanel = payload;
  },
  SET_ACTIVE_QUESTION_INFO_ID(state, payload) {
    state.activeQuestionInfoId = payload;
  },
  ADD_QUESTION_NODES_DATA(state, payload = { questionId, question, answer, like, workflows }) {
    Vue.set(state.questionNodesData, payload.questionId, payload);
  },
  CLEAR_QUESTION_NODES_DATA(state) {
    state.questionNodesData = {};
  },
  SET_IS_LOADING_WORKFLOW_SEARCH(state, payload) {
    state.isLoadingWorkflowSearch = payload;
  },
  SET_IS_ON_SEARCH_MODE(state, payload) {
    state.isOnSearchMode = payload;
  },
  SET_SEARCHED_WORKFLOW_LIST(state, payload = []) {
    state.searchedWorkflowList = payload;
  },
  SET_IS_LOADING_QUESTION_SEARCH(state, payload) {
    state.isLoadingQuestionSearch = payload;
  },
  SET_SEARCHED_QUESTION_LIST(state, payload = []) {
    state.searchedQuestionList = payload;
  },
  SET_IS_LOADING_RELATED_WORKFLOW_LIST(state, payload) {
    state.isLoadingRelatedWorkflowList = payload;
  },
  SET_RELATED_WORKFLOW_LIST(state, payload) {
    state.relatedWorkflowList = payload;
  },
  SET_CLUSTER_INFO(state, payload) {
    state.clusterInfo = payload;
  },
};

export const getters = {
  getter_maven_map_nodes: (state) => state.mavenMapNodes,
  getter_maven_map_nodes_test: (state) => {
    return state.mavenMapNodes.filter(({ questionId }) => questionId);
  },
  getter_maven_map_user_group_list: (state) => {
    return state.mavenMapUserGroupList.filter((group) => group.id && !group.private);
  },
  getter_is_loading_workflow_data: (state) => state.isLoadingWorkflowData,
  getter_workflow_tooltip: (state) => state.workflowTooltip,
  getter_workflow_nodes_data: (state) => state.workflowNodesData,
  getter_question_tooltip: (state) => state.questionTooltip,
  getter_show_workflow_info_panel: (state) => state.showWorkflowInfoPanel,
  getter_active_workflow_info_id: (state) => state.activeWorkflowInfoId,
  getter_is_loading_workflow_summary: (state) => state.isLoadingWorkflowSummary,
  getter_workflow_additional_data: (state) => state.workflowAdditionalData,
  getter_show_question_info_panel: (state) => state.showQuestionInfoPanel,
  getter_active_question_info_id: (state) => state.activeQuestionInfoId,
  getter_question_nodes_data: (state) => state.questionNodesData,
  getter_is_loading_workflow_search: (state) => state.isLoadingWorkflowSearch,
  getter_is_on_search_mode: (state) => state.isOnSearchMode,
  getter_searched_workflow_list: (state) => state.searchedWorkflowList,
  getter_is_loading_question_search: (state) => state.isLoadingQuestionSearch,
  getter_searched_question_list: (state) => state.searchedQuestionList,
  getter_is_loading_related_workflow_list: (state) => state.isLoadingRelatedWorkflowList,
  getter_related_workflow_list: (state) => state.relatedWorkflowList,
  getter_cluster_info: (state) => state.clusterInfo,
};

export const actions = {
  fetchMavenMapNodes: async ({ commit }, payload) => {
    const { ok, data, errorMessage } = await postGetReducedEmbeddingMaven(payload);
    const nodes = ok ? data.items.nodes : [];
    const clusterInfo = ok ? data.items.meta : {};
    commit("SET_MAVEN_MAP_NODES", nodes);
    commit("SET_CLUSTER_INFO", clusterInfo);
    let errorMessageI18n;
    if (!ok) {
      switch (errorMessage) {
        case "no permission":
          break;
        default:
          errorMessageI18n = "alert.unexpectedError";
          break;
      }
    }
    return { ok, errorMessageI18n };
  },
  fetchMavenMapUserGroupList: async ({ commit }) => {
    const { ok, data, errorMessage } = await fetchGroups({ type: "view" });
    const groupItems = ok ? data.items : [];
    commit("SET_MAVEN_MAP_USER_GROUP_LIST", groupItems);
    if (!ok) console.error(errorMessage);

    return { ok };
  },
  showWorkflowInfo: async ({ commit, state }, { x, y, workflowId, groupId }) => {
    if (state.workflowTooltip.show && state.workflowTooltip.workflowId === workflowId) return;

    commit("SET_WORKFLOW_TOOLTIP", { show: true, workflowId, x, y });
    if (state.workflowNodesData[workflowId]) return;

    commit("SET_IS_LOADING_WORKFLOW_DATA", true);

    const {
      ok: workflowOk,
      data: { item: workflowData },
    } = await fetchWorkflowByWorkflowId(workflowId);
    if (!workflowOk) {
      console.error("Err: failed to fetch workflow data");
    } else {
      commit("ADD_WORKFLOW_NODES_DATA", {
        workflowId,
        groupId,
        title: workflowData.title,
        poster: workflowData.poster,
        author: workflowData.author,
        videoDuration: workflowData.videoDuration,
        privateWorkspace: workflowData.privateWorkspace,
        publishedDate: workflowData.publishedDate,
        steps: workflowData.steps,
      });
    }
    commit("SET_IS_LOADING_WORKFLOW_DATA", false);
  },
  hideWorkflowInfo: ({ commit }) => {
    commit("SET_WORKFLOW_TOOLTIP", { show: false, workflowId: null, x: null, y: null });
  },
  showQuestionInfo: ({ commit }, payload = { questionId, question, x, y }) => {
    if (state.questionTooltip.show && state.questionTooltip.questionId === payload.questionId) return;
    commit("SET_QUESTION_TOOLTIP", { show: true, ...payload });
  },
  hideQuestionInfo: ({ commit }) => {
    commit("SET_QUESTION_TOOLTIP", { show: false, questionId: null, question: null, x: null, y: null });
  },
  /** workflow info panel methods */
  setShowWorkflowInfoPanel: ({ commit }, payload) => {
    commit("SET_SHOW_WORKFLOW_INFO_PANEL", payload);
  },
  setActiveWorkflowInfoId: ({ commit }, payload) => {
    commit("SET_ACTIVE_WORKFLOW_INFO_ID", payload);
  },
  fetchWorkflowSummary: async ({ commit }, payload = { oid, token, workflowId }) => {
    if (state.workflowAdditionalData[payload.workflowId]) return;

    commit("SET_IS_LOADING_WORKFLOW_SUMMARY", true);
    const {
      ok,
      data: {
        item: { keypoints = [] },
      },
    } = await postGetWorkflowKeypoints(payload);
    if (!ok) {
      console.error("Err: failed to fetch workflow summary");
    } else {
      commit("ADD_WORKFLOW_ADDITIONAL_DATA", {
        workflowId: payload.workflowId,
        keypoints,
      });
    }

    commit("SET_IS_LOADING_WORKFLOW_SUMMARY", false);
  },
  fetchRelatedWorkflowList: async ({ commit }, payload = { oid, token, workflowId, workspaces }) => {
    commit("SET_IS_LOADING_RELATED_WORKFLOW_LIST", true);
    commit("SET_RELATED_WORKFLOW_LIST", []);

    const {
      ok,
      data: {
        item: { workflows = [] },
      },
    } = await postRelatedWorkflow(payload);
    if (!ok) {
      console.error("Err: failed to fetch related workflow list");
    } else {
      commit("SET_RELATED_WORKFLOW_LIST", workflows);
    }

    commit("SET_IS_LOADING_RELATED_WORKFLOW_LIST", false);
  },
  /** question info panel methods */
  setShowQuestionInfoPanel: ({ commit }, payload) => {
    commit("SET_SHOW_QUESTION_INFO_PANEL", payload);
  },
  setActiveQuestionInfoId: ({ commit }, payload) => {
    commit("SET_ACTIVE_QUESTION_INFO_ID", payload);
  },
  fetchQuestionInfo: async ({ commit }, payload) => {
    if (state.questionNodesData[payload.questionId]) return;
    const {
      ok,
      data: { item },
    } = await postQuestionInfo(payload);
    if (!ok) {
      console.error("Err: failed to fetch question info");
    } else {
      commit("ADD_QUESTION_NODES_DATA", {
        questionId: payload.questionId,
        ...item,
      });
    }
  },
  /** search methods */
  setIsOnSearchMode: ({ commit }, payload) => {
    commit("SET_IS_ON_SEARCH_MODE", payload);
  },
  fetchSearchedWorkflowList: async ({ commit }, payload = { oid, token, text, workspaces }) => {
    commit("SET_IS_LOADING_WORKFLOW_SEARCH", true);
    commit("SET_SEARCHED_WORKFLOW_LIST", []);
    const {
      ok,
      data: {
        items: { workflows = [] },
      },
    } = await postSemanticSearchWorkflow(payload);
    if (!ok) {
      console.error("Err: failed to fetch workflow semantic search");
    } else {
      commit("SET_SEARCHED_WORKFLOW_LIST", workflows);
    }

    commit("SET_IS_LOADING_WORKFLOW_SEARCH", false);
  },
  fetchWorkflowInfo: async ({ commit, state }, { workflowId, groupId }) => {
    if (state.workflowNodesData[workflowId]) return;
    const {
      ok: workflowOk,
      data: { item: workflowData },
    } = await fetchWorkflowByWorkflowId(workflowId);
    if (!workflowOk) {
      console.error("Err: failed to fetch workflow data");
    } else {
      commit("ADD_WORKFLOW_NODES_DATA", {
        workflowId,
        groupId,
        title: workflowData.title,
        poster: workflowData.poster,
        author: workflowData.author,
        videoDuration: workflowData.videoDuration,
        privateWorkspace: workflowData.privateWorkspace,
        publishedDate: workflowData.publishedDate,
        steps: workflowData.steps,
      });
    }
  },
  fetchSearchedQuestionList: async ({ commit }, payload = { oid, token, text, workspaces }) => {
    commit("SET_IS_LOADING_QUESTION_SEARCH", true);
    commit("SET_SEARCHED_QUESTION_LIST", []);
    const { ok, data } = await postSemanticSearchQuestion(payload);
    if (!ok) {
      console.error("Err: failed to fetch question semantic search");
    } else {
      const {
        items: { questions = [] },
      } = data;
      commit("SET_SEARCHED_QUESTION_LIST", questions);
    }

    commit("SET_IS_LOADING_QUESTION_SEARCH", false);
  },
  /** clear all method */
  clearMap: ({ commit }) => {
    commit("SET_MAVEN_MAP_NODES", []);
    commit("SET_IS_LOADING_WORKFLOW_DATA", false);
    commit("SET_WORKFLOW_TOOLTIP", { show: false, workflowId: null, x: null, y: null });
    commit("SET_QUESTION_TOOLTIP", { show: false, questionId: null, question: null, x: null, y: null });
    commit("CLEAR_WORKFLOW_NODES_DATA");
    commit("SET_MAVEN_MAP_USER_GROUP_LIST", []);
    commit("SET_IS_LOADING_WORKFLOW_SUMMARY", false);
    commit("SET_ACTIVE_WORKFLOW_INFO_ID", null);
    commit("CLEAR_WORKFLOW_ADDITIONAL_DATA");
    commit("SET_SHOW_WORKFLOW_INFO_PANEL", false);
    commit("SET_SHOW_QUESTION_INFO_PANEL", false);
    commit("SET_ACTIVE_QUESTION_INFO_ID", null);
    commit("CLEAR_QUESTION_NODES_DATA");
    commit("SET_IS_LOADING_WORKFLOW_SEARCH", false);
    commit("SET_IS_ON_SEARCH_MODE", false);
    commit("SET_SEARCHED_WORKFLOW_LIST", []);
    commit("SET_CLUSTER_INFO", {});
    commit("SET_IS_LOADING_RELATED_WORKFLOW_LIST", false);
    commit("SET_RELATED_WORKFLOW_LIST", []);
    commit("SET_IS_LOADING_QUESTION_SEARCH", false);
    commit("SET_SEARCHED_QUESTION_LIST", []);
  },
};
