// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App";
import router from "./router";
import firebase from "firebase";
// import 'firebase/firestore'
import store from "./store";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import environment from "../env.js";
import axios from "axios";
import auth from "../auth.js";
import Analytics from "./js/analytics/analytics";
import VEscapeHtml from "@/js/xss/v-escape-html-plugin.js";
import VSyncPlayerTracking from "@/js/player-tracking/sync-player-tracking-plugin.js";
import userGuide from "./js/userGuide";
import "@/assets/css/variables.css";
import "@/assets/css/utils.scss";
import FaIcon from "@/plugin/fa-icon";
import DeephowUI from "@/plugin/deephow-ui";
import VueI18n from "vue-i18n";
import { loggingError } from "@/server/error-log-server.js";
import { i18nConfig } from "@/lang/i18n";

const env = environment.env;
const analyticsType = environment.analyticsType;
userGuide.initialize();
const analyticsConfig = environment[analyticsType];
Analytics.initialize(analyticsType, analyticsConfig);
Vue.prototype.$firebaseProxyHost = environment.firebaseProxyHost;
const useFirebaseProxy = environment.backendType == "ali" ? true : false;

Vue.use(FaIcon);
Vue.use(DeephowUI);
Vue.use(VEscapeHtml);
Vue.use(VSyncPlayerTracking);
Vue.use(VueI18n);
export const i18n = new VueI18n(i18nConfig);

//dictionary
const dictionaryURL =
  environment.backendType == "ali"
    ? "https://public.deephow.net/dh-dictionary.json"
    : "https://storage.googleapis.com/deephow-public/dh-dictionary.json";

axios
  .get(dictionaryURL)
  .then((data) => {
    Vue.prototype.$dictionary = data.data;
  })
  .catch(console.error);

//settings
const settingsURL =
  environment.backendType == "ali"
    ? "https://public.deephow.net/dh-settings.json"
    : "https://storage.googleapis.com/deephow-public/dh-settings.json";

axios
  .get(settingsURL)
  .then((data) => {
    Vue.prototype.$settings = data.data;
  })
  .catch(console.error);
Vue.prototype.$settingsURL = settingsURL;

Vue.use(Vuetify, {
  theme: {
    primary: "#4689f4",
    secondary: "#191919",
    accent: "#888888",
    error: "#888888",
    dBlue: "#4689f4",
    dDarkGrey: "#454545",
    dGrey: "#4A4A4A",
    dRed: "#e03535",
    dWhite: "#FBFBFB",
    dDarkBg: "#1E1F22",
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
});

Vue.config.productionTip = false;

// Error handling for Vue hooks
Vue.config.errorHandler = async function (error, vm, info) {
  try {
    await loggingError(error);
  } catch (error) {
    console.log(error);
  }
};

// Error handling for unhandled Promise rejections
window.onunhandledrejection = async function (event) {
  try {
    await loggingError(event.reason);
  } catch (error) {
    console.log(error);
  }
};

// if(window.location.hostname.includes("dev")){
// Vue.prototype.$enableText2Speech = true;
// }

if (environment.backendType == "ali") {
  Vue.prototype.$firebase = null;
  Vue.prototype.$db = null;
} else {
  firebase.initializeApp(environment.firebaseAPI[env]);
  const db = firebase.firestore();
  const settings = { experimentalForceLongPolling: true, merge: true };
  db.settings(settings);

  /* Bind firebase to Vue instance */
  Vue.prototype.$firebase = firebase;
  Vue.prototype.$db = db;
}

Vue.prototype.$env = env;
Vue.prototype.$baseUrl = window.location.origin;

Vue.prototype.$backendType = environment.backendType;
Vue.prototype.$databaseType = environment.databaseType;

window.service = Vue.prototype.$service = environment.service[env];
Vue.prototype.$download =
  environment.backendType == "ali" ? environment.download[env].china : environment.download[env];
Vue.prototype.$collectionVersioning = environment.collectionVersioning[env];

const needChangeEndpointKeys = [
  "language",
  "video",
  "search",
  "admin",
  "sso",
  "sign",
  "analytics",
  "qrcode",
  "recommend",
  "workflow",
  "dataExporter",
];
if (environment.databaseType == "mongoDB") {
  for (const serviceUrlKey in Vue.prototype.$service) {
    if (needChangeEndpointKeys.includes(serviceUrlKey)) {
      if (Vue.prototype.$service.china) {
        Vue.prototype.$service[serviceUrlKey] = Vue.prototype.$service.china[serviceUrlKey];
      }
    }
  }
} else if (useFirebaseProxy) {
  // let service through proxy to ensure will not block by china
  for (const serviceUrlKey in Vue.prototype.$service) {
    if (needChangeEndpointKeys.includes(serviceUrlKey)) {
      Vue.prototype.$service[serviceUrlKey] = Vue.prototype.$service[serviceUrlKey].replace(
        "://",
        "://" + Vue.prototype.$firebaseProxyHost + ":2096/"
      );
    }
  }
}

Vue.prototype.$cachedAvatars = {}; // cache avatars

const eventbus = new Vue();
export default eventbus;

/* eslint-disable no-new */
// new Vue({
//   el: '#app',
//   router,
//   components: { App },
//   template: '<App/>'
// })
let vueInitialized = false;
auth().onAuthStateChanged((user) => {
  if (user) {
    //TODO: get and set user locale and settings before initVue
    initVue(true);

    auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {})
      .catch(function (error) {
        // Handle error
      });
  } else {
    initVue(false);
  }
});

function initVue(authenticated) {
  if (vueInitialized) {
    return;
  }
  vueInitialized = true;
  new Vue({
    i18n,
    router,
    created() {},
    el: "#app",
    store,
    render: (h) => h(App),
  });
}
