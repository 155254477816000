<template>
  <section class="builder-poster-popup">
    <div class="scroll-div">
      <div class="close-bg" @click="closePosterPopup()"></div>

      <!-- ///// Workflow Poster /////-->
      <!-- (Dark theme, screenshot and upload img) -->
      <div v-if="posterType == 'workflow'" class="poster-wrapper vertical-center">
        <div class="window-title-div">
          <p class="window-title">{{ $t("builder.workflowCover") }}</p>
          <button class="window-close" @click="closePosterPopup()">
            <icon-base height="16" width="16">
              <d-icon-close-2 />
            </icon-base>
          </button>
        </div>

        <!-- options-div -->
        <div class="options-div">
          <div v-if="!getter_is_gen2">
            <button
              class="option-btn"
              :class="{ 'option-btn-selected': showPart == 'get-screenshot' }"
              @click="showPart = 'get-screenshot'"
            >
              <div class="checked-circle-div">
                <icon-base v-if="showPart == 'get-screenshot'" width="20" height="20">
                  <d-icon-circle-checked />
                </icon-base>
                <div v-else class="unchecked-circle"></div>
              </div>
              <span style="margin-left: 16px">{{ $t("builder.selectFromTheVideo") }}</span>
            </button>
          </div>
          <div>
            <button
              class="option-btn"
              :class="{ 'option-btn-selected': showPart == 'upload-img' }"
              @click="showPart = 'upload-img'"
            >
              <div class="checked-circle-div">
                <icon-base v-if="showPart == 'upload-img'" width="20" height="20">
                  <d-icon-circle-checked />
                </icon-base>
                <div v-else class="unchecked-circle"></div>
              </div>
              <span style="margin-left: 16px">{{ $t("builder.browseForAFile") }}</span>
            </button>
          </div>
        </div>

        <div class="content-div">
          <!-- get-screenshot -->
          <div class="get-screenshot-div" :class="{ 'show-get-screenshot': showPart == 'get-screenshot' }">
            <div class="video-div">
              <div class="video-box">
                <d-video-secure-multistep ref="workflowPlayer" :steps="steps" :workflow="workflow" preload="auto">
                  <template slot="over-video">
                    <div class="video-darken">
                      <div class="select-btn">
                        <d-button
                          round
                          outline
                          class="caption text-capitalize ma-0 btn-width"
                          color="#fff"
                          @click="getScreenshot()"
                          >{{ $t("all.select") }}</d-button
                        >
                      </div>
                    </div>
                  </template>
                </d-video-secure-multistep>
              </div>
            </div>
            <!-- selected screenshot -->
            <div class="selected-screenshot-div">
              <div class="selected-screenshot-text">
                {{ $t("builder.selectedImage") }}
              </div>
              <div v-if="!gotScreenshot" class="selected-screenshot">
                <div class="video-container">
                  <div class="container-inner">
                    <div class="no-screenshot-div">
                      <div class="no-image-text vertical-center">
                        {{ $t("builder.noImageSelected") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <d-resize-image
                ref="screenshotResize"
                :hidden="!gotScreenshot"
                :imgUrl="screenshotUrl"
                :canvasWidth="uploadImg.w"
                :canvasHeight="uploadImg.h"
                class="selected-canvas"
              ></d-resize-image>
              <canvas hidden ref="screenshotCanvas" class="selected-canvas"></canvas>
            </div>
          </div>

          <!-- upload-img -->
          <div v-if="showPart == 'upload-img'" class="drop-file-div" :class="{ 'dragging-file': isDraggingFile }">
            <!-- drop or click to add image -->
            <div
              v-if="!addedImage"
              class="add-image-div"
              @click="clickAddUpload()"
              @dragenter="onDragToUpload($event)"
              @dragover="onDragToUpload($event)"
              @drop="dropToUpload($event)"
              @dragleave="onDragToUploadLeave($event)"
            >
              <div class="vertical-center">
                <p class="drop-file-text mb-0 mt-2">
                  {{ $t("builder.dropFileMessage") }}
                </p>
                <p class="grey-text body-1 mt-2" :class="{ 'orange-text': showAlert }">
                  ({{ $t("builder.onlyImageAccepted") }})
                  <br />
                  <span v-if="unsupportedFormat">{{ $t("builder.unsupportedImageFormats") }}</span>
                </p>
              </div>
            </div>
          </div>

          <!-- adjust uploaded image  -->
          <div
            class="hide-drop-file-div"
            :class="{
              'show-add-image': showPart == 'upload-img' && addedImage,
            }"
          >
            <div class="image-preview-div">
              <div class="vertical-center">
                <d-resize-image
                  ref="uploadCanvas"
                  contain
                  :imgUrl="imgUrl"
                  :canvasWidth="uploadImg.w"
                  :canvasHeight="uploadImg.h"
                  class="image-canvas"
                ></d-resize-image>
                <div class="image-name-text">{{ getLimitFileName(50) }}</div>
                <button class="select-another-btn" @click="clickAddUpload()">
                  {{ $t("builder.selectAnotherImage") }}
                </button>
                <p class="only-image-text" :class="{ 'orange-text': showAlert }">
                  ({{ $t("builder.onlyImageAccepted") }})
                  <br />
                  <span v-if="unsupportedFormat">{{ $t("builder.unsupportedImageFormats") }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- bottom buttons -->
        <div class="bottom-div center-horizontally">
          <v-spacer></v-spacer>
          <d-button
            dark
            round
            outline
            class="caption text-capitalize mr-2 btn-width"
            color="#fff"
            @click="closePosterPopup()"
            >{{ $t("all.cancel") }}</d-button
          >
          <d-button
            dark
            round
            depressed
            class="caption white--text no-transform btn-width"
            color="#4689f4"
            @click="uploadNewWorkflowPoster()"
            :disabled="disableUploadBtn"
            >{{ $t("builder.done") }}</d-button
          >
        </div>
      </div>

      <!-- ///// Skill Poster /////-->
      <!-- (Light theme, only upload img) -->
      <div v-if="posterType == 'skill'" class="poster-wrapper-light vertical-center">
        <div class="window-title-div-light">
          <p class="window-title">{{ $t("skills.skillCover") }}</p>
          <button class="window-close-light" @click="closePosterPopup()">
            <icon-base height="16" width="16">
              <d-icon-close-2 />
            </icon-base>
          </button>
        </div>
        <div class="content-div-light">
          <!-- upload-img -->
          <div class="drop-file-div-light" :class="{ 'dragging-file-light': isDraggingFile }">
            <!-- drop or click to add image -->
            <div
              v-if="!addedImage"
              class="add-image-div"
              @click="clickAddUpload()"
              @dragenter="onDragToUpload($event)"
              @dragover="onDragToUpload($event)"
              @drop="dropToUpload($event)"
              @dragleave="onDragToUploadLeave($event)"
            >
              <div class="vertical-center">
                <p class="drop-file-text mb-0 mt-2" style="color: #3f3f3f">
                  {{ $t("builder.dropFileMessage") }}
                </p>
                <p class="grey--text body-1 mt-2" :class="{ 'orange-text': showAlert }">
                  ({{ $t("builder.onlyImageAccepted") }})
                  <br />
                  <span v-if="unsupportedFormat">{{ $t("builder.unsupportedImageFormats") }}</span>
                </p>
              </div>
            </div>
          </div>

          <!-- adjust uploaded image  -->
          <div
            class="hide-drop-file-div-light"
            :class="{
              'show-add-image-light': addedImage,
            }"
          >
            <div class="image-preview-div">
              <div class="vertical-center">
                <d-resize-image
                  ref="uploadCanvas"
                  contain
                  :imgUrl="imgUrl"
                  :canvasWidth="uploadImg.w"
                  :canvasHeight="uploadImg.h"
                  class="image-canvas"
                ></d-resize-image>
                <div class="image-name-text" style="color: #3f3f3f">
                  {{ getLimitFileName(50) }}
                </div>
                <button class="select-another-btn" @click="clickAddUpload()">
                  {{ $t("builder.selectAnotherImage") }}
                </button>
                <p class="only-image-text" :class="{ 'orange-text': showAlert }">
                  ({{ $t("builder.onlyImageAccepted") }})
                  <br />
                  <span v-if="unsupportedFormat">{{ $t("builder.unsupportedImageFormats") }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- bottom buttons -->
        <div class="bottom-div center-horizontally">
          <v-spacer></v-spacer>
          <d-button
            round
            outline
            class="caption text-capitalize mr-2 btn-width"
            color="#4689f4"
            @click="closePosterPopup()"
            >{{ $t("all.cancel") }}</d-button
          >
          <d-button
            light
            round
            depressed
            class="caption white--text no-transform btn-width"
            color="#4689f4"
            @click="uploadNewPlaylistPoster()"
            :disabled="disableUpload || !addedImage"
            >{{ $t("builder.done") }}</d-button
          >
        </div>
      </div>
    </div>

    <!-- alerts -->
    <d-alert v-model="alert.isSuccess" type="success" :message="alert.alertText"></d-alert>
    <d-alert v-model="alert.isFailed" type="error" :message="alert.alertText"></d-alert>

    <!-- input for uploading image -->
    <input
      ref="fileUpload"
      type="file"
      accept="image/jpeg, image/gif, image/png, image/webp, image/bmp, image/avif, image/x-icon"
      @change="onChange"
      hidden
    />
  </section>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import DIconClose2 from "@/components/icons/DIconClose2.vue";
import DIconCircleChecked from "@/components/icons/DIconCircleChecked.vue";
import DAlert from "@/components/ui_components/DAlert.vue";
import DResizeImage from "@/components/ui_components/DResizeImage.vue";
import DVideoSecureMultistep from "@/components/ui_components/DVideoSecureMultistep.vue";
import { updateWorkflowPoster } from "@/server/api-server.js";
import { getUuidv4, uploadToCloudStorageByFileType } from "@/server/upload-server.js";
import { loggingError } from "@/server/error-log-server.js";
import { mapGetters } from "vuex";

export default {
  name: "BuilderPosterPopup",
  watch: {
    showPart: function () {
      if (this.showPart != "get-screenshot") {
        this.pauseVideo();
      }
    },
  },
  props: {
    posterType: {
      type: String,
      default: "workflow",
    },
    workflow: {
      type: Object,
      default: () => {},
    },
    steps: {
      type: Array,
      default: () => [],
    },
    playlistData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showPart: "get-screenshot",
      uploadImg: {
        w: 495,
        h: 270,
      },
      // video player
      myVideo: null,
      // get-screenshot
      screenshotCanvas: null,
      screenshotContext: null,
      showingSpinner: false,
      isChangingTime: false,
      loadingInterval: null,
      gotScreenshot: false,
      screenshotUrl: "",
      // upload-img
      unsupportedFormat: false,
      showAlert: false,
      addedImage: false,
      imgUrl: "",
      imgName: "",
      isDraggingFile: false,
      disableUpload: false,
      alert: {
        isSuccess: false,
        isFailed: false,
        alertText: "",
      },
    };
  },
  components: {
    IconBase,
    DIconClose2,
    DIconCircleChecked,
    DAlert,
    DResizeImage,
    DVideoSecureMultistep,
  },
  created() {},
  mounted() {
    if (this.posterType == "workflow") {
      this.myVideo = this.$refs.workflowPlayer.$refs.videoPlayer;
      this.screenshotCanvas = this.$refs.screenshotCanvas;
    }
    this.showPart = !this.getter_is_gen2 ? "get-screenshot" : "upload-img";
  },
  methods: {
    ///// Upload Skill Poster /////
    uploadNewPlaylistPoster() {
      const canvas = this.$refs.uploadCanvas.$refs.imageCanvas;
      if (this.isCanvasBlank(canvas)) return;
      this.uploadPlaylistFile(canvas);
    },
    async uploadPlaylistFile(canvas) {
      try {
        const newFile = await this.getPoster(canvas);
        this.disableUpload = true;
        const imageUrl = await uploadToCloudStorageByFileType("playlistPoster", newFile, {});
        await this.updatePlaylistPoster(imageUrl);
      } catch (e) {
        this.handleUploadFailed();
        loggingError(e);
      }
    },
    updatePlaylistPoster(imageUrl) {
      this.closePosterPopup();
      this.$emit("update-poster", imageUrl);
    },
    ///// Upload Workflow Poster /////
    uploadNewWorkflowPoster() {
      let canvas;
      if (this.showPart == "get-screenshot") {
        canvas = this.$refs.screenshotResize.$refs.imageCanvas;
      }
      if (this.showPart == "upload-img") {
        canvas = this.$refs.uploadCanvas.$refs.imageCanvas;
      }
      //check canvas blank
      if (this.isCanvasBlank(canvas)) {
        return;
      }
      this.pauseVideo();
      this.uploadFile(canvas);
    },
    async uploadFile(canvas) {
      try {
        const newFile = await this.getPoster(canvas);
        this.disableUpload = true;
        const imageUrl = await uploadToCloudStorageByFileType("poster", newFile, {});
        await this.updateWorkflowPosterByApi(imageUrl);
      } catch (e) {
        this.handleUploadFailed();
        loggingError(e);
      }
    },
    getPoster(canvas) {
      const self = this;
      return new Promise(function (resolve, reject) {
        let poster;
        poster = new Image();
        canvas.toBlob(function (blob) {
          let imageFile = new File([blob], "poster-" + getUuidv4() + ".jpg", {
            type: "image/jpeg",
          });
          poster.src = window.URL.createObjectURL(blob);
          resolve(imageFile);
        }, "image/jpeg");
      });
    },
    async updateWorkflowPosterByApi(imageUrl) {
      if (!imageUrl) return;
      const payload = {
        workflowId: this.workflow.id,
        imageUrl,
      };
      const { ok } = await updateWorkflowPoster(payload);
      if (ok) {
        this.alert.alertText = this.$t("builder.alertSuccessfullyUpdatedCover");
        this.alert.isSuccess = true;
        this.$emit("update-poster");
        this.disableUpload = false;
        setTimeout(() => {
          this.closePosterPopup();
        }, 1000);
        return;
      }
      this.handleUploadFailed();
    },
    handleUploadFailed() {
      this.alert.alertText = this.$t("builder.alertFailedUpdatedCover");
      this.alert.isFailed = true;
      this.disableUpload = false;
    },
    isCanvasBlank(canvas) {
      return !canvas
        .getContext("2d")
        .getImageData(0, 0, canvas.width, canvas.height)
        .data.some((channel) => channel !== 0);
    },
    closePosterPopup() {
      this.$emit("close-poster-popup");
    },

    ///// get-screenshot /////
    pauseVideo() {
      this.$refs.workflowPlayer.pauseVideo();
    },
    async getScreenshot() {
      this.screenshotCanvas.width = this.myVideo.videoWidth;
      this.screenshotCanvas.height = this.myVideo.videoHeight;
      this.screenshotContext = this.screenshotCanvas.getContext("2d");
      this.screenshotContext.fillRect(0, 0, this.myVideo.videoWidth, this.myVideo.videoHeight);
      this.screenshotContext.drawImage(this.myVideo, 0, 0, this.myVideo.videoWidth, this.myVideo.videoHeight);
      const self = this;
      const newFile = await self.getPoster(this.screenshotCanvas);
      this.screenshotUrl = URL.createObjectURL(newFile);
      this.gotScreenshot = true;
    },
    ///// upload-img /////
    getLimitFileName(length) {
      const nameLength = length;
      if (this.imgName.length <= nameLength) {
        return this.imgName;
      } else {
        const re = /(?:\.([^.]+))?$/;
        const fileName = re.exec(this.imgName)[1];
        const fileNameLength = fileName.length + 1;
        return this.imgName.substring(0, nameLength - fileNameLength) + "..." + fileName;
      }
    },
    onChange(e) {
      const file = this.$refs.fileUpload;
      if (this.checkFileType(file)) {
        this.imgName = file.files[0].name;
        this.addedImage = true;
        this.imgUrl = URL.createObjectURL(file.files[0]);
      }
    },
    clickAddUpload() {
      this.$refs.fileUpload.click();
    },
    checkFileType(file) {
      let isImage = true;
      this.unsupportedFormat = false;
      if (!file.files[0].type.match("image.*")) {
        isImage = false;
        this.showAlert = true;
        setTimeout(() => {
          this.showAlert = false;
        }, 3000);
        return isImage;
      } else if (file.files[0].type.match("(tiff|svg|xbitmap)")) {
        isImage = false;
        this.showAlert = true;
        this.unsupportedFormat = true;
        setTimeout(() => {
          this.showAlert = false;
          this.unsupportedFormat = false;
        }, 3000);
        return isImage;
      }
      return isImage;
    },
    onDragToUpload(event) {
      event.stopPropagation();
      event.preventDefault();
      this.isDraggingFile = true;
    },
    onDragToUploadLeave(event) {
      event.preventDefault();
      this.isDraggingFile = false;
    },
    dropToUpload(event) {
      event.stopPropagation();
      event.preventDefault();
      this.isDraggingFile = false;
      const file = event.dataTransfer;
      if (this.checkFileType(file)) {
        this.addedImage = true;
        this.imgName = file.files[0].name;
        this.imgUrl = URL.createObjectURL(file.files[0]);
      }
    },
  },
  computed: {
    ...mapGetters(["getter_is_gen2"]),
    disableUploadBtn() {
      if (this.disableUpload) {
        return this.disableUpload;
      }
      let disableBtn = true;
      if (this.showPart == "get-screenshot" && this.gotScreenshot) {
        disableBtn = false;
      }
      if (this.showPart == "upload-img" && this.addedImage) {
        disableBtn = false;
      }
      return disableBtn;
    },
  },
};
</script>

<style scoped>
.builder-poster-popup {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 999;
}
.builder-poster-popup:hover::-webkit-scrollbar-thumb {
  background-color: #2c2d32;
}
.scroll-div {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 120px 48px;
  min-width: 1120px;
  min-height: 710px;
}
@media screen and (max-width: 1024px) {
  .scroll-div {
    padding: 120px 24px;
  }
}
.close-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/* ///// Workflow Poster ///// */
.poster-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 1264px;
  min-width: 976px;
  min-height: 650px;
  margin: 0 auto;
  background-color: #1e1f22;
}
.window-title-div {
  position: relative;
  height: 52px;
  width: 100%;
  border-bottom: 1px solid #303030;
}
.window-title {
  position: absolute;
  top: 8px;
  left: 24px;
  height: 32px;
  color: #8d909f;
  font-size: 24px;
}
.window-close {
  position: absolute;
  top: 18px;
  right: 24px;
  color: #8d909f;
}
.window-close:hover {
  color: #ffffff;
}
/* options-div */
.options-div {
  position: absolute;
  top: 84px;
  left: 0;
  height: calc(100% - 172px);
  width: 314px;
  border-right: 1px solid #303030;
}
.option-btn {
  position: relative;
  width: 100%;
  display: flex;
  text-align: left;
  color: #8d909f;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  padding: 16px 30px;
}
.checked-circle-div {
  position: relative;
  min-width: 20px;
  height: 20px;
}
.unchecked-circle {
  box-sizing: border-box;
  height: 20px;
  width: 20px;
  border: 1px solid #323338;
  border-radius: 10px;
}
.option-btn-selected {
  color: #ffffff;
}
.option-btn:hover {
  color: #ffffff;
}
.option-btn:hover .unchecked-circle {
  border: 1px solid #8d909f;
}
/* content-div */
.content-div {
  position: absolute;
  top: 52px;
  right: 0;
  width: calc(100% - 314px);
  height: calc(100% - 140px);
}
.get-screenshot-div {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  opacity: 0;
  pointer-events: none;
}
.show-get-screenshot {
  opacity: 1;
  pointer-events: auto;
}
.video-div {
  position: relative;
  height: 100%;
  width: 66%;
  padding: 48px 24px;
}
.video-box {
  position: absolute;
  right: 24px;
  width: 82%;
  min-width: 420px;
}
.video-darken {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 90%);
}
.video-darken:hover {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
}
.select-btn {
  position: absolute;
  width: 100%;
  bottom: 32px;
  opacity: 0.4;
}
.video-darken:hover .select-btn {
  opacity: 1;
}
.loading-div {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.spinner-container {
  pointer-events: none;
}
.video-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 */
  height: 0px;
}
.container-inner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
}
.selected-screenshot-div {
  height: 100%;
  width: 34%;
  padding: 48px 24px;
}
.selected-screenshot-text {
  color: #9b9b9b;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: left;
  margin-bottom: 16px;
}
.selected-screenshot {
  width: 66%;
  min-width: 194px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #303030;
}
.selected-canvas {
  width: 66%;
  min-width: 194px;
}
.no-screenshot-div {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.no-image-text {
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  opacity: 0.15;
}
/* upload image */
.drop-file-div {
  position: absolute;
  left: 24px;
  top: 32px;
  width: calc(100% - 54px);
  height: calc(100% - 32px);
  border: 1px dashed #303030;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
}
.dragging-file {
  border: 1px dashed #4689f4;
}
.hide-drop-file-div {
  position: absolute;
  left: 24px;
  top: 32px;
  width: calc(100% - 54px);
  height: calc(100% - 32px);
  border: 1px dashed #303030;
  border-radius: 4px;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
.show-add-image {
  opacity: 1;
  pointer-events: auto;
}
.add-image-div {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.drop-file-text {
  color: #8d909f;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 21px;
}
.grey-text {
  color: #8d909f63;
  height: 48px;
}
.only-image-text {
  color: transparent;
  height: 24px;
}
.orange-text {
  color: orange;
}
.vertical-center {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.image-preview-div {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
}
.image-canvas {
  position: relative;
  margin-top: 18px;
  margin-left: auto;
  margin-right: auto;
  width: 432px;
  border-radius: 4px;
}
.image-name-text {
  color: #ffffff;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
}
.select-another-btn {
  color: #4a90e2;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  margin-top: 12px;
  text-decoration: underline;
}
/* bottom-div */
.bottom-div {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 88px;
  padding: 0 18px;
}
.center-horizontally {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.no-transform {
  text-transform: none;
}
.btn-width {
  min-width: 120px;
}

/* ///// Skill Poster ///// */
.poster-wrapper-light {
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 960px;
  min-width: 860px;
  min-height: 650px;
  margin: 0 auto;
  background-color: #f9f9f9;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
  overflow: hidden;
}
.window-title-div-light {
  position: relative;
  height: 52px;
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
  background-color: #ffffff;
}
.window-close-light {
  position: absolute;
  top: 18px;
  right: 24px;
  color: #4a4a4a;
}
.content-div-light {
  position: absolute;
  top: 52px;
  right: 0;
  width: 100%;
  height: calc(100% - 140px);
}
.drop-file-div-light {
  position: absolute;
  left: 28px;
  top: 32px;
  width: calc(100% - 56px);
  height: calc(100% - 32px);
  border: 1px dashed #cccccc;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  background-color: #ffffff;
}
.dragging-file-light {
  border: 1px dashed #4689f4;
}
.hide-drop-file-div-light {
  position: absolute;
  left: 28px;
  top: 32px;
  width: calc(100% - 56px);
  height: calc(100% - 32px);
  border: 1px dashed #cccccc;
  border-radius: 4px;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
}
.show-add-image-light {
  opacity: 1;
  pointer-events: auto;
}
</style>
