<template>
  <div class="ipRestriction">
    <admin-setting-ip-restriction
      @enableIpRestrictionSetting="enableIpRestrictionSetting"
    ></admin-setting-ip-restriction>

    <div class="ipRestriction--tableTitle mt-5">
      <div class="ipRestriction--tableTitleText text-no-wrap text-truncate" autotest="whitelisted-ip-count">
        <b>{{ getter_whitelisted_ip_list_with_id.length }}</b>
        {{ $t("admin.whitelistedIps") }}
      </div>
      <v-spacer></v-spacer>
      <div>
        <v-btn
          round
          color="#4689f4"
          class="white--text elevation-0 body-2 text-capitalize mx-0"
          @click="createWhitelistedIp"
          autotest="create-whitelisted-ip-btn"
          >{{ $t("admin.addWhitelistedIpRule") }}</v-btn
        >
      </div>
    </div>

    <div class="ipRestriction--table">
      <d-data-table
        add-search
        local-search
        initial-sort
        :headers="tableHeader"
        :items="getter_whitelisted_ip_list_with_id"
        :loading="isLoading"
        :search="searchInput"
        :placeholder="$t('admin.searchForWhitelistedIp')"
        searchAutotest="whitelisted-ip-search-bar"
      >
        <template slot="items" slot-scope="props">
          <td
            class="ipRestriction--tableItem text-xs-left text-capitalize"
            autotest="whitelisted-ip-description-display"
          >
            <span class="text-no-wrap">{{ props.item.name }}</span>
          </td>
          <td class="ipRestriction--tableItem text-xs-left" autotest="whitelisted-ip-or-range-display">
            {{ props.item.rules.join(", ") }}
          </td>
          <td class="ipRestriction--tableItem text-xs-left" autotest="whitelisted-ip-source-filter-display">
            {{ ipVersionMap[props.item.type] }} ranges
          </td>
          <td class="ipRestriction--tableActions text-xs-right text-no-wrap">
            <button class="mr-3" @click="updateWhitelistedIp(props.item)" autotest="edit-whitelisted-ip-btn">
              <icon-base height="22" width="22">
                <d-icon-pen />
              </icon-base>
            </button>
            <button @click="deleteWhitelistedIp(props.item)" autotest="delete-whitelisted-ip-btn">
              <icon-base height="22" width="22">
                <d-icon-trash />
              </icon-base>
            </button>
          </td>
        </template>
        <template slot="no-data">
          <span>{{ $t("admin.noWhitelistedIpCreatedYet") }}</span>
        </template>
        <template slot="no-results">
          <span>{{ $t("analytics.msgNoSearchResults") }}</span>
        </template>
      </d-data-table>
    </div>

    <admin-setting-ip-restriction-popup
      v-if="!!showPopupType"
      @close-popup="closeWhitelistedIpPopup"
      @updateWhitelistedIp="handleUpdateWhitelistedIp"
      :showPopupType="showPopupType"
      :editWhitelistedIp="editWhitelistedIp"
    ></admin-setting-ip-restriction-popup>

    <d-confirmation-popup
      v-model="showDeleteWhitelistedIp"
      light
      :title="$t('admin.deleteWhitelistedIp')"
      :cancelBtnName="$t('all.cancel')"
      :actionBtnName="$t('all.delete')"
      :content="`${$t('admin.popupMessageDeleteWhitelistedIp')} ${$escapeHtml(editWhitelistedIp.name)}?`"
      @clickActionBtn="handleUpdateWhitelistedIp"
      :autotest="{
        close: 'delete-whitelisted-ip-popup-close-btn',
        action: 'delete-whitelisted-ip-popup-delete-btn',
        cancel: 'delete-whitelisted-ip-popup-cancel-btn',
      }"
    ></d-confirmation-popup>
  </div>
</template>

<script>
import MixinUser from "@/components/MixinUser.vue";
import MixinDB from "@/components/MixinDB.vue";
import IconBase from "@/components/IconBase.vue";
import DIconTrash from "@/components/icons/DIconTrash.vue";
import DIconPen from "@/components/icons/DIconPen.vue";
import DConfirmationPopup from "@/components/DPopup/DConfirmationPopup.vue";
import DDataTable from "@/components/ui_components/DDataTable.vue";
import AdminSettingIpRestriction from "@/components/DAdmin/Settings/AdminSettingIpRestriction.vue";
import AdminSettingIpRestrictionPopup from "@/components/DAdmin/AdminSettingIpRestrictionPopup.vue";
import { ipVersionMap } from "@/constants/ipVersion.js";
import { loggingError } from "@/server/error-log-server.js";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "DAdminIPRestriction",
  components: {
    IconBase,
    DIconTrash,
    DIconPen,
    DConfirmationPopup,
    DDataTable,
    AdminSettingIpRestriction,
    AdminSettingIpRestrictionPopup,
  },
  mixins: [MixinUser, MixinDB],
  data() {
    return {
      showPopupType: "",
      actionType: "",
      editWhitelistedIp: {},
      isLoading: false,
      showDeleteWhitelistedIp: false,
      searchInput: "",
      ipVersionMap,
      tableHeader: [
        {
          text: this.$t("admin.whitelistedIpDescription"),
          align: "left",
          sortable: true,
          value: "name",
          autotest: "whitelisted-ip-name-sort",
        },
        {
          text: this.$t("admin.whitelistedIpsOrRange"),
          align: "left",
          sortable: true,
          value: "rules",
          autotest: "whitelisted-ips-or-range-sort",
        },
        {
          text: this.$t("admin.whitelistedIpSourceFilter"),
          align: "left",
          sortable: true,
          value: "type",
          autotest: "whitelisted-ip-source-filter-sort",
        },
        {
          text: "",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("permission", ["getter_ip_restriction_enabled", "getter_whitelisted_ip_list_with_id"]),
  },
  mounted() {
    this.getOrgPermissionsSettings({ organization: this.$organization });
  },
  methods: {
    ...mapActions("global", ["openAlert"]),
    ...mapActions("permission", ["getOrgPermissionsSettings", "updateRestrictedIpSettings"]),
    async enableIpRestrictionSetting(enabled) {
      const allowedRules = [...this.getter_whitelisted_ip_list_with_id].map((ip) => {
        const { id, ...settings } = ip;
        return settings;
      });
      const settings = { enableRestrictedIP: enabled, allowedRules };
      try {
        const { ok } = await this.updateRestrictedIpSettings(settings);
        if (!ok) {
          const message = enabled
            ? this.$t("admin.alertEnableIpRestrictionFailed")
            : this.$t("admin.alertDisableIpRestrictionFailed");
          return this.openAlert({ type: "error", message });
        }
        const message = enabled
          ? this.$t("admin.alertEnableIpRestrictionSuccess")
          : this.$t("admin.alertDisableIpRestrictionSuccess");
        return this.openAlert({ type: "success", message });
      } catch (error) {
        this.openAlert({ type: "alert", message: this.$t("alert.unexpectedError") });
        loggingError(error);
      }
    },
    createWhitelistedIp() {
      this.showPopupType = "create";
      this.actionType = "create";
    },
    updateWhitelistedIp(ip) {
      this.editWhitelistedIp = ip;
      this.showPopupType = "edit";
      this.actionType = "edit";
    },
    deleteWhitelistedIp(ip) {
      this.editWhitelistedIp = ip;
      this.actionType = "delete";
      this.showDeleteWhitelistedIp = true;
    },
    async handleUpdateWhitelistedIp(ip) {
      const settings = this.getUpdatedIpSettings(ip);
      try {
        const { ok, code } = await this.updateRestrictedIpSettings(settings);
        const message = this.getAlertMessage(ok, code);
        const type = ok ? "success" : "error";
        this.openAlert({ type, message });
      } catch (error) {
        this.openAlert({ type: "alert", message: this.$t("alert.unexpectedError") });
        loggingError(error);
      }
    },
    getAlertMessage(ok, code) {
      const messages = this.handleAlertMessages();
      if (ok) return messages.success;
      return messages.failed[code] || messages.failed.default;
    },
    handleAlertMessages() {
      const sharedErrorMessages = {
        51: this.$t("admin.alertWhitelistedIpTypeError"),
        99: this.$t("admin.alertWhitelistedCIDRError"),
      };
      const messageMapping = {
        create: {
          success: this.$t("admin.alertCreateWhitelistedIpSuccess"),
          failed: {
            default: this.$t("admin.alertCreateWhitelistedIpFailed"),
            ...sharedErrorMessages,
          },
        },
        edit: {
          success: this.$t("admin.alertUpdateWhitelistedIpSuccess"),
          failed: {
            default: this.$t("admin.alertUpdateWhitelistedIpFailed"),
            ...sharedErrorMessages,
          },
        },
        delete: {
          success: this.$t("admin.alertDeleteWhitelistedIpSuccess"),
          failed: {
            default: this.$t("admin.alertDeleteWhitelistedIpFailed"),
          },
        },
      };
      const { success, failed } = messageMapping[this.actionType];
      return { success, failed };
    },
    getUpdatedIpSettings(ip) {
      const copiedList = [...this.getter_whitelisted_ip_list_with_id];
      const allowedRulesMapping = {
        create: () => [...copiedList, ip],
        edit: () => this.handleUpdatedWhitelistedIpItem(copiedList, ip, (index) => (copiedList[index] = ip)),
        delete: () =>
          this.handleUpdatedWhitelistedIpItem(copiedList, this.editWhitelistedIp, (index) =>
            copiedList.splice(index, 1)
          ),
      };
      const allowedRules = allowedRulesMapping[this.actionType]().map((ip) => {
        const { id, ...settings } = ip;
        return settings;
      });
      return { enableRestrictedIP: this.getter_ip_restriction_enabled, allowedRules };
    },
    handleUpdatedWhitelistedIpItem(copiedList, ip, action) {
      const index = copiedList.findIndex((whitelistedIp) => whitelistedIp.id === ip.id);
      if (index === -1) return;
      action(index);
      return copiedList;
    },
    closeWhitelistedIpPopup() {
      this.editWhitelistedIp = {};
      this.showPopupType = "";
    },
  },
};
</script>

<style scoped lang="scss">
.ipRestriction {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 32px;
  padding-bottom: 8px;
  text-align: left;
  z-index: 0;
  flex-direction: column;
  display: flex;

  &--tableTitle {
    width: 100%;
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    button {
      width: 140px;
      text-transform: none !important;
    }
  }

  &--tableTitleText {
    font-family: Roboto;
    letter-spacing: 0;
    color: #3f3f3f;
    font-size: 32px;
    line-height: 32px;
  }

  &--table {
    width: 100%;
    height: 100%;
    overflow: auto;

    &:hover::-webkit-scrollbar-thumb {
      background-color: #b6b6b6;
    }
  }

  &--tableItem {
    &:first-child {
      width: 30%;
    }

    &:last-child {
      width: 10%;
    }
  }

  &--tableActions button {
    color: #78767a;

    &:hover {
      color: #2d2e32;
    }
  }
}
</style>
