import { getWorkspaceColorConfig } from "@/js/workspace-color/workspace-color.js";
import { getWorkspaceColorCss } from "@/js/workspace-color/workspace-color.js";

const mutations = {
  STORE_WORKSPACE_COLORS_CONFIG(state, { workspaceColorConfig }) {
    state.workspaceColorsConfig = workspaceColorConfig;
  },
};

const getters = {
  getter_get_workspace_card_color: (state) => (selectedColor) => {
    if (selectedColor && state.workspaceColorsConfig) {
      return {
        borderLeft: `6px solid ${getWorkspaceColorCss(selectedColor, state.workspaceColorsConfig)}`,
      };
    }
  },
};

const actions = {
  async setupWorkspaceColorsConfig({ commit }, { mixinDbRef }) {
    const workspaceColorConfig = await getWorkspaceColorConfig({
      mixinDbRef,
    });

    commit("STORE_WORKSPACE_COLORS_CONFIG", { workspaceColorConfig });
  },
};
export const store = {
  namespaced: true,
  mutations,
  getters,
  actions,
  state: {
    workspaceColorsConfig: null,
  },
};
