<template>
  <section v-if="loadingFinished && accessAllowed" class="builder-main" @mousedown="finishEditing()">
    <div class="BuilderToolbar">
      <button class="tool-bar-icon" @click="closeBuilder()">
        <font-awesome-icon :icon="['far', 'arrow-left']" size="lg" />
      </button>
      <div
        class="edit-poster-div"
        :class="{ 'edit-poster-div--disabled': isPosterDisabled }"
        @click="onClickEditPoster()"
      >
        <div class="poster-icon">
          <font-awesome-icon :icon="['far', 'image']" size="lg" />
        </div>
        <d-image :aspectRatio="16 / 9" :src="workflow.poster" class="video-poster" />
      </div>
      <p
        v-if="!isEditingWorkflowName && workflow.title != ''"
        class="white--text title font-weight-regular text-no-wrap text-truncate mt-3"
        :class="{ 'title-border': !workflow.published }"
        @mouseover="showEditIcon = true"
        @mouseleave="showEditIcon = false"
        @click="clickTitle()"
      >
        {{ workflow.title }}
      </p>
      <input
        v-else
        ref="inputWorkflowName"
        class="edit-video-title"
        :class="{ 'title-empty': workflow.title == '' }"
        :size="getInputSize(workflow.title)"
        type="text"
        maxlength="90"
        :placeholder="$t('editor.hintNoEmptyTitle')"
        v-model="workflow.title"
        @change="madeChanges = true"
        @keydown="handleInput($event, 'Video Title')"
        @mouseover="isTypingWorkflow = true"
        @mouseleave="isTypingWorkflow = false"
      />
      <font-awesome-icon
        class="edit-icon ml-2"
        :class="{ 'edit-icon-show': showEditIcon && !workflow.published }"
        :icon="['fal', 'pen']"
      />
      <workflow-status-tag
        class="mx-2 transition-ease-in-out"
        :style="{ transform: `translateX(${showEditIcon && !workflow.published ? 0 : '-23px'})` }"
        :is-published="workflow.published"
        :status-text="workflow.published ? $t('all.published') : $t('all.unpublished')"
        :show-dot="false"
      />
      <v-spacer></v-spacer>
      <div class="BuilderToolbar__buttons" v-click-outside="closeAIToolkit">
        <div
          v-if="[hasSummmaryPermission, isShowSop, getter_quiz_enabled].some((p) => p)"
          class="ai-toolkit"
          @click="isShowAITookit = !isShowAITookit"
        >
          <button id="ug-ai-toolkit" class="ai-toolkit_wrap linear-gradient-border" type="button">
            <DIconMicroChip />
            <span> {{ $t("builder.aiToolkit") }} </span>
            <font-awesome-icon :icon="['fas', 'chevron-down']" color="#fff" />
          </button>
          <div v-if="isNewAIGen" class="ai-toolkit-alert">
            {{ $t("builder.aiCompletedToast") }}
            <font-awesome-icon :icon="['far', 'xmark']" @click.stop="isNewAIGen = false" />
          </div>
          <div class="ai-toolkit_container linear-gradient-border" v-show="isShowAITookit">
            <div
              v-if="hasSummmaryPermission"
              id="ug-ai_summary"
              class="ai-toolkit_item"
              @click="isShowAISummaryPopup = true"
            >
              <div class="ai-toolkit_item_label">
                <DIconLinearGradientSummary />
                {{ $t("aiSummary.workflowSummary") }}
              </div>
              <font-awesome-icon class="icon-dot-status" :class="summaryDotColor" :icon="['fas', 'circle']" />
            </div>
            <div v-if="isShowSop" id="ug-text_sop" class="ai-toolkit_item" @click="isShowMultimodalSop = true">
              <div class="ai-toolkit_item_label">
                <DIconLinearGradientSop />
                {{ $t("mavenSop.aiSop") }}
              </div>
              <font-awesome-icon class="icon-dot-status" :class="sopDotColor" :icon="['fas', 'circle']" />
            </div>
            <div v-if="getter_quiz_enabled" id="ug-quiz" class="ai-toolkit_item" @click="showGenerateQuestionPopup">
              <div class="ai-toolkit_item_label">
                <DIconLinearGradientQuiz />
                {{ $t("quiz.quiz") }}
              </div>
              <font-awesome-icon class="icon-dot-status" :class="quizDotColor" :icon="['fas', 'circle']" />
            </div>
          </div>
        </div>
        <button
          v-if="workflow.published"
          class="dh-btn btn-secondary"
          @click="pauseVideo(), (showBuilderShare = true)"
          analytics="share"
        >
          <font-awesome-icon :icon="['far', 'share']" />
          <span class="icon-text-spacing">{{ $t("builder.share") }}</span>
        </button>
        <div
          class="more-div"
          @mouseover="(showMoreDropdown = true), (isTypingWorkflow = true)"
          @mouseleave="(showMoreDropdown = false), (isTypingWorkflow = false)"
        >
          <button class="dh-btn btn-secondary more-btn" @click="clickToolbarMore()">
            <font-awesome-icon :icon="['fas', 'ellipsis']" />
          </button>
          <div v-if="showMoreDropdown" class="more-dropdown">
            <v-list dense dark>
              <v-list-tile class="dropdown-list-item" @click="openPreviewVideo" :disabled="isPreviewVideoDisabled">
                <v-list-tile-title class="body-1">{{ $t("builder.preview") }}</v-list-tile-title>
              </v-list-tile>
              <v-list-tile
                v-if="$access.categories"
                class="dropdown-list-item"
                @click="pauseVideo(), (showSettings = true), trackShowSettingsByHeap()"
              >
                <v-list-tile-title class="body-1">{{ $t("admin.categoriesAssignment") }}</v-list-tile-title>
              </v-list-tile>
              <v-list-tile
                class="dropdown-list-item"
                @click="pauseVideo(), (showTranslation = true), trackShowTranslationByHeap()"
              >
                <v-list-tile-title class="body-1">{{ $t("builder.translation") }}</v-list-tile-title>
              </v-list-tile>
              <v-list-tile class="dropdown-list-item" @click="isOpenVersionHistory = true">
                <v-list-tile-title class="body-1">{{ $t("editor.versionHistory") }}</v-list-tile-title>
              </v-list-tile>
              <v-list-tile
                class="dropdown-list-item"
                analytics="review-transcription"
                @click="handleClickMenuReviewTranscription"
              >
                <v-list-tile-title class="body-1">{{ $t("builder.reviewTranscription") }}</v-list-tile-title>
              </v-list-tile>
              <v-list-tile
                v-if="!getter_is_gen2"
                class="dropdown-list-item"
                analytics="reset-workflow"
                @click="handleClickMenuReset"
              >
                <v-list-tile-title class="body-1">{{ $t("builder.resetWorkflow") }}</v-list-tile-title>
              </v-list-tile>
              <v-list-tile analytics="delete-workflow" class="dropdown-list-item" @click="handleClickMenuDelete">
                <v-list-tile-title class="body-1 red--text text-capitalize">{{
                  $t("explorer.deleteWorkflow")
                }}</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </div>
        </div>
        <div class="toolbar-line"></div>
        <button
          class="dh-btn btn-outline-primary text-capitalize"
          @click="debounceSave({ published: false, unpublishedAlert: false })"
          :disabled="!madeChanges"
        >
          {{ $t("all.save") }}
        </button>
        <button v-if="workflow.published" class="dh-btn btn-primary" @click="showEditWorkflowPopup = true">
          {{ $t("builder.editWorkflow") }}
        </button>
        <template v-else>
          <button v-if="allowPublish" class="dh-btn btn-primary text-capitalize" @click="alertAssignCategories()">
            {{ $t("builder.publish") }}
          </button>
          <button v-else class="dh-btn btn-primary text-capitalize" @click="pauseVideo(), (showReviewerPopup = true)">
            {{ $t("builder.sendToReviewer") }}
          </button>
        </template>
      </div>
    </div>
    <!-- tabs header -->
    <div class="tabs-header" v-if="!getter_is_gen2">
      <div class="btns-div">
        <button
          class="tab-btn"
          :class="{ 'tab-btn-active': showBuilderPart == 'viewer' }"
          analytics="viewer-tab"
          @click="showBuilderPart = 'viewer'"
        >
          <span class="tab-text text-capitalize">{{ $t("builder.viewer") }}</span>
        </button>
        <button
          class="tab-btn"
          :class="{ 'tab-btn-active': showBuilderPart == 'attachments' }"
          analytics="attachment-tab"
          @click="(showBuilderPart = 'attachments'), pauseVideo()"
        >
          <span class="tab-text text-capitalize">{{ $t("all.attachments") }}</span>
        </button>
        <button
          class="tab-btn"
          :class="{ 'tab-btn-active': showBuilderPart == 'trim' }"
          :disabled="isDisabledStepTrimOrErase"
          analytics="trim-tab"
          @click="(showBuilderPart = 'trim'), pauseVideo()"
        >
          <span class="tab-text text-capitalize" @click="resetWorkflowForDeveloperTest()">{{
            $t("builder.trim")
          }}</span>
        </button>
        <button
          class="tab-btn"
          :class="{ 'tab-btn-active': showBuilderPart == 'erase' }"
          :disabled="isDisabledStepTrimOrErase"
          analytics="erase-tab"
          @click="(showBuilderPart = 'erase'), pauseVideo()"
        >
          <span class="tab-text text-capitalize" @click="resetWorkflowForDeveloperTest()">{{
            $t("editor.erase")
          }}</span>
        </button>
        <button
          class="tab-btn"
          :class="{ 'tab-btn-active': showBuilderPart == 'annotation' }"
          :disabled="isStepVideosDisabled"
          analytics="annotation-tab"
          @click="(showBuilderPart = 'annotation'), pauseVideo()"
        >
          <span class="tab-text text-capitalize">{{ $t("builder.annotation") }}</span>
        </button>
      </div>
    </div>

    <!-- viewer -->
    <div v-if="showBuilderPart == 'viewer'" class="tabs-body">
      <builder-viewer
        ref="builderViewer"
        :isStepReady="!!steps[currentStep].videos"
        :poster="currentMainPoster"
        :signedSrc="getVideoMetadata(currentStep)"
        :videoWidthViewer="videoWidthViewer"
        :videoHeightViewer="videoHeightViewer"
      />
    </div>

    <!-- attachments -->
    <div v-if="showBuilderPart == 'attachments'" class="tabs-body">
      <builder-attachments
        :step="steps[currentStep]"
        :isPublished="workflow.published"
        @showEditWorkflowPopup="showEditWorkflowPopup = true"
      />
    </div>

    <!-- trim -->
    <div v-if="showBuilderPart === 'trim'" class="tabs-body" :style="{ height: '100%' }">
      <builder-trim
        :madeChanges.sync="madeChanges"
        :currentStepIndex.sync="currentStep"
        :steps="steps"
        :videoPoster="currentMainPoster"
        :videoSource="getVideoMetadata(currentStep)"
        :handleSaveAllChange="publishWorkflow"
        :workflow="workflow"
        :isPublished="workflow.published"
        @showEditWorkflowPopup="showEditWorkflowPopup = true"
        @updateBlockMsg="updateBlockMsg"
      />
    </div>

    <div v-if="showBuilderPart === 'erase'" class="tabs-body" :style="{ height: '100%' }">
      <builder-erase
        :madeChanges.sync="madeChanges"
        :currentStepIndex.sync="currentStep"
        :steps="steps"
        :videoPoster="currentMainPoster"
        :videoSource="getVideoMetadata(currentStep)"
        :handleSaveAllChange="publishWorkflow"
        :workflow="workflow"
        :isPublished="workflow.published"
        @showEditWorkflowPopup="showEditWorkflowPopup = true"
      />
    </div>

    <!-- annotation -->
    <div v-if="showBuilderPart === 'annotation'" class="tabs-body">
      <builder-annotation
        @showEditWorkflowPopup="showEditWorkflowPopup = true"
        :isPublished="workflow.published"
        :madeChanges.sync="madeChanges"
        :currentStepId="steps[currentStep].id"
        :videoEndTime="steps[currentStep].duration"
        :videoPoster="currentMainPoster"
        :videoSource="getVideoMetadata(currentStep)"
      />
    </div>

    <!-- builder steps -->
    <div class="builder-steps-wrapper" v-if="isShowStepsWrapper">
      <div class="builder-steps" :class="{ 'drag-mode': isDraggingStep }">
        <!-- dropzone when move step to the first place, dragOverIdx=-1 when mouseover add step button -->
        <div
          class="hide-dropzone"
          :class="{ 'show-first-dropzone': dragOverIdx == -1 && dragFromIdx != 0 }"
          @dragover="onDragover($event, -1)"
          @dragend="onDragEnd($event)"
          @drop="dragToFirstStep($event)"
        >
          <div class="dropzone-div"></div>
        </div>
        <div v-for="(step, idx) in steps" :key="idx" class="steps-div">
          <div v-if="idx == dragFromIdx" class="step-zone">
            <div class="dropzone-div"></div>
          </div>
          <div class="add-step-gap" @click="showBuilderExplorer(idx)">
            <icon-base color="#FFFFFF" width="13" height="13">
              <d-icon-add />
            </icon-base>
          </div>
          <v-card
            :draggable="!isEditingStepName"
            flat
            color="transparent"
            class="steps-card"
            :class="{
              'steps-card-selected': idx == currentStep,
              'hide-dragging-step': idx == dragFromIdx,
              'step-in-processing': !step.videos && !getter_is_gen2,
            }"
            @dragstart="onDragStart($event, idx)"
            @dragend="onDragEnd($event)"
            @drop="onDrop($event)"
            @mouseover="isTypingStep = true"
            @mouseleave="isTypingStep = false"
          >
            <v-card
              color="grey darken-4"
              :class="{ 'step-in-processing text-uppercase': !step.videos }"
              :data-process-text="$t('explorer.tagProcessing')"
            >
              <div class="active-area" @click="clickNewStep(idx)">
                <div class="active-area-l" @dragover="onDragover($event, idx - 1)"></div>
                <div class="active-area-r" @dragover="onDragover($event, idx)" @dragleave="onDragleave($event)"></div>
              </div>
              <button
                @click="clickEditStepName(idx)"
                class="delete-step-btn edit-step-btn icon-hover"
                :class="{ 'delete-step-btn-show': idx == currentStep }"
              >
                <icon-base width="22" height="22">
                  <d-icon-circle-pen />
                </icon-base>
              </button>
              <v-menu
                top
                left
                offset-y
                slide-x-transition
                class="delete-step-btn"
                :class="{ 'delete-step-btn-show': idx == currentStep }"
                content-class="elevation-0"
              >
                <button slot="activator" @click="clickDeleteStep(idx)" class="icon-hover">
                  <icon-base width="22" height="22">
                    <d-icon-circle-trash />
                  </icon-base>
                </button>
                <!-- Delete Video confirmation -->
                <div class="delete-popup" :hidden="workflow.published && !editable">
                  <div class="delete-popup-div">
                    <button class="delete-popup-btn">
                      <icon-base width="36" height="36">
                        <d-icon-text-cancel />
                      </icon-base>
                      <div class="delete-popup-text text-capitalize">{{ $t("all.cancel") }}</div>
                    </button>
                    <div class="delete-popup-line"></div>
                    <button class="delete-popup-btn" @click="deleteStep(idx)">
                      <icon-base width="36" height="36">
                        <d-icon-text-delete />
                      </icon-base>
                      <div class="delete-popup-text text-capitalize">{{ $t("all.delete") }}</div>
                    </button>
                    <div class="arrow-down"></div>
                  </div>
                </div>
              </v-menu>

              <div :class="{ 'darken-layer': idx != currentStep }"></div>

              <d-image contain class="box-shadow step-img" :aspect-ratio="16 / 9" :src="step.poster"></d-image>
            </v-card>
            <div
              class="step-title"
              :class="{ 'title-selected': idx == currentStep, 'right-to-left': workflow.languageCode == 'he-il' }"
            >
              <div class="mt-2 caption text-no-wrap text-truncate">
                {{ getStepNum(idx) }}.
                <input
                  v-show="(isEditingStepName && idx == currentStep) || step.title == ''"
                  ref="inputStepName"
                  class="edit-step-title"
                  :class="{ 'empty-step-title': step.title == '' }"
                  type="text"
                  maxlength="90"
                  :placeholder="$t('editor.hintNoEmptyTitle')"
                  v-model="step.title"
                  @keydown="handleInput($event, 'Step Title')"
                  @mousedown="isEditingStepName = true"
                  @change="madeChanges = true"
                />
                <v-tooltip
                  v-if="(!isEditingStepName || idx !== currentStep) && step.title !== ''"
                  top
                  left
                  open-delay
                  color="#2C2D32"
                >
                  <span slot="activator">{{ step.title }}</span>
                  <span>{{ step.title }}</span>
                </v-tooltip>
              </div>
            </div>
          </v-card>
          <div
            class="hide-dropzone"
            :class="{ 'show-dropzone': idx == dragOverIdx }"
            @dragover="onDragover($event, idx)"
            @dragend="onDragEnd($event)"
            @drop="onDrop($event)"
          ></div>
          <div v-if="idx == steps.length - 1" class="add-step-gap" @click="handleOpenLastStepGapPopup">
            <icon-base color="#FFFFFF" width="13" height="13">
              <d-icon-add />
            </icon-base>
          </div>
          <div v-if="idx == steps.length - 1" class="last-dropzone" @dragover="onDragover($event, idx)"></div>
        </div>
      </div>
    </div>
    <!-- BuilderPosterPopup -->
    <builder-poster-popup
      v-if="showPosterPopup"
      posterType="workflow"
      :workflow="workflow"
      :steps="steps"
      @close-poster-popup="showPosterPopup = false"
      @update-poster="updatePoster($event)"
    >
    </builder-poster-popup>

    <builder-explorer
      v-if="showExplorer"
      @addStepsEvent="handleAddSteps($event)"
      @closeExplorer="closeBuilderExplorer($event)"
      :languageCode="workflow.languageCode"
      :workflowId="workflowId"
      :group="workflow.group"
      :privateWorkspace="workflow.privateWorkspace"
      :addedSteps="steps"
    ></builder-explorer>

    <d-builder-reviewer-popup
      v-model="showReviewerPopup"
      :workflowId="workflowId"
      @openReviewerSuccessPopup="showReviewerSuccessPopup = true"
    />
    <d-builder-reviewer-success-popup v-model="showReviewerSuccessPopup" :workspaceId="workflow.group" />
    <d-builder-category-popup
      v-model="showSettings"
      :workflow="workflow"
      :is-success.sync="isSuccess"
      :alertText.sync="alertText"
    />

    <builder-translation
      ref="builderTranslation"
      v-if="showTranslation"
      class="builder-transcription"
      @closeTranslation="showTranslation = false"
      @addTranslation="addTranslation($event)"
      @removeTranslation="removeTranslation($event)"
      @editWorkflow="editWorkflow()"
      :showTranslation="showTranslation"
      :steps="steps"
      :workflow="workflow"
      :translationList="translationList"
      :editable="editable"
      :alertTextBuilder="alertText"
      :isSavingBuilder="isSaving"
      :isSuccessBuilder="isSuccess"
      :isNotSuccessBuilder="isNotSuccess"
    ></builder-translation>

    <builder-transcription
      ref="builderTrancription"
      v-if="showTranscription"
      class="builder-transcription"
      @closeTranslation="showTranscription = false"
      @addTranslation="addTranslation($event)"
      @removeTranslation="removeTranslation($event)"
      @editWorkflow="editWorkflow()"
      :showTranscription="showTranscription"
      :steps="steps"
      :workflow="workflow"
      :editable="editable"
      :alertTextBuilder="alertText"
      :isSavingBuilder="isSaving"
      :isSuccessBuilder="isSuccess"
      :isNotSuccessBuilder="isNotSuccess"
    ></builder-transcription>

    <builder-share
      v-if="showBuilderShare"
      class="builder-share"
      :steps="steps"
      :workflow="workflow"
      :videoWidthViewer="videoWidthViewer"
      :translationList="translationList"
      :downloadVideoInfo="downloadVideoInfo"
      @setDownloadVideoInfo="setDownloadVideoInfo($event)"
      @closeBuilderShare="showBuilderShare = false"
      @downloadVideo="downloadVideo($event)"
      @updateWorkflow="(newWorkflow) => (workflow = newWorkflow)"
    ></builder-share>

    <!-- alerts -->
    <d-alert v-model="isSaving" manualClose type="info" :message="$t('editor.alertIsSaving')"></d-alert>
    <d-alert v-model="isNotSuccess" type="error" :message="$t('builder.alertSaveFailed')"></d-alert>
    <d-alert v-model="isOpenAlertVersionDuplicated" type="error" :message="$t('builder.versionIsDuplicated')"></d-alert>
    <d-alert v-if="!isSaving" v-model="isSuccess" type="success" :message="alertText"></d-alert>

    <d-alert v-model="emptyTitleAlert" type="error" :message="$t('editor.alertNoEmptyTitle')"></d-alert>

    <d-download
      v-if="workflow.published"
      :showBuilderShare="showBuilderShare"
      :downloadVideoList="downloadVideoList"
      :downloadVideoListWatch="downloadVideoListWatch"
    ></d-download>
    <builder-reset-workflow-popup v-model="resetWorkflowConfirmation" @resetWorkflow="handleResetWorkflow" />
    <!-- close builder confirmation dialog -->
    <d-confirmation-popup
      v-model="closeConfirmation"
      :title="$t('editor.closeWorkflow')"
      :cancelBtnName="$t('all.no')"
      :actionBtnName="$t('editor.popupBtnClose')"
      :content="$t('editor.popupMsgCloseWorkflow')"
      @clickActionBtn="closeBuilder(true)"
    ></d-confirmation-popup>

    <!-- Delete Video confirmation dialog -->
    <!-- Are you sure you want to delete this workflow? You will lose all the steps and data related to this workflow. -->
    <d-confirmation-popup
      v-model="deleteWorkflowConfirmation"
      redActionBtn
      :title="$t('explorer.deleteWorkflow')"
      :cancelBtnName="$t('all.no')"
      :actionBtnName="$t('explorer.popupBtnDelete')"
      :content="$t('explorer.popupMsgDeleteWorkflow')"
      :analytics="{ action: 'confirm-delete', cancel: 'confirm-close-buildermain' }"
      @clickActionBtn="handleDeleteWorkflow()"
    ></d-confirmation-popup>

    <!-- Edit workflow confirmation dialog -->
    <!-- If you go into edit mode, your workflow will automatically
    be unpublished , are you sure you want to continue? -->
    <d-confirmation-popup
      v-model="showEditWorkflowPopup"
      :title="$t('builder.editWorkflow')"
      :cancelBtnName="$t('all.no')"
      :actionBtnName="$t('builder.popupBtnEdit')"
      :content="$t('builder.popupMsgEditWorkflow')"
      :analytics="{ action: 'confirm-edit', cancel: 'confirm-close-buildermain' }"
      @clickActionBtn="editWorkflow()"
    ></d-confirmation-popup>

    <!-- Publish workflow without category confirmation dialog -->
    <!-- Before you publish, why don't you select some categories for your workflow -->
    <builder-categories-popup
      v-if="showCategoryPopup"
      :workflow="workflow"
      @close-popup="(workflow.subCategories = []), (showCategoryPopup = false)"
      @show-publish-popup="(showCategoryPopup = false), (isOpenConfirmedPublishPopup = true)"
    ></builder-categories-popup>

    <d-confirmation-popup
      :value="actionEnterPopupType !== ''"
      :title="$t('alert.info')"
      :isAllowCancel="false"
      isAllowDontShowMsgAgain
      :actionBtnName="$t('all.yes')"
      :content="actionEnterPopupType ? $t(`builder.popupMsgEnter${actionEnterPopupType}`) : ''"
      @clickActionBtn="handleCloseActionEnterPopup"
    />

    <d-popup-player v-if="loadingFinished" v-model="isOpenPreviewVideo" :videoLink="previewVideoSrc" />

    <version-history-popup
      type="builder"
      :is-open-version-history.sync="isOpenVersionHistory"
      :workflow-id="workflowId"
    />

    <d-builder-publish-confirmed-popup
      v-if="isOpenConfirmedPublishPopup"
      :is-open-confirmed-publish-popup.sync="isOpenConfirmedPublishPopup"
      :version-description.sync="versionDescription"
      :workflow-id="workflowId"
      :categories="workflow.categories || []"
      :languages="(workflow.titleTranslations && Object.entries(workflow.titleTranslations)) || []"
      @confirmPublish="handleConfirmPublish"
    />

    <d-builder-category-confirmed-popup
      :is-open-confirmed-popup.sync="showCategoryConfirmPopup"
      @confirm="showSettings = true"
      @cancel="isOpenConfirmedPublishPopup = true"
    />

    <generate-quiz-popup
      v-if="isShowGenerateQuizPopup"
      :is-show-generate-quiz-popup.sync="isShowGenerateQuizPopup"
      :steps="steps"
      :workflow="workflow"
    />

    <multimodal-sop v-if="isShowMultimodalSop" :workflow="workflow" @close="handleCloseSop" />

    <a-i-summary v-if="isShowAISummaryPopup" :workflow="workflow" @close="isShowAISummaryPopup = false" />

    <last-step-gap-popup
      v-if="lastStepGapPopupData.isShowPopup"
      :is-show-popup.sync="lastStepGapPopupData.isShowPopup"
      :style="{ left: lastStepGapPopupData.left + 'px', top: lastStepGapPopupData.top + 'px' }"
      @openAddStepPopup="showBuilderExplorer(steps.length)"
      @openAIQuipPopup="isShowGenerateQuizPopup = true"
    />
  </section>
</template>

<script>
import MixinDB from "./MixinDB.vue";
import MixinDelete from "./MixinDelete.vue";
import MixinUser from "./MixinUser.vue";
import MixinSchedule from "./MixinSchedule.vue";
import MixinVideo from "./MixinVideo.vue";
import BuilderExplorer from "./BuilderExplorer.vue";
import DBuilderCategoryPopup from "@/components/DPopup/DBuilderCategoryPopup";
import DBuilderReviewerPopup from "@/components/DPopup/DBuilderReviewerPopup";
import DBuilderReviewerSuccessPopup from "@/components/DPopup/DBuilderReviewerSuccessPopup";
import BuilderTranslation from "./BuilderTranslation.vue";
import BuilderTranscription from "./BuilderTranscription.vue";
import BuilderShare from "./BuilderShare.vue";
import DAlert from "./ui_components/DAlert.vue";
import DDownload from "./ui_components/DDownload.vue";
import IconBase from "./IconBase.vue";
import DIconAdd from "./icons/DIconAdd.vue";
import DIconDiagram from "./icons/DIconDiagram.vue";
import DIconTranslation from "./icons/DIconTranslation.vue";
import DIconCircleTrash from "./icons/DIconCircleTrash.vue";
import DIconCirclePen from "./icons/DIconCirclePen.vue";
import DIconTextDelete from "./icons/DIconTextDelete.vue";
import DIconTextCancel from "./icons/DIconTextCancel.vue";
import DImage from "./ui_components/DImage.vue";
import DConfirmationPopup from "./DPopup/DConfirmationPopup.vue";
import BuilderCategoriesPopup from "./DBuilder/BuilderCategoriesPopup.vue";
import BuilderPosterPopup from "./DBuilder/BuilderPosterPopup.vue";
import { setTimeout } from "timers";
import Vue from "vue";
import Downloadjs from "downloadjs";
import { checkCodeIsNotAllowIp } from "@/js/login/checkRestrictedIp";
import DVideoSecure from "./ui_components/DVideoSecure.vue";
import MixinTracker from "./MixinTracker";
import Analytics from "@/js/analytics/analytics";
import BuilderAnnotation from "@/components/DBuilder/BuilderAnnotation";
import BuilderErase from "@/components/DBuilder/BuilderErase";
import BuilderTrim from "@/components/DBuilder/BuilderTrim";
import BuilderViewer from "@/components/DBuilder/BuilderViewer";
import BuilderAttachments from "@/components/DBuilder/BuilderAttachments";
import BuilderResetWorkflowPopup from "@/components/DBuilder/BuilderResetWorkflowPopup";
import { debounce, isEqual } from "lodash-es";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import {
  authWorkflowPermission,
  fetchWorkflowPreviewStatus,
  postGenerateSubtitles,
  postResetWorkflow,
  putWorkflowBuilder,
} from "@/server/api-server";
import { REDIRECT_TO_STUDIO_WORKSPACE } from "@/constants/studio-version";
import DPopupPlayer from "./ui_components/DPopupPlayer";
import VersionHistoryPopup from "@/components/DEditorBuilder/VersionHistory/VersionHistoryPopup.vue";
import DBuilderPublishConfirmedPopup from "@/components/DPopup/DBuilderPublishConfirmedPopup.vue";
import { loggingError } from "@/server/error-log-server.js";
import MultimodalSop from "@/components/DBuilder/MultimodalSop/MultimodalSop.vue";
import { fetchVersionHistoryByWorkflowId } from "@/server/version-history";
import DIconMicroChip from "./icons/DIconMicroChip.vue";
import DIconLinearGradientSummary from "./icons/DIconLinearGradientSummary.vue";
import DIconLinearGradientQuiz from "./icons/DIconLinearGradientQuiz.vue";
import DIconLinearGradientSop from "./icons/DIconLinearGradientSop.vue";
import clickOutsideDirective from "@/directives/click-outside-directive.js";
import { isLanguageSupport } from "@/js/workflow/language";

export default {
  name: "builder-main",
  directives: {
    "click-outside": clickOutsideDirective,
  },
  watch: {
    allAIGenStatus(newStatus, oldStatus) {
      this.isNewAIGen = newStatus.some((s, idx) => {
        return !s && oldStatus[idx];
      });
    },
    workflow: function () {
      if (this.workflow.translations) {
        this.translationList = this.workflow.translations;
      }
    },
    currentStep: function (currentStep) {
      if (this.steps) {
        this.currentMainPoster = this.steps[currentStep].poster;
        this.handleShowActionEnterPopup(currentStep, this.showBuilderPart);
      }
    },
    showBuilderPart: function (showBuilderPart) {
      if (this.steps) {
        this.handleShowActionEnterPopup(this.currentStep, showBuilderPart);
      }
    },
    steps: function () {
      if (this.steps) {
        this.currentMainPoster = this.steps[this.currentStep].poster;
      }
    },
    getter_is_ai_processing: {
      handler(getter_is_ai_processing) {
        if (!getter_is_ai_processing) {
          clearInterval(this.timer.AIProcessing);
          return;
        }
        this.timer.AIProcessing = setInterval(() => {
          this.fetchQuizFromDb(this.workflowId);
        }, 5000);
      },
      immediate: true,
    },
    getter_is_ai_processing_sop: {
      handler(val) {
        if (!val) {
          clearInterval(this.timer.AIProcessingSop);
          return;
        }
        this.timer.AIProcessingSop = setInterval(() => {
          this.fetchSopFromDb(this.workflowId);
        }, 5000);
      },
      immediate: true,
    },
    getter_is_ai_processing_summary: {
      handler(val) {
        if (!val) {
          clearInterval(this.timer.AIProcessingSummary);
          return;
        }
        this.timer.AIProcessingSummary = setInterval(() => {
          this.fetchSummaryFromDb(this.workflowId);
        }, 5000);
      },
      immediate: true,
    },
    showSettings(val) {
      if (!val) {
        if (this.showPublisAfterCatPop) {
          this.isOpenConfirmedPublishPopup = true;
        }
        this.showPublisAfterCatPop = false;
      }
    },
  },
  props: {},
  async created() {
    this.workflowId = this.$route.params.id;
    const { ok, data, code } = await authWorkflowPermission(this.workflowId);
    if (!ok || !data.item.allowView || !data.item.allowEdit) {
      if (!checkCodeIsNotAllowIp(code)) {
        this.$router.push({ name: "404" });
      }
      return;
    }
    this.allowPublish = data.item.allowPublish;
    if (!this.$role || !this.$access) {
      if (!this.$user) {
        this.$user = await this.auth().currentUser;
        this.getUserProfileFromVuex();
        this.getOrganization();
      }
      await this.getUserRole(this.$user);
      await this.setUserAccess();
      if (!this.$access.editor && !this.getter_is_gen2) {
        this.$router.push("/navigator/home");
      }
    }
    await this.startLoading();
  },
  mounted() {
    this.resizeWindow();
    this.checkVersionStatus();
    this.$nextTick(() => {
      window.addEventListener("resize", this.resizeWindow);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeWindow);
    Analytics.removeEventProperties("Workflow ID");
    Analytics.removeEventProperties("Workspace ID");
    this.resetQuizData();
    Object.entries(this.timer).forEach(([, v]) => {
      if (v) {
        clearInterval(v);
      }
    });
  },
  data() {
    const lastStepGapPopupData = {
      isShowPopup: false,
      top: 0,
      left: 0,
    };
    const timer = {
      AIProcessing: 0,
      AIProcessingSop: 0,
      AIProcessingSummary: 0,
    };
    return {
      lastStepGapPopupData,
      timer,
      isOpenPreviewVideo: false,
      previewVideoSrc: window.location.origin + "/PlayerPreview/",
      allowPublish: false,
      workflow: {},
      workflowId: "",
      steps: [],
      loadingFinished: false,
      tabs: null,
      currentMainPoster: "",
      currentStep: 0,
      addStepIndex: null, // follow Array.slice first parameter(start) rule.
      isDraggingStep: false,
      dragOverIdx: -2,
      dragFromIdx: -2,
      showExplorer: false,
      isSaving: false,
      isSuccess: false,
      isNotSuccess: false,
      alertText: "",
      closeConfirmation: false,
      deleteWorkflowConfirmation: false,
      resetWorkflowConfirmation: false,
      madeChanges: false,
      showReviewerPopup: false,
      showReviewerSuccessPopup: false,
      showSettings: false,
      showTranslation: false,
      showTranscription: false,
      translationList: [], //keeps track of available translations for this workflow
      test: "",
      showBuilderShare: false,
      showEditWorkflowPopup: false,
      editable: null,
      showDeleteMenu: false,
      isEditingWorkflowName: false,
      isTypingWorkflow: false,
      isTypingStep: false,
      isEditingStepName: false,
      showBuilderPart: "viewer",
      secretButton: 0,
      accessAllowed: false,
      showMoreDropdown: false,
      showEditIcon: false,
      emptyTitleAlert: false,
      downloadVideoList: [],
      downloadVideoListWatch: [],
      stepOnSnapshot: {},
      workflowOnSnapshot: null,
      videoHeightViewer: 0,
      videoWidthViewer: 0,
      videoStream: null,
      screenRecorder: null,
      downloadVideoInfo: {
        isDownloadMode: false,
        isFullWorkflow: true,
        stepIdx: -1,
        title: "",
        hasSubtitles: false,
        language: "",
      },
      showCategoryPopup: false,
      showPosterPopup: false,
      actionEnterPopupType: "",
      subtitlesStatus: "",
      isOpenVersionHistory: false,
      isOpenConfirmedPublishPopup: false,
      versionDescription: "",
      nextVersionHistoryNumber: 0,
      isOpenAlertVersionDuplicated: false,
      isShowAIQuizNewMark: !Boolean(localStorage.getItem(`closeAIQuizNewMark`)),
      isShowGenerateQuizPopup: false,
      isShowMultimodalSop: false,
      isShowAISummaryPopup: false,
      isSopActivate: false,
      showCategoryConfirmPopup: false,
      isFirstPublish: false,
      showPublisAfterCatPop: false,
      isShowAITookit: false,
      isNewAIGen: false,
    };
  },
  components: {
    IconBase,
    DIconAdd,
    DIconDiagram,
    DIconTranslation,
    BuilderExplorer,
    DBuilderCategoryPopup,
    DBuilderReviewerPopup,
    DBuilderReviewerSuccessPopup,
    BuilderTranslation,
    BuilderTranscription,
    BuilderShare,
    DAlert,
    DDownload,
    DIconCircleTrash,
    DIconCirclePen,
    DIconTextDelete,
    DIconTextCancel,
    BuilderTrim,
    BuilderViewer,
    DImage,
    DConfirmationPopup,
    BuilderCategoriesPopup,
    BuilderPosterPopup,
    DVideoSecure,
    BuilderAnnotation,
    BuilderErase,
    BuilderAttachments,
    BuilderResetWorkflowPopup,
    DPopupPlayer,
    VersionHistoryPopup,
    DBuilderPublishConfirmedPopup,
    GenerateQuizPopup: () => import("@/components/DEditorBuilder/GenerateQuizPopup/GenerateQuizPopup.vue"),
    WorkflowStatusTag: () => import("@/components/DEditorBuilder/WorkflowStatusTag.vue"),
    LastStepGapPopup: () => import("@/components/DBuilder/LastStepGapPopup.vue"),
    MultimodalSop,
    DBuilderCategoryConfirmedPopup: () => import("@/components/DPopup/DBuilderCategoryConfirmedPopup.vue"),
    AISummary: () => import("@/components/DBuilder/AISummary/AISummary.vue"),
    DIconMicroChip,
    DIconLinearGradientSummary,
    DIconLinearGradientQuiz,
    DIconLinearGradientSop,
  },
  mixins: [MixinDB, MixinDelete, MixinUser, MixinSchedule, MixinVideo, MixinTracker],
  methods: {
    ...mapActions("annotation", [
      "fetchMultipleAnnotation",
      "updateAndFetchAnnotation",
      "addNewAnnotationByStepIds",
      "deleteAnnotationByStepId",
    ]),
    ...mapMutations("global", ["MUTATE_ALERT_INFO"]),
    ...mapActions("aiQuizEditor", ["fetchQuizFromDb", "resetQuizData"]),
    ...mapActions("sopEditor", ["fetchSopFromDb"]),
    ...mapActions("aiSummary", ["fetchSummaryFromDb"]),
    ...mapActions("workflowQuiz", ["getWorkflowWithQuiz"]),
    closeAIToolkit() {
      this.isShowAITookit = false;
    },
    async checkVersionStatus() {
      const { ok, data, errorMessage } = await fetchVersionHistoryByWorkflowId(this.workflowId);
      if (ok) {
        this.isFirstPublish = data.items.length === 1;
      }
    },
    handleConfirmPublish({ nextVersionHistoryNumber, isReTraining, quizActive, textSOPActive, aiSummaryActive }) {
      this.isOpenConfirmedPublishPopup = false;
      this.nextVersionHistoryNumber = nextVersionHistoryNumber;
      this.publishWorkflow({
        published: true,
        unpublishedAlert: false,
        retraining: isReTraining,
        quizActive,
        textSOPActive,
        aiSummaryActive,
      });
    },
    showGenerateQuestionPopup() {
      if (!localStorage.getItem("closeAIQuizNewMark")) {
        localStorage.setItem("closeAIQuizNewMark", "closeAIQuizNewMark");
        this.isShowAIQuizNewMark = false;
      }
      this.isShowGenerateQuizPopup = true;
    },
    handleShowActionEnterPopup(currentStepIndex, showBuilderPart) {
      if (!["trim", "erase", "annotation"].includes(showBuilderPart)) return;
      const hasDontShowMeAgainFlag = localStorage.getItem(`dontShowAgainPopupType-${showBuilderPart}`);
      if (hasDontShowMeAgainFlag) return;
      const currentStepId = this.steps[currentStepIndex].id;
      if (!Array.isArray(this.annotations) || this.annotations.length === 0) return;
      const targetAnnotation = this.annotations.find(({ stepId }) => stepId === currentStepId);
      const hasAnnotation = targetAnnotation && targetAnnotation.elements.length > 0;
      if (hasAnnotation && showBuilderPart !== "annotation") {
        this.actionEnterPopupType = showBuilderPart === "trim" ? "Trim" : "Erase";
        return;
      }

      if (!hasAnnotation && showBuilderPart === "annotation") {
        this.actionEnterPopupType = "Annotation";
        return;
      }
    },
    handleCloseActionEnterPopup({ dontShowMsgAgain }) {
      if (dontShowMsgAgain) {
        localStorage.setItem(`dontShowAgainPopupType-${this.showBuilderPart}`, 1);
      }
      this.actionEnterPopupType = "";
    },
    async handleResetWorkflow(keepWorkflow) {
      const { ok, data } = await postResetWorkflow({
        workflowId: this.workflowId,
        keepWorkflow,
      });
      if (ok) {
        if (keepWorkflow) {
          Vue.prototype.$shouldReSyncWorkspaceCountByType = "RESET_WORKFLOW";
        }
        Vue.prototype.$editorGroup = data.item.group;
        this.handleGoBackToWorkspacePage(data.item.group);
        return;
      }
      this.MUTATE_ALERT_INFO({ message: this.$t("alert.unexpectedError"), type: "error" });
    },
    async handleSubtitleStatus() {
      const { ok, data, errorMessage } = await fetchWorkflowPreviewStatus(this.workflowId);
      if (!ok) {
        console.error(errorMessage);
        return;
      }
      this.subtitlesStatus = data.item.subtitlesStatus;
      if (this.subtitlesStatus === "not-ready") {
        const { ok, data, errorMessage } = await postGenerateSubtitles(this.workflowId);
        if (!ok) {
          console.error(errorMessage);
          return;
        }
        this.subtitlesStatus = data.item.subtitlesStatus;
        setTimeout(() => {
          this.handleSubtitleStatus();
        }, 3000);
      }
    },
    async openPreviewVideo() {
      // check permission again
      await authWorkflowPermission(this.workflowId);
      const { id: workflowId } = this.$route.params;
      const stepId = this.steps[this.currentStep].id;
      this.pauseVideo();
      this.isOpenPreviewVideo = true;
      this.previewVideoSrc = window.location.origin + "/PlayerPreview/" + workflowId + "/" + stepId;
      Analytics.setTrack({
        category: "BuilderMain",
        action: "Switch Section",
        name: "Enter Preview",
      });
    },
    getInputSize(title) {
      //Chinese needs a bigger size input to display all the text
      const chinesePattern = new RegExp("[\u4E00-\u9FA5]+");
      if (chinesePattern.test(title)) {
        return title.length * 2;
      }
      return title.length;
    },
    async startLoading() {
      this.accessAllowed = true;
      await this.getWorkflow();
      this.getWorkflowsFromVuex();
      await this.getSteps(this.workflow.steps);
      this.getRealTimeStepUpdate();
      this.getRealTimeWorkflowUpdate(this.workflow.id);
      this.creatDownloadVideoList();
      this.handleSubtitleStatus();
      this.fetchQuizFromDb(this.workflow.id);
      this.fetchSopFromDb(this.workflow.id);
      this.fetchSummaryFromDb(this.workflow.id);
      this.loadingFinished = true;
      Analytics.setEventProperties({ "Workflow ID": this.workflowId, "Workspace ID": this.workflow.group });
    },
    handleCloseSop(isSopActivate) {
      this.isSopActivate = isSopActivate;
      this.isShowMultimodalSop = false;
    },
    setDownloadVideoInfo(videoInfo) {
      this.downloadVideoInfo = videoInfo;
    },
    updateBlockMsg(msg) {
      this.workflow.blocked = msg;
    },
    creatDownloadVideoList() {
      for (let i = 0; i < this.steps.length; i++) {
        this.downloadVideoList.push({
          title: this.steps[i].title,
          progress: 0,
          isDownloading: false,
        });
      }
    },
    resizeWindow() {
      this.videoHeightViewer = parseInt(window.innerHeight - 410);
      this.videoWidthViewer = parseInt(this.videoHeightViewer / 0.5625);
      if (this.videoWidthViewer > window.innerWidth - 110) {
        this.videoWidthViewer = window.innerWidth - 110;
      }
    },
    closeOnSnapShot() {
      this.workflowOnSnapshot(); //close onSnapShot before leaving
      Object.values(this.stepOnSnapshot).forEach((onSnapshot) => {
        onSnapshot();
      });
    },
    async reloadWorkflowAndStep() {
      await this.getWorkflow();
      await this.getSteps(this.workflow.steps);
      this.getRealTimeStepUpdate();
    },
    downloadVideo(stepIdx) {
      if (!this.steps[stepIdx].videos) {
        alert("Video cannot be downloaded while transcoding is in progress. Please try again later.");
      } else {
        const self = this;
        self.downloadVideoList[stepIdx].isDownloading = true;
        self.downloadVideoList.splice();
        self.downloadVideoListWatch[stepIdx] = true;
        self.downloadVideoListWatch.splice();
        const rezOptions = Object.keys(this.steps[stepIdx].videos);
        const highestRez = rezOptions[rezOptions.length - 1];
        const downloadLink = this.steps[stepIdx].videos[highestRez];
        const stepId = this.steps[stepIdx].id;

        const downloadRequestFn = (downloadSrcUrlLink) => {
          const stepNum = stepIdx + 1;
          const fileName =
            this.workflow.title.replace(/\s+/g, "_") +
            "_" +
            stepNum +
            "_" +
            this.steps[stepIdx].title.replace(/\s+/g, "_") +
            ".mp4";
          var x = new XMLHttpRequest();
          x.open("GET", downloadSrcUrlLink, true);
          x.responseType = "blob";
          x.onprogress = function (event) {
            self.downloadVideoList[stepIdx].progress = (event.loaded / event.total) * 100;
            self.downloadVideoList.splice();
          };
          x.onload = function (e) {
            self.downloadVideoList[stepIdx].progress = 0;
            self.downloadVideoList.splice();
            self.downloadVideoList[stepIdx].isDownloading = false;
            self.downloadVideoList.splice();
            self.downloadVideoListWatch[stepIdx] = false;
            self.downloadVideoListWatch.splice();
            // Downloadjs(e.target.response, stepId + ".mp4", "video/mp4");
            Downloadjs(e.target.response, fileName, "video/mp4");
          };
          x.send();
        };

        downloadRequestFn(downloadLink);
      }
    },
    onClickEditPoster() {
      if (this.isPosterDisabled) {
        return;
      }
      if (this.workflow.published) {
        this.showEditWorkflowPopup = true;
      } else {
        this.showPosterPopup = true;
      }
    },
    clickTitle() {
      if (!this.workflow.published) {
        this.showEditIcon = false;
        setTimeout(() => {
          this.editWorkflowName();
        }, 100);
      }
    },
    getVideoSource() {
      if (this.steps[this.currentStep].videos) {
        const rez = Object.keys(this.steps[this.currentStep].videos)[0];
        return this.steps[this.currentStep].videos[rez];
      } else {
        return this.steps[this.currentStep].video;
      }
    },
    getVideoMetadata() {
      const metadata = {
        type: "step",
        rez: "hls",
        id: this.steps[this.currentStep].id,
        notTranscodedVideo: this.steps[this.currentStep].video,
      };

      if (this.steps[this.currentStep].videos) {
        const rez = Object.keys(this.steps[this.currentStep].videos)[0];
        metadata.rez = rez;
      }

      return metadata;
    },
    resetWorkflowForDeveloperTest() {
      if (!this.workflow.title.toLowerCase().includes("i need to reset")) {
        return;
      }
      this.secretButton++;
      const newSteps = [];
      const newStepId = [];
      if (this.secretButton == 5) {
        //exclude imported steps before resetting
        for (let step of this.steps) {
          if (step.workflows[0] == this.workflow.id) {
            newStepId.push(step.id);
            newSteps.push(step);
          }
        }
        this.steps = newSteps;
        this.updateDocument("workflows", this.workflowId, {
          published: false,
          processed: false,
          steps: newStepId,
        })
          .then(() => {
            alert("workflow reset. imported steps were removed if there was any.");
            this.handleGoBackToWorkspacePage(this.workflow.group);
          })
          .catch((err) => {
            alert("failed to reset workflow. please try again.");
            this.secretButton = 0;
            loggingError(err);
          });
      }
    },
    handleGoBackToWorkspacePage(workspaceId) {
      this.$router.push({ name: REDIRECT_TO_STUDIO_WORKSPACE.name, params: { workspaceId } });
    },
    /////// Close Builder //////
    async handleDeleteWorkflow() {
      const isDeleted = await this.deleteWorkflow(this.workflow.id); // MixinDelete.vue
      if (isDeleted) {
        this.closeOnSnapShot();
        this.handleGoBackToWorkspacePage(this.workflow.group);
      }
    },
    closeBuilder(closeWithoutSave) {
      if (!this.madeChanges || this.workflow.published || closeWithoutSave) {
        this.closeOnSnapShot();
        this.handleGoBackToWorkspacePage(this.workflow.group);
      } else {
        this.closeConfirmation = true;
      }
    },
    ////// Viewer //////
    pauseVideo() {
      this.$refs.builderViewer && this.$refs.builderViewer.handlePauseVideo();
    },

    /////// Edit Workflow Name //////
    editWorkflowName() {
      this.isEditingWorkflowName = true;
      this.$nextTick(() => this.$refs.inputWorkflowName.focus());
    },
    finishEditing() {
      if (!this.isTypingWorkflow && this.workflow.title != "") {
        this.isEditingWorkflowName = false;
      }
      if (!this.isTypingStep) {
        this.isEditingStepName = false;
      }
    },
    handleInput(event, type) {
      // finish editing by hitting return key
      if (event.keyCode == 13) {
        //event.keyCode == 13 is to add line breaks
        event.preventDefault();
        this.isEditingWorkflowName = false;
        this.isEditingStepName = false;
        Analytics.setInputChangeTrack({
          category: "BuilderMain",
          action: "Edit Segmentation",
          name: `Edit ${type} in BuilderMain`,
        });
      }
    },

    ////// Builder steps  ///////
    clickDeleteStep(idx) {
      if (this.workflow.published && !this.editable) {
        this.showEditWorkflowPopup = true;
      } else {
        this.currentStep = idx;
        this.showDeleteMenu = true;
        this.isEditingStepName = false;
      }
    },
    showBuilderExplorer(addStepIndex) {
      if (this.workflow.published && !this.editable) {
        this.showEditWorkflowPopup = true;
      } else {
        this.addStepIndex = addStepIndex;
        this.showExplorer = true;
      }
    },
    closeBuilderExplorer() {
      this.addStepIndex = null;
      this.showExplorer = false;
    },
    deleteStep(idx) {
      if (this.steps.length == 1) {
        alert("You cannot delete the only step in the workflow.");
      } else {
        const { id: stepId } = this.steps[idx];
        if (this.currentStep > 0) {
          //make sure currentStep is valid
          this.currentStep--;
        }
        this.steps.splice(idx, 1);
        this.madeChanges = true;
        this.deleteAnnotationByStepId(stepId);
      }
    },
    getStepNum(index) {
      index++;
      if (index < 10) {
        return "0" + index.toString();
      } else {
        return index.toString();
      }
    },
    clickNewStep(idx) {
      this.currentStep = idx;
      this.isEditingStepName = false;
      this.pauseVideo();
    },
    onDragStart(event, idx) {
      if (this.workflow.published && !this.editable) {
        this.showEditWorkflowPopup = true;
      } else {
        this.isDraggingStep = true;
        this.dragFromIdx = idx;
      }
    },
    onDragEnd(event) {
      this.isDraggingStep = false;
      this.dragOverIdx = -2;
      this.dragFromIdx = -2;
    },
    onDragover(event, idx) {
      event.preventDefault();
      if (this.isDraggingStep && this.dragOverIdx != idx) {
        this.dragOverIdx = idx;
      }
    },
    onDragleave(event) {
      event.preventDefault();
      if (this.isDraggingStep) {
        this.dragOverIdx = -2;
      }
    },
    onDrop(event) {
      if (this.isDraggingStep) {
        //drag to right
        if (this.dragFromIdx < this.dragOverIdx) {
          const newSteps = this.moveItem(this.steps, this.dragFromIdx, this.dragOverIdx);
          this.steps = newSteps;
        } else if (
          //drag to left
          this.dragFromIdx >
          this.dragOverIdx + 1
        ) {
          this.dragOverIdx = this.dragOverIdx + 1;
          const newSteps = this.moveItem(this.steps, this.dragFromIdx, this.dragOverIdx);
          this.steps = newSteps;
        }
        this.clickNewStep(this.dragOverIdx);
      }
    },
    moveItem(arr, fromIndex, toIndex) {
      const item = arr[fromIndex];
      if (!item) return arr;
      this.madeChanges = true;
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, item);
      return arr;
    },
    dragToFirstStep() {
      this.dragOverIdx = 0;
      const newSteps = this.moveItem(this.steps, this.dragFromIdx, this.dragOverIdx);
      this.steps = newSteps;
      this.clickNewStep(0);
    },
    handleStepUpdate(event) {
      this.madeChanges = true;
      if (event.diagram) {
        this.steps[event.index].diagram = event.diagram; //update new diagram id to step
        this.steps.splice();
      }
    },
    handleAddSteps(selectedSteps) {
      this.madeChanges = true;
      this.steps.splice(this.addStepIndex, 0, ...selectedSteps);
      if (Array.isArray(selectedSteps)) {
        this.addNewAnnotationByStepIds(selectedSteps.map(({ id }) => id));
      }
    },
    //////// Translation ///////
    removeTranslation(language) {
      this.workflow.translations = this.translationList = this.translationList.filter((el) => el != language);
      //remove language from workflow
      this.updateDocument("workflows", this.workflow.id, {
        translations: this.translationList,
      });
    },
    addTranslation(language) {
      if (language) {
        //avoid adding null as language
        if (!this.workflow.translations) {
          this.workflow.translations = [];
        }
        //avoid adding duplicated translations
        if (!this.translationList.includes(language)) {
          this.translationList.push(language);
          this.workflow.translations = this.translationList;
          this.translationList.splice();
          this.$set(this.workflow, "translations", this.workflow.translations);
          this.updateDocument("workflows", this.workflowId, {
            translations: this.translationList,
          });
        }
      }
    },

    ////// Published to Unpublished //////
    editWorkflow() {
      this.editable = true;
      this.showEditWorkflowPopup = false;
      this.publishWorkflow({ published: false, unpublishedAlert: true });
    },
    clickToolbarMore() {
      if (this.workflow.published && !this.editable) {
        this.showEditWorkflowPopup = true;
      }
    },
    handleClickMenuReviewTranscription() {
      if (this.workflow.published && !this.editable) {
        this.showEditWorkflowPopup = true;
        return;
      }
      this.pauseVideo();
      this.showTranscription = true;
    },
    handleClickMenuReset() {
      if (this.workflow.published && !this.editable) {
        this.showEditWorkflowPopup = true;
        return;
      }
      this.pauseVideo();
      this.resetWorkflowConfirmation = true;
    },
    handleClickMenuDelete() {
      if (this.workflow.published && !this.editable) {
        this.showEditWorkflowPopup = true;
        return;
      }
      this.pauseVideo();
      this.deleteWorkflowConfirmation = true;
    },
    clickEditStepName(idx) {
      if (this.workflow.published && !this.editable) {
        this.showEditWorkflowPopup = true;
      } else {
        this.currentStep = idx;
        this.isEditingStepName = true;
        this.$nextTick(() => this.$refs.inputStepName[idx].focus());
      }
    },

    ///////// Publish Workflow  /////////
    checkEmptyTitles() {
      if (this.workflow.title == "") {
        this.emptyTitleAlert = true;
        return true;
      } else {
        return false;
      }
    },
    checkEmptyStepTitle() {
      for (let i = 0; i < this.steps.length; i++) {
        if (this.steps[i].title == "") {
          this.emptyTitleAlert = true;
          return true;
        }
      }
      return false;
    },
    async publishWorkflow({
      published,
      unpublishedAlert,
      retraining = false,
      quizActive,
      textSOPActive,
      aiSummaryActive,
    }) {
      this.updateAndFetchAnnotation(this.workflowId);
      //stop the saving if has empty title
      if (this.checkEmptyTitles()) {
        return;
      }
      //stop the saving if has empty step title
      if (this.checkEmptyStepTitle()) {
        return;
      }

      try {
        this.isSaving = true;
        const { ok, errorMessage } = await putWorkflowBuilder(this.workflow.id, {
          title: this.workflow.title,
          steps: this.steps.map(({ id, title }) => ({ id, title })),
          published,
          version: this.nextVersionHistoryNumber,
          retraining,
          quizActive,
          textSOPActive,
          aiSummaryActive,
          ...(published && { versionDescription: this.versionDescription }),
        });

        if (!ok) {
          throw errorMessage;
        }
        await this.handleSubtitleStatus();
        this.fetchQuizFromDb(this.workflowId);
        this.fetchSopFromDb(this.workflowId);
        this.fetchSummaryFromDb(this.workflowId);
        this.workflow.published = published;
        this.madeChanges = false;
        this.isSaving = false;
        this.isSuccess = true;
        this.getWorkflowWithQuiz();

        setTimeout(() => {
          this.isSuccess = false;
        }, 3000);

        if (published) {
          this.editable = false;
          this.alertText = this.$t("editor.alertWasPublished");
          return;
        }

        if (unpublishedAlert) {
          this.editable = true;
          this.alertText = this.$t("editor.alertWasUnpublished");
          return;
        }

        this.alertText = this.$t("editor.alertIsSaved");
      } catch (error) {
        if (error.response.data && error.response.data.code === 50) {
          this.isOpenAlertVersionDuplicated = true;
        } else {
          this.isNotSuccess = true;
        }
        console.log(error);
        this.isSaving = false;
        loggingError(error);
      } finally {
        this.versionDescription = "";
      }
    },

    alertAssignCategories() {
      const isDontShow = localStorage.getItem("dontShowCategoryPopup") === "true";
      if (!this.isFirstPublish) return (this.isOpenConfirmedPublishPopup = true);
      if (
        this.$access.categories &&
        (!this.workflow.subCategories || this.workflow.subCategories.length == 0) &&
        !isDontShow
      ) {
        this.showCategoryConfirmPopup = true;
        this.showPublisAfterCatPop = true;
      } else {
        this.isOpenConfirmedPublishPopup = true;
      }
    },
    //////// Get data //////////
    getUserProfileFromVuex() {
      this.$store.dispatch("getUserProfile", this.$user);
    },
    getWorkflowsFromVuex() {
      //only get workflows from same workspace
      this.$store.dispatch("getWorkflows", {
        organization: this.$organization,
        group: this.workflow.group,
        privateWorkspace: this.workflow.privateWorkspace,
      });
    },
    getOrganization() {
      const self = this;
      this.getDocument("users", this.$user.uid)
        .then((user) => {
          Vue.prototype.$organization = user.organization;
          // self.getWorkflowsFromVuex();
        })
        .catch((err) => {
          alert("failed to get organization.");
          loggingError(err);
        });
    },
    async getWorkflow() {
      try {
        const workflow = await this.getDocument("workflows", this.workflowId);
        this.workflow = workflow;
      } catch (error) {
        loggingError(error);
        this.$router.push({ name: "404" });
      }
    },
    async getSteps(steps) {
      if (steps.length === 0) return;
      const promises = steps.map((step) => this.getDocument("steps", step));
      const stepsResult = await Promise.all(promises);
      this.steps = stepsResult;
      this.steps.splice();
    },
    getRealTimeStepUpdate(steps) {
      const self = this;
      if (self.stepsIdArray) {
        for (let id of self.stepsIdArray) {
          //track all realtime updates, only create new ones when there isn't one already
          if (!self.stepOnSnapshot[id]) {
            self.stepOnSnapshot[id] = self.getDocumentListener("steps", id, function (doc) {
              const step = doc.data();
              const index = self.stepsIdArray.indexOf(step.id);
              if (index != undefined) {
                self.steps[index] = step;
                self.steps.splice();
              }
            });
          }
        }
      }
    },
    getRealTimeWorkflowUpdate(id) {
      const self = this;
      self.workflowOnSnapshot = self.getDocumentListener("workflows", id, function (doc) {
        const newWorkflow = doc.data();
        if (!isEqual(newWorkflow.steps, self.workflow.steps)) {
          self.workflow = newWorkflow;
          self.reloadWorkflowAndStep();
        } else {
          self.workflow = newWorkflow;
        }
      });
    },
    updatePoster(url) {
      this.workflow.poster = url;
    },
    trackShowSettingsByHeap() {
      Analytics.setTrack({
        category: "BuilderMain",
        action: "Switch Section",
        name: "Enter Settings",
      });
    },
    trackShowTranslationByHeap() {
      Analytics.setTrack({
        category: "BuilderMain",
        action: "Switch Section",
        name: "Enter Translation",
      });
    },
    handleOpenLastStepGapPopup(event) {
      if (!this.getter_quiz_enabled) {
        this.showBuilderExplorer(this.steps.length);
        return;
      }
      const targetElement = event.target.closest(".add-step-gap");
      const { top, left, width } = targetElement.getBoundingClientRect();
      this.lastStepGapPopupData.isShowPopup = true;
      this.lastStepGapPopupData.top = top;
      this.lastStepGapPopupData.left = left + width;
    },
    debounceSave: debounce(function (event) {
      this.publishWorkflow(event);
    }, 500),
  },
  computed: {
    ...mapState("annotation", ["annotations"]),
    ...mapState("aiQuizEditor", ["isQuizPublished", "questions"]),
    ...mapState("sopEditor", ["isSopPublished"]),
    ...mapState("aiSummary", ["isAISummaryPublished"]),
    ...mapGetters("aiQuizEditor", ["getter_is_ai_processing"]),
    ...mapGetters("sopEditor", ["getter_is_ai_processing_sop"]),
    ...mapGetters("aiSummary", ["getter_is_ai_processing_summary"]),
    ...mapGetters(["getter_is_gen2"]),
    ...mapGetters("permission", [
      "getter_quiz_enabled",
      "getter_multi_modal_sop_enabled",
      "getter_multi_modal_sop_feature_enabled",
      "getter_ai_summary_feature_enabled",
      "getter_ai_summary_enabled",
    ]),
    isAIGening() {
      return [
        this.getter_is_ai_processing,
        this.getter_is_ai_processing_sop,
        this.getter_is_ai_processing_summary,
      ].some((s) => s);
    },
    allAIGenStatus() {
      return [this.getter_is_ai_processing, this.getter_is_ai_processing_sop, this.getter_is_ai_processing_summary];
    },
    isShowSop() {
      const isSupport = isLanguageSupport(this.$settings?.ai.sop, this.workflow.languageCode);
      return isSupport && this.getter_multi_modal_sop_feature_enabled && this.getter_multi_modal_sop_enabled;
    },
    hasSummmaryPermission() {
      const isSupport = isLanguageSupport(this.$settings?.ai.summary, this.workflow.languageCode);
      return isSupport && this.getter_ai_summary_feature_enabled && this.getter_ai_summary_enabled;
    },
    isDisabledStepTrimOrErase() {
      return !this.steps[this.currentStep].videos;
    },
    isStepVideosDisabled() {
      return this.steps.some((step) => !step.videos);
    },
    isPreviewVideoDisabled() {
      return (
        this.madeChanges || this.subtitlesStatus !== "completed" || (this.isStepVideosDisabled && !this.getter_is_gen2)
      );
    },
    isPosterDisabled() {
      if (this.getter_is_gen2) return false;
      const unfinishedStep = this.steps.find((step) => !step.videos);
      return unfinishedStep ? true : false;
    },
    stepsIdArray() {
      const stepsId = [];
      this.steps.forEach((step) => {
        stepsId.push(step.id);
      });
      this.fetchMultipleAnnotation(stepsId);
      return stepsId;
    },
    isShowStepsWrapper() {
      return !["erase", "trim"].includes(this.showBuilderPart);
    },
    quizDotColor() {
      return this.getter_is_ai_processing ? "purple" : this.isQuizPublished ? "text-success" : "text-danger";
    },
    sopDotColor() {
      return this.getter_is_ai_processing_sop ? "purple" : this.isSopPublished ? "text-success" : "text-danger";
    },
    summaryDotColor() {
      return this.getter_is_ai_processing_summary
        ? "purple"
        : this.isAISummaryPublished
        ? "text-success"
        : "text-danger";
    },
  },
};
</script>

<style scoped lang="scss">
.builder-main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #1e1f22;
}
.btn-with-tag {
  position: relative;
}
.btn-with-tag:before {
  content: attr(data-text);
  position: absolute;
  top: -10px;
  right: 0;
  background-color: var(--primary-color);
  border-radius: 80px;
  line-height: 10px;
  font-size: 10px;
  font-weight: 700;
  padding: 4px;
  min-width: 30px;
}
.builder-main .icon-dot-status {
  font-size: 8px;

  &.purple {
    color: var(--loading-color);
  }
}
/* for toolbar */
.BuilderToolbar {
  z-index: 2;
  background: var(--dGrey1-color);
  width: 100%;
  height: var(--header-height);
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 32px;
  &__buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-05);
    .ai-toolkit {
      position: relative;
      &_wrap {
        padding: 12px 24px;
        font-size: 16px;
        display: flex;
        align-items: center;
        gap: 8px;
      }
      &_container {
        position: absolute;
        top: 62px;
        right: 0;
        padding: 16px 8px;
      }
      &-alert {
        position: fixed;
        padding: 8px 16px;
        white-space: nowrap;
        color: white;
        border-radius: var(--spacing-02);
        background: linear-gradient(90deg, #4689f3 0%, #10b981 100%), #4689f3;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
        right: 161px;
        top: 76px;
        display: flex;
        align-items: center;
        gap: 16px;
      }

      &_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: white;
        padding: 10px 16px;
        white-space: nowrap;
        gap: 22px;
        cursor: pointer;
        &_label {
          gap: 16px;
          display: flex;
          align-items: center;
        }
      }

      span {
        color: white;
      }
    }
    .linear-gradient-border {
      border: 2px solid transparent;
      border-radius: 8px;
      background: linear-gradient(to right, var(--dGrey1-color), var(--dGrey1-color)),
        linear-gradient(90deg, #4689f3 0%, #10b981 100%);
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
    }
    .ai-icon {
      background: linear-gradient(90deg, #4689f3 0%, #10b981 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .toolbar-line {
    height: 32px;
    width: 1px;
    background: var(--dGrey5-color);
  }

  .more-btn {
    width: 40px;
  }
}

.edit-poster-div {
  position: relative;
  width: 85px;
  min-width: 85px;
  height: 48px;
  margin-right: 16px;
  cursor: pointer;
  background: #1e1f22;
}
.edit-poster-div--disabled {
  cursor: not-allowed;
}

.poster-icon {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  background: var(--dGrey3-color);
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--dGrey6-color);
}
.edit-poster-div:not(.edit-poster-div--disabled):hover .poster-icon {
  opacity: 1;
  transition: 0.3s;
}
.title-border {
  padding: 4px;
  border: transparent 1px dashed;
  margin-left: -4px;
}
.title-border:hover {
  border: #55565a 1px dashed;
  cursor: pointer;
  transition: 0.3s;
}
.edit-video-title {
  pointer-events: auto;
  padding: 0 8px;
  color: white;
  font-size: 20px;
  line-height: 22px;
  overflow-y: auto;
  background-color: #0c0c0e;
  margin-right: 10px;
  margin-top: 2px;
  border-bottom: #2c2d32 2px solid;
}
.title-empty {
  border-bottom: #e03535 2px solid !important;
}
.edit-video-title:focus {
  outline-width: 0;
}
.edit-video-title::selection {
  color: white;
  background: #4689f4; /* WebKit/Blink Browsers */
}
.edit-video-title::-moz-selection {
  color: white;
  background: #4689f4; /* Gecko Browsers */
}
.edit-icon {
  color: var(--dGrey6-color);
  opacity: 0;
  position: relative;
  left: -20px;
}
.edit-icon-show {
  left: 0;
  opacity: 1;
  transition: 0.3s;
}
.more-div {
  position: relative;
  height: var(--header-height);
  display: flex;
  align-items: center;
}
.tool-bar-icon {
  color: #ffffff;
  height: 28px;
  width: 28px;
  padding: 3px;
  margin-right: 18px;
  opacity: 0.7;
}
.question-new-icon {
  position: relative;
}
.question-new-icon:before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 50%;
  background-color: var(--primary-color);
  border-radius: 80px;
  line-height: 10px;
  font-size: 10px;
  font-weight: 700;
  padding: 3px 0;
  min-width: 30px;
}

.tool-bar-icon:hover {
  opacity: 1;
}

.tool-bar-icon:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
.more-dropdown {
  position: absolute;
  top: 60px;
  left: 0;
  padding: 0;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.v-list {
  background: #2c2d32 !important;
  color: white;
}
.dropdown-list-item:hover {
  background-color: #52545d !important;
}

/* for tab headers */
.tabs-header {
  position: fixed;
  top: var(--header-height);
  left: 0;
  height: 70px;
  width: 100%;
  background-color: #121314;
}

.btns-div {
  --num-of-tabs: 5;
  position: relative;
  padding: 0;
  display: flex;
  height: 70px;
  width: 560px;
}
.tab-btn {
  position: relative;
  color: white;
  height: 70px;
  width: auto;
  margin-left: 32px;
  opacity: 0.7;
}
.tab-text {
  text-align: center;
  height: 24px;
  font-size: 16px;
  padding-top: 2px;
}
.tab-btn-active .tab-text::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #4689f3;
  transition: width 0.3s, left 0.3s;
  margin-bottom: 10px;
}
.tab-btn:hover {
  opacity: 1;
  transition: 0.2s;
}
.tab-btn:disabled {
  cursor: not-allowed;
  color: #9397a6;
}

/* for tab body */
.tabs-body {
  position: fixed;
  top: 140px;
  left: 0;
  height: calc(100% - 300px);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #121314;
}

/* for steps */
.builder-steps-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 160px;
  background-color: #0c0c0e;
}
.builder-steps {
  position: absolute;
  bottom: 0;
  left: 20px;
  width: calc(100% - 40px);
  height: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  padding-top: 34px;
  padding-left: 18px;
  display: flex;
}
.builder-steps:hover::-webkit-scrollbar-thumb {
  background-color: #2c2d32;
}
.drag-mode .add-step-gap {
  display: none;
}
.add-step-gap {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 8px;
  height: 80px;
  background: #303030;
  border-radius: 2px;
  transition: 0.2s;
}

.add-step-gap > svg {
  display: none;
}

.add-step-gap:hover {
  width: 24px;
  background: #4689f3;
}

.add-step-gap:hover > svg {
  display: block;
}

.add-step {
  width: 140px;
  height: 80px;
  border-radius: 2px;
  background-color: #1e1f22;
  margin: 0 20px;
  padding-top: 6px;
}

.center-list {
  vertical-align: middle;
}
.steps-div {
  display: flex;
  position: relative;
}
.hide-dragging-step {
  display: none;
}
.step-zone {
  position: relative;
  width: 0px;
  height: 80px;
  top: 0px;
  animation: hide 0.3s;
  animation-iteration-count: 1;
  /* Safari and Chrome: */
  -webkit-animation: hide 0.3s;
  -webkit-animation-iteration-count: 1;
}
@keyframes hide {
  0% {
    width: 140px;
    opacity: 1;
  }
  100% {
    width: 0;
    opacity: 0;
  }
}
@-webkit-keyframes hide {
  0% {
    width: 140px;
  }
  100% {
    width: 0;
  }
}
.hide-step-zone {
  visibility: visible;
  width: 0px;
  transition: 3s;
}
.steps-card {
  width: 140px;
  position: relative;
  cursor: pointer;
  margin: 0 10px;
}
.steps-card-selected {
  width: 154px;
  top: -5px;
}
.delete-step-btn {
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 2;
  visibility: hidden;
}
.edit-step-btn {
  right: 30px;
}
.delete-step-btn-show {
  visibility: visible;
}
.icon-hover {
  opacity: 0.7;
}
.icon-hover:hover {
  opacity: 1;
}
.delete-popup {
  position: relative;
  height: 52px;
  overflow: hidden;
}
.delete-popup-div {
  position: relative;
  display: flex;
  height: 44px;
  background-color: #e03535;
  border-radius: 2px;
  padding: 0 6px;
}
.delete-popup-btn {
  position: relative;
  color: white;
  opacity: 0.8;
  padding: 4px;
}
.delete-popup-btn:hover {
  opacity: 1;
}
.delete-popup-text {
  position: relative;
  width: 100%;
  z-index: 1;
  bottom: 20px;
  left: 0;
  font-size: 10px;
  text-align: center;
}
.delete-popup-line {
  border-right: 1px solid #bc2626;
  border-left: 1px solid #ff5656;
  height: 22px;
  width: 0;
  margin-top: 10px;
}
.arrow-down {
  position: absolute;
  bottom: -6px;
  right: 3px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 8px 0 8px;
  border-color: #e03535 transparent transparent transparent;
}
.darken-layer {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 2px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 1;
  pointer-events: none;
}
.steps-card.step-in-processing {
  filter: brightness(0.5);
}
.step-in-processing .v-card:after {
  content: attr(data-process-text);
  position: absolute;
  bottom: 0;
  left: 0;
  height: 18px;
  width: 100%;
  background: #9174e5;
  color: #fff;
  border-radius: 0 0 2px 2px;
}
.steps-card:hover:not(.step-in-processing) {
  width: 154px;
}
.steps-card:hover:not(.step-in-processing) .darken-layer {
  opacity: 0;
}
.steps-card:hover:not(.step-in-processing) .delete-step-btn {
  visibility: visible;
}
.steps-card:hover:not(.step-in-processing) .step-title {
  opacity: 1;
}
.active-area {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}
.active-area-l {
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
}
.active-area-r {
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  right: 0;
}
.step-img {
  border-radius: 2px;
}
.edit-step-title {
  margin-top: 0;
  padding-left: 2px;
  width: calc(100% - 20px);
  font-size: 10px;
  height: 14px;
  color: white;
  border: 1px solid #1e1f22;
}
.empty-step-title {
  border-bottom: #e03535 1px solid !important;
}
.edit-step-title:focus {
  outline-width: 0;
}
.edit-step-title::selection {
  color: white;
  background: #4689f4; /* WebKit/Blink Browsers */
}
.edit-step-title::-moz-selection {
  color: white;
  background: #4689f4; /* Gecko Browsers */
}
.placeholeer-step-title {
  width: 126px;
  color: rgba(255, 255, 255, 0.425);
  border: 1px solid #1e1f22;
}
.step-title {
  color: white;
  opacity: 0.6;
  text-align: left;
  width: 100%;
}
.title-selected {
  opacity: 1;
}
.right-to-left {
  direction: rtl;
  text-align: right !important;
}
.hide-dropzone {
  position: relative;
  visibility: hidden;
  width: 0px;
  height: 80px;
  top: 0px;
}
.dropzone-div {
  height: 80px;
  width: 140px;
}
.show-first-dropzone {
  visibility: visible;
  border: 1px dashed grey;
  width: 140px;
  transition: 0.3s;
  margin-left: 10px;
}
.show-dropzone {
  visibility: visible;
  border: 1px dashed grey;
  width: 140px;
  transition: 0.3s;
}
.last-dropzone {
  position: relative;
  height: 80px;
  top: 0px;
  width: 80px;
  opacity: 0;
}
.box-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
}

.builder-settings {
  z-index: 5;
}
.builder-transcription {
  z-index: 5;
}
.builder-share {
  z-index: 5;
}
</style>
