<template lang="html">
  <section class="mixin-tracker"></section>
</template>

<script>
import MixinDB from "./MixinDB.vue";
import MixinAnalytics from "./MixinAnalytics.vue";
import { openDB } from "idb";
import { getBrowserVersion } from "@/js/getBrowserVersion.js";
import { syncPublicPlayerTrackingToServer } from "@/server/video-tacking-server.js";

export default {
  name: "mixin-tracker",
  mixins: [MixinDB, MixinAnalytics],
  mounted() {
    this.workflowTracker["deviceInfo"] = getBrowserVersion();
  },
  data() {
    return {
      playingTracker: {},
      workflowTracker: {
        id: "",
        // uid: string (will add if user is logged in)
        // email: string (will add if user is logged in)
        // organization: string (user's organization. only for private (v1/tracking) | public (tracking/public-videos) will add workflow.organization to the tracking info in the backend)
        deviceInfo: {}, // json: { brower, os, version }
        isPublic: false,
        lastUpdated: 0, // timestamp
        lastSync: -1, // -1 means it's not sync
        location: {}, // json: { latitude, longitude, ip, method }
        onChangeRez: [],
        onChangeStepFromMenu: [],
        onEnter: 0, // timestamp
        onExit: 0, // timestamp
        onNext: [],
        onPrevious: [],
        onSubtitles: [],
        playedDuration: 0,
        steps: {},
        stepsArray: [],
        workflowId: null, // string
      },
      dataStoreCreated: false,
      playedIntervalNo: 0,
      processIfPublicData: false,
    };
  },
  methods: {
    getTrackingId() {
      return this.generateDocId("tracking");
    },
    async saveDataLocally() {
      if (!("indexedDB" in window)) {
        return;
      }
      //idb documentation -> https://github.com/jakearchibald/idb
      const db = await openDB("deephow-db", 1, {
        upgrade(db) {
          db.createObjectStore("playerTracker", { keyPath: "id" });
        },
      });
      const tx = db.transaction("playerTracker", "readwrite");
      const store = tx.objectStore("playerTracker");
      const value = await store.get(this.workflowTracker.id);
      if (!value) {
        store.add(this.workflowTracker);
      } else {
        store.put(this.workflowTracker);
      }
      await tx.done;
      //store tracking data if video is public
      if (this.processIfPublicData) {
        //reset flag value
        this.processIfPublicData = false;
        this.processPublicTrackingDocs();
      }
    },
    createDataStore(workflow, step, organization, user) {
      if (!this.workflowTracker.id) {
        this.workflowTracker.id = this.getTrackingId();
      }

      // when a video is public value should be true - is set on props
      this.workflowTracker.isPublic = this.isPublic;
      if (!this.isPublic) {
        this.workflowTracker.organization = organization; // user's organization
      }
      const isLoggedIn = user && Object.keys(user).length > 0;
      if (isLoggedIn && !this.isPublic) {
        this.workflowTracker.uid = user.uid;
        this.workflowTracker.email = user.email;
      }
      this.workflowTracker.workflowId = workflow.id;
      this.handleOnEnterWorkflow();

      const stepId = step.id;
      if (stepId) {
        if (!this.workflowTracker["steps"]) {
          this.workflowTracker["steps"] = {};
        }
        if (!this.workflowTracker["steps"][stepId]) {
          this.workflowTracker["steps"][stepId] = {};
        }
        if (!this.workflowTracker["steps"][stepId].onPlay) {
          this.workflowTracker["steps"][stepId].onPlay = [];
        }
        if (!this.workflowTracker["steps"][stepId].onPause) {
          this.workflowTracker["steps"][stepId].onPause = [];
        }
        if (!this.workflowTracker["steps"][stepId].onForward) {
          this.workflowTracker["steps"][stepId].onForward = [];
        }
        if (!this.workflowTracker["steps"][stepId].onBackward) {
          this.workflowTracker["steps"][stepId].onBackward = [];
        }
        this.workflowTracker["steps"][stepId].stepDuration = step.duration;
        this.workflowTracker["steps"][stepId].playedIntervals = [];
      }
    },
    //tracks the duration the step was played
    handleOnPlaying(step, playSpeed = 1) {
      const now = Date.now();
      const stepId = step.id;
      if (this.playingTracker[stepId]) {
        const playTime = ((now - this.playingTracker[stepId].lastUpdated) / 1000) * playSpeed;
        if (playTime < 2) {
          //over 2 seconds is considered not continous play so don't count
          const playedDuration = this.playingTracker[stepId].playedDuration + playTime;
          this.playingTracker[stepId].playedDuration = playedDuration;
          this.playingTracker[stepId].lastUpdated = now;
          this.workflowTracker.playedDuration += playTime; //sum the totaly playedDuration across steps
        } else {
          //update lastUpdated time
          this.playingTracker[stepId].lastUpdated = now;
        }
      } else {
        //init for the step
        this.playingTracker[stepId] = {};
        this.playingTracker[stepId].playedDuration = 0;
        this.playingTracker[stepId].lastUpdated = now;
        this.playingTracker[stepId].lastSync = "";
      }
      this.workflowTracker["steps"][stepId].playedDuration = this.playingTracker[stepId].playedDuration;
      this.workflowTracker.lastUpdated = this.workflowTracker["steps"][stepId].lastUpdated = now;
    },
    handleOnPlay(stepId) {
      const now = Date.now();
      this.workflowTracker["steps"][stepId].onPlay.push(now);
      this.workflowTracker.lastUpdated = this.workflowTracker["steps"][stepId].lastUpdated = now;
    },
    handleOnPause(stepId) {
      const now = Date.now();
      this.workflowTracker["steps"][stepId].onPause.push(now);
      this.workflowTracker.lastUpdated = this.workflowTracker["steps"][stepId].lastUpdated = now;
    },
    handleOnEnterWorkflow() {
      this.workflowTracker.onEnter = Date.now();
    },
    handleOnExitWorkflow() {
      this.workflowTracker.onExit = Date.now();
    },
    handleOnSubtitles(languageCode) {
      if (!this.workflowTracker.onSubtitles.includes(languageCode)) {
        this.workflowTracker.onSubtitles.push(languageCode);
      }
    },
    handleOnChangeRez(rez) {
      if (!this.workflowTracker.onChangeRez.includes(rez)) {
        this.workflowTracker.onChangeRez.push(rez);
      }
    },
    handleOnChangeStepFromMenu(step) {
      this.workflowTracker.onChangeStepFromMenu.push(step.id);

      const hasPlayedIntervals =
        this.workflowTracker["steps"] &&
        this.workflowTracker["steps"][step.id] &&
        Array.isArray(this.workflowTracker["steps"][step.id].playedIntervals);
      this.playedIntervalNo = hasPlayedIntervals ? this.workflowTracker["steps"][step.id].playedIntervals.length : 0;
      this.manageTrackingInterval({ action: "onPlay", stepId: step.id, currentTime: 0 });
    },
    handleOnNext(workflow, stepId) {
      const currentStepIndex = workflow.steps.indexOf(stepId);
      const nextStepId = workflow.steps[currentStepIndex + 1];
      if (!nextStepId) return;

      const change = {
        current: stepId,
        next: nextStepId,
      };

      //if step was viewed before
      //append the next interval into it
      if (
        this.workflowTracker["steps"][workflow.steps[currentStepIndex + 1]] &&
        this.workflowTracker["steps"][workflow.steps[currentStepIndex + 1]].playedIntervals
      ) {
        this.playedIntervalNo =
          this.workflowTracker["steps"][workflow.steps[currentStepIndex + 1]].playedIntervals.length;
        this.manageTrackingInterval({ action: "onPlay", stepId: workflow.steps[currentStepIndex + 1], currentTime: 0 });
      }

      this.workflowTracker.onNext.push(change);
    },
    handleOnPrevious(workflow, stepId) {
      const currentStepIndex = workflow.steps.indexOf(stepId);
      const previousStepId = workflow.steps[currentStepIndex - 1];
      if (!previousStepId) return;

      const change = {
        current: stepId,
        previous: previousStepId,
      };

      //if step was viewed before
      //append the next interval into it
      if (
        this.workflowTracker["steps"][workflow.steps[currentStepIndex - 1]] &&
        this.workflowTracker["steps"][workflow.steps[currentStepIndex - 1]].playedIntervals
      ) {
        this.playedIntervalNo =
          this.workflowTracker["steps"][workflow.steps[currentStepIndex - 1]].playedIntervals.length;
        this.manageTrackingInterval({ action: "onPlay", stepId: workflow.steps[currentStepIndex - 1], currentTime: 0 });
      }
      this.workflowTracker.onPrevious.push(change);
    },
    handleOnForward(step, currentTime) {
      this.workflowTracker["steps"][step.id].onForward.push(currentTime);
    },
    handleOnBackward(step, currentTime) {
      this.workflowTracker["steps"][step.id].onBackward.push(currentTime);
    },
    handleOnStepChanged({ stepId }) {
      if (!stepId) {
        return;
      }
      this.workflowTracker.stepsArray.push(stepId);
    },
    trackPlayer(args) {
      this.$syncPlayerTracking.addUpdateTrackingDataTaskOfUserToQueue({
        mixinTrackerRef: this,
        trackingDataArg: args,
        callback: () => {
          this.updateTrackingInfo(args);
        },
      });
    },
    updateTrackingInfo({
      workflow,
      event,
      step,
      organization,
      user,
      settings,
      currentTime,
      nextStep,
      previousStep,
      playSpeed,
    }) {
      //data store must be created before tracking can happen
      if (!this.workflowTracker.id) {
        //first time creating datastore
        this.createDataStore(workflow, step, organization, user);
      } else {
        if (!this.workflowTracker.steps[step.id]) {
          //create datastore for new steps
          this.createDataStore(workflow, step, organization, user);
          //add a new tracking interval
          this.manageTrackingInterval({ action: "onPlay", stepId: step.id, currentTime: 0 });
        }

        this.checkOrganization(organization);

        const workflowId = "id" in workflow ? workflow.id : "";
        const stepId = step.id;

        let nextStepId, previousStepId;
        if (nextStep) {
          nextStepId = nextStep.id;
        }
        if (previousStep) {
          previousStepId = previousStep.id;
        }

        if (event == "on_playing") {
          this.handleOnPlaying(step, playSpeed);
        } else if (event == "on_play") {
          this.handleOnPlay(stepId);
          //add a new tracking interval
          this.manageTrackingInterval({ action: "onPlay", stepId, currentTime: this.currentVideoTime });
        } else if (event == "on_pause") {
          //close the previous tracking interval
          this.manageTrackingInterval({ action: "onPause", stepId, currentTime: this.currentVideoTime });
          this.handleOnPause(stepId);
          //store tracking data if video is public
          if (this.isPublic) {
            this.processIfPublicData = true;
          }
        } else if (event == "on_enter_workflow") {
          this.handleOnEnterWorkflow(workflowId);
        } else if (event == "on_change_step_from_menu") {
          this.handleOnChangeStepFromMenu(step);
        } else if (event == "on_next") {
          //close the previous tracking interval
          this.manageTrackingInterval({ action: "onPause", stepId, currentTime: this.currentVideoTime });
          //reset tracking interval on next;
          this.playedIntervalNo = 0;
          this.handleOnNext(workflow, stepId, this.currentVideoTime);
          //store tracking data if video is public
          if (this.isPublic) {
            this.processIfPublicData = true;
          }
        } else if (event == "on_previous") {
          //close the previous tracking interval
          this.manageTrackingInterval({ action: "onPause", stepId, currentTime: this.currentVideoTime });
          this.handleOnPrevious(workflow, stepId, this.currentVideoTime);
          //store tracking data if video is public
          if (this.isPublic) {
            this.processIfPublicData = true;
          }
        } else if (event == "on_subtitles") {
          this.handleOnSubtitles(settings.languageCode);
        } else if (event == "on_change_rez") {
          this.handleOnChangeRez(settings.rez);
        } else if (event == "on_forward") {
          this.handleOnForward(step, currentTime);
          //close the previous tracking interval
          this.manageTrackingInterval({ action: "onPause", stepId, currentTime: currentTime });
          //add a new tracking interval
          this.manageTrackingInterval({ action: "onPlay", stepId, currentTime: currentTime + 5 });
        } else if (event == "on_backward") {
          this.handleOnBackward(step, currentTime);
          //close the previous tracking interval
          this.manageTrackingInterval({ action: "onPause", stepId, currentTime: currentTime });
          //add a new tracking interval
          //if current time - backward is lower than 0, then set currentTime to 0
          this.manageTrackingInterval({
            action: "onPlay",
            stepId,
            currentTime: currentTime - 5 < 0 ? 0 : currentTime - 5,
          });
        } else if (event == "on_ending") {
          //close the previous tracking interval
          this.manageTrackingInterval({ action: "onPause", stepId, currentTime: step.duration });
        } else if (event == "on_close_video_player") {
          //close the previous tracking interval
          this.manageTrackingInterval({ action: "onPause", stepId, currentTime: this.currentVideoTime });
        } else if (event == "on_playNext") {
          this.playedIntervalNo = 0;
          //add a new tracking interval
          this.manageTrackingInterval({ action: "onPlay", stepId: nextStepId, currentTime: this.currentVideoTime });
        } else if (event == "on_next_public") {
          //close the previous tracking interval
          this.manageTrackingInterval({ action: "onPause", stepId, currentTime: this.currentVideoTime });
          //if step was viewed before
          //append the next interval into it
          //if not reset playedIntervalNo
          if (this.workflowTracker["steps"][nextStepId] && this.workflowTracker["steps"][nextStepId].playedIntervals) {
            this.playedIntervalNo = this.workflowTracker["steps"][nextStepId].playedIntervals.length;
            this.manageTrackingInterval({ action: "onPlay", stepId: nextStepId, currentTime: 0 });
          } else {
            this.playedIntervalNo = 0;
          }
        } else if (event == "on_previous_public") {
          //close the previous tracking interval
          this.manageTrackingInterval({ action: "onPause", stepId, currentTime: this.currentVideoTime });
          //if step was viewed before
          //append the next interval into it
          //if not reset playedIntervalNo
          if (
            this.workflowTracker["steps"][previousStepId] &&
            this.workflowTracker["steps"][previousStepId].playedIntervals
          ) {
            this.playedIntervalNo = this.workflowTracker["steps"][previousStepId].playedIntervals.length;
            this.manageTrackingInterval({ action: "onPlay", stepId: previousStepId, currentTime: 0 });
          } else {
            this.playedIntervalNo = 0;
          }
        } else if (event == "on_progress_bar") {
          //close the previous tracking interval
          this.manageTrackingInterval({ action: "onPause", stepId, currentTime });
        }
        this.saveDataLocally();
      }
    },

    checkOrganization(organization) {
      const isMissingOrganization = !this.isPublic && organization && !this.workflowTracker.organization;
      if (isMissingOrganization) {
        this.workflowTracker.organization = organization;
      }
    },

    async processPublicTrackingDocs() {
      // TODO: remove processPublicTrackingDocs when new version of public tracking is stable
      return;
      if (!("indexedDB" in window)) {
        return;
      }
      //connect to openDB
      let { transaction, store } = await this.createNewDbTransaction("playerTracker");
      //getting tracked documents from openDb
      const docs = await store.getAll();
      //close transaction
      await transaction.done;
      //set documents which need to be sync
      const toSync = docs
        .map((doc) => {
          if (doc.lastSync == -1) {
            doc.onExit = doc.lastUpdated;
            //delete lasSync property from doc before save
            delete doc.lastSync;

            return doc;
          }
        })
        .filter(Boolean);

      let numberOfIterations = toSync && toSync.length;
      for (const doc of toSync) {
        if (!doc.isPublic) {
          continue;
        }
        //send document to player-server
        await syncPublicPlayerTrackingToServer({
          trackingInfo: doc,
        });
        //set document as updated
        doc.lastSync = Date.now();
        //connect to openDB
        let { transaction, store } = await this.createNewDbTransaction("playerTracker");
        //update document in db
        await store.put(doc);

        if (!--numberOfIterations) {
          //close transaction
          await transaction.done;
          console.log("player tracker data successfully synced.");
        }
      }
    },
    async createNewDbTransaction(objStoreName, dbName = "deephow-db") {
      const localDb = await openDB(dbName, 1, {
        upgrade(localDb) {
          localDb.createObjectStore(objStoreName, { keyPath: "id" });
        },
      });
      const transaction = localDb.transaction(objStoreName, "readwrite");
      const store = transaction.objectStore(objStoreName);

      return { transaction, store };
    },
    manageTrackingInterval({ action, stepId, currentTime }) {
      if (!this.workflowTracker["steps"][stepId]) {
        return;
      }

      //if the the step was changed
      ////reset the tracking interval no
      if (
        this.workflowTracker["steps"][stepId].playedIntervals &&
        this.workflowTracker["steps"][stepId].playedIntervals.length === 0
      ) {
        this.playedIntervalNo = 0;
      }

      if (action === "onPlay" && this.workflowTracker["steps"][stepId].playedIntervals[this.playedIntervalNo]) {
        this.workflowTracker["steps"][stepId].playedIntervals[this.playedIntervalNo][action] = currentTime;
      } else if (action === "onPlay" && !this.workflowTracker["steps"][stepId].playedIntervals[this.playedIntervalNo]) {
        //create a new tracking interval with on play value
        this.workflowTracker["steps"][stepId].playedIntervals.push({
          [action]:
            this.workflowTracker["steps"][stepId].playedIntervals &&
            this.workflowTracker["steps"][stepId].playedIntervals.length === 0
              ? 0
              : currentTime,
        });
        //keep trackingIntervalNo as it is
        return this.playedIntervalNo;
      } else if (action === "onPause" && this.workflowTracker["steps"][stepId].playedIntervals[this.playedIntervalNo]) {
        this.workflowTracker["steps"][stepId].playedIntervals[this.playedIntervalNo][action] = currentTime;
        //if onPlay time is equal with onPause time user not played the video
        //remove this value from playedIntervals
        //return the current playedIntervalNo
        if (
          this.workflowTracker["steps"][stepId].playedIntervals[this.playedIntervalNo]["onPause"] ===
          this.workflowTracker["steps"][stepId].playedIntervals[this.playedIntervalNo]["onPlay"]
        ) {
          this.workflowTracker["steps"][stepId].playedIntervals.splice(this.playedIntervalNo, 1);
          return this.playedIntervalNo;
        }
        //increment trackingIntervalNo with 1
        return (this.playedIntervalNo += 1);
      }

      return this.playedIntervalNo;
    },
    async trackUserAction({ workflowId, action, timestamp }) {},
  },
};
</script>
