<template>
  <d-teleport to="body">
    <d-layout column class="DDialog" :class="theme">
      <d-layout column class="DDialog__Container" :style="{ height: height, width: width }">
        <div class="DDialog__CloseBtn" :style="{ ...closeButtonStyle }" @click="close">
          <font-awesome-icon :icon="['far', 'xmark']" size="lg" />
        </div>

        <template v-if="showHeader">
          <d-layout column class="DDialog__Header">
            <slot name="header">
              <d-layout row class="DDialog__HeaderInfo">
                <div class="DDialog__HeaderTitle">{{ title }}</div>
                <div class="DDialog__HeaderSubtitle">
                  <slot name="subtitle"></slot>
                </div>
              </d-layout>
            </slot>
          </d-layout>
          <div v-if="showHeaderBorder" class="DDialog__HeaderBorder"></div>
        </template>

        <div class="DDialog__Content" :class="{ 'DDialog__Content--no-header': !showHeader }">
          <d-layout column class="DDialog__ContentInner"> <slot></slot> </d-layout>
        </div>

        <template v-if="showFooter">
          <d-layout class="DDialog__Footer" row>
            <slot name="footer">
              <button v-if="showCancelButton" class="dh-btn btn-outline-primary DDialog__FooterBtn" @click="cancel">
                {{ cancelText || $t("all.cancel") }}
              </button>

              <button
                v-if="showConfirmButton"
                class="dh-btn btn-primary DDialog__FooterBtn"
                :class="{ disabled: isConfirmDisabled }"
                @click="confirm"
              >
                {{ confirmText || $t("all.confirm") }}
              </button>

              <slot name="customButtons"></slot>
            </slot>
          </d-layout>
        </template>
      </d-layout>
    </d-layout>
  </d-teleport>
</template>

<script>
import DTeleport from "@/components/ui_components/DTeleport.vue";

export default {
  name: "DDialog",
  components: {
    DTeleport,
  },
  props: {
    theme: {
      type: String, // "dark" | "light"
      default: "dark",
    },
    title: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "400px",
    },
    width: {
      type: String,
      default: "500px",
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    showConfirmButton: {
      type: Boolean,
      default: true,
    },
    showCancelButton: {
      type: Boolean,
      default: true,
    },
    confirmText: {
      type: String,
      default: "",
    },
    cancelText: {
      type: String,
      default: "",
    },
    isConfirmDisabled: {
      type: Boolean,
      default: false,
    },
    closeButtonStyle: {
      type: Object, // css object
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  computed: {
    showHeader() {
      return this.$slots.header || this.title || this.$slots.subtitle;
    },
    showHeaderBorder() {
      return this.showHeader && !this.$slots.header;
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },
    cancel() {
      this.$emit("cancel");
    },
    confirm() {
      if (this.isConfirmDisabled) return;
      this.$emit("confirm");
    },
  },
};
</script>

<style lang="scss" scoped>
.DDialog {
  position: fixed;
  top: 0;
  left: 0;

  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;
  z-index: 9999;
  overflow: hidden;

  &__Container {
    flex: 0 0 auto;
    position: relative;
    height: 400px;
    max-height: 80%;
    width: 500px;
  }
  &__CloseBtn {
    position: absolute;
    top: 18px;
    right: 24px;
    cursor: pointer;
    color: var(--brand-white);
  }
}

.DDialog__Header {
  flex: 0 0 auto;
  padding: 18px 24px;
  &Info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 4px;
    padding-right: 40px; // leave some space for close btn
  }
  &Title {
    flex: 0 0 auto;
    text-align: left;
    max-width: 100%;
  }
  &Subtitle {
    flex: 1 0 auto;
    text-align: left;
  }
}
.DDialog__Content {
  flex: 1;
  overflow: overlay;
  padding: 24px;
  &--no-header {
    padding-top: 44px;
  }
}
.DDialog__Footer {
  flex: 0 0 auto;
  justify-content: flex-end;
  column-gap: 16px;
  padding: 24px;
  &Btn {
    min-width: 120px;
  }
}

// dark theme (new ui style)
.DDialog.dark {
  // TODO: if DDialog is nested (Dialog triggers another Dialog), background will be too dark
  background: rgba(0, 0, 0, 0.85);
  color: var(--brand-white);
  .DDialog__Container {
    background: var(--dDarkBg-color);
    border-radius: 4px;
  }

  .DDialog__Header {
    font-size: 18px;
    line-height: 24px;
  }
  .DDialog__HeaderBorder {
    height: 1px;
    background: var(--dGrey-color);
  }
}

// light theme (old ui style)
.DDialog.light {
  // TODO: if DDialog is nested (Dialog triggers another Dialog), background will be too dark
  background: rgba(0, 0, 0, 0.7);
  .DDialog__Container {
    background: var(--brand-white);
    border-radius: 4px;
  }

  $header-color-light: #2c2d32;
  .DDialog__Header {
    background-color: $header-color-light; // TODO: currently not a variable
    color: var(--brand-white);
    font-size: 18px;
    line-height: 24px;
  }
  .DDialog__HeaderBorder {
    height: 1px;
    background: $header-color-light;
  }
}
</style>
