<template>
  <div class="ssoMapping">
    <div>
      <d-layout class="integrationTitle">
        <d-flex>
          <p class="integrationTitle__text mb-0">
            {{ $t("admin.ssoMapping") }} - {{ $tc("admin.workspacesIntegrated", displayWorkspaces.length) }}
          </p>
        </d-flex>
      </d-layout>
      <div class="attributes">
        <div class="attributes__title">{{ $t("admin.attributes") }}</div>
        <p class="attributes__subtitle">{{ $t("admin.oneAttributeToMap") }}</p>
        <div class="attributes__selector">
          <d-layout class="align-center">
            <d-flex class="mr-4">
              <div class="attributes__selectorButtonWrapper">
                <div
                  class="attributes__selectorButton"
                  :class="{ 'attributes__selectorButton--disabled': !changeEnabled }"
                  @click="showAttributeList = !showAttributeList"
                >
                  <p class="attributes__value">{{ attributeValue }}</p>
                  <v-spacer></v-spacer>
                  <v-icon color="#8d909f" class="dropdown-arrow pt-2">keyboard_arrow_down</v-icon>
                </div>
                <v-card v-if="showAttributeList" class="attributes__dropdown">
                  <v-list dense light class="attributes__dropdownList">
                    <v-list-tile
                      v-for="field in attributeFields"
                      :key="field.key"
                      class="attributes__dropdownItem"
                      @click="selectAttribute(field.key)"
                    >
                      <v-list-tile-title>{{ field.value }}</v-list-tile-title>
                    </v-list-tile>
                  </v-list>
                  <div class="attributes__closeDropdown" @click="showAttributeList = false"></div>
                </v-card>
              </div>
            </d-flex>
            <d-flex>
              <d-button
                small
                round
                depressed
                class="attributes__changeAttributeButton body-2 px-4"
                color="dBlue"
                :disabled="changeEnabled"
                dark
                @click="showConfirmation = 'change-attribute'"
                >{{ $t("admin.changeAttribute") }}</d-button
              >
            </d-flex>
          </d-layout>
        </div>
      </div>

      <div v-if="madeChange" class="updateMapping">
        <div class="updateMapping__icon">
          <icon-base width="24" height="24" color="#4689F3">
            <d-icon-info />
          </icon-base>
        </div>
        <div class="updateMapping__titleWrapper">
          <p class="updateMapping__title">{{ $t("admin.mappingValuesUpdated") }}</p>
          <p class="updateMapping__subtitle">{{ $t("admin.mappingValuesUpdatedSubtitle") }}</p>
        </div>
        <v-spacer></v-spacer>
        <d-button
          small
          round
          depressed
          outline
          class="updateMapping__button body-2 px-4 ml-4"
          color="dBlue"
          light
          @click="showConfirmation = 'discard-changes'"
          >{{ $t("admin.discardChanges") }}</d-button
        >
        <d-button
          small
          round
          depressed
          class="updateMapping__button body-2 px-4 white--text"
          color="dBlue"
          light
          @click="showConfirmation = 'apply-changes'"
          >{{ $t("admin.applyChanges") }}</d-button
        >
      </div>

      <div class="attributesMappingTable">
        <div class="attributesMappingTable__searchBox d-flex align-center">
          <div class="attributesMappingTable__searchIcon">
            <icon-base color="#52545D" width="24" height="24">
              <d-icon-search />
            </icon-base>
          </div>
          <form autocomplete="off" style="width: 100%">
            <v-text-field
              ref="searchField"
              flat
              solo
              v-model="searchInput"
              placeholder="Search for Workspaces..."
              single-line
              hide-details
              background-color="transparent"
              color="#4689f4"
              class="attributesMappingTable__searchInput"
              autotest="ssoMapping-search-for-workspaces"
            ></v-text-field>
          </form>
        </div>
        <div class="attributesMappingTable__dataTableWrapper">
          <v-data-table
            disable-initial-sort
            class="attributesMappingTable__dataTable"
            :class="{ 'attributesMappingTable__dataTable--noData': displayWorkspaces.length === 0 }"
            :headers="tableHeader"
            :items="displayWorkspaces"
            :loading="isLoading"
            :search="searchInput"
            item-key="id"
            :rows-per-page-items="[10, 25, 50, { text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 }]"
            :rows-per-page-text="$t('skills.skillsPerPage')"
            :pagination.sync="pagination"
          >
            <v-progress-linear slot="progress" color="dBlue" height="2" indeterminate></v-progress-linear>
            <template slot="headerCell" slot-scope="props">
              <span class="attributesMappingTable__headerText" :autotest="props.header.autotest">{{
                props.header.text
              }}</span>
            </template>

            <template slot="items" slot-scope="props">
              <td class="text-xs-left text-capitalize" autotest="sso-mapping-workspace-name-display">
                <span class="text-no-wrap">{{ props.item.name }}</span>
              </td>
              <td class="text-xs-left" autotest="sso-mapping-workspace-group-display">
                {{ props.item.workspaceGroupName }}
              </td>
              <td class="text-xs-left" autotest="sso-mapping-attribute-values-display">
                <span v-if="props.item.attributeValues.length > 0">{{ props.item.attributeValues.join(", ") }}</span>
                <span v-else class="attributesMappingTable__fieldText">{{ props.item.attributeTextDisplay }}</span>
              </td>
              <td class="text-xs-right">
                <div v-if="props.item.name.toLowerCase() !== 'general'" class="attributesMappingTable__buttonsWrapper">
                  <button class="mr-3" @click="editWorkspaceItem('add', props.item)">
                    <icon-base width="20" height="20" color="#52545D">
                      <d-icon-add-sm />
                    </icon-base>
                  </button>
                  <button v-if="props.item.attributeValues.length > 0" @click="editWorkspaceItem('remove', props.item)">
                    <icon-base width="20" height="20" color="#52545D">
                      <d-icon-remove-sm />
                    </icon-base>
                  </button>
                </div>
              </td>
            </template>

            <template slot="no-data">
              <tr v-if="isLoading">
                <td colspan="4">
                  <span>{{ $t("admin.loadingWorkspaces") }}</span>
                </td>
              </tr>

              <tr v-else>
                <td colspan="4">
                  <span>{{ $t("admin.noSsoMappingWorkspaces") }}</span>
                </td>
              </tr>
            </template>

            <template slot="no-results">
              <tr class="noSearchResult">
                <td colspan="4">
                  <span>{{ $t("admin.noResultsFound") }}</span>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>

    <d-admin-edit-values-popup
      v-if="showPopup"
      :popupType="popupType"
      :editWorkspace="editWorkspace"
      :attributeValues="attributeValues"
      @close-popup="showPopup = false"
      @add-attributes="updateAttributes"
      @remove-attributes="updateAttributes"
    ></d-admin-edit-values-popup>

    <d-change-attribute-confirmation
      v-if="showConfirmation === 'change-attribute'"
      @close-popup="showConfirmation = ''"
      @change-attribute="changeAttribute"
    ></d-change-attribute-confirmation>

    <d-discard-mapping-changes-confirmation
      v-if="showConfirmation === 'discard-changes'"
      @close-popup="showConfirmation = ''"
      @discard-changes="discardMappingChanges"
    ></d-discard-mapping-changes-confirmation>

    <d-save-mapping-changes-confirmation
      v-if="showConfirmation == 'apply-changes'"
      :changedWorkspaces="changedWorkspaces"
      @close-popup="showConfirmation = ''"
      @apply-changes="applyMappingChanges"
    ></d-save-mapping-changes-confirmation>
  </div>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import DIconSearch from "@/components/icons/DIconSearch.vue";
import DIconPen from "@/components/icons/DIconPen.vue";
import DIconAddSm from "@/components/icons/DIconAddSm.vue";
import DIconRemoveSm from "@/components/icons/DIconRemoveSm.vue";
import DAdminEditValuesPopup from "@/components/DAdmin/DAdminEditValuesPopup.vue";
import DChangeAttributeConfirmation from "@/components/DAdmin/confirmations/DChangeAttributeConfirmation.vue";
import DIconInfo from "@/components/icons/DIconInfo.vue";
import DDiscardMappingChangesConfirmation from "@/components/DAdmin/confirmations/DDiscardMappingChangesConfirmation.vue";
import DSaveMappingChangesConfirmation from "@/components/DAdmin/confirmations/DSaveMappingChangesConfirmation.vue";
import DDataTable from "@/components/ui_components/DDataTable.vue";
import MixinUser from "@/components/MixinUser.vue";
import MixinDB from "@/components/MixinDB.vue";
import { cloneDeep } from "lodash-es";
import {
  fetchOrganizationMappings,
  putOrganizationMappings,
  fetchMappingAttributeFields,
  fetchMappingAttributeValues,
} from "@/server/organization-mapping-server.js";
import { loggingError } from "@/server/error-log-server.js";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "DAdminSsoMapping",
  components: {
    IconBase,
    DIconSearch,
    DIconPen,
    DIconAddSm,
    DIconRemoveSm,
    DAdminEditValuesPopup,
    DChangeAttributeConfirmation,
    DIconInfo,
    DDiscardMappingChangesConfirmation,
    DSaveMappingChangesConfirmation,
    DDataTable,
  },
  mixins: [MixinUser, MixinDB],
  data() {
    return {
      integrationsMenu: {},
      integrationPage: "kahuna",
      deleteWorkspacesConfirmation: false,
      showAddWorkspacePopup: false,
      deleteConfirmation: false,
      currentWorkspace: {},
      pagination: { sortBy: "publishedAt", descending: true },
      workspaces: [],
      workspacesWithAttribute: [],
      displayWorkspaces: [],
      attributeFields: [],
      attributeValues: [],
      mappings: [],
      selectedAttributeKey: "",
      showAttributeList: false,
      searchInput: "",
      showPopup: false,
      editWorkspace: {},
      updateMappings: [],
      tableHeader: [
        {
          text: this.$t("all.workspace"),
          align: "left",
          sortable: true,
          value: "name",
          autotest: "workspace-name-sort",
        },
        {
          text: this.$t("all.workspaceGroup"),
          align: "left",
          sortable: true,
          value: "workspaceGroupName",
          autotest: "workspace-group-name-sort",
        },
        {
          align: "left",
          sortable: true,
          value: "attributeValues",
          autotest: "sso-mapping-attribute-values-sort",
        },
        {
          text: "",
          align: "right",
          sortable: true,
          value: "actions",
        },
      ],
      popupType: "",
      changeEnabled: false,
      showConfirmation: "",
      isLoading: false,
      madeChange: false,
      changedWorkspaces: [],
    };
  },
  computed: {
    ...mapGetters("workspace", ["getter_workspaces_with_workspace_groups_name"]),
    attributeValue() {
      const field = this.attributeFields.find((field) => field.key === this.selectedAttributeKey);
      return field?.value || this.$t("admin.selectAttribute");
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    ...mapActions("global", ["openAlert"]),
    ...mapActions("workspaceGroups", ["getWorkspaceGroupList"]),
    async initialize() {
      this.selectedAttributeKey = this.$clientSettings?.userMappings?.groupMapping?.[0] ?? "";
      this.fetchInitialData();
    },
    async fetchInitialData() {
      this.isLoading = true;
      try {
        await Promise.all([
          this.getOrganizationMappings(),
          this.handleFetchMappingAttributeFields(),
          this.handleFetchMappingAttributeValues(),
          this.getWorkspaceGroupList({ workspacesType: "admin" }),
        ]);
        this.handleWorkspacesWithAttribute();
      } catch (error) {
        this.alertUnexpectedError();
        loggingError(error);
      } finally {
        this.isLoading = false;
      }
    },
    async handleFetchMappingAttributeFields() {
      this.attributeFields = await fetchMappingAttributeFields();
    },
    async handleFetchMappingAttributeValues() {
      this.attributeValues = await fetchMappingAttributeValues({ field: this.selectedAttributeKey });
      this.attributeValues.sort();
    },
    handleWorkspacesWithAttribute() {
      this.workspaces = [...this.getter_workspaces_with_workspace_groups_name];
      this.getWorkspacesWithAttribute();
    },

    /**
     * 'workspacesWithAttribute' matches each workspace with its corresponding mapping by id.
     * Then enriches the workspace with the selected attribute's key and values from the mapping.
     */
    getWorkspacesWithAttribute() {
      const mappingsWithKey = this.mappings.reduce((acc, mapping) => ({ ...acc, [mapping.mapId]: mapping }), {});
      this.workspacesWithAttribute = this.workspaces.map((workspace) => {
        const mapping = mappingsWithKey[workspace.id];
        const attribute = mapping ? { [this.selectedAttributeKey]: mapping[this.selectedAttributeKey] } : {};
        const attributeValues = attribute[this.selectedAttributeKey] || [];
        attributeValues.sort();
        const attributeTextDisplay =
          workspace.name.toLowerCase() === "general" ? "N/A" : this.$t("admin.clickPlusToAddValues");
        return { ...workspace, attribute, attributeValues, attributeTextDisplay };
      });
      this.displayWorkspaces = cloneDeep(this.workspacesWithAttribute);
      this.displayWorkspaces.sort();
    },
    async getOrganizationMappings() {
      const { ok, data } = await fetchOrganizationMappings("groups");
      if (ok) this.mappings = data.item;
    },
    async updateAttributes(data) {
      const values = this.handleAttributeValues(data);
      const idx = this.displayWorkspaces.findIndex((workspace) => workspace.id === this.editWorkspace.id);
      idx !== -1 && (this.displayWorkspaces[idx].attributeValues = values);
      const pos = this.changedWorkspaces.findIndex((workspaceName) => workspaceName === this.editWorkspace.name);
      pos === -1 && this.changedWorkspaces.unshift(this.editWorkspace.name);
      this.madeChange = true;
    },
    handleAttributeValues(attributes) {
      const attributeValues = [...this.editWorkspace.attributeValues];
      if (this.popupType === "add") {
        return [...attributeValues, ...attributes].sort();
      }
      if (this.popupType === "remove") {
        return attributeValues.filter((value) => !attributes.includes(value));
      }
    },
    editWorkspaceItem(type, item) {
      this.popupType = type;
      this.editWorkspace = item;
      this.showPopup = true;
    },
    async selectAttribute(key) {
      this.selectedAttributeKey = key;
      this.showAttributeList = false;
      this.displayWorkspaces = [];
      this.isLoading = true;
      try {
        await this.handleFetchMappingAttributeValues();
        this.handleWorkspacesWithAttribute();
      } catch (error) {
        console.log(error);
        loggingError(error);
      } finally {
        this.isLoading = false;
        this.changeEnabled = false;
      }
    },
    async applyMappingChanges() {
      this.showConfirmation = "";
      this.madeChange = false;
      try {
        const mappings = this.displayWorkspaces.map((workspace) => ({
          mapId: workspace.id,
          values: workspace.attributeValues,
        }));
        const { ok } = await putOrganizationMappings({
          type: "groups",
          attribute: this.selectedAttributeKey,
          mappings,
        });
        if (!ok) {
          return this.openAlert({ type: "error", message: this.$t("admin.alertAppliedFailed") });
        }
        this.$clientSettings.userMappings.groupMapping = [this.selectedAttributeKey];
        this.displayWorkspaces = [];
        await this.fetchInitialData();
        this.openAlert({ type: "success", message: this.$t("admin.alertAppliedSuccess") });
      } catch (error) {
        this.alertUnexpectedError();
        loggingError(error);
      } finally {
        this.changedWorkspaces = [];
      }
    },
    discardMappingChanges() {
      this.showConfirmation = "";
      this.madeChange = false;
      this.changedWorkspaces = [];
      this.displayWorkspaces = cloneDeep(this.workspacesWithAttribute);
      this.openAlert({ type: "success", message: this.$t("admin.alertDiscardedSuccess") });
    },
    changeAttribute() {
      this.changeEnabled = true;
      this.showConfirmation = "";
      this.selectedAttributeKey = "";
      this.displayWorkspaces = this.workspaces.map((workspace) => {
        const attributeTextDisplay =
          workspace.name.toLowerCase() === "general" ? "N/A" : this.$t("admin.clickPlusToAddValues");
        return { ...workspace, attribute: {}, attributeValues: [], attributeTextDisplay };
      });
    },
    alertUnexpectedError() {
      this.openAlert({ type: "error", message: this.$t("alert.unexpectedError") });
    },
  },
};
</script>

<style scoped lang="scss">
.ssoMapping {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: left;
  z-index: 0;
  flex-direction: column;
  display: flex;

  .integrationTitle {
    border: 1px solid #e7e7e7;
    border-bottom: none;
    background-color: #ffffff;
    padding: 24px;

    &__text {
      color: #3f3f3f;
      font-family: Roboto;
      font-size: 20px;
      letter-spacing: 0;
    }
  }

  &--tableTitle {
    color: #3f3f3f;
    font-family: Roboto;
    font-size: 32px;
    letter-spacing: 0;
    line-height: 32px;
    margin-bottom: 20px;
  }

  .attributes {
    width: 100%;
    border: 1px solid #e7e7e7;
    background-color: #ffffff;
    box-shadow: 0 0 4px -2px rgba(0, 0, 0, 0.15);
    padding: 24px;

    &__title {
      color: #3f3f3f;
      font-family: Roboto;
      font-size: 20px;
      letter-spacing: 0;
    }

    &__subtitle {
      color: #919191;
      font-family: Roboto;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 18px;
      margin-bottom: 20px;
    }

    &__selector {
      position: relative;
      max-width: 650px;
    }

    &__selectorButtonWrapper {
      position: relative;
      width: 100%;
    }

    &__selectorButton {
      position: relative;
      width: 100%;
      height: 36px;
      display: flex;
      border-bottom: 1px solid #dadada;

      &--disabled {
        pointer-events: none !important;
        opacity: 0.6;
      }
    }

    &__value {
      color: #52545d;
      font-family: Roboto;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 36px;
    }

    &__dropdown {
      width: 100%;
      top: 36px;
      max-height: 240px;
      overflow-y: auto;
      background-color: white;
      z-index: 1;

      &:hover::-webkit-scrollbar-thumb {
        background-color: #b6b6b6;
      }
    }

    &__dropdownList {
      width: 100%;
    }

    &__dropdownItem:hover {
      background-color: rgba(245, 245, 245) !important;
    }

    &__closeDropdown {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: -1;
    }

    &__changeAttributeButton {
      margin: 0;
      text-transform: none !important;
    }
  }

  .updateMapping {
    border: 1px solid #4689f3;
    background-color: rgba(70, 137, 243, 0.2);
    box-shadow: 0 0 4px -2px rgba(0, 0, 0, 0.15);
    padding: 20px 16px;
    margin-top: 32px;
    min-height: 80px;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    &__icon {
      position: relative;
      height: 24px;
      width: 24px;
    }

    &__titleWrapper {
      margin-left: 16px;
      position: relative;
      width: 100%;
    }

    &__title {
      color: #3f3f3f;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;
      margin: 0;
    }

    &__subtitle {
      color: #8d909f;
      font-family: Roboto;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 20px;
      margin: 6px 0 0 0;
    }

    &__button {
      text-transform: none !important;
    }
  }

  .attributesMappingTable {
    position: relative;
    border: 1px solid #e7e7e7;
    background-color: #ffffff;
    box-shadow: 0 0 4px -2px rgba(0, 0, 0, 0.15);

    &__searchBox {
      position: relative;
      width: 100%;
      height: 72px;
      border: 1px solid #e7e7e7;
      background-color: #ffffff;
      box-shadow: 0 0 4px -2px rgba(0, 0, 0, 0.15);
      padding: 0 22px;
    }

    &__searchIcon {
      height: 32px;
      width: 32px;
      padding: 4px;
    }

    &__searchInput {
      margin: 0;
      padding: 0;

      ::v-deep .v-text-field__slot input {
        font-family: Roboto;
        font-size: 20px;
        letter-spacing: 0;
        line-height: 24px;

        ::placeholder {
          opacity: 0.5;
          color: #919191;
          font-family: Roboto;
          font-size: 20px;
          letter-spacing: 0;
          line-height: 24px;
        }
      }
    }

    &__dataTableWrapper {
      width: 100%;
      overflow: auto;

      &:hover::-webkit-scrollbar-thumb {
        background-color: #b6b6b6;
      }

      ::v-deep table.v-table thead tr {
        border-bottom: 1px solid #e2e7f0;
        background-color: #eff2f7;
        height: 32px;
        color: #475267;
        font-family: Roboto;
        font-size: 12px;
        letter-spacing: 0;
        text-transform: uppercase;
        font-weight: 400;
        line-height: 12px;
        text-align: left;
      }

      ::v-deep table.v-table tbody td {
        border-bottom: 1px solid #e2e7f0;
        height: 48px;
        color: #3f3f3f;
        font-family: Roboto;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 20px;
        text-align: left;
      }

      ::v-deep .v-table__overflow:hover::-webkit-scrollbar-thumb {
        background-color: #b6b6b6;
      }

      .attributesMappingTable__dataTable {
        ::v-deep table.v-table tbody .noSearchResult td {
          text-align: center;
        }
      }

      .attributesMappingTable__dataTable--noData ::v-deep table.v-table tbody td {
        text-align: center;
      }
    }

    &__headerText {
      text-transform: uppercase;
      color: #4e4e4e;
      font-family: Roboto;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 14px;
    }

    &__fieldText {
      color: rgba(82, 84, 93, 0.5);
      white-space: nowrap;
    }

    &__buttonsWrapper {
      display: flex;
      padding-top: 4px;
    }
  }
}
</style>
