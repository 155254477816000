<template>
  <v-dialog :value="value" @input="$emit('input', $event)" max-width="1200px" persistent>
    <v-card class="reviewPopup">
      <v-card-title class="reviewPopup__title">
        <span class="reviewPopup__title--text">{{ $t("builder.alertTitleSelectReviewers") }}</span>
      </v-card-title>
      <v-card-text class="reviewPopup__body">
        <div class="reviewPopup__body--left">
          <DSearchInput v-model="searchText" />
          <div class="reviewPopup__select-all-btn">
            <button @click.stop="handleSelectAll" type="button">
              {{ $t("admin.selectAll") }}
            </button>
          </div>
          <div class="reviewPopup__body--left-list">
            <template v-if="!isSearchResultEmpty">
              <div
                v-for="user in filterUsersBySearch"
                :key="user.id"
                class="reviewPopup__user"
                @click="handleToggleUser(user.id)"
              >
                <div class="reviewPopup__user--left">
                  <d-avatar :size="40" :userId="user.id" />
                  <div class="reviewPopup__user--info">
                    <div class="reviewPopup__user--name">{{ user.displayName }}</div>
                    <div class="reviewPopup__user--email">{{ user.email }}</div>
                  </div>
                </div>
                <template>
                  <button v-if="selectedUserIds.includes(user.id)" class="reviewPopup__user--checked-btn">
                    <d-icon-circle-checked />
                  </button>
                  <button v-else class="reviewPopup__user--add-btn">
                    <d-icon-plus />
                  </button>
                </template>
              </div>
            </template>
            <div v-else class="reviewPopup__body--left-list-empty">
              <span>{{ $t("explorer.noSearchResult") }}</span>
            </div>
          </div>
        </div>
        <div class="reviewPopup__body--right">
          <div class="reviewPopup__body--right-list">
            <div
              v-for="userId in selectedUserIds"
              :key="userId"
              class="reviewPopup__user"
              @click="handleRemoveUser(userId)"
            >
              <div class="reviewPopup__user--left">
                <d-avatar :size="40" :userId="userId" />
                <div class="reviewPopup__user--info">
                  <div class="reviewPopup__user--name">{{ usersMap[userId].displayName }}</div>
                  <div class="reviewPopup__user--email">{{ usersMap[userId].email }}</div>
                </div>
              </div>
              <div class="close" />
            </div>
          </div>
        </div>
      </v-card-text>

      <v-card-actions class="reviewPopup__actions">
        <d-button
          outline
          round
          color="#4689f4"
          class="elevation-0 body-2 text-capitalize btn-width"
          light
          @click="handleClose"
          >{{ $t("new.cancel") }}
        </d-button>
        <d-button
          @click="handleSave"
          round
          color="#4689f4"
          :disabled="selectedUserIds.length === 0"
          light
          class="elevation-0 body-2 text-capitalize btn-width white--text"
          >{{ $t("skills.assign") }}
        </d-button>
      </v-card-actions>
    </v-card>
    <d-alert v-model="alertConfig.isShow" :type="alertConfig.type" :message="alertConfig.message" />
  </v-dialog>
</template>

<script>
import DAvatar from "@/components/ui_components/DAvatar.vue";
import DAlert from "@/components/ui_components/DAlert";
import DSearchInput from "@/components/DForm/DSearchInput";
import IconBase from "@/components/IconBase.vue";
import DIconPlus from "@/components/icons/editor/DIconPlus";
import DIconCircleChecked from "@/components/icons/colored/DIconCircleChecked.vue";
import { mapActions, mapState } from "vuex";
import { escapeRegExp } from "lodash-es";
import { notifyWorkflowReviewRequest } from "@/server/notifications-server.js";
export default {
  name: "DBuilderReviewerPopup",
  components: {
    IconBase,
    DIconCircleChecked,
    DAlert,
    DAvatar,
    DIconPlus,
    DSearchInput,
  },
  async created() {
    await this.fetchReviewers(this.workflowId);
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    workflowId: {
      type: String,
      required: true,
    },
  },
  data() {
    const alertConfig = {
      isShow: false,
      message: this.$t("editor.alertIsSaving"),
      type: "info",
    };
    return {
      alertConfig,
      selectedUserIds: [],
      searchText: "",
    };
  },
  methods: {
    ...mapActions("builderMain", ["fetchReviewers"]),
    handleClose() {
      this.$emit("input", false);
    },
    handleAddUser(targetUserId) {
      this.selectedUserIds.push(targetUserId);
    },
    handleRemoveUser(targetUserId) {
      this.selectedUserIds = this.selectedUserIds.filter((id) => id !== targetUserId);
    },
    handleToggleUser(targetUserId) {
      const isSelected = this.selectedUserIds.includes(targetUserId);
      return isSelected ? this.handleRemoveUser(targetUserId) : this.handleAddUser(targetUserId);
    },
    handleSelectAll() {
      this.selectedUserIds = this.filterUsersBySearch.map(({ id }) => id);
    },
    async handleSave() {
      if (this.selectedUserIds.length === 0) return;
      this.alertConfig.isShow = true;
      this.alertConfig.message = this.$t("editor.alertIsSaving");
      this.alertConfig.type = "info";

      const users = this.selectedUserIds.map((userId) => this.usersMap[userId]);
      const { ok } = await notifyWorkflowReviewRequest({
        vueInstanceRef: this,
        workflowId: this.workflowId,
        users,
      });
      if (ok) {
        this.handleClose();
        this.$emit("openReviewerSuccessPopup");
        return;
      }
      this.alertConfig.type = "error";
      this.alertConfig.message = this.$t("builder.alertSendToReviewFailed");
      this.alertConfig.isShow = true;
      return;
    },
  },
  computed: {
    ...mapState("builderMain", ["reviewers"]),
    isSearchMode() {
      return this.searchText !== "";
    },
    filterUsersBySearch() {
      if (this.searchText === "") {
        return this.reviewers;
      }
      const r = RegExp(escapeRegExp(this.searchText), "i");
      return this.reviewers.filter(({ displayName, email }) => !!r.exec(displayName) || !!r.exec(email));
    },
    isSearchResultEmpty() {
      return this.isSearchMode && this.filterUsersBySearch.length === 0;
    },
    usersMap() {
      return this.reviewers.reduce((result, user) => ({ ...result, [user.id]: user }), {});
    },
  },
  watch: {
    value: {
      immediate: true,
      async handler(value) {
        if (value) {
          this.searchText = "";
          this.selectedUserIds = [];
          await this.fetchReviewers(this.workflowId);
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.reviewPopup {
  --bg-color: #1e1f22;
  --bg-sub-color: #1d1e21;
  --text-color: #9397a6;
  --border-color: #9b9b9b;
  --primary-color: #4689f3;
  --body-height: 60vh;
  @media (max-width: 1440px) {
    --body-height: 500px;
  }

  &.v-card {
    border-radius: 4px;
    background-color: transparent;
  }

  &__title {
    height: 84px;
    padding: 0 14px;
    background-color: var(--bg-color);
    border-bottom: 1px solid #0c0c0e;
    display: flex;
    align-items: flex-end;

    &--text {
      position: relative;
      bottom: -1px;
      padding: 0 14px 14px;
      border-bottom: 2px solid var(--primary-color);
      color: white;
      font-size: 16px;
    }
  }

  &__select-all-btn {
    font-size: 14px;
    cursor: pointer;
    color: var(--primary-color);
    text-align: right;
    margin: 16px 8px;
  }

  &__body {
    height: var(--body-height);
    padding: 0;

    background-color: var(--bg-color);

    display: flex;

    &--right,
    &--left {
      flex: 1;
      padding: 16px;

      &-list {
        overflow: auto;
      }
      &-list-empty {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        color: #fff;
      }

      .close {
        border-radius: 50%;
        height: 24px;
        width: 24px;
        background-color: var(--bg-sub-color);
        color: var(--text-color);
        border: 1px solid var(--border-color);
        position: relative;
        cursor: pointer;

        &::before,
        &::after {
          --deg: 45deg;
          content: "";
          display: block;
          position: absolute;
          width: 45%;
          height: 1px;
          background-color: currentColor;
          top: 50%;
          left: 50%;
          transform-origin: center;
          transform: translate(-50%, -50%) rotate(var(--deg));
        }

        &::after {
          --deg: -45deg;
        }
      }
    }

    &--left {
      &-list {
        height: calc(100% - 100px);
        margin: 0 8px;
      }
    }

    &--right {
      background: #17181a;
      &-list {
        margin: 8px;
        height: 100%;
      }
    }
  }

  &__user {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5px 0;
    cursor: pointer;
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &--left {
      flex-grow: 1;
      display: flex;
      align-items: center;
      gap: 12px;
    }

    &--info {
      display: flex;
      flex-direction: column;
      gap: 4px;
      text-align: left;
    }
    &--name {
      color: white;
      font-size: 16px;
    }
    &--email {
      color: #919191;
      font-size: 14px;
    }
    &--checked-btn {
      color: var(--primary-color);
      min-width: 32px;
      height: 32px;
    }

    &--add-btn {
      color: var(--border-color);
      min-width: 32px;
      height: 32px;
      border: 1px solid var(--border-color);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__actions {
    height: 84px;
    background-color: var(--bg-color);
    border-top: 1px solid #0c0c0e;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      width: 100px;
    }
  }
}
</style>
