<template>
  <section>
    <admin-setting-permission-item
      :allow="getter_safety_watermark_enabled"
      :title="$t('admin.forceSafetyInfoWatermark')"
      :subtitle="$t('admin.allowForceSafetyInfoWatermarkMsg')"
      autotest="workflow-comments-toggle"
      @click-switch="showConfirmation = true"
    />

    <d-change-permission-confirmation
      v-if="showConfirmation"
      :title="$t('admin.forceSafetyInfoWatermark')"
      :content="
        getter_safety_watermark_enabled
          ? $t('admin.disableForceSafetyInfoWatermarkMsg')
          : $t('admin.enableForceSafetyInfoWatermarkMsg')
      "
      @close-popup="showConfirmation = false"
      @change-permission="updateSafetyWatermark"
    ></d-change-permission-confirmation>
  </section>
</template>

<script>
import AdminSettingPermissionItem from "@/components/DAdmin/Settings/AdminSettingPermissionItem.vue";
import DChangePermissionConfirmation from "@/components/DAdmin/confirmations/DChangePermissionConfirmation.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AdminSettingSafetyWatermark",
  components: {
    AdminSettingPermissionItem,
    DChangePermissionConfirmation,
  },
  data() {
    return {
      showConfirmation: false,
    };
  },
  computed: {
    ...mapGetters("permission", ["getter_safety_watermark_enabled"]),
  },
  methods: {
    ...mapActions("global", ["openAlert"]),
    ...mapActions("permission", ["updateSafetyWatermarkSetting"]),
    async updateSafetyWatermark() {
      this.showConfirmation = false;
      const toEnable = !this.getter_safety_watermark_enabled;
      const updateData = {
        customizedPermissions: {
          enableSafetyWatermark: toEnable,
        },
      };
      try {
        await this.updateSafetyWatermarkSetting({ organization: this.$organization, updateData });
        const message = toEnable
          ? this.$t("admin.alertEnableForceSafetyInfoWatermarkSuccess")
          : this.$t("admin.alertDisableForceSafetyInfoWatermarkSuccess");
        this.openAlert({ message, type: "success" });
      } catch (error) {
        const message = toEnable
          ? this.$t("admin.alertEnableForceSafetyInfoWatermarkFailed")
          : this.$t("admin.alertDisableForceSafetyInfoWatermarkFailed");
        this.openAlert({ message, type: "error" });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
