<template>
  <div @click.stop class="DCard">
    <div class="DCard__main">
      <div class="DCard__content">
        <slot></slot>
      </div>

      <div class="DCard__actions">
        <slot name="actions"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import DIconClose2 from "@/components/icons/DIconClose2.vue";

export default {
  name: "DCard",
  components: {
    IconBase,
    DIconClose2,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style scoped lang="scss">
.DCard {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: auto;

  &__main {
    position: relative;
    background-color: #fff;
    border-color: #fff;
    margin: 0 auto;
    padding: 24px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.35);
    max-height: 100%;
    overflow: scroll;
  }
}
</style>
