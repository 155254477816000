<template>
  <div class="d-admin-permissions">
    <div class="tab-title-row center-horizontally">
      <div class="text-no-wrap content-title text-style text-truncate">
        {{ $t("all.defaultInterfaceLanguage") }}
      </div>
      <v-spacer></v-spacer>
    </div>

    <div class="table-div">
      <d-data-table
        add-search
        local-search
        initial-sort
        :headers="tableHeader"
        :items="myWorkspaces"
        :loading="isLoading"
        :placeholder="$t('admin.searchForWorkspaces')"
        searchAutotest="workspace-search-bar"
      >
        <v-progress-linear slot="progress" color="dBlue" height="2" indeterminate></v-progress-linear>
        <template slot="headerCell" slot-scope="props">
          <span>{{ props.header.text }}</span>
        </template>
        <template slot="items" slot-scope="props">
          <td class="text-xs-left name-width">
            <span class="name-text" autotest="workspace-name-display">{{ props.item.name }}</span>
          </td>
          <td class="text-xs-left">
            <v-menu transition="slide-y-transition" offset-y autotest="workspace-language-dropdown">
              <button slot="activator" class="role-btn text-capitalize btn-hover">
                {{ getUILocaleName(props.item.locale) }}
                <v-icon small class="dropdown-arrow">keyboard_arrow_down</v-icon>
              </button>
              <v-list dense>
                <div v-for="locale in languageList" :key="locale">
                  <v-list-tile
                    v-if="props.item.locale !== locale"
                    class="dropdown-list-item body-1 text-capitalize"
                    @click="setEditedWorkspace(props.item, locale)"
                  >
                    {{ getUILocaleName(locale) }}
                  </v-list-tile>
                </div>
              </v-list>
            </v-menu>
          </td>
        </template>
        <template slot="no-data">
          <span>{{ $t("admin.msgNoWorkspacesYet") }}</span>
        </template>
        <template slot="no-results">
          <span>{{ $t("analytics.msgNoSearchResults") }}</span>
        </template>
      </d-data-table>
    </div>

    <d-confirm-popup
      v-model="showChangeLanguage"
      :title="$t('admin.changeLanguage')"
      :cancelBtn="$t('all.cancel')"
      :actionBtn="$t('admin.YesChangeBtn')"
      :actionBtnColor="'#4689F3'"
      @click-action-btn="changeDefaultLanguage"
    >
      <template slot="icon">
        <icon-base width="120" height="120" viewBox="0 0 96 120">
          <d-icon-mapping />
        </icon-base>
      </template>
      <template slot="content">
        <span v-html="$t('admin.popupMsgChangeLanguage', { name: editedWorkspace.name })"></span>
      </template>
    </d-confirm-popup>
  </div>
</template>

<script>
import MixinUser from "@/components/MixinUser.vue";
import MixinDB from "@/components/MixinDB.vue";
import MixinAdmin from "@/components/MixinAdmin.vue";
import IconBase from "@/components/IconBase.vue";
import DIconDone from "@/components/icons/DIconDone.vue";
import DIconTrash from "@/components/icons/DIconTrash.vue";
import DIconPen from "@/components/icons/DIconPen.vue";
import DIconMapping from "@/components/icons/colored/DIconMapping.vue";
import DConfirmPopup from "@/components/DPopup/DConfirmPopup.vue";
import DConfirmationPopup from "@/components/DPopup/DConfirmationPopup.vue";
import AdminSubCategory from "@/components/DAdmin/AdminSubCategory.vue";
import DIconWorkspaces from "../icons/DIconWorkspaces.vue";
import DDataTable from "@/components/ui_components/DDataTable.vue";
import authChoice from "@/../auth";
import { loggingError } from "@/server/error-log-server.js";
import { mapMutations, mapActions } from "vuex";
import { getUILocaleName, MIGRATE_TO_CURRENT_UI_LOCALE_MAP, UI_LOCALES } from "@/lang/i18n";
export default {
  name: "AdminLanguages",
  props: {},
  watch: {},
  mixins: [MixinUser, MixinDB, MixinAdmin],
  mounted() {
    this.getWorkspaceList();
  },
  data() {
    return {
      languageList: UI_LOCALES,
      myWorkspaces: [],
      editedWorkspace: {},
      locale: "",
      showChangeLanguage: false,
      isLoading: false,
      tableHeader: [
        {
          text: this.$t("admin.workspace"),
          align: "left",
          sortable: true,
          value: "name",
          autotest: "workspace-sort",
        },
        {
          text: this.$t("all.interfaceLanguage"),
          align: "left",
          sortable: false,
          value: "language",
        },
      ],
    };
  },
  components: {
    IconBase,
    DIconDone,
    DIconTrash,
    DIconPen,
    DIconMapping,
    DConfirmPopup,
    DConfirmationPopup,
    AdminSubCategory,
    DIconWorkspaces,
    DDataTable,
  },
  methods: {
    ...mapMutations("global", ["MUTATE_ALERT_INFO"]),
    ...mapActions("workspace", ["patchWorkspace"]),
    getUILocaleName,
    async getWorkspaceList() {
      this.isLoading = true;
      try {
        this.myWorkspaces = await this.getMyWorkspaces();
        this.myWorkspaces.sort(function (a, b) {
          const groupA = a.name.toUpperCase();
          const groupB = b.name.toUpperCase();
          if (groupA < groupB) {
            return -1;
          }
          if (groupA > groupB) {
            return 1;
          }
          return 0;
        });
      } catch (error) {
        alert("Something went wrong when getting workspace list.");
        loggingError(error);
      } finally {
        this.isLoading = false;
      }
    },
    getMyWorkspaces() {
      if (this.$role == "admin-organization") {
        return this.getDocumentByFieldValue("groups", "organization", this.$organization);
      }
      /**
       * $userProfile.roles is an object with workspaceId as key and role as value.
       * Example: {"foo": "publisher", "bar": "viewer", "baz": "admin-organization"}.
       */
      const notViewerWorkspaceIds = Object.keys(this.$userProfile.roles).filter(
        (workspaceId) => this.$userProfile.roles[workspaceId] !== "viewer"
      );
      return Promise.all(notViewerWorkspaceIds.map((workspaceId) => this.getDocument("groups", workspaceId)));
    },
    setEditedWorkspace(workspace, locale) {
      this.editedWorkspace = workspace;
      this.locale = MIGRATE_TO_CURRENT_UI_LOCALE_MAP[locale] ?? locale;
      this.showChangeLanguage = true;
    },
    async changeDefaultLanguage() {
      const { organization, workspaceGroupId, id, name } = this.editedWorkspace;
      const data = {
        idToken: await authChoice().currentUser.getIdToken(),
        organization,
        workspaceGroupId,
        groupId: id,
        name,
        locale: this.locale,
      };
      try {
        await this.patchWorkspace({ data });
        const matchedWorkspace = this.myWorkspaces.find((workspace) => workspace.id === this.editedWorkspace.id);
        if (!matchedWorkspace) return;
        matchedWorkspace.locale = this.locale;
        const message = this.$t("admin.alertLanguageSuccessfullyChanged");
        this.MUTATE_ALERT_INFO({ message, type: "success" });
      } catch (error) {
        const message = this.$t("admin.alertFailedChangeLanguage");
        this.MUTATE_ALERT_INFO({ message, type: "error" });
        loggingError(error);
      }
    },
  },
};
</script>

<style scoped>
.d-admin-permissions {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 32px;
  text-align: left;
  z-index: 0;
  flex-direction: column;
  display: flex;
}
.tab-title-row {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}
.center-horizontally {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.table-div {
  height: 100%;
  width: 100%;
  overflow: auto;
}
.table-div:hover::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
}
.text-style {
  font-family: Roboto;
  letter-spacing: 0;
}
.content-title {
  color: #3f3f3f;
  font-size: 32px;
  line-height: 32px;
}

.table-btn {
  color: #78767a;
}
.table-btn:hover {
  color: #2d2e32;
}
.name-width {
  width: 40%;
}
.name-text {
  color: #3f3f3f;
}
.role-btn {
  position: relative;
  background-color: white;
  height: 34px;
  width: 174px;
  text-align: left;
  padding: 0 8px;
  border: 1px solid #dadada;
}
.dropdown-arrow {
  position: absolute;
  right: 8px;
  color: #78767a;
  top: 8px;
}
.dropdown-list-item {
  color: #78767a;
}
.dropdown-list-item:hover {
  background-color: #f5f5f5 !important;
}
</style>
