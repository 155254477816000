<template>
  <svg :width="width" :height="height" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="19" height="19" rx="3.5" fill="#4689F3" />
    <rect x="0.5" y="0.5" width="19" height="19" rx="3.5" stroke="white" />
    <path
      d="M7.54299 15C7.62443 15 7.70136 14.9643 7.77376 14.8929L15.9186 6.85714C15.9729 6.80357 16 6.73214 16 6.64286C16 6.55357 15.9729 6.47321 15.9186 6.40179L15.5928 6.10714C15.5385 6.03571 15.4661 6 15.3756 6C15.2851 6 15.2127 6.03571 15.1584 6.10714L7.52941 13.6071L4.7813 10.8958C4.73705 10.8641 4.68477 10.8482 4.62443 10.8482C4.53394 10.8482 4.46154 10.8839 4.40724 10.9554L4.08145 11.25C4.02715 11.3214 4 11.4018 4 11.4911C4 11.5804 4.02715 11.6518 4.08145 11.7054L7.31222 14.8929C7.38462 14.9643 7.46154 15 7.54299 15Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "DIconCheckedBox",
  props: {
    width: {
      type: [String, Number],
      default: () => "20px",
    },
    height: {
      type: [String, Number],
      default: () => "20px",
    },
  },
};
</script>

<style scoped lang="scss"></style>
