import { SCREEN_TYPE } from "@/constants/quiz";
import { getUserQuizResult } from "@/server/user-quiz-server";
import { getWorkflowQuiz } from "@/server/workflow-server";
import { getWorkflowWithQuiz } from "@/server/api-server";

export const namespaced = true;

export const state = {
  isFetching: false,
  screenType: SCREEN_TYPE.CLOSE,
  headerInfo: {
    workflowId: "",
    skillName: "",
    workflowName: "",
    titleTranslations: null,
  },
  quizId: "",
  quizItems: [],
  quizResult: {
    correctAnswerCount: 0,
    status: "",
  },
  workflowsWithQuiz: [],
};

export const getters = {
  getter_is_quiz_screen_open(state) {
    return state.screenType !== SCREEN_TYPE.CLOSE;
  },
  getter_is_workflow_has_quiz: (state) => (workflowId) => {
    return state.workflowsWithQuiz.find((item) => item.workflowId === workflowId);
  },
};

export const mutations = {
  SET_IS_FETCHING(state, isFetching) {
    state.isFetching = isFetching;
  },
  SET_SCREEN_TYPE(state, screenType) {
    state.screenType = screenType;
  },
  SET_WORKFLOW_QUIZ(state, { item }) {
    const {
      id: quizId,
      questions,
      skillName,
      workflow: { id, title, titleTranslations },
    } = item;
    state.headerInfo = { workflowId: id, workflowName: title, titleTranslations, skillName };
    state.quizId = quizId;
    state.quizItems = questions;
  },
  SET_USER_QUIZ_RESULT(state, { item }) {
    const {
      correctAnswerCount,
      status,
      questions,
      skillName,
      workflow: { id, title, titleTranslations },
      quizId,
    } = item;
    state.headerInfo = { workflowId: id, workflowName: title, titleTranslations, skillName };
    state.quizId = quizId;
    state.quizItems = questions;
    state.quizResult = { correctAnswerCount, status };
  },
  SET_QUIZ_ID(state, payload) {
    state.quizId = payload;
  },
  RESET_ALL_QUIZ_STATE(state) {
    state.isFetching = false;
    state.screenType = SCREEN_TYPE.CLOSE;
    state.headerInfo = {
      workflowId: "",
      skillName: "",
      workflowName: "",
      titleTranslations: null,
    };
    state.quizId = "";
    state.quizItems = [];
    state.quizResult = {
      correctAnswerCount: 0,
      status: "",
    };
  },
  SET_WORKFLOW_WITH_QUIZ(state, payload) {
    state.workflowsWithQuiz = payload;
  },
};

export const actions = {
  closeQuizScreen({ commit }) {
    commit("RESET_ALL_QUIZ_STATE");
  },
  openQuizScreen({ dispatch }, { skillId, workflowId, userQuizId }) {
    const isReviewTheQuiz = !!userQuizId;
    if (isReviewTheQuiz) {
      dispatch("fetchUserQuizResult", { userQuizId, skillId });
      return;
    }
    dispatch("fetchWorkflowQuiz", { workflowId, skillId });
  },
  async fetchWorkflowQuiz({ commit }, payload) {
    commit("SET_IS_FETCHING", true);
    commit("SET_SCREEN_TYPE", SCREEN_TYPE.TAKE_QUIZ);
    const { ok, data } = await getWorkflowQuiz(payload);
    if (ok) {
      commit("SET_WORKFLOW_QUIZ", data);
    } else {
      commit("SET_QUIZ_ID", "");
    }
    commit("SET_IS_FETCHING", false);
  },
  async fetchUserQuizResult({ commit }, payload) {
    commit("SET_IS_FETCHING", true);
    commit("SET_SCREEN_TYPE", SCREEN_TYPE.REVIEW_QUIZ);
    const { ok, data } = await getUserQuizResult(payload);
    if (ok) {
      commit("SET_USER_QUIZ_RESULT", data);
    } else {
      commit("SET_QUIZ_ID", "");
    }
    commit("SET_IS_FETCHING", false);
  },
  async getWorkflowWithQuiz({ commit }) {
    const { ok, data } = await getWorkflowWithQuiz();
    if (!ok) return;
    commit("SET_WORKFLOW_WITH_QUIZ", data.item);
  },
};
