<template>
  <div class="DShareScormDownload">
    <v-menu
      transition="slide-y-transition"
      bottom
      left
      offset-y
      open-on-click
      :class="{ DShareScormDownload__menu: true }"
    >
      <button slot="activator" class="DShareScormDownload__button">
        <span v-if="!selectedResolution" class="DShareScormDownload__buttonLabel">{{
          $t("builder.selectDownloadWorkflowQuality")
        }}</span>
        <span v-else class="DShareScormDownload__buttonLabel">{{ `${selectedResolution}P` }}</span>
        <v-spacer></v-spacer>
        <v-icon small color="#8D909F" class="sort-by-arrow">keyboard_arrow_down</v-icon>
      </button>
      <v-list dark dense>
        <v-list-tile
          v-for="option in SCORMResolutionOptions"
          :key="option"
          class="DShareScormDownload__list"
          :class="{ 'DShareScormDownload__list--active': selectedResolution === option }"
          @click="onChangeResolution(option)"
        >
          <v-list-tile-title class="body-1">
            {{ `${option}P` }}
          </v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    selectedResolution: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      SCORMResolutionOptions: [720, 1080],
    };
  },
  methods: {
    onChangeResolution(value = 0) {
      this.$emit("change-resolution", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.DShareScormDownload {
  margin-top: 40px;
  &__menu {
    margin-bottom: 24px;
    width: 100%;
  }
  &__button {
    box-sizing: border-box;
    height: 34px;
    width: 100%;
    border: 1px solid #323338;
    background-color: #17181a;
    display: flex;
    padding: 7px 12px;
  }
  &__buttonLabel {
    opacity: 0.5;
    color: #ffffff;
    font-family: Roboto;
    font-size: 12px;
    line-height: 18px;
  }
  &__list {
    &:hover {
      background-color: #52545d !important;
    }
    &--active {
      color: #4689f3;
    }
  }
}
</style>
