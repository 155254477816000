<template>
  <div class="PlayerSummary" v-if="isSummaryReady">
    <d-layout column>
      <d-layout column class="PlayerSummary__Highlight">
        <d-layout row v-for="highlight in highlights" :key="highlight.key" class="PlayerSummary__HighlightItem">
          <font-awesome-icon :class="`icon--${highlight.iconColor}`" :icon="highlight.icon"></font-awesome-icon>
          <d-layout row class="PlayerSummary__HighlightItem--content-wrap">
            <div class="PlayerSummary__HighlightItem--title">{{ highlight.title }}</div>
            <div class="PlayerSummary__HighlightItem--content">{{ highlight.content }}</div>
          </d-layout>
        </d-layout>
      </d-layout>
      <d-layout column class="PlayerSummary__Summary" v-if="isCollapse">
        <d-layout column v-for="summary in summaries" :key="summary.title" class="PlayerSummary__SummaryItem">
          <div class="PlayerSummary__SummaryItem--title">{{ summary.title }}</div>
          <d-layout column class="PlayerSummary__SummaryItem--content">
            <div v-for="content in summary.contents" :key="content">{{ content }}</div>
          </d-layout>
        </d-layout>
      </d-layout>
      <d-button
        v-if="canCollapse && this.highlights.length > 0"
        class="PlayerSummary__ShowMoreBtn"
        outline
        @click="toggleShowMore"
      >
        <d-layout row class="PlayerSummary__ShowMoreBtn--content">
          {{ isShowMore ? $t("all.showLess") : $t("all.showMore") }}
          <font-awesome-icon v-if="!isShowMore" :icon="['fas', 'chevron-down']"></font-awesome-icon>
          <font-awesome-icon v-else :icon="['fas', 'chevron-up']"></font-awesome-icon>
        </d-layout>
      </d-button>
    </d-layout>
  </div>
</template>

<script>
import { fetchWorkflowAISummary } from "@/server/api-server";
const HIGHLIGHT_KEY = {
  WARNING: "safety/warning",
  TOOL: "tools",
  NOTE: "important note",
};
const SUMMARY_STATUS = {
  COMPLETE: "completed",
  PENDING: "pending",
  PROCESSING: "processing",
  FAILED: "failure",
};

export default {
  name: "PlayerSummary",
  components: {},
  props: {
    workflowId: {
      type: String,
      required: true,
    },
    canCollapse: {
      type: Boolean,
      default: false,
    },
    summary: {
      type: Object || Null,
      default: () => null,
    },
    workflowLanguage: {
      type: String,
      default: "en-us",
    },
  },
  data() {
    return {
      isShowMore: false,
      isSummaryReady: false,
      highlights: [
        // {
        //   key: string,
        //   title: string,
        //   content: string,
        //   icon: string[],
        //   iconColor: string,
        // },
      ],
      summaries: [
        // {
        //   title: string,
        //   contents: string[],
        // }
      ],
      highlightKey: {
        [HIGHLIGHT_KEY.WARNING]: {
          order: 1,
          icon: ["fas", "triangle-exclamation"],
          iconColor: "warning",
        },
        [HIGHLIGHT_KEY.TOOL]: {
          order: 2,
          icon: ["fas", "screwdriver-wrench"],
          iconColor: "primary",
        },
        [HIGHLIGHT_KEY.NOTE]: {
          order: 3,
          icon: ["fas", "quote-left"],
          iconColor: "primary",
        },
      },
    };
  },
  computed: {
    isCollapse() {
      return (
        !this.canCollapse ||
        (this.canCollapse && this.isShowMore) ||
        (this.isSummaryReady && this.highlights.length === 0) // no highlights
      );
    },
  },
  methods: {
    toggleShowMore() {
      this.isShowMore = !this.isShowMore;
    },

    formatAISummary(highlights, contents) {
      const getHighlightTitle = (key, lang, highlight) => {
        const title = highlight.translations[lang];
        if (key === HIGHLIGHT_KEY.TOOL) {
          const toolCnt = highlight.content[lang].join(" ").split(",").length;
          return `${title} (${toolCnt}) :`;
        }
        return `${title} :`;
      };

      const formattedHighlight = highlights
        .map((item) => {
          const key = item.key;
          const availableLanguage = Object.keys(item.translations);
          const lang = availableLanguage.includes(this.workflowLanguage) ? this.workflowLanguage : "en-us";
          return {
            key,
            title: getHighlightTitle(key, lang, item),
            content: item.content[lang].join(" "), // item.content is string[]
            ...this.highlightKey[key],
          };
        })
        .sort((a, b) => a.order - b.order);

      const formattedContent = contents.map((item) => {
        const availableLanguage = Object.keys(item.translations);
        const lang = availableLanguage.includes(this.workflowLanguage) ? this.workflowLanguage : "en-us";
        return {
          title: item.translations[lang],
          contents: item.content[lang],
        };
      });

      return { formattedHighlight, formattedContent };
    },
    async fetchAISummary() {
      const params = { workflowId: this.workflowId };
      try {
        this.isSummaryReady = false;
        const { ok, data } = await fetchWorkflowAISummary(params);
        if (!ok || !data.success || data.item.status !== SUMMARY_STATUS.COMPLETE) return;
        this.setSummary(data.item.content);
      } catch (error) {
        console.error({ error });
      }
    },
    setSummary(content) {
      const { highlights, contents } = content;
      const { formattedHighlight, formattedContent } = this.formatAISummary(highlights, contents);
      this.highlights = formattedHighlight;
      this.summaries = formattedContent;
      this.isSummaryReady = true;
    },
  },
  mounted() {},
  created() {
    if (this.summary) {
      this.setSummary(this.summary);
      return;
    }
    this.fetchAISummary();
  },
};
</script>

<style lang="scss" scoped>
.icon--warning {
  margin-top: 4px;
  color: var(--warning-color);
}
.icon--primary {
  margin-top: 4px;
  color: var(--primary-color);
}

.PlayerSummary__Highlight {
  row-gap: var(--spacing-05);
  &Item {
    align-self: flex-start;
    align-items: flex-start;
    border-radius: var(--spacing-03);
    border: 1px solid var(--dGrey3-color);
    background: var(--dGrey2-color);
    color: var(--brand-white);
    padding: var(--spacing-02) var(--spacing-05);
    text-align: left;
    &--content-wrap {
      display: inline;
      margin-left: var(--spacing-03);
    }
    &--title {
      display: inline-flex;
      flex: 0 0 auto;
      font-size: 14px;
      font-weight: 500;
      line-height: 160%;
    }
    &--content {
      display: inline;
      flex: 1;
      margin-left: var(--spacing-03);
    }
  }
}
.PlayerSummary__Summary {
  margin-top: 26px;
  align-items: flex-start;
  row-gap: var(--spacing-05);

  &Item {
    font-size: 16px;
    line-height: 140%;
    text-align: left;
    row-gap: var(--spacing-05);
    &--title {
      color: var(--brand-white);
      font-weight: 700;
    }
    &--content {
      row-gap: var(--spacing-03);
      color: var(--dGrey6-color);
    }
  }
}

.PlayerSummary__ShowMoreBtn {
  display: inline-flex;
  align-self: flex-start;
  margin: 0px;
  margin-top: var(--spacing-07);
  border-radius: 8px;
  &--content {
    align-items: center;
    column-gap: var(--spacing-03);
  }
}
</style>
