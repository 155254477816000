<template>
  <div class="AttachmentDiagramCard">
    <d-image class="attachments__diagram--img" :aspect-ratio="16 / 9" :src="imgURL" contain></d-image>
  </div>
</template>

<script>
import DImage from "@/components/ui_components/DImage.vue";

export default {
  name: "AttachmentDiagramCard",
  components: {
    DImage,
  },
  props: {
    imgURL: String,
  },
};
</script>

<style lang="scss" scoped>
.AttachmentDiagramCard {
  width: 100%;
  border-radius: 4px;
  background-color: #28292e;
  overflow: hidden;
  cursor: pointer;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
}
</style>
