<template>
  <div class="CommentReactions">
    <comment-react-add-btn
      :commentId="comment.id"
      :isMobileDevice="isMobileDevice"
      @add-reaction="clickReactionFromPicker($event)"
      class="CommentReactions__addBtn"
    />
    <template v-if="comment.reactions && Object.keys(comment.reactions).length > 0">
      <div v-for="(users, keyName) in comment.reactions" :key="comment.id + keyName" class="CommentReactions__btn">
        <comment-react-btn
          :users="users"
          :keyName="keyName"
          :isMobileDevice="isMobileDevice"
          @add-reaction="addReaction($event)"
          @remove-reaction="removeReaction($event)"
          @open-reactions-popup="showPopup = !showPopup"
        />
      </div>
    </template>
    <CommentReactionsPopup v-if="showPopup" :reactions="comment.reactions" @close-popup="showPopup = false" />
  </div>
</template>

<script>
import CommentReactAddBtn from "@/components/DWorkflowPlayer/comments/CommentReactAddBtn.vue";
import CommentReactBtn from "@/components/DWorkflowPlayer/comments/CommentReactBtn.vue";
import CommentReactionsPopup from "@/components/DWorkflowPlayer/comments/CommentReactionsPopup.vue";
import { fetchCommentReactions, createCommentReaction, deleteCommentReaction } from "@/server/api-server.js";
import { mapActions } from "vuex";

export default {
  name: "CommentReactions",
  components: { CommentReactAddBtn, CommentReactBtn, CommentReactionsPopup },
  props: {
    comment: {
      type: Object,
      required: true,
    },
    isMobileDevice: Boolean,
  },
  data() {
    return {
      showPopup: false,
    };
  },
  methods: {
    ...mapActions("workflowComments", ["updateReactionsInCommentsData", "deleteReactionInCommentsData"]),
    async fetchReactions() {
      const { ok, data, errorMessage } = await fetchCommentReactions({ commentId: this.comment.id });
      if (ok) {
        const newReactions = data.item || {};
        this.updateReactionsInCommentsData({ updatedCommentId: this.comment.id, newReactions });
      } else {
        console.error(errorMessage);
      }
    },
    async removeReaction({ reactionId, emojiCode }) {
      const commentId = this.comment.id;
      const params = {
        commentId,
        reactionId,
      };
      const { ok, data, errorMessage } = await deleteCommentReaction(params);
      if (ok) {
        this.deleteReactionInCommentsData({ updatedCommentId: this.comment.id, reactionId, emojiCode });
      } else {
        console.error(errorMessage);
      }
    },
    async addReaction({ emojiChar, emojiCode }) {
      const commentId = this.comment.id;
      const params = {
        commentId,
        emojiChar,
        emojiCode,
      };
      const { ok, data, errorMessage } = await createCommentReaction(params);
      if (ok) {
        const currentDate = new Date();
        const timestamp = currentDate.toISOString();
        const newReaction = {
          id: data.item.id,
          uid: this.$user.uid,
          displayName: this.$user.displayName,
          email: this.$user.email,
          emojiChar,
          createdAt: timestamp,
        };
        let newReactions = this.comment.reactions;
        if (newReactions[emojiCode]) {
          newReactions[emojiCode].push(newReaction);
        } else {
          newReactions[emojiCode] = [newReaction];
        }
        this.updateReactionsInCommentsData({ updatedCommentId: this.comment.id, newReactions });
      } else {
        console.error(errorMessage);
      }
    },
    clickReactionFromPicker({ emojiChar, emojiCode }) {
      const currentUserId = this.$user.uid;
      const users = this.comment.reactions[emojiCode] || [];
      const index = users
        .map((user) => {
          return user.uid;
        })
        .indexOf(currentUserId);
      if (index === -1) {
        this.addReaction({ emojiChar, emojiCode });
      } else {
        const reactionId = users[index].id;
        this.removeReaction({ reactionId, emojiCode });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.CommentReactions {
  display: flex;
  flex-wrap: wrap;
  &__addBtn {
    margin: 0 6px 4px 2px;
  }
  &__btn {
    margin: 0 2px 4px 2px;
  }
}
</style>
