<template>
  <v-menu transition="slide-y-transition" bottom left offset-y open-on-hover>
    <button slot="activator" :autotest="autotest">
      <span class="text-no-wrap filter-text">
        {{ $t("admin.status") }}:
        <span style="color: #3f3f3f; width: 50%">
          {{ statusDictionary[currentStatus] }}
          <span class="text-lowercase">{{ $t("all.users") }}</span>
        </span>
        <v-icon color="#8D909F" class="sort-by-arrow">keyboard_arrow_down</v-icon>
      </span>
    </button>
    <v-list dense light>
      <v-list-tile v-for="status in filterStatus" :key="status" @click="changeCurrentStatus(status)">
        <v-list-tile-title class="body-1" :class="{ 'current-list-item': currentStatus == status }">
          {{ statusDictionary[status] }}
          <span class="text-lowercase">{{ $t("all.users") }}</span>
        </v-list-tile-title>
      </v-list-tile>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "UserStatusFilter",
  props: {
    autotest: String,
    filterStatus: Array,
    currentStatus: String,
    statusDictionary: Object,
  },
  watch: {},
  created() {},
  mounted() {},
  data() {
    return {
      status: this.currentStatus,
    };
  },
  components: {},
  methods: {
    changeCurrentStatus(status) {
      this.status = status;
      this.$emit("changeCurrentStatus", this.status);
    },
  },
  computed: {},
};
</script>

<style scoped>
.sort-by-arrow {
  position: relative;
  margin-bottom: -4px;
}
.filter-text {
  color: #8d909f;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: right;
}
.current-list-item {
  color: #4689f3;
}
</style>
