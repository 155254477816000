<template>
  <div class="CommentReactionPicker" :style="pickerStyle" :class="{ CommentReactionPicker__mobile: isMobileDevice }">
    <div class="CommentReactionPicker__pickerWrapper" :class="{ CommentReactionPicker__pickerMobile: isMobileDevice }">
      <Picker
        v-if="emojiData !== null"
        :data="emojiData"
        color="#4689F3"
        :showSearch="false"
        @select="selectEmoji"
        :i18n="i18n"
        set="apple"
        class="CommentReactionPicker__picker"
      />
    </div>
    <div class="CommentReactionPicker__close" @click="$emit('close-picker')"></div>
  </div>
</template>

<script>
import { EmojiIndex, Picker } from "emoji-mart-vue-fast";
import data from "emoji-mart-vue-fast/data/all.json";
import "emoji-mart-vue-fast/css/emoji-mart.css";

export default {
  name: "CommentReactionPicker",
  components: { Picker },
  props: {
    pickerPosition: {
      type: Object,
      require: true,
    },
    isMobileDevice: Boolean,
  },
  created() {
    this.emojiData = new EmojiIndex(data);
  },
  data() {
    return {
      emojiData: null,
      i18n: {
        // search is not added to current design
        search: "Search",
        notfound: "No Emoji Found",
        categories: {
          search: "Search Results",
          recent: this.$t("player.emojiRecent"),
          smileys: this.$t("player.emojiSmileys"),
          people: this.$t("player.emojiPeople"),
          nature: this.$t("player.emojiNature"),
          foods: this.$t("player.emojiFoods"),
          activity: this.$t("player.emojiActivity"),
          places: this.$t("player.emojiActivity"),
          objects: this.$t("player.emojiPlaces"),
          symbols: this.$t("player.emojiSymbols"),
          flags: this.$t("player.emojiFlags"),
          custom: this.$t("player.emojiCustom"),
        },
      },
    };
  },
  methods: {
    selectEmoji(emoji) {
      const emojiChar = emoji.native;
      const emojiCode = emoji.unified;
      this.$emit("add-reaction", { emojiChar, emojiCode });
      this.$emit("close-picker");
    },
  },
  computed: {
    pickerStyle() {
      if (this.isMobileDevice) return null;
      if (!this.pickerPosition || Object.keys(this.pickerPosition).length === 0) return null;
      return {
        top: this.pickerPosition.top + "px",
        left: this.pickerPosition.left + "px",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.CommentReactionPicker {
  z-index: 1;
  position: fixed;
  width: 346px;
  height: 308px;
  border-radius: 4px;
  background: #28292e;
  box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.25);
  &__mobile {
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
  &__pickerWrapper {
    position: relative;
    height: 100%;
    width: 100%;
  }
  &__pickerMobile {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 50%;
    width: 100%;
    padding: 20px 20px 0 20px;
    display: flex;
    justify-content: center;
    background: #28292e;
    border-radius: 16px 16px 0px 0px;
  }
  &__picker {
    position: relative;
    z-index: 1;
  }
  &__close {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .emoji-mart {
    position: relative;
    color: transparent;
    padding: 8px 0 0 0;
    border: none;
    height: 100%;
    width: 100%;
    max-width: 100%;
    background-color: #28292e;
  }
  .emoji-mart ::v-deep .emoji-mart-bar {
    border: none;
  }
  .emoji-mart ::v-deep .emoji-mart-scroll {
    background-color: #28292e;
    position: absolute;
    height: calc(100% - 72px);
    width: 100%;
    top: 54px;
    text-align: left;
  }

  .emoji-mart ::v-deep {
    .emoji-mart-category-label {
      background-color: #28292e;
      pointer-events: none !important;
      padding-top: 6px;
      font-size: 12px;
      text-align: left;
      font-weight: 600;
      line-height: 20px;
      text-transform: uppercase;
      color: #9397a6;
    }
  }
  .emoji-mart ::v-deep {
    .emoji-mart-emoji span {
      cursor: pointer;
    }
    .emoji-mart-category button {
      &:before {
        content: "";
        opacity: 0.1;
      }
    }
  }
}
</style>
