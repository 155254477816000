<template>
  <div class="DVideoWatermark">
    <div v-if="!logoSrc" class="DVideoWatermark__powered-by-deephow">
      <powered-by-deephow-white width="100%" />
    </div>
    <div v-else class="DVideoWatermark__container">
      <img
        class="DVideoWatermark__logo"
        :class="{ 'DVideoWatermark__logo--vertical': fillHeightLogo }"
        :src="logoSrc"
        alt="logo"
      />
      <div class="DVideoWatermark__powered-by">
        <powered-by-deephow width="100%" />
      </div>
    </div>
  </div>
</template>
<script>
import PoweredByDeephow from "@/components/icons/logo/PoweredByDeephow";
import PoweredByDeephowWhite from "@/components/icons/logo/PoweredByDeephowWhite";

export default {
  name: "DVideoWatermarkTemplate",
  props: {
    logoSrc: String,
    fillHeightLogo: Boolean,
  },
  components: {
    PoweredByDeephow,
    PoweredByDeephowWhite,
  },
};
</script>

<style scoped lang="scss">
$width: 200px;
$height: 120px;
$logoMaxHeight: 74px;
$logoMaxWidth: 180px;
.DVideoWatermark {
  position: relative;
  width: $width;
  height: $height;
  &__container {
    position: relative;
    width: 100%;
    padding: 10px 10px 36px 10px;
    max-height: 100%;
    background: white;
  }
  &__logo {
    position: relative;
    height: auto;
    max-height: 74px;
    width: $logoMaxWidth;
    object-fit: contain;
    &--vertical {
      display: flex;
      width: auto;
      height: $logoMaxHeight;
      margin: 0 auto;
    }
  }
  &__powered-by {
    position: absolute;
    width: 100%;
    height: 36px;
    left: 0;
    bottom: 0;
    padding: 0 10px 0 10px;
    background: white;
    overflow: hidden;
  }
  &__powered-by-deephow {
    width: 100%;
    padding: 0 10px 0 10px;
  }
}
</style>
