<template>
  <button
    ref="reactBtn"
    class="CommentReactBtn"
    :class="{ CommentReactBtn__selected: currentUserSelected }"
    @touchstart.prevent="ontouchstart()"
    @touchend.prevent="ontouchend()"
    @click="onclickReactBtn()"
    @mouseenter="toggleToShowTooltip()"
    @mouseleave="(showTooltip = false), (addTooltip = false)"
  >
    <div class="CommentReactBtn__emoji">
      <emoji :data="index" set="apple" :emoji="emojiChar" :size="16" />
    </div>
    <div class="CommentReactBtn__num">{{ getReactNum(users.length) }}</div>
    <div
      ref="namesTooltip"
      v-if="addTooltip"
      class="CommentReactBtn__tooltip"
      :style="tooltipStyle"
      :class="{ 'CommentReactBtn__tooltip--show': showTooltip }"
    >
      <emoji :data="index" set="apple" :emoji="emojiChar" :size="24" class="CommentReactBtn__tooltip--emoji" />
      <div v-text="displayUserNames"></div>
      <div class="CommentReactBtn__tooltip--arrow"></div>
    </div>
  </button>
</template>

<script>
import data from "emoji-mart-vue-fast/data/all.json";
import { Emoji, EmojiIndex } from "emoji-mart-vue-fast";
import "emoji-mart-vue-fast/css/emoji-mart.css";
import { debounce } from "lodash-es";

export default {
  name: "CommentReactBtn",
  components: { Emoji },
  props: {
    keyName: String,
    users: {
      type: Array,
      default: () => [],
    },
    isMobileDevice: Boolean,
  },
  created() {
    this.index = new EmojiIndex(data);
    this.emojiChar = this.users.length > 0 ? this.users[0].emojiChar : "";
  },
  data() {
    return {
      index: null,
      emojiChar: "",
      addTooltip: false,
      showTooltip: false,
      tooltip: {
        top: null,
        left: null,
      },
      startTime: null,
      endTime: null,
    };
  },
  methods: {
    getReactNum(length) {
      if (length < 10) {
        return `0${length}`;
      }
      return length;
    },
    ontouchstart() {
      this.startTime = new Date().getTime();
    },
    ontouchend() {
      this.endTime = new Date().getTime();
      const duration = this.endTime - this.startTime;
      if (duration < 500) {
        //Short press
        this.debounceChangeSelectedReaction();
      } else {
        // Long press
        this.$emit("open-reactions-popup");
      }
    },
    onclickReactBtn() {
      if (this.isMobileDevice) return;
      this.debounceChangeSelectedReaction();
    },
    debounceChangeSelectedReaction: debounce(function () {
      this.changeSelectedReaction();
    }, 300),
    changeSelectedReaction() {
      if (this.currentUserSelected) {
        const reactionId = this.users[this.currentUserIndex].id;
        const emojiCode = this.keyName;
        this.$emit("remove-reaction", { reactionId, emojiCode });
      } else {
        const emojiChar = this.emojiChar;
        const emojiCode = this.keyName;
        this.$emit("add-reaction", { emojiChar, emojiCode });
      }
    },
    toggleToShowTooltip() {
      this.addTooltip = true;
      this.$nextTick(() => {
        this.setTooltipPosition();
        setTimeout(() => {
          this.showTooltip = true;
        }, 200);
      });
    },
    setTooltipPosition() {
      const tooltipEle = this.$refs.namesTooltip;
      const btn = this.$refs.reactBtn;
      if (tooltipEle && btn) {
        const btnPosition = btn.getBoundingClientRect();
        this.tooltip.top = btnPosition.top - tooltipEle.clientHeight - 12;
        this.tooltip.left = btnPosition.left - tooltipEle.clientWidth / 2 + btn.clientWidth / 2;
      }
    },
  },
  computed: {
    tooltipStyle() {
      return {
        top: this.tooltip.top + "px",
        left: this.tooltip.left + "px",
      };
    },
    displayUserNames() {
      let text = "";
      if (this.users.length === 0) return text;
      const lastIndex = this.users.length - 1;
      this.users.forEach((user, index) => {
        if (index === lastIndex) {
          text += user.displayName;
        } else {
          text += user.displayName + ", ";
        }
      });
      return text;
    },
    currentUserSelected() {
      return this.currentUserIndex !== -1;
    },
    currentUserIndex() {
      const currentUserId = this.$user.uid;
      const index = this.users
        .map((user) => {
          return user.uid;
        })
        .indexOf(currentUserId);
      return index;
    },
  },
};
</script>

<style lang="scss" scoped>
.CommentReactBtn {
  position: relative;
  display: flex;
  height: 24px;
  padding: 0 6px 0 1px;
  line-height: 24px;
  background: hsl(0, 0%, 7%);
  border: 1px solid #303030;
  border-radius: 12px;
  overflow: hidden;
  &__selected {
    border: 1px solid #4689f3;
  }
  &__emoji {
    position: relative;
    top: -2.5px;
    width: 27px;
  }
  &__num {
    font-size: 12px;
  }
  &__tooltip {
    z-index: 1;
    display: inline-block;
    position: fixed;
    top: 100px;
    left: 100px;
    max-width: 300px;
    padding: 8px 16px 12px 16px;
    border-radius: 4px;
    background: #28292e;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    border: 1px solid #3d3f45;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    opacity: 0;
    &--show {
      opacity: 1;
      transition: 0.3s;
    }
    &--emoji {
      display: inline-block;
      height: 32px;
    }
    &--arrow {
      z-index: -1;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
    }
    &--arrow:before {
      position: absolute;
      bottom: -16px;
      left: calc(50% - 6px);
      content: "";
      width: 0;
      height: 0;
      overflow: hidden;
      display: block;
      border-width: 8px;
      border-color: #3d3f45 transparent transparent transparent;
      border-style: solid;
      pointer-events: none;
    }
    &--arrow:after {
      position: absolute;
      bottom: -15px;
      left: calc(50% - 6px);
      content: "";
      width: 0;
      height: 0;
      display: block;
      border-width: 8px;
      border-color: #28292e transparent transparent transparent;
      border-style: solid;
      pointer-events: none;
    }
  }
}
</style>
