<template>
  <div class="d-data-table">
    <slot v-if="addSearch" name="search">
      <!-- fallback content -->
      <d-table-search
        v-if="localSearch"
        v-model="searchInput"
        :placeholder="placeholder || $t('analytics.searchUser')"
        :searchAutotest="searchAutotest"
      >
        <slot name="search-right"></slot>
      </d-table-search>
    </slot>
    <v-progress-linear
      v-if="loading"
      class="progress-linear"
      :class="{ 'progress-linear-search': addSearch }"
      indeterminate
      color="#4689f4"
      height="2"
    ></v-progress-linear>
    <div
      ref="scrollTable"
      class="d-table-div"
      :class="{
        'd-table-search': addSearch,
        'd-table-div--relative': relative,
        'd-table-div--small': small,
      }"
      @scroll="onScroll()"
    >
      <table ref="innerTable" class="d-table">
        <thead>
          <tr>
            <th v-for="(item, idx) in headers" :key="'header' + idx">
              <slot name="headers" :item="item">
                <div :style="{ 'text-align': item.align || 'left' }">
                  <!-- fallback content -->
                  <div class="header-text text-no-wrap" :class="{ 'center-text': item.align == 'center' }">
                    {{ item.text }}
                    <button
                      v-if="item.sortable"
                      class="sort-button"
                      :class="{ 'show-sort-button': item.value == sortBy }"
                      @click="sortTable(item.value)"
                      :autotest="item.autotest || ''"
                    >
                      <v-icon small v-if="item.value != sortBy">unfold_more</v-icon>
                      <v-icon small v-if="item.value == sortBy && sortAscending">expand_less</v-icon>
                      <v-icon small v-if="item.value == sortBy && !sortAscending">expand_more</v-icon>
                    </button>
                  </div>
                </div>
              </slot>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(item, index) in displayItems" :key="'item' + index">
            <slot name="items" :item="item" :index="index">
              <!-- fallback content -->
              <td v-for="(header, i) in headers" :key="'item' + header.value + i">
                <span v-if="header.value">{{ item[header.value] }}</span>
              </td>
            </slot>
          </tr>
        </tbody>
        <tfoot class="foot-div">
          <div v-if="!loading && displayItems.length == 0 && !noSearchResults" class="row-msg-div-loading">
            <slot name="no-data">{{ $t("all.noRecordFound") }}</slot>
          </div>
          <div v-if="loading" class="row-msg-div-loading">
            <slot name="loading-msg">
              <span>{{ $t("all.loadingRecords") }}</span>
            </slot>
          </div>
          <div v-if="noSearchResults" class="row-msg-div-loading">
            <slot name="no-results">
              <span>{{ $t("analytics.msgNoSearchResults") }}</span>
            </slot>
          </div>
          <div
            v-if="!loading && !noSearchResults && loadedAllData && displayItems.length > 0"
            class="row-msg-div-loading"
          >
            <span>{{ $t("all.loadedAllRecords") }}</span>
          </div>
          <div v-if="paddingBottom" class="padding-bottom" :class="{ 'show-user-card': showUserCard }"></div>
        </tfoot>
      </table>
    </div>
  </div>
</template>
<script>
import IconBase from "../IconBase.vue";
import DIconSearch from "../icons/DIconSearch.vue";
import DTableSearch from "../ui_components/DTableSearch.vue";
export default {
  name: "DDataTable",
  props: {
    loading: Boolean,
    headers: Array,
    items: Array,
    paddingBottom: Boolean,
    addSearch: Boolean,
    initialSort: Boolean,
    infiniteScroll: Boolean,
    localSearch: Boolean,
    noSearchResults: Boolean,
    loadedAllData: Boolean,
    showUserCard: Boolean,
    placeholder: String,
    searchAutotest: String,
    relative: Boolean,
    small: Boolean,
    // currentStatus: String,
    // search: String,
  },
  components: {
    IconBase,
    DIconSearch,
    DTableSearch,
  },
  watch: {
    loading: function () {
      if (!this.loading && this.initialSort) {
        this.$nextTick(() => (this.sortBy = this.headers[0].value));
      }
    },
    // items: function () {
    //   this.$nextTick(() => this.loadFullScreenItems());
    // },
  },
  mounted() {},
  data() {
    return {
      searchInput: "",
      sortBy: "",
      sortAscending: true,
      scrollTop: 0,
      maxScrollTop: 0,
    };
  },
  methods: {
    loadFullScreenItems() {
      if (
        this.$refs.scrollTable &&
        this.$refs.innerTable &&
        this.infiniteScroll &&
        !this.loadedAllData &&
        !this.loading
      ) {
        const innerTableHeight = this.$refs.innerTable.clientHeight;
        const scrollDivHeight = this.$refs.scrollTable.clientHeight;
        if (innerTableHeight <= scrollDivHeight) {
          this.$emit("load-more");
        }
      }
    },
    onScroll() {
      if (this.$refs.scrollTable && this.infiniteScroll && !this.loadedAllData && !this.loading) {
        this.scrollTop = this.$refs.scrollTable.scrollTop;
        this.maxScrollTop = this.$refs.scrollTable.scrollHeight - this.$refs.scrollTable.clientHeight - 1;
        if (this.scrollTop >= this.maxScrollTop) {
          this.$emit("load-more");
          this.maxScrollTop = this.$refs.scrollTable.scrollHeight - this.$refs.scrollTable.clientHeight;
        }
      }
    },
    sortTable(sortValue) {
      if (sortValue == this.sortBy) {
        this.sortAscending = !this.sortAscending;
      } else {
        this.sortBy = sortValue;
        this.sortAscending = true;
      }
    },
    updateSortedWorkflow() {
      this.sortedItems = this.items.sort(this.compare);
    },
    getItems(a, b) {
      let itemA, itemB;
      const sortArray = this.sortBy.split(".");
      for (let i = 0; i < sortArray.length; i++) {
        if (i == 0) {
          itemA = a && a[sortArray[i]] ? a[sortArray[i]] : "";
          itemB = b && b[sortArray[i]] ? b[sortArray[i]] : "";
        } else {
          itemA = itemA && itemA[sortArray[i]] ? itemA[sortArray[i]] : "";
          itemB = itemB && itemB[sortArray[i]] ? itemB[sortArray[i]] : "";
        }
      }
      const reIsNum = /^[0-9]+.?[0-9]*/; // is number or not
      const reIsDate = /(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/((19|20)\d\d)/;
      if (reIsDate.test(itemA) && reIsDate.test(itemB)) {
        return [new Date(itemA), new Date(itemB)];
      }
      if (!reIsNum.test(itemA) || !reIsNum.test(itemB)) {
        // for strings
        return [itemA.toString().toLowerCase(), itemB.toString().toLowerCase()];
      }
      return [itemA, itemB];
    },
    compare(a, b) {
      if (this.sortBy) {
        // accept value like a.b

        const [itemA, itemB] = this.getItems(a, b);
        if (itemA < itemB) {
          if (this.sortAscending) {
            return -1;
          } else {
            return 1;
          }
        } else {
          if (this.sortAscending) {
            return 1;
          } else {
            return -1;
          }
        }
      }
      // if (this.sortBy && a[this.sortBy] && b[this.sortBy]) {
      //   if (a[this.sortBy] < b[this.sortBy]) {
      //     if (this.sortAscending) {
      //       return -1;
      //     } else {
      //       return 1;
      //     }
      //   } else {
      //     if (this.sortAscending) {
      //       return 1;
      //     } else {
      //       return -1;
      //     }
      //   }
      // }
      return 0;
    },
  },
  computed: {
    displayItems() {
      if (!this.items) {
        return [];
      }
      return this.items
        .filter((item) => {
          if (this.localSearch) {
            for (const header of this.headers) {
              if (
                item[header.value] &&
                item[header.value].toString().toLowerCase().includes(this.searchInput.toLowerCase())
              ) {
                return true;
                break;
              }
            }
            return false;
          } else {
            return true;
          }
        })
        .sort(this.compare);
    },
  },
  beforeDestroy() {},
};
</script>

<style scoped lang="scss">
.d-data-table {
  position: relative;
  height: 100%;
  width: 100%;
  border: 1px solid var(--table-boder-color);
  background-color: #ffffff;
  box-shadow: 0 0 4px -2px rgba(0, 0, 0, 0.15);
}
.d-table-div {
  --table-padding-x: 24px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
  display: inline-block;
  text-align: left;
  border-top: 1px solid var(--table-boder-color);
  &--relative {
    border-top: 0;
    position: relative;
    overflow: unset;
  }
  &--small {
    --table-padding-x: 12px;
  }
}
.d-table-div::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.d-table-div:hover::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.d-table-div:hover::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
}
.d-table-search {
  top: 72px;
  left: 0;
  height: calc(100% - 72px);
}
.progress-linear {
  position: absolute;
  z-index: 2;
  top: 30px;
  left: 0;
  width: 100%;
}
.progress-linear-search {
  top: 100px;
}
.d-table {
  width: 100%;
  border-collapse: collapse !important;
}
.d-table > thead > tr > th {
  border-bottom: 1px solid var(--table-boder-color);
  padding: 0 var(--table-padding-x);
  background-color: var(--table-th-bg);
  color: var(--dLigthGrey400-color);
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;
  z-index: 1;
  height: 42px;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  font-weight: 400;
  text-transform: uppercase;
}
.header-text {
  position: relative;
  display: inline-block;
  height: 16px;
  padding-right: 18px;
}
.center-text {
  right: -9px;
}
.sort-button {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  overflow: hidden;
}
.header-text:hover .sort-button {
  opacity: 1;
}
.show-sort-button {
  opacity: 1;
}
.d-table > tbody > tr > td {
  border-top: 1px solid var(--table-boder-color);
  padding: 8px var(--table-padding-x);
  height: 46px;
  color: #3f3f3f;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
}

.d-table > tbody:last-of-type > tr:last-child > * {
  border-bottom: none;
}
.foot-div {
  position: relative;
  border-top: 1px solid var(--table-boder-color);
}
.padding-bottom {
  height: 120px;
  transition: 0.5s;
}
.show-user-card {
  height: 500px;
  transition: 0.5s;
}
.row-msg-div {
  position: absolute;
  width: 100%;
  display: block;
  top: 118px;
  z-index: 2;
  padding: 24px;
  color: #3f3f3f;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
}
.row-msg-div-loading {
  position: absolute;
  width: 100%;
  text-align: center;
  color: #3f3f3f;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
  padding: 24px;
}
.table-row-div {
  position: relative;
  width: 100%;
  text-align: center;
  padding: 24px;
  color: #3f3f3f;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
}
</style>
