<template>
  <div>
    <admin-setting-permission-item
      :allow="isEnabled"
      :title="title"
      :subtitle="description"
      :beta="beta"
      autotest="ai-assistance-permission-toggle"
      @click-switch="showConfirmation = true"
    />

    <d-change-permission-confirmation
      v-if="showConfirmation"
      :title="title"
      :content="isEnabled ? disableMessage : enableMessage"
      @close-popup="showConfirmation = false"
      @change-permission="handleChangePermission"
    ></d-change-permission-confirmation>
  </div>
</template>

<script>
import AdminSettingPermissionItem from "@/components/DAdmin/Settings/AdminSettingPermissionItem.vue";
import DChangePermissionConfirmation from "@/components/DAdmin/confirmations/DChangePermissionConfirmation.vue";

export default {
  name: "DAdminSettingSwitcher",
  components: {
    AdminSettingPermissionItem,
    DChangePermissionConfirmation,
  },
  props: {
    isEnabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    enableMessage: {
      type: String,
      required: true,
    },
    disableMessage: {
      type: String,
      required: true,
    },
    beta: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showConfirmation: false,
    };
  },
  methods: {
    handleChangePermission() {
      this.showConfirmation = false;
      this.$emit("changePermission", !this.isEnabled);
    },
  },
};
</script>
