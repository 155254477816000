<template>
  <button
    class="DButton"
    type="button"
    v-on="$listeners"
    @mousedown="handleMouseDown"
    ref="DButtonRef"
    :class="buttonClassName"
    :style="buttonStyle"
  >
    <div
      class="v-ripple"
      v-for="ripple in ripples"
      :style="ripple"
      :key="ripple.key"
      @animationend="handleAnimationEnd(ripple.key)"
    ></div>
    <div class="DButton__content">
      <slot></slot>
    </div>
  </button>
</template>
<script>
export default {
  name: "DButton",
  props: {
    round: Boolean,
    outline: Boolean,
    disabled: Boolean,
    color: {
      type: String,
      default: () => "#4689f4",
    },
    small: Boolean,
    icon: Boolean,
    block: Boolean,
    light: Boolean,
    dark: Boolean,
    depressed: Boolean,
  },
  data() {
    return {
      ripples: [],
    };
  },
  computed: {
    isCssColor() {
      return !!this.color && !!this.color.match(/^(#|(rgb|hsl)a?\()/);
    },
    buttonClassName() {
      return {
        "DButton--round": this.round,
        "DButton--outline DButton--depressed": this.outline,
        "DButton--depressed": this.depressed && !this.outline,
        "DButton--disabled": this.disabled,
        "DButton--small": this.small,
        "DButton--icon": this.icon,
        "DButton--block": this.block,
        "DButton--dark": this.dark,
        "DButton--light": this.light,
        ...(!this.isCssColor && { [this.color]: this.color }),
      };
    },
    buttonStyle() {
      if (!this.isCssColor) {
        return;
      }
      if (!this.outline && !this.disabled) {
        return {
          "background-color": this.color,
          "border-color": this.color,
        };
      }
      return {
        color: this.color,
        "caret-color": this.color,
      };
    },
  },
  methods: {
    handleAnimationEnd(key) {
      this.ripples = this.ripples.filter((ripple) => ripple.key !== key);
    },
    handleMouseDown(event) {
      const {
        offsetX: left,
        target: { clientWidth },
      } = event;
      this.ripples.push({
        "--left": left - clientWidth / 2 + "px",
        key: Math.random().toString(16).slice(2),
      });
    },
  },
};
</script>

<style lang="scss">
.DButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 6px 8px;
  height: 36px;
  border-radius: 2px;
  padding: 0 16px;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  position: relative;
  user-select: none;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), color 1ms;
  min-width: 88px;
  text-transform: uppercase;
  flex: 0 0 auto;
  outline: 0;

  vertical-align: middle;

  &:not(.DButton--depressed) {
    will-change: box-shadow;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }

  &:before {
    border-radius: inherit;
    color: inherit;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }

  &:hover {
    &:before {
      background-color: currentColor;
      opacity: 0.12;
    }
  }

  &__content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    color: inherit;
    white-space: nowrap;
    border-radius: inherit;
    flex: 1 0 auto;
    margin: 0 auto;
    width: inherit;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }

  &--round {
    /*
      css import should be removed when v-card component are replaced.
      this css as below will affect round props.
      .v-card>:last-child:not(.v-btn):not(.v-chip) {
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: inherit;
      }
    */
    border-radius: 28px !important;
  }

  &--outline {
    border: 1px solid;
    background: transparent !important;
    box-shadow: none;
  }

  &--small {
    font-size: 13px;
    height: 28px;
    padding: 0 8px;
  }

  &--icon {
    padding: 0;
    background: transparent;
    box-shadow: none !important;
    border-radius: 50%;
    justify-content: center;
    min-width: 0;
    width: 36px;
  }

  &--block {
    display: flex;
    flex: 1;
    margin: 6px 0;
    width: 100%;
  }

  .v-icon {
    // TODO This css should be remove when v-icon is replace by other component
    color: inherit;
  }
}

/** Disabled */
.DButton {
  &--disabled {
    box-shadow: none !important;
    pointer-events: none;
    color: hsla(0, 0%, 100%, 0.3) !important;

    &:not(.DButton--icon):not(.DButton--outline) {
      background-color: hsla(0, 0%, 100%, 0.12) !important;
    }
  }
}

/** Dark style */
.DButton {
  &.DButton--dark {
    color: #fff;
    &.DButton--disabled:not(.DButton--icon):not(.DButton--outline) {
      background-color: hsla(0, 0%, 100%, 0.12) !important;
    }

    &:not(.DButton--icon) {
      background-color: #212121;
    }
    &.DButton--disabled {
      color: hsla(0, 0%, 100%, 0.3) !important;
      .v-icon {
        color: hsla(0, 0%, 100%, 0.3) !important;
      }
    }
  }
}

/** Light style */
.DButton {
  &.DButton--light {
    &.DButton--disabled:not(.DButton--icon):not(.DButton--outline) {
      background-color: rgba(0, 0, 0, 0.12) !important;
      color: rgba(0, 0, 0, 0.26) !important;
    }
    &:not(.DButton--icon) {
      background-color: #f5f5f5;
    }

    &.DButton--disabled {
      color: rgba(0, 0, 0, 0.26) !important;
      .v-icon {
        color: rgba(0, 0, 0, 0.26) !important;
      }
    }
  }
}

/** Ripple feature */
.DButton {
  .v-ripple {
    --left: 0;
    border-radius: inherit;
    width: 100%;
    height: 100%;
    z-index: 0;
    contain: strict;
    color: inherit;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    pointer-events: none;

    &:before {
      border-radius: inherit;
      bottom: 0;
      content: "";
      position: absolute;
      opacity: 0.2;
      left: 0;
      right: 0;
      top: 0;
      transform-origin: center center;
      transition: inherit;
      background-color: currentColor;
      animation: scale 0.4s cubic-bezier(0.4, 0, 0.2, 1) forwards;
      height: 100%;
    }
  }
}

@keyframes scale {
  from {
    transform: translateX(var(--left)) scaleX(0.2);
  }
  to {
    transform: scaleX(1.4);
  }
}
</style>
