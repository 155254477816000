var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"PlayerStepCard",class:{
      'PlayerStepCard--done': _vm.isDone,
      'PlayerStepCard--current': _vm.isCurrentStep,
      'PlayerStepCard--sm': _vm.isSmallCard,
    }},[_c('div',{staticClass:"PlayerStepCard__poster"},[_c('d-image',{attrs:{"aspect-ratio":16 / 9,"src":_vm.step.poster}}),_vm._v(" "),_c('div',{staticClass:"PlayerStepCard__poster--progress",class:{
          'PlayerStepCard__poster--progressDark': _vm.isCurrentStep || _vm.isDone,
          'PlayerStepCard__poster--progressStroke': _vm.stepProgress > 0,
        }},[_c('div',[_c('v-progress-circular',{attrs:{"rotate":"-90","size":"32","width":"2","color":_vm.isDone ? '#9397A6' : '#4689F3',"value":_vm.stepProgress}},[_c('span',{staticClass:"PlayerStepCard__poster--stepNum"},[_vm._v(_vm._s(_vm.stepNum))])])],1)])],1),_vm._v(" "),_c('div',{staticClass:"PlayerStepCard__info"},[_c('div',{staticClass:"PlayerStepCard__info--title",class:{ 'PlayerStepCard__info--titleDone': _vm.isDone }},[_vm._v("\r\n        "+_vm._s(_vm.stepTitle)+"\r\n      ")]),_vm._v(" "),_c('div',{staticClass:"PlayerStepCard__info--bottom"},[_c('span',{staticClass:"PlayerStepCard__info--icon"},[(_vm.isCurrentStep)?_c('font-awesome-icon',{staticClass:"text-primary",attrs:{"icon":['fas', 'play']}}):(_vm.isDone)?_c('font-awesome-icon',{staticClass:"text-primary",attrs:{"icon":['fas', 'check']}}):_vm._e(),_vm._v("\r\n          "+_vm._s(_vm.videoDuration)+"\r\n        ")],1),_vm._v(" "),(_vm.attachmentNum > 0)?_c('button',{staticClass:"PlayerStepCard__info--icon",on:{"click":function($event){$event.stopPropagation();return _vm.openAttachments($event)}}},[_c('font-awesome-icon',{class:{ 'text-primary': _vm.isCurrentStep },attrs:{"icon":['fas', 'paperclip']}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.attachmentNum))])],1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }