<template>
  <div class="AttachmentPdfCard" @click="openNewTag">
    <d-icon-pdf-page class="AttachmentPdfCard__icon" />
    <div class="AttachmentPdfCard__text">
      <div v-if="item.name" class="AttachmentPdfCard__text--title">{{ item.name }}</div>
      <div>{{ fileSize }}</div>
    </div>
  </div>
</template>

<script>
import DIconPdfPage from "@/components/icons/colored/DIconPdfPage.vue";
import { formatBytes } from "@/js/common/formatText.js";

export default {
  name: "AttachmentPdfCard",
  props: {
    item: {
      type: Object,
      require: true,
    },
  },
  components: {
    DIconPdfPage,
  },
  methods: {
    openNewTag() {
      const win = window.open(this.item.link, "_blank");
      win.focus();
    },
  },
  computed: {
    fileSize() {
      return formatBytes({ bytes: this.item.size });
    },
  },
};
</script>

<style lang="scss" scoped>
.AttachmentPdfCard {
  width: 100%;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 4px;
  background-color: #28292e;
  display: flex;
  text-align: left;
  cursor: pointer;
  &__icon {
    min-height: 32px;
    min-width: 32px;
    margin-right: 16px;
  }
  &__text {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #919191;
    word-wrap: normal;
    word-break: break-all;
    &--title {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
      margin-bottom: 4px;
    }
  }
}
</style>
