<template>
  <button class="dh-btn btn-secondary" :class="{ reverse, hiddenBorder }" :disabled="disabled" @click="$emit('click')">
    <label class="DSwitch" :class="{ disabled }">
      <input type="checkbox" :checked="value" disabled readonly />
      <span class="DSwitch__slider"></span>
    </label>
    <span> {{ btnTxt }}</span>
  </button>
</template>
<script>
export default {
  name: "DActivateSwitch",
  props: {
    disabled: Boolean,
    value: Boolean,
    reverse: {
      type: Boolean,
      default: false,
    },
    hiddenBorder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    btnTxt() {
      return this.value ? this.$t("all.active") : this.$t("all.inactive");
    },
  },
};
</script>
<style lang="scss" scoped>
.DSwitch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 16px;
  --cursort-type: pointer;

  &.disabled {
    --cursort-type: not-allowed;
  }
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  &__slider {
    cursor: var(--cursort-type);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #bababa;
    transition: 0.4s;
    border-radius: 16px;
    &:before {
      position: absolute;
      content: "";
      height: 12px;
      width: 12px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }
  }

  input:checked + .DSwitch__slider {
    background-color: var(--primary-color);
  }

  input:focus + .DSwitch__slider {
    box-shadow: 0 0 1px var(--primary-color);
  }

  input:checked + .DSwitch__slider:before {
    transform: translateX(16px);
  }
}
.dh-btn {
  display: flex;
  flex-direction: row;
  &.reverse {
    flex-direction: row-reverse;
  }
  &.hiddenBorder {
    border: none;
  }
}
</style>
