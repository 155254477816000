import { ANNOTATION_TYPE } from "@/constants/annotationStatus";
import { uploadToCloudStorageByFileType } from "@/server/upload-server";

export const getUploadedImageById = async (annotations) => {
  try {
    const imageFilesToUpload = await annotations.reduce(async (result, { stepId, elements }) => {
      const updatedResult = await result;
      const elementResults = await Promise.all(
        elements.map(async (element) => {
          if (element.type !== ANNOTATION_TYPE.IMAGE || !element.imageUrl.startsWith("blob:")) return {};

          const blob = await fetch(element.imageUrl).then((res) => res.blob());
          return {
            [element.id]: new File([blob], `${stepId}-${element.id}.jpg`, {
              type: "image/jpeg",
            }),
          };
        })
      );
      return { ...updatedResult, ...Object.assign({}, ...elementResults) };
    }, Promise.resolve({}));

    const uploadedResult = await Promise.all(
      Object.entries(imageFilesToUpload).map(([id, file]) =>
        uploadToCloudStorageByFileType("annotationImage", file, {}).then((url) => ({ id, url }))
      )
    );

    const uploadedImageById = uploadedResult.reduce((acc, { id, url }) => ({ ...acc, [id]: url }), {});
    return { ok: true, uploadedImageById };
  } catch (e) {
    console.error("getUploadedImageById failed", e);
    return { ok: false, uploadedImageById: {} };
  }
};
