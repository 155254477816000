import Vue from "vue";
import { getReactionSetByEnv, getFilteredUserReactionsData } from "@/js/reactions/reactions.js";
import { updateWorkflowReaction, deleteWorkflowReaction, fetchWorkflowReactions } from "@/server/api-server";
import { notifyWorkflowReactionRequest } from "@/server/notifications-server.js";

export const namespaced = true;

export const state = {
  reactionWorkflowId: "",
  reactionSet: [],
  currentUserReaction: "",
  reactionsByWorkflow: [],
};

export const mutations = {
  STORE_REACTIONS_WORKFLOW_ID(state, workflowId) {
    state.reactionWorkflowId = workflowId;
  },
  CLEAN_REACTIONS_WORKFLOW_ID(state) {
    state.reactionWorkflowId = "";
  },
  UPDATE_CURRENT_USER_REACTION(state, reactionId) {
    state.currentUserReaction = reactionId;
  },
  CLEAR_CURRENT_USER_REACTION(state) {
    state.currentUserReaction = "";
  },
  STORE_REACTION_SET(state, reactionSet = []) {
    state.reactionSet = reactionSet;
  },
  STORE_REACTIONS_BY_WORKFLOW(state, reactions) {
    state.reactionsByWorkflow = reactions;
  },
  CLEAN_REACTIONS_BY_WORKFLOW(state) {
    state.reactionsByWorkflow = [];
  },
  REMOVE_REACTION_BY_USER_ID(state, currentUid) {
    const index = state.reactionsByWorkflow.findIndex((reaction) => reaction.uid === currentUid);
    if (index === -1) {
      console.warn("Not found mapping user when removing reaction");
      return;
    }
    state.reactionsByWorkflow.splice(index, 1);
    state.currentUserReaction = "";
  },
};

export const actions = {
  storeReactionSet({ commit, state }) {
    if (state.reactionSet.length > 0) return;
    const allReactions = Vue.prototype.$settings["workflow-reaction"] || [];
    const reactionSet = getReactionSetByEnv({ allReactions });
    commit("STORE_REACTION_SET", reactionSet);
  },
  cleanReactions({ commit }) {
    commit("CLEAN_REACTIONS_BY_WORKFLOW");
    commit("CLEAR_CURRENT_USER_REACTION");
    commit("CLEAN_REACTIONS_BY_WORKFLOW");
  },
  async fetchReactionsByWorkflowId({ commit, state }, { workflowId }) {
    const currentUid = Vue.prototype.$user.uid;
    commit("STORE_REACTIONS_WORKFLOW_ID", workflowId);
    const { ok, data, errorMessage } = await fetchWorkflowReactions({ workflowId });
    if (ok) {
      const allUserReactions = data.items;
      const filteredReactions = getFilteredUserReactionsData({ reactionSet: state.reactionSet, allUserReactions });
      commit("CLEAN_REACTIONS_BY_WORKFLOW");
      commit("STORE_REACTIONS_BY_WORKFLOW", filteredReactions);
      const foundCurrentUserReaction = filteredReactions.find((reaction) => reaction.uid === currentUid);
      const currentUserReaction =
        foundCurrentUserReaction && foundCurrentUserReaction.reaction ? foundCurrentUserReaction.reaction : "";
      commit("UPDATE_CURRENT_USER_REACTION", currentUserReaction);
    } else {
      console.error("Faild to fetch Reactions", errorMessage);
    }
  },
  async updateReactionByWorkflowId({ dispatch }, payload = { workflowId, reactionId, vueInstanceRef }) {
    const { ok, errorMessage } = await updateWorkflowReaction({
      workflowId: payload.workflowId,
      reaction: payload.reactionId,
    });
    if (ok) {
      await dispatch("fetchReactionsByWorkflowId", { workflowId: payload.workflowId });
      // send notification to workflow creator
      await notifyWorkflowReactionRequest({
        vueInstanceRef: payload.vueInstanceRef,
        workflowId: payload.workflowId,
      });
    } else {
      console.error("Faild to update Reaction", errorMessage);
    }
  },
  async deleteReactionByWorkflowId({ commit, dispatch }, payload = { workflowId }) {
    const { ok, errorMessage } = await deleteWorkflowReaction({
      workflowId: payload.workflowId,
    });
    if (ok) {
      const currentUid = Vue.prototype.$user.uid;
      commit("REMOVE_REACTION_BY_USER_ID", currentUid);
    } else {
      console.error("Faild to delete Reaction", errorMessage);
    }
  },
};
