<template>
  <svg width="24" height="24" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 12H7V2H10V3C10 3.5625 10.4375 4 11 4C11.5312 4 12 3.5625 12 3V1C12 0.46875 11.5312 0 11 0H1C0.4375 0 0 0.46875 0 1V3C0 3.5625 0.4375 4 1 4C1.53125 4 2 3.5625 2 3V2H5V12H4C3.4375 12 3 12.4688 3 13C3 13.5625 3.4375 14 4 14H8C8.53125 14 9 13.5625 9 13C9 12.4688 8.53125 12 8 12ZM19 6H11C10.4375 6 10 6.46875 10 7V8C10 8.53125 10.4375 9 11 9C11.5312 9 12 8.53125 12 8H14V12H13.5C12.9375 12 12.5 12.4375 12.5 13C12.5 13.5312 12.9375 14 13.5 14H16.5C17.0312 14 17.5 13.5312 17.5 13C17.5 12.4375 17.0312 12 16.5 12H16V8H18C18 8.53125 18.4375 9 19 9C19.5312 9 20 8.53125 20 8V7C20 6.46875 19.5312 6 19 6Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "DIconFontSize",
};
</script>

<style scoped></style>
