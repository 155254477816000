<template>
  <d-button
    round
    class="text-capitalize mx-auto"
    color="#4689f4"
    block
    dark
    :disabled="disabled"
    :style="{ 'max-width': width }"
    @click="$router.push({ name: REDIRECT_TO_STUDIO_WORKSPACE.name, params: { workspaceId } })"
    >{{ gen2BtnText && getter_is_gen2 ? gen2BtnText : $t(REDIRECT_TO_STUDIO_WORKSPACE.i18nKey) }}</d-button
  >
</template>
<script>
import { mapGetters } from "vuex";
import { REDIRECT_TO_STUDIO_WORKSPACE } from "@/constants/studio-version";
export default {
  name: "DBackToStudioWorkspaceBtn",
  props: {
    disabled: {
      type: Boolean,
      default: () => false,
    },
    gen2BtnText: {
      type: String,
    },
    width: {
      type: Number | String,
      required: false,
    },
    workspaceId: {
      type: String,
      required: false,
    },
  },
  data: function () {
    return {
      REDIRECT_TO_STUDIO_WORKSPACE,
    };
  },
  computed: {
    ...mapGetters(["getter_is_gen2"]),
  },
};
</script>
