import axios from "axios";
import environment from "../../env";
import { apiWrapperGenerator } from "./lib";

function getBaseUrl() {
  // NOTICE: this API is not support china version, so the value is null
  return environment.backendType === "firebase"
    ? environment.service[environment.env].visualizationServer
    : environment.service[environment.env]["china"].visualizationServer;
}

const visualizationServer = axios.create({
  baseURL: getBaseUrl(),
});

visualizationServer.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => Promise.resolve(error)
);
const apiWrapper = apiWrapperGenerator(visualizationServer);

export const postGetReducedEmbeddingMaven = (
  payload = {
    idToken: "",
    oid: "",
  }
) => apiWrapper("post", `/get-reduced-embedding-maven`, payload);

export const postSemanticSearchWorkflow = (
  payload = {
    idToken: "",
    oid: "",
    text: "",
    workspaces: [],
  }
) => apiWrapper("post", `/semanticSearch/workflow`, payload);

export const postQuestionInfo = (
  payload = {
    idToken: "",
    oid: "",
    questionId: "",
  }
) => apiWrapper("post", `/question`, payload);
export const postRelatedWorkflow = (
  payload = {
    idToken: "",
    oid: "",
    workflowId: "",
    workspaces: [],
  }
) => apiWrapper("post", `/related/workflow `, payload);

export const postSemanticSearchQuestion = (
  payload = {
    idToken: "",
    oid: "",
    text: "",
    workspaces: [],
  }
) => apiWrapper("post", `/semanticSearch/question`, payload);
