import dayjs from "dayjs";
export const getHourMinuteSecond = (sec) => {
  if (!sec || sec === Infinity) return "0:00:00";
  const hours = Math.floor(sec / 3600);
  sec %= 3600;
  const minutes = Math.floor(sec / 60);
  const seconds = Math.floor(sec - minutes * 60);
  const padding = minutes < 10 ? "0" : "";
  const paddingSec = seconds < 10 ? "0" : "";
  return hours + ":" + padding + minutes + ":" + paddingSec + seconds;
};

export const getDateTimeText = (date) => {
  return dayjs(date).format("MM/DD/YY h:mm A");
};

export const getDateText = (date) => {
  return dayjs(date).format("MM/DD/YYYY");
};

export const getShortDateText = (date) => {
  return dayjs(date).format("MM/DD/YY");
};

export const getAbbMonthDateText = (date) => {
  return dayjs(date).format("DD MMM YYYY, hh:mma");
};

export const getNowUnixTimestamp = () => {
  return dayjs().unix();
};

// 12, September 2024
export const getDateWithFullMonthName = (date) => {
  return dayjs(date).format("DD, MMMM YYYY");
};
