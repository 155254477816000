<template>
  <v-dialog :value="value" dark maxWidth="512">
    <v-card class="card">
      <button class="card__close-btn" @click="$emit('input', false)">
        <icon-base color="#9397A6" width="16" height="16">
          <d-icon-close2 />
        </icon-base>
      </button>
      <d-icon-popup-alert />
      <div class="card__body">
        <div class="card__title">{{ $t("alert.alert") }}</div>
        <div class="card__subtitle">{{ $t("builder.resetWorkflowAlert") }}</div>
        <v-checkbox
          class="card__checkbox"
          v-model="selectedKeepOldWorkflow"
          hide-details
          :label="$t('builder.resetWorkflowKeepCopy')"
        />
      </div>
      <div class="card__footer">
        <d-button
          outline
          round
          color="#4689f4"
          class="body-2 text-capitalize"
          analytics="confirm-close-buildermain"
          light
          @click="$emit('input', false)"
          >{{ $t("new.cancel") }}
        </d-button>
        <d-button
          dark
          round
          color="#4689f4"
          class="dh-editor-next body-2 text-capitalize"
          analytics="confirm-reset"
          light
          @click="handleResetClick"
        >
          {{ $t("builder.reset") }}
        </d-button>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import DIconClose2 from "@/components/icons/DIconClose2.vue";
import DIconPopupAlert from "@/components/icons/builder/DIconPopupAlert.vue";
import IconBase from "@/components/IconBase.vue";
export default {
  name: "BuilderResetWorkflowPopup",
  components: {
    DIconClose2,
    DIconPopupAlert,
    IconBase,
  },
  props: {
    value: {
      type: Boolean,
      request: true,
    },
  },
  data() {
    return {
      selectedKeepOldWorkflow: false,
    };
  },
  methods: {
    async handleResetClick() {
      this.$emit("resetWorkflow", this.selectedKeepOldWorkflow);
    },
  },
  watch: {
    value(value) {
      if (!value) {
        this.selectedKeepOldWorkflow = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.v-dialog {
  .card {
    background-color: #1d1e21;
    border: 1px solid #33353a;
    padding: 32px 24px;
    &__close-btn {
      position: absolute;
      top: 16px;
      right: 16px;
    }
    &__body {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 32px 0;
    }

    &__title {
      font-weight: bold;
      font-size: 20px;
    }

    &__subtitle {
      color: #9397a6;
      font-size: 18px;
      line-height: 21px;
      margin: 8px 0;
    }
    &__checkbox ::v-deep .v-icon {
      color: #33353a;
    }

    &__footer > button {
      height: 32px;
    }
  }
}
</style>
