<template>
  <v-list class="DPlayerSettingOptions" dark two-line>
    <v-list-tile
      class="DPlayerSettingOptions__option"
      :class="{ 'DPlayerSettingOptions__option--selected': option[valueAttr] === currentValue }"
      v-for="option in options"
      :key="option[valueAttr]"
      @click="
        currentValue = option[valueAttr];
        $emit('change-value', { value: option[valueAttr] });
      "
    >
      <v-list-tile-content
        class="DPlayerSettingOptions__label"
        :class="{ 'DPlayerSettingOptions__label--selected': option[valueAttr] === currentValue }"
      >
        {{ option[labelAttr] }}
      </v-list-tile-content>
      <v-list-tile-action v-if="option[valueAttr] === currentValue">
        <icon-base color="#4689F3" width="14" height="14">
          <d-icon-done />
        </icon-base>
      </v-list-tile-action>
    </v-list-tile>
  </v-list>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import DIconDone from "@/components/icons/DIconDone.vue";
export default {
  name: "DPlayerSettingOptions",
  components: {
    IconBase,
    DIconDone,
  },
  props: {
    options: {
      type: Array,
      require: true,
    },
    valueAttr: {
      type: String,
    },
    labelAttr: {
      type: String,
    },
    defaultValue: {
      type: [String, Number],
    },
  },
  data() {
    return {
      currentValue: "",
    };
  },
  created() {
    this.currentValue = this.defaultValue;
  },
};
</script>
<style lang="scss" scoped>
.DPlayerSettingOptions {
  // Due to production not working, need to add important
  background: transparent !important;
  &__option ::v-deep .v-list__tile {
    margin-bottom: 8px;
    border-radius: 4px;
    height: 40px;
    padding: 6px 25px 6px 40px;
    &:hover {
      background: #28292e !important;
      .DPlayerSettingOptions__label {
        color: #ffffff;
      }
    }
  }
  &__option--selected ::v-deep .v-list__tile {
    color: #4689f3;
    background: #28292e;
  }
  &__label {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #9397a6;
    &--selected {
      color: #ffffff;
    }
  }
}
</style>
