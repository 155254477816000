import { i18n } from "@/main";
import { getExportProcessesByPagination } from "@/server/rustici-server.js";

export const namespaced = true;

export const state = {
  totalCount: 0,
  exportProcessesList: [],
};

export const getters = {
  getter_export_processes_list: (state) => state.exportProcessesList,
  getter_total_count: (state) => state.totalCount,
  getter_not_ready_export_processes_ids: (state) => {
    return state.exportProcessesList.reduce(
      (result, process) => (!["finished", "error"].includes(process.status) ? [...result, process.id] : result),
      []
    );
  },
};

export const mutations = {
  SET_EXPORT_PROCESSES_LIST(state, payload) {
    state.totalCount = payload.page.dataCount;
    state.exportProcessesList = payload.items;
  },
};

export const actions = {
  async fetchExportProcessesList({ commit, dispatch }, params) {
    const { ok, data } = await getExportProcessesByPagination(params);
    if (!ok) {
      dispatch(
        "global/openAlert",
        {
          message: i18n.t("admin.alertGetRusticiDownloadHistoryFailed"),
          type: "error",
        },
        { root: true }
      );
      return;
    }
    commit("SET_EXPORT_PROCESSES_LIST", data);
  },
};
