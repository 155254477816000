<template>
  <div class="CommentReactionsPopup" @click="$emit('close-popup')">
    <div class="CommentReactionsPopup__popup" @click.stop>
      <div class="CommentReactionsPopup__title">
        {{ $t("player.userReaction") }}
        <button class="CommentReactionsPopup__title--close" @click="$emit('close-popup')">
          <icon-base color="#8D909F" height="16" width="16">
            <d-icon-close-2 />
          </icon-base>
        </button>
      </div>
      <div class="CommentReactionsPopup__tabs">
        <div
          v-for="(users, keyName) in reactions"
          :key="keyName + 'popup'"
          @click="currentReaction = keyName"
          class="CommentReactionsPopup__tabs--tab"
          :class="{ 'CommentReactionsPopup__tabs--currentTab': currentReaction === keyName }"
        >
          <div>{{ users[0].emojiChar }}</div>
        </div>
      </div>
      <div class="CommentReactionsPopup__list">
        <div v-for="user in userList" :key="user.uid" class="CommentReactionsPopup__list--item">
          <d-avatar :size="40" :userId="user.uid" />
          <div class="CommentReactionsPopup__list--itemText">
            {{ user.displayName }}
            <br />
            <!-- <span>Product Assurance Administrator</span> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconBase from "@/components/IconBase.vue";
import DIconClose2 from "@/components/icons/DIconClose2.vue";
import DAvatar from "@/components/ui_components/DAvatar.vue";

export default {
  name: "CommentReactionsPopup",
  props: {
    reactions: {
      type: Object,
      default: () => {},
    },
  },
  components: { IconBase, DIconClose2, DAvatar },
  created() {
    this.currentReaction = Object.keys(this.reactions)[0];
  },
  data() {
    return {
      currentReaction: "",
    };
  },
  methods: {},
  computed: {
    userList() {
      return this.reactions[this.currentReaction];
    },
  },
};
</script>

<style lang="scss" scoped>
.CommentReactionsPopup {
  z-index: 1;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  &__popup {
    position: absolute;
    max-height: 580px;
    height: 50%;
    width: 100%;
    left: 0;
    bottom: 0;
    background: #1d1e21;
    border-radius: 16px 16px 0px 0px;
  }
  &__title {
    height: 60px;
    width: 100%;
    padding: 30px 16px;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #9397a6;
    text-transform: uppercase;
    &--close {
      position: absolute;
      height: 24px;
      width: 24px;
      right: 16px;
      top: 26px;
    }
  }
  &__tabs {
    height: 56px;
    width: 100%;
    padding: 0 16px;
    background: #28292e;
    display: flex;
    justify-content: space-between;
    overflow: auto;
    &--tab {
      height: 100%;
      line-height: 24px;
      font-size: 24px;
      min-width: 48px;
      margin-right: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: transparent 2px solid;
      cursor: pointer;
    }
    &--currentTab {
      border-bottom: #4689f3 2px solid;
    }
  }
  &__tabs::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  &__list {
    overflow: auto;
    height: calc(100% - 116px);
    padding: 12px 0;
    &--item {
      display: flex;
      padding: 12px 16px;
      align-items: center;
      justify-content: center;
    }
    &--itemText {
      width: 100%;
      margin-left: 16px;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      span {
        margin-top: 4px;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: #919191;
      }
    }
  }
}
</style>
