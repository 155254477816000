export const store = {
  namespaced: true,
  state: {
    has_new_skill: false,
  },
  mutations: {
    TOGGLE_NOTIFICATIONS_FOR_NEW_SKILLS(state, { hasNewSkill }) {
      // [SD2-100] TODO: Waiting for the conclusion on how to show the blue dot
      // state.has_new_skill = hasNewSkill;
    },
  },
};
