<template lang="html">
  <section class="mixin-schedule"></section>
</template>

<script lang="js">
import MixinDB from "./MixinDB.vue";
  export default  {
    name: 'mixin-schedule',
    props: [],
    mixins: [MixinDB],
    mounted() {

    },
    data() {
      return {

      }
    },
    methods: {
      createSubtitles(language, stepId) {
        return new Promise(async function(resolve, reject){
          if (language && stepId) {
            const translationId = this.generateDocId("translations");
            const data = {
              id: translationId,
              languageCode: language,
              source: language,
              level: "STEP",
              organization: this.$store.state.userProfile[0].organization,
              stepId: stepId,
              status: "scheduled",
              subtitlesOnly: true,
              errorCode: 0,
              backendType: this.$backendType,
              timestamp: await this.getServerTimestamp()
            };
            this.addDocument("translations", translationId, data)
              .then(() => {
                resolve();
              })
              .catch(reject);
          }else{
            reject();
          }
        }.bind(this))

      },
    },
    computed: {

    }
}
</script>

<style scoped>
.mixin-error {
}
</style>
