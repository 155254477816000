import axios from "axios";
import environment from "../../env";
import store from "@/store";
import services from "@/constants/services.js";
import { getBrowserVersion } from "@/js/getBrowserVersion.js";
import { servicesMapping } from "@/js/error-log/error-log.js";

function getBaseUrl() {
  return environment.backendType === "firebase"
    ? environment.service[environment.env].analytics
    : environment.service[environment.env]["china"].analytics;
}

const errorLogServer = axios.create({
  baseURL: `${getBaseUrl()}v1`,
});

errorLogServer.interceptors.request.use(
  async (config) => {
    config.headers["x-server-token"] = "b7143b2638ceac0f4c5658a10e67466a";
    return config;
  },
  (error) => Promise.resolve(error)
);

export async function recordErrorLog(params) {
  return await errorLogServer.post("/core-features/error-log", params);
}

function getFunctionNameFromErrorStack(error) {
  const stackLine = error.stack?.split("\n")[1];
  const matchResult = stackLine.match(/at\s+(?:[\w\.]*\.)?(\w+)\s+\(/);
  return matchResult ? matchResult[1] : "";
}

export async function loggingError(error) {
  try {
    const service = servicesMapping[error.response?.config?.baseURL] || "mvpVue";
    const { serviceName, serviceModule } = services[service];
    const functionName = error.stack ? getFunctionNameFromErrorStack(error) : "";
    const errorMessage = error.response?.data?.message?.trim() || error.message;
    const message = functionName ? `[${functionName}] ${errorMessage}` : `${errorMessage}`;
    const deviceInfo = getBrowserVersion();
    const { id: uid, organization } = store?.getters?.user_profile_list[0] || {};
    const code = error.response?.data?.code || 50;
    await recordErrorLog({
      serviceName,
      serviceModule,
      code,
      ...(uid && organization && { uid, organization }),
      deviceInfo,
      message,
    });
  } catch (error) {
    console.log(error);
  }
}
