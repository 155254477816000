<template>
  <video
    ref="videoPlayer"
    @timeupdate="$emit('timeupdate')"
    @ended="$emit('ended')"
    @loadeddata="$emit('loadeddata')"
    @waiting="$emit('waiting')"
    @canplay="$emit('canplay')"
    @playing="$emit('playing')"
    v-on="$listeners"
    :muted="muted"
    :src="source"
    :poster="signedPoster"
    :crossorigin="crossorigin"
    :style="customStyle"
    :preload="preload"
    :width="width"
  ></video>
</template>

<script>
import { checkIsSignedUrl, getSignedURL, getSignedUrlByRez } from "@/server/sign-server.js";
import { getImageBlob, getSignedImageUrl } from "@/js/common/imageLib.js";
import { loggingError } from "@/server/error-log-server.js";

export default {
  name: "d-video-secure",
  props: {
    muted: {
      type: Boolean,
      default: () => false,
    },
    src: {
      type: String,
      default: "",
    },
    signedSrc: {
      type: Object,
      default: () => {},
    },
    poster: {
      type: String,
      default: "",
    },
    crossorigin: {
      type: String,
      default: "",
    },
    customStyle: {
      type: Object,
      default: () => {},
    },
    preload: {
      type: String,
      default: "auto",
    },
    width: {
      type: String,
      default: "100%",
    },
  },
  watch: {
    src: function () {
      this.getSource(this.src);
    },
    signedSrc: function () {
      this.getSignedSource(this.signedSrc);
    },
    poster: function () {
      this.getSignedPoster(this.poster);
    },
  },
  mounted() {
    this.getSignedSource(this.signedSrc);
    this.getSignedPoster(this.poster);

    const isNeedTryOtherAvailableVideoSource = !this.signedSrc;
    if (isNeedTryOtherAvailableVideoSource) {
      this.tryToLoadAvailiableVideoSource({
        src: this.src,
      });
    }
  },
  data() {
    return {
      source: "",
      previousSignedSourceId: "",
      signedPoster: "",
    };
  },
  methods: {
    tryToLoadAvailiableVideoSource({ src }) {
      if (src) {
        this.getSource(this.src);
      }
    },
    async getSource(src) {
      let videoSource = src;

      const isNeedSigned = !!src && src.startsWith("gs://");
      if (isNeedSigned) {
        videoSource = await getSignedURL({ src });
        const isVideoSourceOutDate = src !== this.src;
        if (isVideoSourceOutDate) {
          return;
        }
      }

      this.source = videoSource;
    },
    async getSignedSource(signedSrc) {
      if (!signedSrc) {
        return;
      }
      //prevent repeated signing for the same video
      if (this.previousSignedSourceId == signedSrc.id) {
        return;
      }
      this.previousSignedSourceId = signedSrc.id;
      try {
        const result = await getSignedUrlByRez(signedSrc.type, signedSrc.id, signedSrc.rez);
        if (result.ok) {
          this.source = result.url;
          return;
        }
        const isSigned = checkIsSignedUrl(signedSrc.notTranscodedVideo);
        if (isSigned) {
          this.source = signedSrc.notTranscodedVideo;
          return;
        }
        const videoSource = await getSignedURL({ src: signedSrc.notTranscodedVideo });
        this.source = videoSource;
      } catch (e) {
        this.source = "";
        console.error("Could not get signed URL", e);
        loggingError(e);
      }
    },
    play() {
      var playPromise = this.$refs.videoPlayer.play();
      if (playPromise !== undefined) {
        playPromise.catch((error) => loggingError(error));
      }
    },
    pause() {
      this.$refs.videoPlayer.pause();
    },
    restartVideo() {
      this.pause();
      this.$refs.videoPlayer.currentTime = 0;
    },
    getCurrentTime() {
      return this.$refs.videoPlayer.currentTime;
    },
    setCurrentTime(currentTime) {
      this.$refs.videoPlayer.currentTime = currentTime;
    },
    load() {
      this.$refs.videoPlayer.load();
    },
    clearPreviousSrc() {
      this.source = "";
      this.previousSignedSourceId = "";
    },
    async getSignedPoster(src) {
      try {
        if (!src) {
          return;
        }
        const signedUrl = await getSignedImageUrl(src);
        const { ok, url: blobUrl } = await getImageBlob(signedUrl);
        this.signedPoster = ok ? blobUrl : signedUrl;
      } catch (e) {
        this.signedPoster = "";
        loggingError(e);
      }
    },
  },
  computed: {},
};
</script>

<style scoped></style>
