import { PROGRESS_ICON_STATUS } from "@/constants/workflowStatus";

export const SKILL_QUIZ_STATUS = {
  PASSED: "Success",
  FAILED: "Did not pass",
  INCOMPLETE: "Incomplete",
};

export const SKILL_DETAIL_QUIZ_STATUS = {
  PASSED: "You passed",
  FAILED: "Did not pass",
};

export const QUIZ_STATUS = {
  FAILED: "failed",
  PASSED: "passed",
};

export const SCREEN_TYPE = {
  CLOSE: "CLOSE",
  TAKE_QUIZ: "TAKE_QUIZ",
  REVIEW_QUIZ: "REVIEW_QUIZ",
};
