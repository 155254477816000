export const MAVEN_ROUTE_NAME = {
  MAVEN_CHAT: "MavenChat",
  MAVEN_MAP: "map",
  MAVEN: "maven",
};
export const MavenRoute = [
  {
    path: `/${MAVEN_ROUTE_NAME.MAVEN_CHAT}`,
    name: MAVEN_ROUTE_NAME.MAVEN_CHAT,
    props: true,
    component: () => import("@/components/DMavenChat/MavenChat.vue"),
    meta: { auth: true },
  },
  {
    path: `/${MAVEN_ROUTE_NAME.MAVEN_MAP}`,
    name: MAVEN_ROUTE_NAME.MAVEN_MAP,
    props: true,
    component: () => import("@/components/DMavenMap/MavenMap.vue"),
    meta: { auth: true },
  },
  {
    path: "/maven",
    name: "maven",
    props: true,
    component: () => import("@/components/DMaven2.0/Maven.vue"),
    meta: { auth: true },
  },
];
export const mavenAllowedListInMobile = [MAVEN_ROUTE_NAME.MAVEN_CHAT];
export default MavenRoute;
