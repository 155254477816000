export const namespaced = true;
export const state = {
  consentsPopupType: "banner",
  showAcceptCookiePopup: false,
};

export const actions = {
  openUserConsentsPopup({ commit }, { type }) {
    commit("OPEN_USER_CONSENTS_POPUP", { type });
  },
  closeUserConsentsPopup({ commit }) {
    commit("CLOSE_USER_CONSENTS_POPUP");
  },
};

export const mutations = {
  OPEN_USER_CONSENTS_POPUP(state, { type }) {
    state.consentsPopupType = type;
    state.showAcceptCookiePopup = true;
  },
  CLOSE_USER_CONSENTS_POPUP(state) {
    state.showAcceptCookiePopup = false;
  },
};
