var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"BuilderShareDownload"},[_c('div',{staticClass:"BuilderShareDownload__container"},[_c('div',{staticClass:"BuilderShareDownload__container--settings",class:{ 'move-left': !_vm.isShowSettingLists }},[_c('p',{staticClass:"BuilderShareDownload__container--settings-title"},[_vm._v("\r\n          "+_vm._s(_vm.$t("player.settings"))+"\r\n        ")]),_vm._v(" "),_vm._l((_vm.downloadSettings),function(ref){
var text = ref.text;
var icon = ref.icon;
var value = ref.value;
var type = ref.type;
var lists = ref.lists;
return _c('div',{key:type,class:_vm.judgeSettingClassName(type),on:{"click":function($event){_vm.handleClickSetting({ lists: lists, type: type, text: text, value: value })}}},[_c(icon,{tag:"component"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(text))]),_vm._v(" "),_c('span',{staticClass:"value"},[_vm._v(_vm._s(value))]),_vm._v(" "),_c('v-icon',{attrs:{"color":"white"}},[_vm._v("navigate_next")])],1)})],2),_vm._v(" "),_c('div',{staticClass:"BuilderShareDownload__container--lists",class:{ 'move-right': !_vm.isShowSettingLists }},[_c('div',{staticClass:"BuilderShareDownload__container--lists-title"},[_c('button',{on:{"click":function($event){_vm.selectedType = ''}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("navigate_before")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.listTile))])],1)]),_vm._v(" "),_c('div',{staticClass:"lists"},_vm._l((_vm.selectedLists),function(ref){
var text = ref.text;
var value = ref.value;
return _c('div',{key:value,class:{ isSelected: _vm.downloadVideoConfig[_vm.selectedType] === value },on:{"click":function($event){_vm.handleClickList(value)}}},[_vm._v("\r\n            "+_vm._s(text)+"\r\n            "),(_vm.downloadVideoConfig[_vm.selectedType] === value)?_c('v-icon',{attrs:{"color":"#4689F3"}},[_vm._v("done")]):_vm._e()],1)}))])])])}
var staticRenderFns = []

export { render, staticRenderFns }