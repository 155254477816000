export const IS_SUPPORT_FULLSCREEN = document.fullscreenEnabled;

export const togglePlayerFullscreen = () => {
  const element = document.documentElement;
  if (element.requestFullscreen) element.requestFullscreen();
  else if (element.mozRequestFullScreen) element.mozRequestFullScreen();
  else if (element.webkitRequestFullscreen) element.webkitRequestFullscreen();
  else if (element.msRequestFullscreen) element.msRequestFullscreen();
};

export const closePlayerFullscreen = () => {
  if (!IS_SUPPORT_FULLSCREEN) {
    console.warn("It's not support fullscreen!");
    return;
  }

  if (document.cancelFullScreen) document.cancelFullScreen();
  else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
  else document.webkitCancelFullScreen();
};

export const isShowingFullscreen = () => {
  const fullscreen =
    document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.mozFullScreenElement ||
    document.msFullscreenElement ||
    null;
  return fullscreen !== null;
};
