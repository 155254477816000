<template>
  <div v-if="value" class="DPopupPlayer" @click="$emit('input', false)">
    <div class="DPopupPlayer__content">
      <div class="DPopupPlayer__player" @click.stop>
        <button class="DPopupPlayer__closeBtn" @click="$emit('input', false)">
          <icon-base color="white" height="24" width="24">
            <d-icon-close-2 />
          </icon-base>
        </button>
        <div class="DPopupPlayer__player--inner">
          <iframe
            width="100%"
            height="100%"
            :src="videoLink"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            class="DPopupPlayer__player--iframe"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import IconBase from "@/components/IconBase.vue";
import DIconClose2 from "@/components/icons/DIconClose2.vue";

export default {
  name: "DPopupPlayer",
  props: {
    value: Boolean, //for v-model value
    videoLink: String,
  },
  components: {
    IconBase,
    DIconClose2,
  },
};
</script>

<style lang="scss" scoped>
.DPopupPlayer {
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  overflow: auto;
  &:hover::-webkit-scrollbar-thumb {
    background-color: #2c2c2c;
  }
  &__content {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 764px;
    min-width: 1064px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__player {
    position: relative;
    min-width: 1024px;
    width: 90%;
    &--inner {
      position: relative;
      width: 100%;
      padding-top: 56.25%;
      /* 16:9 */
      height: 0px;
      border-radius: 4px;
      overflow: hidden;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
      background-color: #0c0c0e;
    }
    &--iframe {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
  &__closeBtn {
    position: absolute;
    top: 40px;
    right: -40px;
    height: 28px;
    width: 28px;
  }
}
</style>
